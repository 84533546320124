import React, {useEffect, useState} from 'react';
import {loadStripe, Stripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import {CenteredSpinner, FadeIn} from '@/components/animation';
import {Col, Container, Row} from 'react-bootstrap';
import {config} from '@/config';
import UpdatePaymentMethodForm from '@/pages/Settings/components/UpdatePaymentMethodForm';

function UpdatePaymentMethod() {
    const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>>();

    useEffect(() => {
        setStripePromise(loadStripe(config.stripeClientApiKey));
    }, []);

    if (!stripePromise) {
        return <CenteredSpinner/>;
    }

    return (
        <FadeIn>
            <Container fluid>
                <Row>
                    <Col lg={{offset: 4, span: 4}}
                         md={{offset: 3, span: 6}}>
                        <Elements stripe={stripePromise}>
                            <UpdatePaymentMethodForm/>
                        </Elements>
                    </Col>
                </Row>
            </Container>
        </FadeIn>
    );
}

export default UpdatePaymentMethod;
