import React, {useCallback, useEffect, useMemo, useState} from 'react';
import collect from 'collect.js';
import {FieldProps, useFormikContext} from 'formik';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import {SelectGroup, SelectOption, SelectOptions} from '@shared/responseModels';
import classNames from 'classnames';

declare type SelectOptionsOrGroups<SelectOption, SelectGroup> = readonly (SelectOption | SelectGroup)[];

interface Props {
    className?: string;
    disabled?: boolean;
    placeholder: string;
    field: string;
    form: string;
    error: string;
    options: SelectOptionsOrGroups<SelectOption, SelectGroup>;
    isMulti: boolean;
    isGrouped: boolean,
    isCreatable?: boolean;
    menuPlacement?: 'auto' | 'top' | 'bottom';
}

function FormikSelect(
    {
        className,
        disabled,
        placeholder,
        field,
        form,
        options,
        isMulti = false,
        isGrouped = false,
        isCreatable = false,
        menuPlacement = 'auto',
    }: Props & FieldProps,
) {
    const {errors, touched} = useFormikContext();
    const [internalOptions, setInternalOptions] = useState(options || []);

    useEffect(() => {
        setInternalOptions(options);
    }, [options])

    const flattenedOptions = useMemo(() => {
        return !isGrouped ?
            internalOptions as SelectOption[] :
            collect(internalOptions)
                .flatMap((group) => ((group as SelectGroup).options))
                .all();
    }, [internalOptions, isGrouped]);

    const onChange = (option: unknown) => {
        const changedValue = isMulti ?
            (option as SelectOption[]).map((item: SelectOption) => item.value) :
            (option as SelectOption).value;
        form.setFieldValue(field.name, changedValue);
    };

    const getValue = () => {
        if (!internalOptions || !field.value) {
            return isMulti ? [] : ('');
        }

        return isMulti ?
            (flattenedOptions as SelectOption[]).filter((option: SelectOption) => field.value.indexOf(option.value) >= 0) :
            (flattenedOptions as SelectOption[]).find((option: SelectOption) => option.value === field.value);
    };

    const reactSelectProps = () => {
        return {
            menuPlacement,
            className: classNames(className),
            name: field.name,
            value: getValue(),
            onChange,
            placeholder,
            options: internalOptions,
            isMulti,
            isDisabled: disabled,
            styles: {
                control: (css: object) => ({
                    ...css,
                    borderRadius: '20px',
                    minHeight: '34.4px',
                    height: isMulti ? 'inherit' : '34.4px',
                    padding: isMulti ? '10px' : '0px',
                    boxShadow: 'none',
                }),
                multiValue: (css: object, state: object) => ({
                    ...css,
                    borderRadius: '20px',
                }),
                valueContainer: (css: object, state: object) => ({
                    ...css,
                    padding: '0px 20px',
                    marginBottom: '6px',
                    borderRadius: '50px',
                }),
                menu: (css: object) => ({
                    ...css,
                    zIndex: 9999,
                    width: 'max-content',
                    minWidth: '100%',
                }),
                menuPortal: (css: object) => ({
                    ...css,
                    zIndex: 9999,
                }),
            },
        };
    };

    const createOption = (label: string) => ({
        label,
        value: 0,
    });

    const onCreate = useCallback( (inputValue: string) => {
        const newOption = createOption(inputValue);
        const updatedOptions = (internalOptions as SelectOptions);
        updatedOptions.push(newOption);
        setInternalOptions(updatedOptions);
        onChange([field.value, newOption]);
    }, []);

    return (
        <>
            {isCreatable ?
                <CreatableSelect onCreateOption={onCreate}
                                 {...reactSelectProps()}
                /> :
                <Select {...reactSelectProps()}/>
            }
            {/*This is a hack to get the react-bootstrap error message to appear*/}
            <input type="hidden"
                   className={errors[field.name] && touched[field.name] ? 'is-invalid' : ''}
                   id={field.name}
                   name={field.name}
                   value={field.value}
            />
        </>
    );
}

export default FormikSelect;
