import React, {useCallback, useEffect, useState} from 'react';
import {Button, ButtonToolbar, Stack} from 'react-bootstrap';
import {faCheck, faSpinner, faUndo} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {HeadShake} from '@/components/animation';

interface Props {
    formName?: string;
    confirmationButtonText?: string;
    disabled: boolean;
    isSubmitting: boolean;
    setConfirmationStatus?: (confirmationStatus: boolean) => void;
    resetForm?: () => void;
}

function SubmitOnConfirmButton({
    formName,
    confirmationButtonText,
    disabled,
    isSubmitting,
    setConfirmationStatus,
    resetForm,
}: Props) {
    confirmationButtonText ??= 'Save';
    const [isAskingForConfirmation, setIsAskingForConfirmation] = useState(false);

    const onClick = useCallback(() => {
        setIsAskingForConfirmation(true);
    }, []);

    const onCancel = useCallback(() => {
        setIsAskingForConfirmation(false);
    }, []);

    useEffect(() => {
        if (!isSubmitting) {
            setIsAskingForConfirmation(false);
        }
    }, [isSubmitting]);

    useEffect(() => {
        if (setConfirmationStatus) {
            setConfirmationStatus(isAskingForConfirmation);
        }
    }, [isAskingForConfirmation]);

    return (
        <div>
            {!isAskingForConfirmation ?
                <Stack direction="horizontal" gap={2}>
                    <Button onClick={onClick}
                            type="button"
                            disabled={disabled}>
                        <FontAwesomeIcon icon={faCheck} fixedWidth/>
                        <span className="px-1">{confirmationButtonText}</span>
                    </Button>

                    {resetForm &&
                        <Button variant="outline-secondary" onClick={resetForm}>
                            <FontAwesomeIcon icon={faUndo} fixedWidth/>
                            <span className="px-1">Reset</span>
                        </Button>
                    }
                </Stack> :
                <HeadShake>
                    <ButtonToolbar>
                        <Button className="me-1"
                                type="submit"
                                form={formName}
                                disabled={disabled}
                        >
                            Confirm?
                            {isSubmitting && <FontAwesomeIcon icon={faSpinner} spin fixedWidth className="ms-1"/>}
                        </Button>
                        <Button variant="link" onClick={onCancel}
                                className="text-decoration-none bg-white text-secondary border border-secondary border-2">
                            <span className="px-1">Cancel</span>
                        </Button>
                    </ButtonToolbar>
                </HeadShake>
            }
        </div>
    );
}

export default SubmitOnConfirmButton;
