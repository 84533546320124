import React, {PropsWithChildren, useEffect, useMemo, useState} from 'react';
import './text-preview.scss';
import {toShortDateString} from '@/services/textFormatting';
import parse from 'html-react-parser';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {useAppContext} from '@/AppContext';

interface Props {
    isMessagePreRendered?: boolean;
    shouldShowSignOff?: boolean;
    message?: string;
    disabled?: boolean;
    signOff?: string;
    signature?: string;
    greeting?: string;
    hasOffer?: boolean;
    offerExpirationDate?: Date;
    censoredMessage?: string;

    isTextWebLinkEnabled: boolean;
    textWebLinkUrl: string;
}

function TextPreview({
    isMessagePreRendered = false,
    shouldShowSignOff = true,
    children,
    disabled,
    signOff,
    signature,
    greeting,
    message,
    hasOffer,
    offerExpirationDate,
    censoredMessage,
    isTextWebLinkEnabled,
    textWebLinkUrl,
}: PropsWithChildren<Props>) {
    const {currentUser} = useAppContext();
    const [fullText, setFullText] = useState<string>();

    const firstName = useMemo(() => {
        return currentUser.firstName ?? 'Jeff';
    }, [currentUser]);

    const suffix = useMemo(() => {
        const optOutText = 'Txt STOP to unsubscribe';
        if (!shouldShowSignOff) {
            return optOutText;
        }
        const formattedSignOff = signOff ? `<br><br>${signOff}` : '';
        const formattedSignature = signature ? `<br>${signature}` : '';
        const suffix = `${formattedSignOff}${formattedSignature}<br><br>${optOutText}`;

        if (hasOffer && offerExpirationDate) {
            return `Valid till ${toShortDateString(offerExpirationDate.toISOString())} ${suffix}`;
        }

        return suffix;
    }, [signOff, signature, hasOffer, offerExpirationDate, shouldShowSignOff]);

    useEffect(() => {
        if (isMessagePreRendered) {
            setFullText(message);
            return;
        }

        const linkHref = textWebLinkUrl ? textWebLinkUrl : 'http://example.com';
        const linkText = textWebLinkUrl ? textWebLinkUrl : 'http://example.com';
        const webLinkUrl = isTextWebLinkEnabled ? `<a href="${linkHref}" target="_blank">${linkText}</a><br/><br/>` : '';

        const finalMessage = censoredMessage ? censoredMessage : message;
        const text = `${greeting ?? ''} ${firstName}, ${finalMessage} ${webLinkUrl}${suffix}`;
        const parsedText = parse(text);

        setFullText(parsedText as string);
    }, [isMessagePreRendered, censoredMessage, message, hasOffer, offerExpirationDate, isTextWebLinkEnabled]);

    return (
        <div className="text-preview p-2 mx-4">
            <div className="iphone-x">
                <div className="speaker">Speaker</div>
                <div className="camera">Camera</div>

                <div className="mx-auto"
                     style={{
                        width: '100%',
                        height: '100%',
                        borderRadius: '40px',
                        overflowY: 'scroll',
                    }}
                >
                    <div className="bg-light w-100 text-center border-bottom"
                         style={{
                            paddingTop: '4em',
                            paddingBottom: '0.4em',
                        }}>

                        <div>
                            <div className="d-inline-block">+1 (555) 555-0100</div>
                            <div className="d-inline-block text-muted">
                                <FontAwesomeIcon icon={faChevronRight} className="ms-1" size="2xs"/>
                            </div>
                        </div>
                    </div>
                    <div className="imessage" style={{height: '100%'}}>

                        {!!children &&
                            <p className="from-them no-tail emoji">
                                {children}
                            </p>
                        }
                        <p className="from-them margin-b_one"
                           style={{opacity: disabled ? 0.5 : 1, whiteSpace: 'pre-line'}}>
                            {fullText}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TextPreview;
