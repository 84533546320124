import React, {FormEvent, useCallback, useRef, useState} from 'react';
import {CardElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {Card, Form} from 'react-bootstrap';
import {useBusinessSettingsResource} from '@/services/api';
import {StripeCardElement, StripeError} from '@stripe/stripe-js';
import {SubmitOnConfirmButton} from '@/components/form';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleExclamation} from '@fortawesome/free-solid-svg-icons';

function UpdatePaymentMethodForm() {
    const businessSettingsResource = useBusinessSettingsResource();
    const stripe = useStripe();
    const elements = useElements();
    const [stripeError, setStripeError] = useState<StripeError>();
    const [isProcessing, setIsProcessing] = useState(false);
    const formRef = useRef(null);

    const handleSubmit = useCallback(async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (elements == null || stripe == null) {
            return;
        }

        setIsProcessing(true);
        const stripeCardElement = elements.getElement(CardElement) as StripeCardElement;
        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: 'card',
            card: stripeCardElement,
        });

        setStripeError(error);
        if (!error) {
            await businessSettingsResource.updatePaymentMethod(paymentMethod.id);
            stripeCardElement.clear();
        }
        setIsProcessing(false);
    }, [stripe, elements]);

    return (
        <Form ref={formRef} onSubmit={handleSubmit} noValidate>
            <Card className="shadow">
                <Card.Header>
                    <h4 className="fw-bold ms-3 mt-2 me-0 mb-1">Payment Method</h4>
                </Card.Header>
                <Card.Body>
                    <div className="my-4 mx-3">
                        <CardElement options={{hidePostalCode: true}}/>
                        {stripeError &&
                            <div className="text-danger mt-3">
                                <FontAwesomeIcon icon={faCircleExclamation} fixedWidth/>
                                <span>{stripeError.message}</span>
                            </div>
                        }
                    </div>
                </Card.Body>

                <Card.Footer>
                    <SubmitOnConfirmButton isSubmitting={isProcessing}
                                           disabled={!stripe || !elements || isProcessing}
                                           confirmationButtonText="Update"
                    />
                </Card.Footer>
            </Card>
        </Form>
    );
}

export default UpdatePaymentMethodForm;
