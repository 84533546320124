import React from 'react';
import classNames from 'classnames';
import {flexRender, Header, RowData} from '@tanstack/react-table';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSort, faSortAsc, faSortDesc} from '@fortawesome/free-solid-svg-icons';
import {If} from '@/components/container';
import {Stack} from 'react-bootstrap';
import {SelectOptions} from '@shared/responseModels';
import {SelectFilter} from '@/components/filters';

interface Props<TData> {
    header: Header<TData, unknown>;
    position: number;
    disabled: boolean;
}

export function DesktopHeader<TData extends RowData>({header, position, disabled}: Props<TData>) {
    const meta = header.column.columnDef?.meta;

    function onApplyFilter(selectedOptions: SelectOptions) {
        header.column.setFilterValue(selectedOptions);
    }

    return (
        <th key={header.id}
            colSpan={header.colSpan}
            style={{width: header.column.getSize()}}
            className={classNames('text-nowrap pe-3', position === 0 ? 'ps-3' : '')}
        >
            {header.isPlaceholder ? null : (
                <div className={classNames(
                    disabled ? 'text-white' : '',
                    header.column.getCanSort() ? 'select-none' : '',
                    !disabled && header.column.getCanSort() ? 'clickable' : '',
                    disabled ? 'disabled' : '',
                )}
                >
                    <Stack direction="horizontal" gap={1}>
                        <div onClick={header.column.getToggleSortingHandler()}>
                            {flexRender(
                                header.column.columnDef.header,
                                header.getContext(),
                            )}
                        </div>

                        <If isTrue={(meta?.discreteFilterOptions?.available?.length || 0) > 1}
                            as={SelectFilter}
                            available={meta?.discreteFilterOptions?.available ?? []}
                            selected={meta?.discreteFilterOptions?.selected ?? []}
                            onApplyFilter={onApplyFilter}
                        />

                        <If isTrue={header.column.getCanSort()}
                            onClick={header.column.getToggleSortingHandler()}>
                            <If isTrue={header.column.getIsSorted() === 'asc'}>
                                <FontAwesomeIcon icon={faSortAsc} fixedWidth/>
                            </If>
                            <If isTrue={header.column.getIsSorted() === 'desc'}>
                                <FontAwesomeIcon icon={faSortDesc} fixedWidth/>
                            </If>
                            <If isTrue={!header.column.getIsSorted()}>
                                <FontAwesomeIcon icon={faSort} fixedWidth/>
                            </If>
                        </If>
                    </Stack>
                </div>
            )}
        </th>
    );
}
