import React, {createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {cache} from '@/services';
import {
    BusinessSelectOption,
    BusinessSelectOptions,
    ConfigurationResponse,
    MeResponse,
    SelectOption,
    SelectOptions,
} from '@shared/responseModels';
import {getSidebarMenuItems, MenuItem} from '@/services/menu';
import {logger} from 'sparkloyalty-frontend/logging';
import {Country} from 'react-phone-number-input';
import {ConfigurationFields} from '@shared/responseModels/ConfigurationResponse';

interface Constants {
    unlayerProjectId: number;
    unknownValue: string;
    campaignImageMaxFileSizeInBytes: number;
    defaultMaxImageFileSizeInMegabytes: number;
}

interface Context {
    billing: {
        stripeCustomerPortalUrl: string,
    };
    customerSupport: {
        isHubSpotSupportChatEnabled: boolean,
        phoneNumberIsDisplayed: boolean,
        phoneNumber: string,
        emailAddress: string;
        customerPortalUrl: string;
        knowledgeBaseUrl: string;
    };
    currentUser: MeResponse;
    currentClientTimeZone: string;
    businessOptions: BusinessSelectOptions;
    selectedBusinessOption: BusinessSelectOption;
    setCurrentUser: (meResponse: MeResponse) => void;
    setCurrentClientTimeZone: (timezone: string) => void;
    setBusinessOptions: (businessOptions: BusinessSelectOptions) => void;
    setSelectedBusinessOption: (businessOption: BusinessSelectOption) => void;
    restoreBusinessOptionFromCache: (businessOptions: BusinessSelectOptions) => BusinessSelectOption;
    fields: ConfigurationFields;
    selectOptions: {
        businessCategory: SelectOptions,
        locationRoles: SelectOptions,
        milestoneTriggerOperator: SelectOptions,
    }
    constants: Constants;
    sidebarMenuItems: MenuItem[],
    imageUrls: {
        defaultRewardImage: string
    },
    internationalization: {
        phoneCountryCodes: Record<string, string>,
        defaultPhoneCountryCode: Country,
        defaultLocationCountryCode: string,
    },
    customerListSearchTerm: string | null,
    setCustomerListSearchTerm: (searchTerm: string) => void,
    campaignListSearchTerm: string | null,
    setCampaignListSearchTerm: (searchTerm: string) => void,
}

const AppContext = createContext<Context>({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    restoreBusinessOptionFromCache(businessOptions: BusinessSelectOptions): BusinessSelectOption {
        return undefined!;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setBusinessOptions(businessOptions: BusinessSelectOptions): void {
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setCurrentUser(meResponse: MeResponse): void {
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setSelectedBusinessOption(businessOption: BusinessSelectOption): void {
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setCurrentClientTimeZone(timezone: string): void {
    },
    billing: {
        stripeCustomerPortalUrl: '',
    },
    customerSupport: {
        isHubSpotSupportChatEnabled: false,
        phoneNumberIsDisplayed: false,
        phoneNumber: '',
        emailAddress: '',
        customerPortalUrl: '',
        knowledgeBaseUrl: '',
    },
    currentUser: {
        merchantUnlayerSignature: '',
        merchantId: -1,
        username: '',
        firstName: '',
        email: '',
        preferences: {
            hasAdvancedEditor: false,
            hasUnlayerEditor: false,
        },
        businesses: [],
    } as MeResponse,
    currentClientTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    businessOptions: [],
    selectedBusinessOption: {} as BusinessSelectOption,
    fields: {
        campaignType: {
            map: {},
            items: [],
        },
        country: {
            map: {},
            items: [],
        },
        usState: {
            map: {},
            items: [],
        },
        dateRange: {
            map: {},
            items: [],
        },
        loyaltyProgramAccountType: {
            map: {},
            items: [],
        },
        shopperLevel: {
            map: {},
            items: [],
        },
    },
    selectOptions: {
        businessCategory: [],
        locationRoles: [],
        milestoneTriggerOperator: [],
    },
    constants: {} as Constants,
    sidebarMenuItems: [],
    imageUrls: {
        defaultRewardImage: '',
    },
    internationalization: {
        phoneCountryCodes: {},
        defaultPhoneCountryCode: 'US',
        defaultLocationCountryCode: 'USA',
    },
    customerListSearchTerm: '',
    setCustomerListSearchTerm: (searchTerm: string) => {},
    campaignListSearchTerm: '',
    setCampaignListSearchTerm: (searchTerm: string) => {},
});

interface Props {
    configuration: ConfigurationResponse;
}

function AppContextFactory({configuration, children}: PropsWithChildren<Props>) {
    // Get initial context state from local storage.
    const defaultSelectedBusinessOption = {
        value: null,
        label: '',
        timezone: '',
        locations: [],
        permissions: [],
    } as unknown as BusinessSelectOption;

    const constants = useMemo(() => ({
        unlayerProjectId: configuration.unlayerProjectId,
        unknownValue: '-',
        campaignImageMaxFileSizeInBytes: configuration.campaignImage.maxFileSizeInBytes,
        defaultMaxImageFileSizeInMegabytes: parseFloat(
            (configuration.campaignImage.maxFileSizeInBytes / 1000000).toFixed(2),
        ),
    }), []);
    const [billing] = useState(configuration.billing);
    const [customerSupport] = useState(configuration.customerSupport);
    const [selectOptions] = useState(configuration.selectOptions);
    const [fields] = useState(configuration.fields);
    const [imageUrls] = useState({
        defaultRewardImage: configuration.imageUrls.defaultRewardImage,
    });
    const [internationalization] = useState(configuration.internationalization);
    const [currentClientTimeZone, setCurrentClientTimeZone] = useState(
        Intl.DateTimeFormat().resolvedOptions().timeZone,
    );
    const [currentUser, setCurrentUser] = useState<MeResponse>({
        username: '',
        firstName: '...',
        email: '',
        preferences: {
            hasAdvancedEditor: false,
            hasUnlayerEditor: false,
        },
        businesses: [],
    } as unknown as MeResponse);
    const [businessOptions, setBusinessOptions] = useState<BusinessSelectOptions>([]);
    const [selectedBusinessOption, setSelectedBusinessOption] = useState<BusinessSelectOption>(defaultSelectedBusinessOption);
    const [sidebarMenuItems, setSidebarMenuItems] = useState<MenuItem[]>([]);

    const [customerListSearchTerm, setCustomerListSearchTerm] = useState('');
    const [campaignListSearchTerm, setCampaignListSearchTerm] = useState('');

    useEffect(() => {
        if (!selectedBusinessOption.value) {
            return;
        }

        // Check if updated selectedBusinessOption differs from what is in the list.
        const indexOfUpdatedBusinessOption = businessOptions.findIndex((x: SelectOption) => {
            return x.value === selectedBusinessOption.value && selectedBusinessOption.label !== x.label;
        });

        if (indexOfUpdatedBusinessOption > -1) {
            // If business option was updated in the merchant dashboard, splice it into the business option list.
            businessOptions.splice(indexOfUpdatedBusinessOption, 1, selectedBusinessOption);
            setBusinessOptions(businessOptions);
            setSelectedBusinessOption(selectedBusinessOption);
        }

        // Update sidebar items.
        const sideBarMenuItems = getSidebarMenuItems(selectedBusinessOption || {});
        setSidebarMenuItems(sideBarMenuItems);

        // Cache selected business.
        const savedState = cache.get('AppContext');

        if (savedState?.selectedBusinessOption?.value !== selectedBusinessOption?.value || savedState?.currentClientTimeZone !== currentClientTimeZone) {
            cache.set('AppContext', {selectedBusinessOption, currentClientTimeZone});
            // Refresh the entire page when the selected business changes.
            window.document.getElementById('merchant-dashboard-app')?.remove();
            window.location.href = window.location.href.split('?')[0];
        }
    }, [selectedBusinessOption, currentClientTimeZone]);

    const restoreBusinessOptionFromCache = useCallback((businessOptions: BusinessSelectOptions) => {
        const defaultOption = businessOptions[0];
        try {
            const savedState = cache.get('AppContext');
            if (savedState?.currentClientTimeZone) {
                setCurrentClientTimeZone(savedState?.currentClientTimeZone);
            }
            if (savedState?.selectedBusinessOption) {
                if (savedState?.selectedBusinessOption.value === 0) {
                    return defaultOption;
                }
                const cachedBusinessOption = savedState.selectedBusinessOption;
                const indexOfCachedBusinessOption = businessOptions.findIndex((x: SelectOption) => {
                    return x.value === cachedBusinessOption.value;
                });

                if (indexOfCachedBusinessOption > -1) {
                    return businessOptions[indexOfCachedBusinessOption];
                }
            }
        } catch (error) {
            logger.error(error);
        }
        return defaultOption;
    }, []);

    const store = {
        billing,
        customerSupport,
        currentUser,
        businessOptions,
        selectedBusinessOption,
        setCurrentUser,
        setBusinessOptions,
        setSelectedBusinessOption,
        currentClientTimeZone,
        setCurrentClientTimeZone,
        fields,
        selectOptions,
        constants,
        sidebarMenuItems,
        restoreBusinessOptionFromCache,
        imageUrls,
        internationalization,
        // Search state
        customerListSearchTerm,
        setCustomerListSearchTerm,
        campaignListSearchTerm,
        setCampaignListSearchTerm,
    };

    return (<AppContext.Provider value={store}>{children}</AppContext.Provider>);
}

export default AppContextFactory;

export function useAppContext() {
    return useContext(AppContext);
}
