import React from 'react';
import {Field, Form, FormikProps} from 'formik';
import {Col, Container, FormControl, FormGroup, FormLabel, Row, Stack} from 'react-bootstrap';
import {AddressFields, BackButton, FormikSelect, SubmitOnConfirmButton} from '@/components/form';
import {PageFooter, PageHeader, SimpleCard} from '@/components/container';
import {useAppContext} from '@/AppContext';
import {LocationFormValues} from '@shared/requestSchemas';

export const LocationDetailsForm = ({
    handleSubmit,
    errors,
    isSubmitting,
    resetForm,
    values,
    touched,
}: FormikProps<LocationFormValues>) => {
    const {selectedBusinessOption} = useAppContext();

    return (
        <>
            <PageHeader pageName={(
                <Stack direction="horizontal">
                    <div className="me-3">
                        <BackButton to="/locations"/>
                    </div>
                    <div>
                        <div>{values.name}</div>
                        <div className="text-muted align-text-bottom" style={{fontSize: '0.8rem'}}>
                            Location Details
                        </div>
                    </div>
                </Stack>
            )}>
            </PageHeader>
            <Form onSubmit={handleSubmit}>
                <Container fluid>
                    <Row>
                        <Col lg={{span: 3}}>
                            <SimpleCard title="Location"
                                        isUpgradeCalloutVisible={selectedBusinessOption.canUpgradeTo.usesLocationAmenities}
                                        upgradeCalloutText="your plan to access Amenity/SEO business tags."
                            >
                                <FormGroup controlId="name" className="mb-3">
                                    <FormLabel>Location Name</FormLabel>
                                    <Field as={FormControl}
                                           name="name"
                                           isInvalid={!!errors.name}/>
                                    <FormControl.Feedback type="invalid">
                                        {errors.name}
                                    </FormControl.Feedback>
                                </FormGroup>

                                {(selectedBusinessOption.usesLocationAmenities || selectedBusinessOption.canUpgradeTo.usesLocationAmenities) &&
                                    <FormGroup controlId="amenityTags" className="mb-3">
                                        <FormLabel>Amenities</FormLabel>
                                        <Field component={FormikSelect}
                                               disabled={!selectedBusinessOption.usesLocationAmenities}
                                               name="amenityTags"
                                               isMulti
                                               options={values.availableAmenityTags}
                                               isInvalid={!!errors.amenityTags}/>
                                        <FormControl.Feedback type="invalid">
                                            {errors.amenityTags}
                                        </FormControl.Feedback>
                                    </FormGroup>
                                }
                            </SimpleCard>
                        </Col>
                        <Col lg={{span: 5}}>
                            <SimpleCard title="Address">
                                <AddressFields values={values}
                                               errors={errors}
                                               isSubmitting={isSubmitting}
                                               touched={touched}
                                />
                            </SimpleCard>
                        </Col>
                    </Row>
                </Container>

                <PageFooter>
                    <SubmitOnConfirmButton disabled={isSubmitting}
                                           resetForm={resetForm}
                                           isSubmitting={isSubmitting}/>
                </PageFooter>
            </Form>
        </>
    );
};
