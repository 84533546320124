import React, {useMemo} from 'react';
import {Button, Col, Container, Row, Stack} from 'react-bootstrap';
import {FadeIn} from '@/components/animation';
import {useLoaderData, useNavigate} from 'react-router';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import {AutoCampaignListResponse} from '@shared/responseModels/business';
import {AutoCampaignSummaryCard} from '@/pages/Campaigns/components/AutoCampaignSummaryCard';
import {CampaignSubNav} from '@/pages/Campaigns/components/CampaignSubNav';
import {UpgradeCallout} from '@/components/product';
import {useAppContext} from '@/AppContext';
import {InfoCard, SectionHeader} from '@/components/container';

export function AutoCampaignList() {
    const {selectedBusinessOption} = useAppContext();
    const navigate = useNavigate();
    const campaigns = useLoaderData() as AutoCampaignListResponse;
    const canCreateMilestoneCampaign = useMemo(() => {
        return (campaigns.milestone?.length ?? 0) < selectedBusinessOption.maximumMilestoneCampaigns;
    }, [selectedBusinessOption, campaigns]);

    return (
        <>
            <CampaignSubNav>
                <Stack direction="horizontal" className="my-2" gap={2}>
                    {(selectedBusinessOption.canUpgrade && !canCreateMilestoneCampaign && campaigns.milestone.length > 0) &&
                        <div className="bg-primary text-center rounded">
                            <UpgradeCallout canUpgradeTo={true} text="your plan to add more milestone campaigns."/>
                        </div>
                    }

                    {campaigns.hasCampaigns && !(selectedBusinessOption.canUpgrade && !canCreateMilestoneCampaign) &&
                        <>
                            <Button className="pe-3"
                                    variant="outline-primary"
                                    disabled={!canCreateMilestoneCampaign}
                                    onClick={(event) => {
                                    event.preventDefault();
                                    navigate('/campaigns/create-visit-milestone');
                                }}
                            >
                                <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1"/>
                                <span>Visit Milestone</span>
                            </Button>
                            <Button className="pe-3"
                                    variant="outline-primary"
                                    disabled={!canCreateMilestoneCampaign}
                                    onClick={(event) => {
                                    event.preventDefault();
                                    navigate('/campaigns/create-lifetime-points-milestone');
                                }}
                            >
                                <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1"/>
                                <span>Lifetime Points Milestone</span>
                            </Button>
                        </>
                    }
                </Stack>
            </CampaignSubNav>

            <FadeIn>
                {campaigns.hasCampaigns &&
                    <>
                        <Container fluid>
                            <Row>
                                <Col lg={3} md={6}>
                                    <SectionHeader>Standard Campaigns</SectionHeader>
                                    <Stack direction="vertical">
                                        {campaigns.standard.map((campaign) => {
                                            return (
                                                <div key={campaign.id}>
                                                    <AutoCampaignSummaryCard campaign={campaign}/>
                                                </div>
                                            );
                                        })}
                                    </Stack>
                                </Col>

                                <Col lg={3} md={6}>
                                    <SectionHeader>Visit Again Campaigns</SectionHeader>
                                    <Stack direction="vertical">
                                        {campaigns.visitAgain.map((campaign) => {
                                            return (
                                                <div key={campaign.id}>
                                                    <AutoCampaignSummaryCard campaign={campaign}/>
                                                </div>
                                            );
                                        })}
                                    </Stack>
                                </Col>

                                <Col lg={6}>
                                    <SectionHeader>Milestone Campaigns</SectionHeader>
                                    <Row>
                                        {campaigns.milestone.map((campaign) => {
                                            return (
                                                <Col lg={6} key={campaign.id}>
                                                    <AutoCampaignSummaryCard campaign={campaign}
                                                                             isDeletable={true}/>
                                                </Col>
                                            );
                                        })}

                                        {(selectedBusinessOption.canUpgrade && !canCreateMilestoneCampaign && campaigns.milestone.length === 0) &&
                                            <Col>
                                                <InfoCard>
                                                    <UpgradeCallout canUpgradeTo={true} text="your plan to add milestone campaigns."/>
                                                </InfoCard>
                                            </Col>
                                        }

                                        {campaigns.milestone?.length === 0 && canCreateMilestoneCampaign &&
                                            <Col>
                                                <InfoCard>
                                                    <span>Create a</span>
                                                    <a className="text-decoration-none clickable mx-1"
                                                       onClick={(event) => {
                                                            event.preventDefault();
                                                            navigate('/campaigns/create-visit-milestone');
                                                        }}>
                                                        Visit
                                                    </a>
                                                    <span>or a</span>
                                                    <a className="text-decoration-none clickable mx-1"
                                                       onClick={(event) => {
                                                            event.preventDefault();
                                                            navigate('/campaigns/create-lifetime-points-milestone');
                                                        }}
                                                    >
                                                        Lifetime Points
                                                    </a>
                                                    <span>milestone campaign.</span>
                                                </InfoCard>
                                            </Col>
                                        }
                                    </Row>
                                </Col>

                            </Row>
                        </Container>
                    </>
                }
            </FadeIn>
        </>
    );
}
