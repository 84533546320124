import React, {ReactElement, useMemo, useState} from 'react';
import {Field, FieldHookConfig, useField, useFormikContext} from 'formik';
import {FormGroup, FormLabel, FormText, InputGroup, Stack} from 'react-bootstrap';
import {ValidationError} from '@/components/form/ValidationError';
import classNames from 'classnames';
import {EmojiPicker} from '@/components/form/index';
import {Emoji} from '@/components/form/EmojiPicker';
import {ReferenceableFormControl} from '@/components/form/ReferenceableFormControl';
import {spliceTextAtCaretPosition} from '@/services/textFormatting';
import {FieldAttributes} from 'formik/dist/Field';
import {Box, If} from '@/components/container';

interface Props {
    label: string | ReactElement;
    maxLength?: number;
    helpText?: string | ReactElement | null;
    emojiButtonSuffix?: boolean,
    multiline?: boolean;
    onValidate?: (value?: string | number) => Element | ReactElement | null | string | void | Promise<string | void>;
}

export function Input({
    label,
    maxLength,
    helpText,
    emojiButtonSuffix = false,
    multiline = false,
    type = 'text',
    className,
    disabled,
    onValidate,
    onChange,
    validate,
    ...props
}: Props & FieldAttributes<unknown>) {
    const [inputRef, setInputRef] = useState(null);
    const {errors, touched} = useFormikContext<Record<string, string>>();
    const [field, meta, helpers] = useField(props as FieldHookConfig<string>);
    const isInvalid = useMemo(() => {
        const fieldName = field.name;
        return !disabled && !!errors[fieldName] && touched[fieldName];
    }, [errors, touched, field]);

    const singleLineInputWithAddon = useMemo(() => !multiline && emojiButtonSuffix, [multiline, emojiButtonSuffix]);
    const multilineInputWithAddon = useMemo(() => multiline && emojiButtonSuffix, [multiline, emojiButtonSuffix]);

    function onEmojiSelect(emoji: Emoji) {
        const value = spliceTextAtCaretPosition(inputRef, meta.value, emoji.native);
        helpers.setValue(value, true);
    }

    return (
        <fieldset disabled={disabled} className="m-0 p-0">
            <FormGroup controlId={field.name} className={classNames('mb-3', className)}>
                <FormLabel>{label}</FormLabel>

                <Box as={singleLineInputWithAddon ? InputGroup : 'div'}>
                    <Field validate={onValidate ? (value: string | number) => onValidate(value) : validate}
                           {...(onChange ? {onChange} : {})}
                           as={ReferenceableFormControl}
                           formControlAs={multiline ? 'textarea' : 'input'}
                           innerRef={setInputRef}
                           isInvalid={isInvalid}
                           maxLength={maxLength ?? 200}
                           type={type}
                           style={multiline ? {height: 130, resize: 'none'} : {}}
                           {...props}
                    />

                    <If isTrue={singleLineInputWithAddon} as={EmojiPicker} onEmojiSelect={onEmojiSelect}/>

                    <ValidationError fieldName={field.name}/>
                </Box>

                <Stack direction="horizontal" gap={2}
                       className="mt-2">
                    <Stack direction="vertical">
                        <If isTrue={!!maxLength} as={FormText}>
                            Characters remaining: {maxLength! - (field.value?.length ?? 0)}
                        </If>
                        <If isTrue={!!helpText} as={FormText}>{helpText}</If>
                    </Stack>
                    <If isTrue={multilineInputWithAddon} className="ms-auto">
                        <EmojiPicker standalone className="me-3" onEmojiSelect={onEmojiSelect}/>
                    </If>
                </Stack>
            </FormGroup>
        </fieldset>
    );
}
