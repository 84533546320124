import React, {PropsWithChildren, ReactElement} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import classNames from 'classnames';

interface Props {
    pageName?: string | ReactElement;
    className?: string[] | string;
}

function PageHeader({pageName, className, children}: PropsWithChildren<Props>) {
    return (
        <div className={classNames('p-3 mb-3 bg-white shadow', className)}>
            <Container fluid className="my-0 py-0">
                <Row>
                    <Col md="6">
                        <div className="h3 mt-0 mb-1">
                            {pageName}
                        </div>
                    </Col>
                    <Col md="6">
                        <div className="float-end">
                            {children}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default PageHeader;
