import * as yup from 'yup';
import {InferType} from 'yup';


export const whenTieredRewardsDoNotUseClover = {
    keys: ['isTieredRewardsLoyaltyProgram', 'usesClover'],
    options: {
        is: (isTieredRewardsLoyaltyProgram: boolean, usesClover: boolean) => isTieredRewardsLoyaltyProgram && !usesClover,
        then: (schema) => schema.of(
            yup.object().noUnknown(true).shape({
                id: yup.number()
                    .positive(),
                uuid: yup.string()
                    .uuid(),
                rewardImageUrl: yup.string()
                    .nullable()
                    .url('The logo URL must be a valid URL.'),
                rewardTitle: yup.string()
                    .max(255, 'Reward Name should be 255 characters or fewer.')
                    .required('Required.'),
                cost: yup.number()
                    .integer()
                    .min(1, 'Cost must be at least a minimum of 1')
                    .max(199999, 'Cost can at most be a maximum of 199,999')
                    .required('Required.'),
            }),
        ).required('Required.'),
    },
};

export const whenTieredRewardsUseClover = {
    keys: ['isTieredRewardsLoyaltyProgram', 'usesClover'],
    options: {
        is: (isTieredRewardsLoyaltyProgram: boolean, usesClover: boolean) => {
            return isTieredRewardsLoyaltyProgram && usesClover;
        },
        then: (schema) => schema.of(
            yup.object().noUnknown(true).shape({
                id: yup.number()
                    .positive(),
                uuid: yup.string()
                    .uuid(),
                rewardImageUrl: yup.string()
                    .nullable()
                    .url('The logo URL must be a valid URL.'),
                rewardTitle: yup.string()
                    .max(255, 'Reward Name should be 255 characters or fewer.'),
                clover: yup.object().shape({
                    discountType: yup.string().oneOf(['amount', 'percentage']),
                    amount: yup.number()
                        .nullable()
                        .transform((value, originalValue) => {
                            return originalValue === '' ? null : value;
                        })
                        .typeError('Must be a between $1 and $1,000')
                        .when('discountType', {
                            is: (discountType: string) => {
                                return discountType === 'amount';
                            },
                            then: (schema) => {
                                return schema
                                    .min(1, 'Must be a between $1 and $1,000')
                                    .max(1000, 'Must be a between $1 and $1,000')
                                    .required('Required.');
                            },
                        }),
                    percentage: yup.number()
                        .nullable()
                        .transform((value, originalValue) => {
                            return originalValue === '' ? null : value;
                        })
                        .typeError('Must be a between 1% and 100%')
                        .when('discountType', {
                            is: (discountType: string) => !discountType || discountType === 'percentage',
                            then: (schema) => schema
                                .min(1, 'Must be a between 1% and 100%')
                                .max(100, 'Must be a between 1% and 100%')
                                .required('Required.'),
                        }),
                }),
                cost: yup.number()
                    .integer()
                    .min(1, 'Cost must be at least a minimum of 1')
                    .max(199999, 'Cost can at most be a maximum of 199,999')
                    .required('Required.'),
            }),
        ).required('Required.'),
    },
};


export const RewardSchema = yup.object().shape({
    // Punch Card
    isPunchCardLoyaltyProgram: yup.boolean(),
    rewardItem: yup.string()
        .max(30, 'Reward Name should be 30 characters or fewer.')
        .when('isPunchCardLoyaltyProgram', {
            is: true,
            then: (schema) => schema.required('Required.'),
        }),
    maxPoints: yup.number()
        .integer()
        .min(1, 'Points must be at least a minimum of 1')
        .max(199999, 'Points can at most be a maximum of 199,999')
        .when('isPunchCardLoyaltyProgram', {
            is: true,
            then: (schema) => schema
                .required('Required.'),
        }),
    rewardImageUrl: yup.string()
        .nullable()
        .url('The logo URL must be a valid URL.'),

    // Tiered reward
    usesClover: yup.boolean().required(),
    isTieredRewardsLoyaltyProgram: yup.boolean(),
    tieredRewards: yup.array()
        .when(whenTieredRewardsDoNotUseClover.keys, whenTieredRewardsDoNotUseClover.options)
        .when(whenTieredRewardsUseClover.keys, whenTieredRewardsUseClover.options),
});

export type TieredRewardFields =
    'id'
    | 'cost'
    | 'uuid'
    | 'rewardTitle'
    | 'rewardImageUrl'
    | 'rewardImageFile'
    | 'clover.amount'
    | 'clover.percentage';

export interface TieredReward extends Record<TieredRewardFields, unknown> {
    id: number;
    cost: number;
    uuid: string;
    rewardTitle: string;
    rewardImageUrl: string;
    rewardImageFile: Blob;
}

export interface RewardFormValues extends InferType<typeof RewardSchema> {
    rewardImageFile?: File;
    tieredRewards: TieredReward[];
}
