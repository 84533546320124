import React, {useEffect, useState} from 'react';
import {CenteredSpinner} from '@/components/animation';
import {useAppContext} from '@/AppContext';
import {useBusinessSettingsResource} from '@/services/api';
import {Formik} from 'formik';
import BusinessInfoForm from '@/pages/Settings/components/BusinessInfoForm';
import {BusinessInfoSchema} from '@shared/requestSchemas';

function BusinessSettings() {
    const businessSettingsResource = useBusinessSettingsResource();
    const {selectedBusinessOption, setSelectedBusinessOption} = useAppContext();
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [businessSettings, setBusinessSettings] = useState({});

    const submitBusinessInfo = (values, {setSubmitting}) => {
        businessSettingsResource.updateBusinessInfo(values)
            .then(({data}) => {
                setBusinessSettings(data);
                selectedBusinessOption.label = data.businessName;
                setSelectedBusinessOption(selectedBusinessOption);
            })
            .finally(() => setSubmitting(false));
    };

    const fetchData = () => {
        setIsPageLoading(true);
        businessSettingsResource.getBusinessSettings()
            .then(({data}) => {
                setBusinessSettings(data);
            })
            .finally(() => setIsPageLoading(false));
    };

    useEffect(() => {
        fetchData();
    }, [selectedBusinessOption]);

    if (isPageLoading) {
        return <CenteredSpinner/>;
    }

    return (
        <div className="mb-5">
            <Formik validationSchema={BusinessInfoSchema}
                    component={BusinessInfoForm}
                    initialValues={businessSettings}
                    onSubmit={submitBusinessInfo}/>
        </div>
    );
}

export default BusinessSettings;
