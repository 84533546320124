import React, {RefObject} from 'react';
import {FormControl} from 'react-bootstrap';

interface Props {
    innerRef: ((instance: (HTMLInputElement | null)) => void) | RefObject<HTMLInputElement>;
    formControlAs: 'textarea' | 'input';
}

export function ReferenceableFormControl({innerRef, formControlAs, ...props}: Props) {
    return <FormControl ref={innerRef} as={formControlAs} {...props} />;
}
