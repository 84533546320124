export const convertToCSV = (filename: string, objArray: string) => {
    const parse = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let processedRow = '';

    const processData: Record<string, unknown>[] = []

    parse.map((value: Record<string, unknown>) => {
        delete value['searchScore'];
        processData.push(value)
    })

    const array = [Object.keys(processData[0])].concat(processData)

    for (let i = 0; i < array.length; i++) {
        let line = '';
        for (const index in array[i]) {
            if (line != '') line += ','

            if (index === 'searchScore' || array[i][index] === 'searchScore')
                continue

            if (index === 'email' && array[i][index] === null) {
                line += '-';
                continue
            }

            line += array[i][index];
        }

        processedRow += line + '\r\n';
    }

    const blob = new Blob([processedRow], { type: 'text/csv;charset=utf-8;' });
    // @ts-expect-error msSaveBlob may or may not exist. It is for IE 10+ support.
    if (window.navigator.msSaveBlob) {
        // @ts-expect-error If msSaveBlob exists, use it.
        window.navigator.msSaveBlob(blob, filename);
    } else {
        const link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}

