import React, {useMemo, useState} from 'react';
import {Button, FormGroup, OverlayTrigger, Popover} from 'react-bootstrap';
import {useAppContext} from '@/AppContext';
import {Field, Form, Formik} from 'formik';

import {useTemplateResource} from '@/services/api';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';

interface Props {
    emailImage: string;
    emailImageLink: string;
    subject: string;
    message: string;
    isOfferEnabled: boolean;
    offerDescription: string;
    offerExpirationDate: Date;
    hasCustomEmailTemplate: boolean;
    campaignDetailsEmailTemplate: string;
}

function PreviewEmailSender(
    {
        emailImage,
        emailImageLink,
        message,
        subject,
        isOfferEnabled,
        offerDescription,
        offerExpirationDate,
        hasCustomEmailTemplate,
        campaignDetailsEmailTemplate,
    }: Props,
) {
    const templateResource = useTemplateResource();
    const {selectedBusinessOption, currentUser} = useAppContext();

    const [isSendPreviewEmailPopoverOpen, setIsSendPreviewEmailPopoverOpen] = useState(false);
    const [isSendingPreviewEmail, setIsSendingPreviewEmail] = useState(false);
    const sendEmailPreviewPopoverForm = (
        <Popover id="send-email-preview-popover-form">
            <Popover.Header as="h3">Send Preview Email</Popover.Header>
            <Popover.Body>
                <Formik initialValues={{previewEmailAddress: currentUser.email}}
                        enableReinitialize
                        onSubmit={(values, {setSubmitting}) => {
                        setIsSendingPreviewEmail(true);
                        if (hasCustomEmailTemplate) {
                            templateResource.sendPreviewCustomTemplateEmail({
                                subject,
                                toAddress: values.previewEmailAddress,
                                campaignDetailsEmailTemplate: campaignDetailsEmailTemplate,
                            })
                                .then(() => {
                                    setIsSendPreviewEmailPopoverOpen(false);
                                })
                                .finally(() => setIsSendingPreviewEmail(false));
                        } else {
                            const params = {
                                emailImage,
                                emailImageLink,
                                subject,
                                toAddress: values.previewEmailAddress,
                                message,
                                ...(isOfferEnabled ? {
                                    offerDescription,
                                    offerExpirationDate: offerExpirationDate?.toISOString(),
                                } : {}),
                            };
                            templateResource.sendPreviewEmail(params)
                                .then(() => {
                                    setIsSendPreviewEmailPopoverOpen(false);
                                })
                                .finally(() => setIsSendingPreviewEmail(false));
                        }
                    }}>
                    <Form>
                        <FormGroup className="mb-3">
                            <label htmlFor="previewEmailAddress"
                                   className="col-form-label sr-only">Email</label>
                            <Field type="text"
                                   name="previewEmailAddress"
                                   className="form-control form-control-sm"
                                   disabled={isSendingPreviewEmail}/>
                        </FormGroup>
                        <Button type="submit"
                                variant="primary"
                                disabled={isSendingPreviewEmail}
                                title="Save">
                            {
                                !isSendingPreviewEmail ?
                                    'Send' :
                                    <FontAwesomeIcon spin={isSendingPreviewEmail}
                                                     icon={faSpinner}
                                                     fixedWidth/>
                            }
                        </Button>
                        <Button variant="link" onClick={() => setIsSendPreviewEmailPopoverOpen(false)}>Cancel</Button>
                    </Form>
                </Formik>
            </Popover.Body>
        </Popover>
    );

    const canSendEmail = useMemo(() => {
        const hasMessage = hasCustomEmailTemplate ? campaignDetailsEmailTemplate?.length > 0 : message?.length > 0;
        return hasMessage && subject?.length > 0;
    }, [campaignDetailsEmailTemplate, message, subject]);

    return (
        <OverlayTrigger
            show={isSendPreviewEmailPopoverOpen}
            placement="top"
            overlay={sendEmailPreviewPopoverForm}>
            <Button onClick={() => setIsSendPreviewEmailPopoverOpen(true)}
                    disabled={!canSendEmail}>Send Preview Email</Button>
        </OverlayTrigger>
    );
}

export default PreviewEmailSender;
