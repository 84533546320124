import * as yup from 'yup';
import {InferType} from 'yup';

const excludeValue = 0;
const includeValue = 1;
const requireValue = 2;
const basicStateValues = [
    excludeValue,
    includeValue,
];
const allStateValues = [
    ...basicStateValues,
    requireValue
];

export const SignUpFormSettingsSchema = yup.object().shape({
    // Profile
    mobileNumberState: yup.number().oneOf([requireValue]).required('Required.'),
    firstNameState: yup.number().oneOf([requireValue]).required('Required.'),
    lastNameState: yup.number().oneOf(allStateValues).required('Required.'),
    emailState: yup.number().oneOf(allStateValues).required('Required.'),
    dateOfBirthState: yup.number().oneOf(allStateValues).required('Required.'),

    // Address
    addressLine1State: yup.number().oneOf(allStateValues).required('Required.'),
    addressLine2State: yup.number().oneOf(allStateValues).required('Required.'),
    cityState: yup.number().oneOf(allStateValues).required('Required.'),
    stateState: yup.number().oneOf(allStateValues).required('Required.'),
    countryState: yup.number().oneOf(allStateValues).required('Required.'),
    zipCodeState: yup.number().oneOf(allStateValues).required('Required.'),

    // Preferences
    emailOptInState: yup.number().oneOf(basicStateValues).required('Required.'),
    textOptInState: yup.number().oneOf(basicStateValues).required('Required.'),
});

export interface SignUpFormSettingsFormValues extends InferType<typeof SignUpFormSettingsSchema> {
    isDateOfBirthConfigurable: boolean | undefined;
    signUpFormUrl: string;
    locationName: string;
}
