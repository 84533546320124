import React, {useEffect, useMemo, useState} from 'react';
import {Button, Collapse, OverlayTrigger, Popover, Stack} from 'react-bootstrap';
import Select from 'react-select';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFilter, faFilterCircleXmark} from '@fortawesome/free-solid-svg-icons';
import {SelectOptions} from '@shared/responseModels';
import {If, SimpleModal} from '../container';

interface DiscreteFilterState {
    current: string;
    isPristine: boolean;
    isDirty: boolean;
}

export interface SelectFilterProps {
    available: SelectOptions;
    selected?: SelectOptions;
    onApplyFilter: (options: SelectOptions) => void;
    modalTitle?: string;
    isInline?: boolean;
}

export function SelectFilter({
    onApplyFilter,
    available,
    selected = [],
    modalTitle = 'Filter',
    isInline = false,
}: SelectFilterProps) {
    const [pristineDiscreteFilter, setPristineDiscreteFilter] = useState<string>(JSON.stringify(selected));
    const [options, setOptions] = useState<SelectOptions>(JSON.parse(pristineDiscreteFilter));
    const discreteFilterState = useMemo<DiscreteFilterState>(() => {
        const current = JSON.stringify(options);
        return {
            current,
            isPristine: pristineDiscreteFilter === current,
            isDirty: pristineDiscreteFilter !== current,
        };
    }, [pristineDiscreteFilter, options]);

    const isDiscreteFilterActive = useMemo(() => (
        options?.length > 0 && available?.length !== options?.length
    ), [available, selected, options]);

    function onResetFilter() {
        setOptions([]);
    }

    function onApplyFilterInternalWithOptions(newOptions: SelectOptions) {
        onApplyFilter(newOptions);
        setPristineDiscreteFilter(discreteFilterState.current);

        if (!isInline) {
            // This is a pretty egregious hack to get the popover to close programmatically.
            document.body.click();
        }
    }

    function onApplyFilterInternal() {
        onApplyFilterInternalWithOptions(options);
    }

    const input = useMemo(() => (
        <Select isMulti={true}
                autoFocus={true}
                options={available}
                value={options}
                onChange={(selectedOptions) => {
                    setOptions(selectedOptions.map(x => x));
                    if (isInline) {
                        onApplyFilterInternalWithOptions(selectedOptions as SelectOptions);
                    }
                }}
        />
    ), [available, selected, options, isInline]);

    useEffect(() => {
        setOptions(selected);
    }, [selected]);

    if (!available) {
        return null;
    }

    return (
        <>
            <If isTrue={isInline}>
                {input}
            </If>
            <If isTrue={!isInline} className="d-none d-sm-block">
                <OverlayTrigger
                    trigger="click"
                    placement="bottom"
                    rootClose
                    transition={Collapse}
                    onToggle={(value: boolean) => {
                        if (!value) {
                            onApplyFilterInternal();
                        }
                    }}
                    overlay={(
                        <Popover>
                            <Popover.Body style={{width: 250}}>
                                <div className="mb-2">
                                    {input}
                                </div>

                                <Stack direction="horizontal" gap={2}>
                                    <Button onClick={onApplyFilterInternal}
                                            size="sm"
                                            className="w-100"
                                    >
                                        Apply Filter
                                    </Button>
                                    <Button onClick={onResetFilter}
                                            variant="link"
                                            size="sm"
                                    >
                                        Reset
                                    </Button>
                                </Stack>
                            </Popover.Body>
                        </Popover>
                    )}>

                    {({ref, ...triggerHandler}) => (
                        <Button ref={ref}
                                variant="link"
                                {...triggerHandler}
                                className="d-inline-flex align-items-center"
                        >
                            <FontAwesomeIcon
                                icon={isDiscreteFilterActive ? faFilterCircleXmark : faFilter}/>
                        </Button>
                    )}
                </OverlayTrigger>
            </If>

            <If isTrue={!isInline} className="d-block d-md-none">
                <SimpleModal modalTitle={modalTitle}
                             buttonIcon={<FontAwesomeIcon icon={faFilter}/>}
                             modalBodyHeight="1%"
                >
                    {input}
                </SimpleModal>
            </If>
        </>
    );
}
