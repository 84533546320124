import {logger} from 'sparkloyalty-frontend/logging';

const url = {
    imageFileToUrl: (image: File | Blob | MediaSource) => {
        if (!image) {
            return '';
        }
        try {
            return URL.createObjectURL(image);
        } catch (error) {
            logger.error(error);
        }
        return '';
    },
};

export default url;
