import cache from '@/services/cache';

const defaultClientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
export let clientTimeZone = defaultClientTimeZone;

export function useClientTimeZone() {
    const cacheKey = 'clientTimeZone';
    return {
        get() {
            const item = cache.get(cacheKey);
            clientTimeZone = item?.clientTimeZone || defaultClientTimeZone;
            if (!item?.clientTimeZone) {
                cache.set(cacheKey, {clientTimeZone});
            }
            return clientTimeZone;
        },
        set(timeZone: string) {
            clientTimeZone = timeZone;
            cache.set(cacheKey, {clientTimeZone});
        }
    }
}
