import React, {useEffect, useState} from 'react';
import TimezoneSelect from 'react-timezone-select';
import {useClientTimeZone} from '@/services/timeZones';
import {setClientTimeZoneHeader} from '@/services/api';
import {useAppContext} from '@/AppContext';
import {Stack} from 'react-bootstrap';
import {IconButton} from '@/components/form';
import {faClose, faGlobe} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {DateTime} from 'luxon';

interface Props {
    isMobile?: boolean,
}

export function TimeZonePicker({isMobile}: Props) {
    const [isOpen, setIsOpen] = useState(!isMobile);
    const clientTimeZone = useClientTimeZone();
    const {currentClientTimeZone, setCurrentClientTimeZone} = useAppContext();
    setClientTimeZoneHeader(clientTimeZone.get());
    const [label, setLabel] = useState<string>();

    function setOpenButtonLabel(timeZone: string) {
        setLabel(DateTime.now().setZone(timeZone).toFormat('ZZ ZZZZ'));
    }

    useEffect(() => {
        setOpenButtonLabel(currentClientTimeZone);
    }, [currentClientTimeZone]);

    return (
        <>
            {(isMobile && !isOpen) && (
                <IconButton onClick={() => setIsOpen(true)}
                            icon={<FontAwesomeIcon icon={faGlobe} fixedWidth/>}
                            variant="outline-secondary"
                            text={label}
                />
            )}

            {isOpen && (
                <Stack direction={isMobile ? 'horizontal' : 'vertical'} gap={1}>
                    {!isMobile && <div className="h5 text-white text-muted mt-3">Time Zone</div>}
                    <TimezoneSelect value={currentClientTimeZone}
                                    displayValue="UTC"
                                    labelStyle="abbrev"
                                    onChange={(timeZone) => {
                            setOpenButtonLabel(timeZone.value);
                            // For Date Filters
                            clientTimeZone.set(timeZone.value);
                            // Axios Header
                            setClientTimeZoneHeader(timeZone.value);
                            // App Context
                            setCurrentClientTimeZone(timeZone.value);
                        }}
                                    styles={{
                            control: (css) => ({
                                ...css,
                                minWidth: 400,
                            }),
                            menu: (css) => ({
                                ...css,
                                zIndex: 9999,
                                width: 'max-content',
                                minWidth: '100%',
                            }),
                            menuPortal: (css) => ({
                                ...css,
                                zIndex: 9999,
                            }),
                        }}
                    />
                    {isMobile && (
                        <IconButton onClick={() => setIsOpen(false)}
                                    variant="outline-secondary"
                                    icon={<FontAwesomeIcon icon={faClose} fixedWidth/>}
                        />
                    )}
                </Stack>
            )}
        </>
    );
}
