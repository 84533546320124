import React, {useCallback, useEffect, useState} from 'react';
import {Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {Panel} from '@/components/container';
import ComparisonText from '@/pages/Dashboard/components/ComparisonText';
import CustomTooltip from '@/pages/Dashboard/components/CustomTooltip';
import {useBusinessAnalyticsResource} from '@/services/api';
import {useAppContext} from '@/AppContext';
import {themeColors} from '@/services/theme';
import {CheckinDataPoints} from '@shared/responseModels/analytics';
import {
    DateFormatter,
    dateFormatterFactory,
    dateFormatterFromTimeSeriesIntervalFactory,
    toMonthDayString,
} from '@/services/textFormatting';
import {AxisInterval} from 'recharts/types/util/types';

type QueryParam = {
    dateRange: number;
    fromDate?: string;
    toDate?: string;
    locations: number[];
}

interface Props {
    locations: number[];
}

function Checkins({locations}: Props) {
    const businessAnalyticsResource = useBusinessAnalyticsResource();
    const {selectedBusinessOption, fields, currentClientTimeZone} = useAppContext();

    const [isProcessing, setIsProcessing] = useState(true);
    const [dateRange, setDateRange] = useState<number>(fields.dateRange.map.thisWeek ?? 0);
    const [fromDate, setFromDate] = useState<Date>();
    const [toDate, setToDate] = useState<Date>();
    const [dateFormatter, setDateFormatter] = useState<DateFormatter>(dateFormatterFactory(toMonthDayString));
    const [xAxisInterval, setXAxisInterval] = useState<AxisInterval>(0);

    const [comparisonContent, setComparisonContent] = useState({
        repeats: {
            info: 0,
            color: themeColors.primary,
            type: 'Returning',
            percentage: 0,
        },
        signUps: {
            info: 0,
            color: themeColors.success,
            type: 'New',
            percentage: 0,
        },
    });
    const [dataPoints, setDataPoints] = useState<CheckinDataPoints>();

    const fetchCheckinsData = useCallback(() => {
        setIsProcessing(true);

        // Prepare query params
        const queryParams: QueryParam = {
            dateRange,
            locations,
        };

        // Prepare date filter
        // @ts-ignore
        if (dateRange === fields.dateRange.map.custom) {
            queryParams.fromDate = fromDate?.toISOString();
            queryParams.toDate = toDate?.toISOString();
        }

        businessAnalyticsResource.getAnalyticsCheckins(queryParams)
            .then(({data}) => {
                comparisonContent.repeats.info = data.totalRepeats;
                comparisonContent.repeats.percentage = data.deltaPercentageRepeats;
                comparisonContent.signUps.info = data.totalSignUps;
                comparisonContent.signUps.percentage = data.deltaPercentageSignUps;
                setComparisonContent(comparisonContent);
                setDataPoints(data.dataPoints);
                setDateFormatter(dateFormatterFromTimeSeriesIntervalFactory(data.timeSeriesInterval));

                setXAxisInterval(
                    dateRange === fields.dateRange.map.thisYear || dateRange === fields.dateRange.map.thisWeek ?
                        0 : 'preserveStartEnd',
                );
            })
            .finally(() => setIsProcessing(false));
    }, [selectedBusinessOption, dateRange, fromDate, toDate, currentClientTimeZone, locations]);

    useEffect(() => {
        fetchCheckinsData();
    }, [selectedBusinessOption, dateRange, fromDate, toDate, currentClientTimeZone, locations]);

    return (
        <Panel title={'Checkins'}
               getFilterValue={setDateRange}
               setFromDate={setFromDate}
               setToDate={setToDate}
               isProcessing={isProcessing}
        >
            <ComparisonText content1={comparisonContent.repeats} content2={comparisonContent.signUps}/>
            <ResponsiveContainer height="100%" width="100%">
                <BarChart data={dataPoints}
                          margin={{top: 30, right: 60, left: 0, bottom: 30}}>
                    <XAxis dataKey="date"
                           axisLine={false}
                           tickLine={false}
                           tickFormatter={dateFormatter.format}
                           type={'category'}
                           interval={xAxisInterval}
                           angle={-45}
                           textAnchor="end"
                    />
                    <YAxis axisLine={false} tickLine={false} allowDecimals={false}/>
                    <Tooltip content={<CustomTooltip payload={dataPoints} dateFormatter={dateFormatter}/>}/>
                    <Bar dataKey="repeats" stackId="a" fill={comparisonContent.repeats.color}/>
                    <Bar dataKey="signUps" stackId="a" fill={comparisonContent.signUps.color}/>
                </BarChart>
            </ResponsiveContainer>
        </Panel>
    );
}

export default Checkins;
