import * as yup from 'yup';
import {InferType} from 'yup';
import {hexColorRule} from '../matchRules';

export const CustomerSettingsSchema = yup.object().shape({
    pointsNeededToEarnVipStatus: yup.number()
        .when('isLifetimePointVipStatusEnabled', {
            is: true,
            then: (schema) => schema.min(0).required('Required.'),
        }),
    vipStatusDisplayName: yup.string()
        .when('isLifetimePointVipStatusEnabled', {
            is: true,
            then: (schema) => schema.required('Required.'),
        }),
    vipStatusTextColor: yup.string()
        .required('Text color is required.')
        .matches(...hexColorRule),
    vipStatusBackgroundColor: yup.string()
        .required('Background color is required.')
        .matches(...hexColorRule),
});

export interface CustomerSettingsFormValues extends InferType<typeof CustomerSettingsSchema> {
    campaignDetailsId?: number;
}
