import * as yup from 'yup';
import {validateOptionalZipCode} from '../regularExpressions';

export const ShopperSchema = yup.object().shape({
    firstName: yup.string()
        .max(100, 'Should be 100 characters or fewer.'),
    lastName: yup.string()
        .max(100, 'Should be 100 characters or fewer.'),

    dateOfBirth: yup.string(),
    birthMonth: yup.number()
        .typeError('A month must be selected.')
        .nullable()
        .min(1)
        .max(12),
    birthDayOfMonth: yup.number()
        .typeError('A day of the month must be selected.')
        .nullable()
        .min(1)
        .max(31),

    email: yup.string()
        .email('Must be a valid email address.')
        .nullable()
        .max(255, 'Email should be 255 characters or fewer.'),

    addressLine1: yup.string()
        .max(255, 'Street address should be 255 characters or fewer.'),
    addressLine2: yup.string()
        .max(255, 'Address line 2 should be 255 characters or fewer.'),
    city: yup.string()
        .max(255, 'City should be 255 characters or fewer.'),
    state: yup.string()
        .max(255, 'State should be 255 characters or fewer.'),
    province: yup.string()
        .max(255, 'Province/Region name should be 255 characters or fewer.'),
    country: yup.string()
        .max(255, 'Country should be 255 characters or fewer.'),
    zipCode: yup.string()
        .matches(
            validateOptionalZipCode,
            'Must be a valid zip code, e.g. 12345 or 12345-6789.',
        ),
});
