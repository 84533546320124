import React from 'react';
import {FadeIn} from '@/components/animation';
import {Outlet} from 'react-router-dom';

function LocationsLayout() {
    return (
        <FadeIn>
            <Outlet/>
        </FadeIn>
    );
}

export default LocationsLayout;
