import React, {useEffect, useState} from 'react';
import {useKioskSettingsResource} from '@/services/api';
import {useAppContext} from '@/AppContext';
import {Formik} from 'formik';
import {CenteredSpinner, FadeIn} from '@/components/animation';
import KioskSettingsForm from '@/pages/Rewards/components/KioskSettingsForm';
import {KioskSettingsSchema} from '@shared/requestSchemas';

function KioskSettings() {
    const kioskSettingsResource = useKioskSettingsResource();
    const {selectedBusinessOption} = useAppContext();
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [kioskSettings, setKioskSettings] = useState({});

    const fetchData = () => {
        setIsPageLoading(true);
        kioskSettingsResource.getList()
            .then(({data}) => {
                setKioskSettings(data);
            })
            .finally(() => setIsPageLoading(false));
    };

    useEffect(() => {
        fetchData();
    }, [selectedBusinessOption]);

    if (isPageLoading) {
        return <CenteredSpinner/>;
    }

    return (
        <FadeIn>
            <Formik schema={KioskSettingsSchema}
                    component={KioskSettingsForm}
                    initialValues={kioskSettings}
                    enableReinitialize
                    onSubmit={(values, {setSubmitting}) => {
                        kioskSettingsResource.update(values)
                            .then(({data}) => {
                                setKioskSettings(data);
                                values = data;
                            })
                            .finally(() => setSubmitting(false));
                    }}
            />
        </FadeIn>
    );
}

export default KioskSettings;
