import React, {useEffect, useState} from 'react';
import {
    createBrowserRouter,
    createRoutesFromElements,
    LoaderFunctionArgs,
    Route,
    RouterProvider,
} from 'react-router-dom';
import NotFound from '@/pages/NotFound';
import Layout from '@/components/layout';
import {DashboardLayout} from '@/pages/Dashboard';
import {CustomerDetails, CustomerList, CustomersLayout, FlagCustomerList} from '@/pages/Customers';
import {CustomCampaignDetails, CustomCampaignList} from '@/pages/Campaigns';
import {useAppContext} from '@/AppContext';
import {
    useAutoCampaignResource,
    useCustomCampaignResource,
    useLocationResource,
    useShopperResource,
    useSignUpFormSettingsResource,
} from '@/services/api';
import {CenteredSpinner} from '@/components/animation';
import {KioskSettings, RewardSettings, RewardsLayout} from '@/pages/Rewards';
import {BrandSettings, BusinessSettings, CustomerSettings, SettingsLayout, UpdatePaymentMethod} from '@/pages/Settings';
import {LocationDetails, LocationsLayout, SignUpFormSettings} from '@/pages/Locations';
import LocationList from '@/pages/Locations/LocationList';
import {customCampaignFactory, useNotify} from '@/services';
import {HubSpotSupportChat} from '@/components/support';
import {CampaignsLayout} from '@/pages/Campaigns/CampaignsLayout';
import {AutoCampaignList} from '@/pages/Campaigns/AutoCampaignList';
import {AutoCampaignDetails} from '@/pages/Campaigns/AutoCampaignDetails';
import {RouterErrorBoundary} from '@/components/container';
import {AxiosResponse} from 'axios';
import {MeResponse} from '@shared/responseModels';
import {CustomCampaignDetailsResponse} from '@shared/responseModels/business';
import {isString} from 'lodash';
import {DateTime} from 'luxon';

interface Props {
    me: Promise<AxiosResponse<MeResponse>>;
}

function App({me}: Props) {
    const notify = useNotify();

    const {
        setCurrentUser,
        setBusinessOptions,
        selectedBusinessOption,
        restoreBusinessOptionFromCache,
        setSelectedBusinessOption,
    } = useAppContext();
    const [isAppInitialized, setIsAppInitialized] = useState<boolean>(false);

    const autoCampaignResource = useAutoCampaignResource();
    const customCampaignResource = useCustomCampaignResource();
    const locationResource = useLocationResource();
    const shopperResource = useShopperResource();
    const signUpFormSettingsResource = useSignUpFormSettingsResource();

    const defaultPageIndex = 0;
    const defaultPageSize = 10;
    const defaultSortByDesc = 'true';

    useEffect(() => {
        if (isAppInitialized) {
            return;
        }

        setIsAppInitialized(true);
        me.then(({data}: AxiosResponse<MeResponse>) => {
            setCurrentUser(data);
            const options = data.businesses;
            setBusinessOptions(options);
            const businessOption = restoreBusinessOptionFromCache(options);
            setSelectedBusinessOption(businessOption);
        })
            .catch((error: Error) => {
                notify.error(error, 'Could not get user information');
            })
            .finally();
    }, []);

    const errorBoundaryProps = {
        errorElement: <RouterErrorBoundary/>,
        hasErrorBoundary: true,
    };

    if (!selectedBusinessOption.value) {
        return <CenteredSpinner/>;
    }

    const router = createBrowserRouter(
        createRoutesFromElements(
            <>
                <Route element={<Layout/>}
                       {...errorBoundaryProps}
                >
                    {/* Dashboard */}
                    <Route index
                           element={<DashboardLayout/>}
                           {...errorBoundaryProps}
                    />

                    {/* Customers */}
                    <Route path="customers"
                           element={<CustomersLayout/>}
                           {...errorBoundaryProps}
                    >
                        <Route index
                               element={<CustomerList/>}
                               loader={async (loaderArgs: LoaderFunctionArgs) => {
                                   const searchParams = new URL(loaderArgs.request.url).searchParams;
                                   const {data} = await shopperResource.getList({
                                       pageIndex: searchParams.get('pageIndex') ?? defaultPageIndex.toString(),
                                       pageSize: searchParams.get('pageSize') ?? defaultPageSize.toString(),
                                       sortByDesc: searchParams.get('sortByDesc') ?? defaultSortByDesc.toString(),
                                       sortByField: searchParams.get('sortByField') ?? 'lastVisitedAt',
                                       search: searchParams.get('search') ?? '',
                                       locations: searchParams.get('locations') ?? '',
                                   });
                                   return data;
                               }}
                        />
                        <Route path="flags" element={<FlagCustomerList/>}
                               loader={async (loaderArgs: LoaderFunctionArgs) => {
                                   const searchParams = new URL(loaderArgs.request.url).searchParams;
                                   const {data} = await shopperResource.getFlaggedList({
                                       pageIndex: searchParams.get('pageIndex') ?? defaultPageIndex.toString(),
                                       pageSize: searchParams.get('pageSize') ?? defaultPageSize.toString(),
                                       sortByDesc: searchParams.get('sortByDesc') ?? defaultSortByDesc.toString(),
                                       sortByField: searchParams.get('sortByField') ?? 'created',
                                       search: searchParams.get('search') ?? '',
                                       locations: searchParams.get('locations') ?? '',
                                   });
                                   return data;
                               }}
                        />
                        <Route path="*" element={<NotFound/>}/>
                    </Route>

                    <Route path="customers/:customerId"
                           element={<CustomerDetails/>}
                    />

                    {/* Campaigns */}
                    <Route path="campaigns"
                           element={<CampaignsLayout/>}
                           {...errorBoundaryProps}
                    >
                        {/* Custom Campaigns */}
                        <Route path="" element={<CustomCampaignList/>}
                               loader={async (loaderArgs: LoaderFunctionArgs) => {
                                   const searchParams = new URL(loaderArgs.request.url).searchParams;
                                   const {data} = await customCampaignResource.getList({
                                       pageIndex: searchParams.get('pageIndex') ?? defaultPageIndex.toString(),
                                       pageSize: searchParams.get('pageSize') ?? defaultPageSize.toString(),
                                       sortByDesc: searchParams.get('sortByDesc') ?? defaultSortByDesc.toString(),
                                       sortByField: searchParams.get('sortByField') ?? 'scheduledTime',
                                       search: searchParams.get('search') ?? '',
                                   });
                                   return data;
                               }}
                        />

                        {/* Auto Campaigns */}
                        <Route path="auto" element={<AutoCampaignList/>}
                               loader={async () => {
                                   const {data} = await autoCampaignResource.getList();
                                   return data;
                               }}
                        />
                    </Route>

                    <Route path="campaigns/create-announcement"
                           element={<CustomCampaignDetails/>}
                           loader={async () => {
                               try {
                                   const data = await customCampaignResource.getCampaignPageConfig();
                                   const campaign = customCampaignFactory(selectedBusinessOption, {
                                       compiledCustomEmailTemplate: data.compiledCustomEmailTemplate,
                                       unlayerEmailDesign: data.unlayerEmailDesign,
                                       isOfferEnabled: false,
                                   });
                                   campaign.templateVariables = data.variables;
                                   return {
                                       ...data,
                                       campaign,
                                   };
                               } catch {
                                   return null;
                               }
                           }}
                    />
                    <Route path="campaigns/create-offer"
                           element={<CustomCampaignDetails/>}
                           loader={async () => {
                               try {

                                   const data = await customCampaignResource.getCampaignPageConfig();
                                   const campaign = customCampaignFactory(selectedBusinessOption, {
                                       compiledCustomEmailTemplate: data.compiledCustomEmailTemplate,
                                       unlayerEmailDesign: data.unlayerEmailDesign,
                                       isOfferEnabled: true,
                                   });
                                   campaign.templateVariables = data.variables;
                                   return {
                                       ...data,
                                       campaign,
                                   };
                               } catch {
                                   return null;
                               }
                           }}
                    />
                    <Route path="campaigns/:campaignId"
                           element={<CustomCampaignDetails/>}
                           loader={async (loaderFunctionArgs) => {
                               try {
                                   const defaultCampaign = customCampaignFactory(selectedBusinessOption);
                                   const campaignId = loaderFunctionArgs.params.campaignId as unknown as number;
                                   const data = await customCampaignResource.getCampaignPageConfig(campaignId);
                                   const refreshedCampaign = (data.campaign || defaultCampaign) as CustomCampaignDetailsResponse;
                                   if (data.campaign) {
                                       // Loading an existing campaign's details...
                                       refreshedCampaign.campaignDetailsEmailTemplate = (data.campaign.campaignDetailsEmailTemplate?.length ?? 0) > 0 ?
                                           data.campaign.campaignDetailsEmailTemplate :
                                           data.compiledCustomEmailTemplate;

                                       refreshedCampaign.unlayerEmailDesign = data.campaign.unlayerEmailDesign ? data.campaign.unlayerEmailDesign : null;

                                       refreshedCampaign.offerExpirationDate = isString(refreshedCampaign.offerExpirationDate) && refreshedCampaign.offerExpirationDate.length > 0 ?
                                           DateTime.fromISO(refreshedCampaign.offerExpirationDate).toJSDate() :
                                           DateTime.local().plus({month: 1}).toJSDate();

                                       refreshedCampaign.scheduleFor = isString(refreshedCampaign.scheduleFor) && refreshedCampaign.scheduleFor.length > 0 ?
                                           DateTime.fromISO(refreshedCampaign.scheduleFor).toJSDate() :
                                           DateTime.local().set({minute: 0}).plus({week: 1, hour: 1}).toJSDate();

                                   } else {
                                       // Loading a new campaign's details...
                                       refreshedCampaign.campaignDetailsEmailTemplate = data.compiledCustomEmailTemplate;
                                       refreshedCampaign.unlayerEmailDesign = data.unlayerEmailDesign;
                                   }

                                   refreshedCampaign.templateVariables = data.variables;
                                   return {
                                       ...data,
                                       campaign: refreshedCampaign,
                                   };
                               } catch {
                                   return null;
                               }
                           }}
                    />

                    <Route path="campaigns/auto/:campaignId" element={<AutoCampaignDetails/>}
                           loader={async (loaderFunctionArgs) => {
                               const {data} = await autoCampaignResource.get(loaderFunctionArgs.params.campaignId as string);
                               return data;
                           }}
                    />

                    <Route path="campaigns/create-lifetime-points-milestone"
                           element={<AutoCampaignDetails/>}
                           loader={async () => {
                               const {data} = await autoCampaignResource.getMilestonePrototype(10);
                               return {isNewMilestone: true, ...data};
                           }}
                    />

                    <Route path="campaigns/create-visit-milestone"
                           element={<AutoCampaignDetails/>}
                           loader={async () => {
                               const {data} = await autoCampaignResource.getMilestonePrototype(11);
                               return {isNewMilestone: true, ...data};
                           }}
                    />

                    {/* Rewards */}
                    <Route path="rewards"
                           element={<RewardsLayout/>}
                           {...errorBoundaryProps}
                    >
                        <Route index element={<RewardSettings/>}/>
                        <Route path="kiosk" element={<KioskSettings/>}/>
                        <Route path="*" element={<NotFound/>}/>
                    </Route>

                    {/* Locations */}
                    <Route path="locations/"
                           element={<LocationsLayout/>}
                           {...errorBoundaryProps}
                    >
                        <Route index element={<LocationList/>}
                               loader={async () => {
                                   const {data} = await locationResource.getList();
                                   return data;
                               }}
                        />
                        <Route path=":locationId/edit" element={<LocationDetails/>}/>
                        <Route path=":locationId/sign-up-form"
                               element={<SignUpFormSettings/>}
                               loader={async (loaderArgs: LoaderFunctionArgs) => {
                                   const locationId = loaderArgs.params?.locationId as unknown as number;
                                   try {
                                       const {data} = await signUpFormSettingsResource.get(locationId);
                                       return data;
                                   } catch {
                                       return null;
                                   }
                               }}
                        />
                        <Route path="*" element={<NotFound/>}/>
                    </Route>

                    {/* Settings */}
                    <Route path="settings"
                           element={<SettingsLayout/>}
                           {...errorBoundaryProps}
                    >
                        <Route index element={<BusinessSettings/>}/>
                        <Route path="brand" element={<BrandSettings/>}/>
                        <Route path="customer" element={<CustomerSettings/>}/>
                        <Route path="update-payment-method" element={<UpdatePaymentMethod/>}/>
                        <Route path="*" element={<NotFound/>}/>
                    </Route>

                    <Route path="*" element={<NotFound/>}/>
                </Route>
            </>,
        ),
    );

    return (
        <>
            <HubSpotSupportChat/>
            <RouterProvider router={router}/>
        </>
    );
}

export default App;
