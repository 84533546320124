import {FiAlertCircle} from 'react-icons/all';
import React from 'react';
import {Button, Container, Stack} from 'react-bootstrap';
import {useRouteError} from 'react-router';
import {AxiosError} from 'axios';

export function RouterErrorBoundary() {
    const error = useRouteError() as AxiosError;
    const isAccessDeniedError = error?.response?.status === 403;

    return (
        <div role="alert" className="mt-5">
            <Container>
                <Stack>
                    <div>
                        <div className="text-danger text-center">
                            <FiAlertCircle style={{fontSize: 100}} className="mx-auto"/>
                        </div>
                    </div>
                    <div>
                        <div className="p-3 text-center">
                            Oops...
                        </div>
                        <div className="text-center mb-4">
                            {isAccessDeniedError ?
                                'You are not authorized to view this page.' :
                                error.message
                            }
                        </div>
                    </div>
                    {
                        !isAccessDeniedError && (
                            <div className="mx-auto">
                                <div>
                                    <Button onClick={() => {
                                        window.location.reload();
                                    }}>Try Refreshing?</Button>
                                </div>
                            </div>
                        )
                    }
                </Stack>
            </Container>
        </div>
    );
}
