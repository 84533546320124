import React from 'react';
import {Alert} from 'react-bootstrap';
import {useFormikContext} from 'formik';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faWarning} from '@fortawesome/free-solid-svg-icons';

interface Fields {
    form: Record<string, string>[],
}

export function ServerSideFormValidationErrors() {
    const {errors} = useFormikContext<Fields>();
    return (
        <>
            {errors.form && (
                Object.values(errors.form as string[]).map((formError, index) => (
                    <Alert variant="danger" key={index}>
                        <FontAwesomeIcon icon={faWarning} fixedWidth className="me-1"/>
                        {formError}
                    </Alert>
                ))
            )}
        </>
    );
}
