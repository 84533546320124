import React, {useEffect, useState} from 'react';
import {Menu, MenuItem, ProSidebar, SidebarContent, SidebarFooter, SidebarHeader} from 'react-pro-sidebar';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronCircleLeft, faChevronCircleRight} from '@fortawesome/free-solid-svg-icons';
import {Button, Collapse, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {Link, useLocation} from 'react-router-dom';
import appLogoIcon from '@images/app_logo_icon.svg';
import {useAppContext} from '@/AppContext';
import {MenuItem as SidebarMenuItem} from '@/services/menu';
import {FadeIn} from '@/components/animation';
import {UpgradePlanModal} from '@/components/product';
import {cache} from '@/services';

function Aside() {
    const location = useLocation();
    const {sidebarMenuItems} = useAppContext();
    const [isStateRestored, setIsStateRestored] = useState(false);
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
    const [menuItems, setMenuItems] = useState<SidebarMenuItem[]>([]);

    useEffect(() => {
        const pathParts = location.pathname.split('/').filter((x) => x !== '');
        const updatedMenuItems = sidebarMenuItems.map((menuItem: SidebarMenuItem) => {
            const isRootMatch = pathParts.length >= 1 && pathParts[0] === menuItem.to;
            const isExactMatch = menuItem.to === location.pathname;
            menuItem.active = isRootMatch || isExactMatch;
            return menuItem as SidebarMenuItem;
        });
        setMenuItems(updatedMenuItems);
    }, [sidebarMenuItems, location.pathname]);

    useEffect(() => {
        const state = cache.get('Aside');
        setIsSidebarCollapsed(state?.isSidebarCollapsed ?? false);
        setIsStateRestored(true);
    }, []);

    useEffect(() => {
        if (isStateRestored) {
            cache.set('Aside', {isSidebarCollapsed});
        }
    }, [isSidebarCollapsed]);

    if (!isStateRestored) {
        return null;
    }

    function makeMenuItem(sidebarMenuItem: SidebarMenuItem) {
        return (
            <MenuItem icon={<FontAwesomeIcon icon={sidebarMenuItem.icon}/>} active={sidebarMenuItem.active}>
                <Link to={sidebarMenuItem.to} className={sidebarMenuItem.active ? 'text-primary' : ''}>{sidebarMenuItem.name}</Link>
            </MenuItem>
        );
    }

    return (
        <ProSidebar
            collapsed={isSidebarCollapsed}
            breakPoint="md"
            className="d-lg-block d-none"
            style={{
                height: '100vh',
                overflowY: 'auto',
                overflowX: 'hidden',
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                zIndex: 101,
            }}
        >
            <SidebarHeader className="border-end">
                <div className="sidebar-header">
                    <img src={appLogoIcon}
                         alt="Loyalti"
                         style={{height: '30px', marginLeft: '12px'}}/>
                    <Collapse in={!isSidebarCollapsed} dimension="width">
                        <span className="ms-2">Loyalti</span>
                    </Collapse>
                </div>
            </SidebarHeader>
            <SidebarContent className="border-end">
                <Menu iconShape="square">
                    {menuItems.map((x: SidebarMenuItem) => (
                        <div key={x.key}>
                            {isSidebarCollapsed ?
                                <OverlayTrigger placement="right"
                                                overlay={(<Tooltip>{x.name}</Tooltip>)}
                                >
                                    {makeMenuItem(x)}
                                </OverlayTrigger> :
                                makeMenuItem(x)
                            }
                        </div>
                    ))}
                </Menu>
            </SidebarContent>

            <SidebarFooter className="border-end pb-3 border-top-0">
                <div className="d-flex justify-content-center">
                    <UpgradePlanModal isCollapsed={isSidebarCollapsed}/>
                </div>
            </SidebarFooter>

            <SidebarFooter className="border-end">
                <div className="p-2 text-center">
                    <Button
                        onClick={() => setIsSidebarCollapsed(!isSidebarCollapsed)}
                        aria-expanded={isSidebarCollapsed}
                        variant="link"
                        style={{textDecoration: 'none', color: '#adadad'}}
                        size="sm"
                    >
                        <div style={{whiteSpace: 'nowrap'}}>
                            <FontAwesomeIcon icon={isSidebarCollapsed ? faChevronCircleRight : faChevronCircleLeft}/>
                            {!isSidebarCollapsed &&
                                <FadeIn className="d-inline">
                                    <span className="ps-2">Collapse</span>
                                </FadeIn>}
                        </div>
                    </Button>
                </div>
            </SidebarFooter>
        </ProSidebar>
    );
}

export default Aside;
