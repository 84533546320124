import React, {PropsWithChildren} from 'react';
import classNames from 'classnames';

interface Props {
    className?: string;
}

function FadeIn({
    className,
    children,
}: PropsWithChildren<Props>) {
    return (
        <div className={classNames('animate__animated animate__fadeIn animate__faster', className)}>
            {children}
        </div>
    );
}

export default FadeIn;
