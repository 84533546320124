import React, {PropsWithChildren} from 'react';
import {ButtonGroup, ButtonToolbar} from 'react-bootstrap';
import Switch from 'react-switch';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faX, IconDefinition} from '@fortawesome/free-solid-svg-icons';
import {themeColors} from '@/services/theme';
import {If} from '@/components/container';
import {Color} from 'react-bootstrap/esm/types';
import {HelpTip} from '@/components/form/HelpTip';

interface Props {
    onChange(value: boolean): Promise<void>;

    checked: boolean;
    disabled: boolean;
    text?: string;
    switchable?: boolean;
    checkedWhileUnSwitchableIf?: boolean;
    unSwitchableTooltip?: string;

    checkedIcon?: IconDefinition,
    uncheckedIcon?: IconDefinition,
    uncheckedColor?: Color;
}

export function BigSwitch({
    text,
    onChange,
    checked,
    disabled,
    switchable = true,
    checkedWhileUnSwitchableIf = false,
    unSwitchableTooltip,
    checkedIcon = faCheck,
    uncheckedIcon = faX,
    uncheckedColor = 'danger',
    children,
}: PropsWithChildren<Props>) {
    return (
        <ButtonToolbar>
            <ButtonGroup style={{display: 'flex', alignItems: 'center'}}>
                <If isTrue={switchable}
                    as={Switch}
                    onChange={onChange}
                    uncheckedIcon={
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            fontSize: 15,
                            paddingRight: 2,
                        }}>
                            <FontAwesomeIcon icon={uncheckedIcon}
                                             fixedWidth
                                             className="text-white"
                            />
                        </div>
                    }
                    checkedIcon={
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            fontSize: 15,
                            paddingRight: 2,
                        }}>
                            <FontAwesomeIcon icon={checkedIcon}
                                             fixedWidth
                                             className="text-white"
                            />
                        </div>
                    }
                    offColor={themeColors[uncheckedColor]}
                    onColor={themeColors.primary}
                    checked={checked}
                    disabled={disabled}/>

                <If isTrue={!switchable} as={HelpTip} text={unSwitchableTooltip}>
                    <FontAwesomeIcon
                        icon={checked || checkedWhileUnSwitchableIf ? checkedIcon : uncheckedIcon}
                        fixedWidth
                        className="bg-info text-white rounded-circle p-2"
                        style={{borderRadius: '50%', height: 14, width: 14}}
                    />
                </If>

                <If isTrue={!!text}
                    as="small"
                    className="d-inline-block float-start me-2 mx-2">
                    {text}
                </If>
                {children}
            </ButtonGroup>
        </ButtonToolbar>
    )
    ;
}
