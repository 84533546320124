import React, {PropsWithChildren} from 'react';

interface Props {
}

function HeadShake({children}: PropsWithChildren<Props>) {
    return (
        <div className="animate__animated animate__slow animate__headShake">
            {children}
        </div>
    );
}

export default HeadShake;
