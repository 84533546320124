import React from 'react';

export function HubSpotWorkAround() {
    return (
        <div className="visually-hidden">
            <label htmlFor="cc-num"
                   className="visually-hidden"
            >
                credit card HubspotCollectedFormsWorkaround
                https://community.hubspot.com/t5/APIs-Integrations/How-to-stop-collected-forms-from-collecting-a-form/m-p/299172#M28102
            </label>
            <input name="cc-num"
                   className="visually-hidden"
                   required
                   value="HubspotCollectedFormsWorkaround"
                   onChange={() => {
                }}
                   id="cc-num"
            />
        </div>
    );
}
