import {Button, OverlayTrigger, Stack, Tooltip} from 'react-bootstrap';
import {SimpleCard} from '@/components/container';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle, faEdit, faMinusCircle, faTrash} from '@fortawesome/free-solid-svg-icons';
import React, {useState} from 'react';
import {useAutoCampaignResource} from '@/services/api';
import {useNavigate} from 'react-router';
import {AutoCampaignDetailsResponse} from '@shared/responseModels/business';
import {ActOnConfirmButton, HorizontalDivider} from '@/components/form';
import {toNumberString} from '@/services/textFormatting';
import classNames from 'classnames';

interface Props {
    campaign: AutoCampaignDetailsResponse;
    isDeletable?: boolean;
}

export function AutoCampaignSummaryCard({campaign, isDeletable = false}: Props) {
    const navigate = useNavigate();
    const [isProcessing, setIsProcessing] = useState(false);
    const autoCampaignResource = useAutoCampaignResource();

    const onClickDeleteCampaign = () => {
        autoCampaignResource.delete(campaign.id).then(() => navigate('/campaigns/auto'));
    };

    const tooltipDelayMilliseconds = 500;

    return (
        <SimpleCard hasBodyPadding={false}
                    hasCompactCardHeader={true}
                    title={
                        <Stack direction="horizontal" gap={1}>
                            <OverlayTrigger placement="top"
                                            overlay={(<Tooltip>The campaign is turned {campaign.isEnabled ? 'on' : 'off'}</Tooltip>)}
                                            delay={tooltipDelayMilliseconds}
                    >
                                <FontAwesomeIcon icon={campaign.isEnabled ? faCheckCircle : faMinusCircle}
                                                 className={classNames('fs-4 text-info', campaign.isEnabled ? '' : 'text-muted')}
                                                 fixedWidth/>
                            </OverlayTrigger>
                            {campaign.summaryName}
                            <div className="ms-auto me-2"/>
                            {isDeletable &&

                            <OverlayTrigger placement="top"
                                            overlay={(<Tooltip>Delete</Tooltip>)}
                                            delay={tooltipDelayMilliseconds}
                                >
                                <div>
                                    <ActOnConfirmButton buttonVariant="link"
                                                        buttonSize="sm"
                                                        className="mx-0 mx-0"
                                                        icon={<FontAwesomeIcon icon={faTrash} fixedWidth/>}
                                                        confirmButtonVariant="danger"
                                                        confirmButtonText="Yes, Delete"
                                                        onConfirm={onClickDeleteCampaign}
                                        />
                                </div>
                            </OverlayTrigger>
                    }

                            <OverlayTrigger placement="top"
                                            overlay={(<Tooltip>Edit</Tooltip>)}
                                            delay={tooltipDelayMilliseconds}
                    >
                                <Button onClick={() => navigate(`/campaigns/auto/${campaign.id}`)}
                                        size="sm"
                                        variant="link"
                                        className="mx-0 mx-0"
                                        style={{paddingInline: 0}}
                                        disabled={isProcessing}
                        >
                                    <FontAwesomeIcon icon={faEdit} fixedWidth/>
                                </Button>
                            </OverlayTrigger>
                        </Stack>
            }
        >

            <div className="px-3 py-2 fst-italic fs-7 fw-light">
                {campaign.description}
            </div>

            <div className="px-3 py-2">
                <Stack direction="horizontal" className="mb-1" gap={2}>
                    <div className="text-muted">Offer {campaign.hasSecondOffer && '1'}</div>
                    <div>{campaign?.offerDescription1}</div>
                </Stack>
                {campaign.hasSecondOffer &&
                    <Stack direction="horizontal" className="mb-1" gap={2}>
                        <div className="text-muted">Offer 2</div>
                        <div>{campaign?.offerDescription2}</div>
                    </Stack>
                }
            </div>

            <HorizontalDivider/>

            <Stack direction="horizontal" gap={2} className="px-3 py-2 fs-7">
                <div>{toNumberString(campaign.offerStats?.total as number)} <span>Total</span></div>
                <div>{toNumberString(campaign.offerStats?.claimed as number)} Claimed</div>
                <div>{toNumberString(campaign.offerStats?.redeemed as number)} Redeemed</div>
            </Stack>
        </SimpleCard>
    );
}
