import React from 'react';
import {Cell, flexRender, RowData} from '@tanstack/react-table';
import {isFunction} from 'lodash';
import {Stack} from 'react-bootstrap';
import {If} from '@/components/container';
import {SelectFilter} from '@/components/filters';
import {SelectOptions} from '@shared/responseModels';

interface Props<TData> {
    cell: Cell<TData, unknown>;
    position: number;
}

export function MobileCell<TData extends RowData>({cell, position}: Props<TData>) {
    const header = cell.column.columnDef.header;
    const headerValue = isFunction(header) ? (header as CallableFunction)() : header;
    const meta = cell.column.columnDef?.meta;

    function renderCell(cell: Cell<TData, unknown>) {
        const mobileCell = cell?.column?.columnDef?.meta?.mobileCell;
        return flexRender(mobileCell ?? cell.column.columnDef.cell, cell.getContext());
    }

    function onApplyFilter(selectedOptions: SelectOptions) {
        cell.column.setFilterValue(selectedOptions);
    }

    return (
        <Stack direction="horizontal" className="mb-2" gap={1} key={position}>
            <div className="fw-bold bg-light"
                 style={{
                     fontSize: 11,
                     width: 110,
                 }}
            >
                <Stack direction="horizontal" gap={1}>
                    {headerValue}

                    <If isTrue={(meta?.discreteFilterOptions?.available?.length || 0) > 1}
                        as={SelectFilter}
                        available={meta?.discreteFilterOptions?.available ?? []}
                        selected={meta?.discreteFilterOptions?.selected ?? []}
                        onApplyFilter={onApplyFilter}
                        modalTitle={headerValue as string}
                    />
                </Stack>

            </div>
            <div className="w-50 text-start">
                {renderCell(cell)}
            </div>
        </Stack>
    );
}
