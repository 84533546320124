import React, {MouseEventHandler} from 'react';
import {Button} from 'react-bootstrap';
import classNames from 'classnames';
import {useNavigate} from 'react-router';
import {To} from 'history';
import {ButtonVariant} from 'react-bootstrap/types';

type Props = {
    className?: string,
    text?: string,
    icon?: React.ReactElement,
    disabled?: boolean;
    circular?: boolean,
    onClick?: MouseEventHandler<HTMLButtonElement>
    to?: To;
    variant?: ButtonVariant;
    size?: 'sm' | 'lg';
    title?: string;
}

const IconButton: React.FC<Props> = ({
    text,
    icon,
    disabled,
    className,
    circular,
    onClick,
    to,
    variant = 'primary',
    size,
    title,
}) => {
    const navigate = useNavigate();

    return (
        <Button className={classNames(circular ? 'rounded-circle' : '', className)}
                style={!text ? {
                    height: 33,
                    width: 33,
                    padding: 0,
                } : {}}
                variant={variant}
                size={size}
                title={title}
                disabled={!!disabled}
                onClick={(event) => {
                    if (onClick) {
                        onClick(event);
                    }
                    if (to) {
                        navigate(to);
                    }
                }}>

            {icon}
            {text && <span className="px-1">{text}</span>}

        </Button>
    );
};

export default IconButton;
