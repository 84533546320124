import React, {useEffect, useState} from 'react';
import {useAppContext} from '@/AppContext';
import {makeQueryString} from '@/services/api';
import {useDebounce} from 'use-debounce';

interface Props {
    emailImage: string;
    emailImageLink: string;
    emailMessage: string;
    isOfferEnabled: boolean;
    offerDescription: string;
    claimButtonText?: string;
    offerExpirationDate: Date;
}

function InlineHtmlEmailPreview({
    emailImage,
    emailImageLink,
    emailMessage,
    isOfferEnabled,
    offerDescription,
    offerExpirationDate,
    claimButtonText = 'Claim Offer',
}: Props) {
    const {selectedBusinessOption} = useAppContext();
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);
    const [debouncedMessage] = useDebounce(emailMessage, 300);

    useEffect(() => {
        const params = {
            emailImage: emailImage ?? '',
            emailImageLink: emailImageLink ?? '',
            message: debouncedMessage ? debouncedMessage : '',
            ...(isOfferEnabled ? {
                claimButtonText,
                offerDescription,
            } : {}),
            ...(isOfferEnabled && offerExpirationDate? {
                offerExpirationDate: offerExpirationDate.toISOString(),
            } : {}),
        };

        const queryString = makeQueryString(params);
        setPreviewUrl(`/api/business/${selectedBusinessOption.value}/templates/get-html-preview?${queryString}`);
    }, [
        emailImage,
        emailImageLink,
        isOfferEnabled,
        offerDescription,
        offerExpirationDate,
        debouncedMessage,
    ]);

    return (
        <>
            <div className="border-start bg-white d-flex flex-column w-100 overflow-scroll"
                 style={{
                    minHeight: '750px',
                }}
            >
                {previewUrl &&
                    <iframe src={previewUrl}
                            style={{
                            flexGrow: 1,
                            margin: 0,
                            padding: 0,
                            border: 'none',
                            width: '100%',
                            height: '100%',
                            backgroundColor: '#fff',
                            position: 'relative',
                            top: 0,
                            left: 0,
                        }}
                    />
                }
            </div>
        </>
    );
}

export default InlineHtmlEmailPreview;
