import React, {ReactElement, useCallback} from 'react';
import {ButtonVariant} from 'react-bootstrap/types';
import Swal, {SweetAlertIcon} from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {isFunction} from 'lodash';
import {IconButton} from '@/components/form/index';

interface Props {
    tooltipText?: string;
    icon?: React.ReactElement,
    text?: string,
    onConfirm: () => void;
    buttonVariant?: ButtonVariant;
    confirmButtonVariant?: ButtonVariant;
    confirmButtonText?: string;
    alertIcon?: SweetAlertIcon;
    buttonSize?: 'sm' | 'lg';
    disabled?: boolean;
    className?: string;
    alertMessage?: ReactElement | string;
}

export default function ActOnConfirmButton(
    {
        tooltipText,
        icon,
        text,
        onConfirm,
        buttonVariant,
        confirmButtonVariant = 'primary',
        confirmButtonText = 'Yes',
        alertIcon = 'question',
        buttonSize,
        disabled,
        className = '',
        alertMessage = <p>This action cannot be undone.</p>,
    }: Props,
) {
    const MySwal = withReactContent(Swal);

    const onClick = useCallback(() => {
        MySwal.fire({
            icon: alertIcon,
            title: <p>Are you sure?</p>,
            html: alertMessage,
            buttonsStyling: false,
            confirmButtonText,
            customClass: {
                confirmButton: `btn btn-${confirmButtonVariant} text-white me-1`,
                cancelButton: 'btn btn-outline-secondary'
            },
            showCancelButton: true,
        }).then((result) => {
            if (result.isConfirmed && isFunction(onConfirm)) {
                onConfirm();
            }
        });
    }, []);

    return (
        <IconButton className={className}
                    title={tooltipText}
                    variant={buttonVariant}
                    size={buttonSize}
                    icon={icon}
                    text={text}
                    onClick={onClick}
                    disabled={disabled}
        />
    );
}
