import React, {useCallback, useEffect, useState} from 'react';
import {useBusinessAnalyticsResource} from '@/services/api';
import {useAppContext} from '@/AppContext';
import {CenteredSpinner} from '@/components/animation';
import {If, SimpleCard} from '@/components/container';
import {toMonthDayString, toNumberString, toShortTimeString} from '@/services/textFormatting';
import {groupBy} from 'lodash';
import {Stack} from 'react-bootstrap';
import {RedemptionDataPoint} from '@shared/responseModels/business';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCalendarAlt} from '@fortawesome/free-solid-svg-icons';

interface Props {
    locations: number[];
}

export function Redemptions({locations}: Props) {
    const {selectedBusinessOption} = useAppContext();
    const [isProcessing, setIsProcessing] = useState(true);
    const businessAnalyticsResource = useBusinessAnalyticsResource();
    const [totalRedemptions, setTotalRedemptions] = useState(0);
    const [groupedDataPoints, setGroupedDataPoints] = useState<Record<string, RedemptionDataPoint[]>>({});

    const fetchData = useCallback(() => {
        setIsProcessing(true);
        businessAnalyticsResource.getAnalyticsRedemptions({locations})
            .then(({data}) => {
                setTotalRedemptions(data.totalRedemptions);
                setGroupedDataPoints(groupBy(data.dataPoints, 'createdDate'));
            })
            .finally(() => setIsProcessing(false));
    }, [selectedBusinessOption, locations]);

    useEffect(() => {
        fetchData();
    }, [selectedBusinessOption, locations]);

    return (
        <>
            <SimpleCard title={<h4 className="text-muted ms-1 mt-2 me-0 mb-0">Rewards by Day - Last 90 Days</h4>}
                        style={{height: 345}}
            >
                <If isTrue={isProcessing} as={CenteredSpinner} height={210}/>
                <If isTrue={!isProcessing}>
                    <Stack direction="vertical">
                        <h2 className="mb-0">{toNumberString(totalRedemptions)}</h2>
                        <small className="text-muted">Total</small>
                    </Stack>

                    <div className="pb-3 overflow-y-scroll" style={{height: 200, overflowY: 'scroll'}}>
                        {Object.keys(groupedDataPoints).map((date, index) => {
                            const groupedData = groupedDataPoints[date];
                            return <div key={index}>
                                <Stack direction="horizontal" className="mt-3" gap={1}>
                                    <div className="text-info text-nowrap">
                                        <span className="me-1"><FontAwesomeIcon icon={faCalendarAlt}/></span>
                                        <span>{toMonthDayString(date)}</span>
                                    </div>
                                    <div className="small">•</div>
                                    <div className="small fst-italic">
                                        {`${toNumberString(groupedData.length)} ${groupedData.length === 1 ? 'redemption' : 'redemptions'}`}
                                    </div>
                                </Stack>

                                <div>
                                    {groupedData.map((redemption, grouping) => {
                                        return (
                                            <div className="ms-1 my-2 fw-light" key={grouping}>
                                                <Stack direction="horizontal" gap={3}>
                                                    <div className="text-nowrap text-end" style={{width: 54}}>
                                                        {toShortTimeString(redemption.created)}
                                                    </div>
                                                    <div className="w-25 text-nowrap text-truncate">
                                                        {redemption.customerName}
                                                    </div>
                                                    <div className="text-nowrap">
                                                        {toNumberString(redemption.points)} points
                                                    </div>
                                                    <div className="text-wrap">
                                                        {redemption.redeemedItemName}
                                                    </div>
                                                </Stack>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>;
                        })}
                    </div>
                </If>
            </SimpleCard>
        </>
    );
}
