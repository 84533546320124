import React, {useCallback} from 'react';
import {Formik, FormikHelpers} from 'formik';
import {useLoaderData, useNavigate} from 'react-router';
import AutoCampaignDetailsForm from '@/pages/Campaigns/components/AutoCampaignDetailsForm';
import {AutoCampaignFormValues, AutoCampaignSchema} from '@shared/requestSchemas';
import {cloneDeep} from 'lodash';
import {useAutoCampaignResource} from '@/services/api';
import {SetFieldError} from '@shared/formik';
import {AxiosError} from 'axios';
import {ErrorResponse} from '@shared/responseModels';

export function AutoCampaignDetails() {
    const campaign = useLoaderData() as AutoCampaignFormValues;
    const navigate = useNavigate();
    const autoCampaignResource = useAutoCampaignResource();

    const addServerSideFieldErrors = useCallback((setFieldError: SetFieldError, error: AxiosError<ErrorResponse>) => {
        error?.response?.data.validationMessages.map((fieldError: {
            paramName: string,
            message: string
        }) => {
            setFieldError(fieldError.paramName, fieldError.message);
        });
    }, []);

    const onSubmit = useCallback((values: AutoCampaignFormValues, {setSubmitting, setFieldError}: FormikHelpers<AutoCampaignFormValues>) => {
        const campaignToPersist = cloneDeep(values);
        if (campaign.id) {
            autoCampaignResource.update(campaign.id, campaignToPersist)
                .then(({data}) => navigate(`/campaigns/auto/${data.id}`))
                .catch((error) => {
                    addServerSideFieldErrors(setFieldError, error);
                })
                .finally(() => setSubmitting(false));
        } else {
            autoCampaignResource.create(campaignToPersist)
                .then(({data}) => navigate(`/campaigns/auto/${data.id}`))
                .catch((error) => {
                    addServerSideFieldErrors(setFieldError, error);
                })
                .finally(() => setSubmitting(false));
        }
    }, [campaign.id]);

    return (
        <div className="mb-5">
            <Formik initialValues={campaign}
                    enableReinitialize
                    validationSchema={AutoCampaignSchema}
                    onSubmit={onSubmit}
                    component={AutoCampaignDetailsForm}
            />
        </div>
    );
}
