import React, {useEffect, useState} from 'react';
import {
    Alert,
    ButtonGroup,
    Card,
    Col,
    Container,
    FormControl,
    FormGroup,
    FormLabel,
    FormSelect, InputGroup,
    Row,
    Stack,
} from 'react-bootstrap';
import {ArrayHelpers, Field, FieldArray, Form, FormikErrors, FormikTouched, FormikValues} from 'formik';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowDown, faArrowUp, faEye, faPlus, faTrash} from '@fortawesome/free-solid-svg-icons';
import {CardHeaderTitle, If, MobileRewardCard, PageFooter} from '@/components/container';
import {FormikImage, IconButton, ServerSideFormValidationErrors, SubmitOnConfirmButton} from '@/components/form';
import classNames from 'classnames';
import {url} from '@/services';
import {useAppContext} from '@/AppContext';
import {UpgradeCallout} from '@/components/product';
import {RewardFormValues, TieredReward, TieredRewardFields} from '@shared/requestSchemas';
import {isString} from 'lodash';

interface Props<T> {
    isSubmitting: boolean;
    touched: FormikTouched<T>;
    errors: FormikErrors<T>;
    values: FormikValues;
    resetForm: () => void;
}

function RewardSettingsForm({
    values,
    errors,
    isSubmitting,
    touched,
    resetForm,
}: Props<RewardFormValues>) {
    const {selectedBusinessOption} = useAppContext();
    const {imageUrls} = useAppContext();
    const [selectedTieredReward, setSelectedTieredReward] = useState<TieredReward>();

    useEffect(() => {
        if (!selectedTieredReward) {
            return;
        }
        const updatedReward = values.tieredRewards.filter((x: TieredReward) => x?.id === selectedTieredReward?.id);
        if (updatedReward.length > 0) {
            setSelectedTieredReward(updatedReward[0]);
        }
    }, [values.tieredRewards]);

    function getPropertyByString(obj: object, propString: string) {
        // Split the property string into an array
        const props = propString.split('.');
        // Iterate through the array to access nested properties
        return props.reduce((acc, curr) => {
            // @ts-ignore
            return acc && acc[curr];
        }, obj);
    }

    function isTieredRewardFieldInvalid(index: number, fieldName: TieredRewardFields): boolean {
        if (!touched.tieredRewards || !errors.tieredRewards) {
            return false;
        }
        if (!touched.tieredRewards[index]) {
            return false;
        }
        if (!errors.tieredRewards[index]) {
            return false;
        }

        const tieredRewardTouched = getPropertyByString(touched.tieredRewards[index], fieldName);
        const tieredReward = errors.tieredRewards[index];
        const tieredRewardError = isString(tieredReward) ?
            false :
            getPropertyByString(tieredReward, fieldName);
        return !!tieredRewardError && !!tieredRewardTouched;
    }

    function renderArrayFieldError(index: number, fieldName: TieredRewardFields): string {
        if (!errors.tieredRewards || !errors.tieredRewards[index]) {
            return '';
        }

        const tieredReward = errors.tieredRewards[index];
        if (isString(tieredReward)) {
            return tieredReward;
        }
        return getPropertyByString(tieredReward, fieldName)?.toString();
    }

    return (
        <>
            <Container fluid>
                <ServerSideFormValidationErrors/>
            </Container>

            <Form noValidate>
                <FormGroup controlId="usesClover" className="d-none">
                    <FormLabel>Uses Clover</FormLabel>
                    <Field type="checkbox" className="d-non"/>
                </FormGroup>

                <Container fluid className="mb-3">
                    {values.isPunchCardLoyaltyProgram ?
                        <Row>
                            <Col lg={{span: 6}} md={{span: 12}}>
                                <Card className="shadow">
                                    <Card.Header>
                                        <CardHeaderTitle>Reward</CardHeaderTitle>
                                    </Card.Header>
                                    <Card.Body>
                                        <FormGroup controlId="rewardItem" className="mb-3">
                                            <FormLabel>Reward Name</FormLabel>
                                            <Field as={FormControl}
                                                   name="rewardItem"
                                                   disabled={isSubmitting}
                                                   isInvalid={!!errors.rewardItem && touched.rewardItem}/>
                                            <FormControl.Feedback type="invalid">
                                                {errors.rewardItem}
                                            </FormControl.Feedback>
                                        </FormGroup>

                                        <FormGroup controlId="maxPoints" as={Col} xs={4} className="mb-3">
                                            <FormLabel>Points</FormLabel>
                                            <Field as={FormControl}
                                                   name="maxPoints"
                                                   type="number"
                                                   min={1}
                                                   max={199999}
                                                   disabled={isSubmitting}
                                                   isInvalid={!!errors.maxPoints && touched.maxPoints}
                                            />
                                            <FormControl.Feedback type="invalid">
                                                {errors.maxPoints}
                                            </FormControl.Feedback>
                                        </FormGroup>

                                        {selectedBusinessOption.usesMobileImageUpload &&
                                            <FormGroup controlId="rewardImageFile" className="mb-3">
                                                <FormLabel>Reward Image</FormLabel>

                                                <Field as={FormikImage}
                                                       name="rewardImageFile"
                                                       disabled={isSubmitting}
                                                       aspectRatio={(8 / 9)}
                                                       isInvalid={errors.rewardImageFile && touched.rewardImageFile}
                                                       imageUrl={values.rewardImageUrl}
                                                />

                                                <FormControl.Feedback type="invalid">
                                                    {errors.rewardImageFile}
                                                </FormControl.Feedback>
                                            </FormGroup>
                                        }
                                    </Card.Body>
                                </Card>
                            </Col>
                            {selectedBusinessOption.usesMobileApp &&
                                <Col lg={{span: 6}} md={{span: 12}}>
                                    <Card className="shadow">
                                        <Card.Header>
                                            <CardHeaderTitle>Mobile App Preview</CardHeaderTitle>
                                        </Card.Header>
                                        <Card.Body className="m-auto py-5">
                                            <MobileRewardCard businessLogoUrl={values.businessLogoUrl}
                                                              rewardName={values.rewardItem}
                                                              rewardCost={values.maxPoints}
                                                              rewardImageUrl={values.rewardImageFile ?
                                                                  URL.createObjectURL(values.rewardImageFile) :
                                                                  values.rewardImageUrl}
                                            />
                                        </Card.Body>

                                        {selectedBusinessOption.canUpgradeTo.usesMobileImageUpload && (
                                            <Card.Footer className="bg-primary p-2 text-center">
                                                <UpgradeCallout
                                                    canUpgradeTo={true}
                                                    text="your plan to add custom reward images."
                                                />
                                            </Card.Footer>
                                        )}
                                    </Card>
                                </Col>
                            }
                        </Row> : null}

                    {values.isTieredRewardsLoyaltyProgram ?
                        <FieldArray
                            name="tieredRewards"
                            render={(arrayHelpers: ArrayHelpers) => (
                                <Row>
                                    <Col xxl={{span: 6}} xl={{span: 12}}>
                                        <Card className="shadow">
                                            <Card.Header>
                                                <CardHeaderTitle>
                                                    Rewards
                                                </CardHeaderTitle>
                                            </Card.Header>
                                            <div>
                                                <table className="table m-0 border-bottom-0">
                                                    <tbody>
                                                    <tr>
                                                        <th style={{width: '3%'}} className="text-center">#</th>
                                                        {selectedBusinessOption.usesMobileImageUpload && <th>Image</th>}
                                                        <If isTrue={!selectedBusinessOption.usesClover} as="th">Name</If>
                                                        <If isTrue={selectedBusinessOption.usesClover} as="th">Discount</If>
                                                        <th>Cost</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                    </tbody>
                                                    <tbody>
                                                    {values.tieredRewards && values.tieredRewards.length > 0 ? (
                                                        values.tieredRewards.map((reward: TieredReward, index: number) => (
                                                            <tr key={index}
                                                                className={
                                                                    classNames('border-bottom pt-2 pb-3 px-3',
                                                                        values.tieredRewards[index] === selectedTieredReward ?
                                                                            'bg-light' : '',
                                                                        !values.tieredRewards[index].id ?
                                                                            'border-lg-start-warning' : '',
                                                                    )
                                                                }
                                                            >
                                                                <td width="3%" className="text-center">
                                                                    {reward.id ? reward.id : 'New'}
                                                                </td>

                                                                {selectedBusinessOption.usesMobileImageUpload &&
                                                                    <td>
                                                                        <FormGroup
                                                                            controlId={`tieredRewards.${index}.rewardImageFile`}>
                                                                            <FormLabel className="sr-only">Reward
                                                                                Image</FormLabel>
                                                                            <Field as={FormikImage}
                                                                                   name={`tieredRewards.${index}.rewardImageFile`}
                                                                                   size="sm"
                                                                                   disabled={isSubmitting}
                                                                                   aspectRatio={(8 / 9)}
                                                                                   isInvalid={isTieredRewardFieldInvalid(index, 'rewardImageFile') && touched.rewardImageFile}
                                                                                   imageUrl={values.tieredRewards[index].rewardImageUrl}
                                                                                   layout="table"
                                                                            />
                                                                            <FormControl.Feedback type="invalid">
                                                                                {renderArrayFieldError(index, 'rewardImageFile')}
                                                                            </FormControl.Feedback>
                                                                        </FormGroup>
                                                                    </td>
                                                                }

                                                                <If isTrue={!selectedBusinessOption.usesClover} as="td">
                                                                    <FormGroup
                                                                        controlId={`tieredRewards.${index}.rewardTitle`}>
                                                                        <FormLabel className="sr-only">Name</FormLabel>
                                                                        <Field as={FormControl}
                                                                               name={`tieredRewards.${index}.rewardTitle`}
                                                                               size="sm"
                                                                               disabled={isSubmitting}
                                                                               isInvalid={isTieredRewardFieldInvalid(index, 'rewardTitle')}
                                                                        />
                                                                        <FormControl.Feedback type="invalid">
                                                                            {renderArrayFieldError(index, 'rewardTitle')}
                                                                        </FormControl.Feedback>
                                                                    </FormGroup>
                                                                </If>

                                                                <If isTrue={selectedBusinessOption.usesClover} as="td">
                                                                    <Stack direction="horizontal" gap={2}>
                                                                        <FormGroup
                                                                            controlId={`tieredRewards.${index}.clover.discountType`}>
                                                                            <Field as={FormSelect}
                                                                                   size="sm"
                                                                                   aria-label="Clover reward type"
                                                                                   defaultValue="percentage"
                                                                                   name={`tieredRewards.${index}.clover.discountType`}
                                                                            >
                                                                                <option value="percentage">Percentage
                                                                                    Off
                                                                                </option>
                                                                                <option value="amount">
                                                                                    Fixed Amount Off
                                                                                </option>
                                                                            </Field>
                                                                        </FormGroup>

                                                                        <div>Take</div>

                                                                        <div style={{width: 140}}
                                                                             className={!values[`tieredRewards`][index]?.clover?.discountType || values[`tieredRewards`][index]?.clover?.discountType === 'percentage' ? '' : 'd-none'}
                                                                        >
                                                                            <FormGroup
                                                                                controlId={`tieredRewards.${index}.clover.percentage`}>
                                                                                <FormLabel className="sr-only">Discount
                                                                                    Amount</FormLabel>
                                                                                <InputGroup size="sm">
                                                                                    <Field as={FormControl}
                                                                                           type="text"
                                                                                           name={`tieredRewards.${index}.clover.percentage`}
                                                                                           disabled={isSubmitting}
                                                                                           isInvalid={isTieredRewardFieldInvalid(index, 'clover.percentage')}
                                                                                           autoComplete="off"
                                                                                    />
                                                                                    <InputGroup.Text>%</InputGroup.Text>
                                                                                    <FormControl.Feedback
                                                                                        type="invalid">
                                                                                        {renderArrayFieldError(index, 'clover.percentage')}
                                                                                    </FormControl.Feedback>
                                                                                </InputGroup>
                                                                            </FormGroup>
                                                                        </div>

                                                                        <div style={{width: 140}}
                                                                             className={values[`tieredRewards`][index]?.clover?.discountType === 'amount' ? '' : 'd-none'}
                                                                        >
                                                                            <FormGroup
                                                                                controlId={`tieredRewards.${index}.clover.amount`}>
                                                                                <FormLabel className="sr-only">Discount
                                                                                    Amount</FormLabel>
                                                                                <InputGroup size="sm">
                                                                                    <InputGroup.Text>$</InputGroup.Text>
                                                                                    <Field as={FormControl}
                                                                                           type="text"
                                                                                           name={`tieredRewards.${index}.clover.amount`}
                                                                                           disabled={isSubmitting}
                                                                                           isInvalid={isTieredRewardFieldInvalid(index, 'clover.amount')}
                                                                                           autoComplete="off"
                                                                                    />
                                                                                    <FormControl.Feedback
                                                                                        type="invalid">
                                                                                        {renderArrayFieldError(index, 'clover.amount')}
                                                                                    </FormControl.Feedback>
                                                                                </InputGroup>
                                                                            </FormGroup>
                                                                        </div>

                                                                        <div>Off</div>
                                                                    </Stack>
                                                                </If>

                                                                <td>
                                                                    <FormGroup
                                                                        controlId={`tieredRewards.${index}.cost`}>
                                                                        <FormLabel className="sr-only">Cost</FormLabel>
                                                                        <Field as={FormControl}
                                                                               type="number"
                                                                               min={1}
                                                                               max={199999}
                                                                               name={`tieredRewards.${index}.cost`}
                                                                               size="sm"
                                                                               disabled={isSubmitting}
                                                                               isInvalid={isTieredRewardFieldInvalid(index, 'cost')}
                                                                        />
                                                                        <FormControl.Feedback type="invalid">
                                                                            {renderArrayFieldError(index, 'cost')}
                                                                        </FormControl.Feedback>
                                                                    </FormGroup>
                                                                </td>

                                                                <td>
                                                                    <Stack direction="horizontal" gap={2}>
                                                                        <IconButton
                                                                            variant="danger"
                                                                            className="text-white"
                                                                            disabled={isSubmitting}
                                                                            title="Delete"
                                                                            size="sm"
                                                                            icon={
                                                                                <FontAwesomeIcon icon={faTrash}
                                                                                                 fixedWidth/>
                                                                            }
                                                                            onClick={() => {
                                                                                arrayHelpers.remove(index);
                                                                            }}
                                                                        />

                                                                        <ButtonGroup>
                                                                            <IconButton
                                                                                disabled={isSubmitting || index === 0}
                                                                                title="Move up"
                                                                                size="sm"
                                                                                icon={<FontAwesomeIcon icon={faArrowUp}
                                                                                                       fixedWidth/>}
                                                                                onClick={() => {
                                                                                    arrayHelpers.swap(index, index - 1);
                                                                                }}
                                                                            />

                                                                            <IconButton
                                                                                disabled={isSubmitting || index === values.tieredRewards.length - 1}
                                                                                title="Move down"
                                                                                size="sm"
                                                                                icon={<FontAwesomeIcon
                                                                                    icon={faArrowDown}
                                                                                    fixedWidth/>}
                                                                                onClick={() => {
                                                                                    arrayHelpers.swap(index, index + 1);
                                                                                }}
                                                                            />
                                                                        </ButtonGroup>

                                                                        {selectedBusinessOption.usesMobileApp &&
                                                                            <IconButton
                                                                                title="Preview"
                                                                                size="sm"
                                                                                disabled={isSubmitting}
                                                                                icon={<FontAwesomeIcon
                                                                                    icon={faEye}
                                                                                    fixedWidth/>}
                                                                                onClick={() => {
                                                                                    setSelectedTieredReward(values.tieredRewards[index]);
                                                                                }}/>
                                                                        }
                                                                    </Stack>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) : null}
                                                    </tbody>
                                                </table>
                                            </div>
                                            {(selectedBusinessOption.canUpgrade && values.tieredRewards?.length === selectedBusinessOption.maximumRewardTiers) && (
                                                <div className="bg-primary p-2 text-center">
                                                    <UpgradeCallout
                                                        canUpgradeTo={true}
                                                        text="your plan to add more rewards."
                                                    />
                                                </div>
                                            )}
                                            <Card.Footer>
                                                <Stack direction="horizontal" gap={3}>
                                                    <div>
                                                        <IconButton icon={<FontAwesomeIcon icon={faPlus} fixedWidth/>}
                                                                    text="Add Reward"
                                                                    disabled={isSubmitting || values.tieredRewards?.length >= selectedBusinessOption.maximumRewardTiers}
                                                                    onClick={() => arrayHelpers.push({
                                                                        rewardTitle: '',
                                                                        cost: 1,
                                                                        rewardImageUrl: imageUrls.defaultRewardImage,
                                                                        clover: {
                                                                            discountType: 'percentage',
                                                                            amount: '',
                                                                            percentage: '',
                                                                        },
                                                                    })}/>
                                                    </div>
                                                    <div className="ms-auto me-4">
                                                        {values.tieredRewards?.length ?? 0} of {selectedBusinessOption.maximumRewardTiers} Rewards
                                                    </div>
                                                </Stack>
                                            </Card.Footer>
                                        </Card>
                                    </Col>
                                    {selectedBusinessOption.usesMobileApp &&
                                        <Col xxl={{span: 4}} xl={{span: 6}}>
                                            {selectedTieredReward &&
                                                <Card className="shadow">
                                                    <Card.Header>
                                                        <CardHeaderTitle>Mobile App Preview</CardHeaderTitle>
                                                    </Card.Header>
                                                    <Card.Body className="m-auto py-5">
                                                        <MobileRewardCard businessLogoUrl={values.businessLogoUrl}
                                                                          rewardName={selectedTieredReward?.rewardTitle}
                                                                          rewardCost={selectedTieredReward?.cost || 0}
                                                                          rewardImageUrl={selectedTieredReward?.rewardImageFile ?
                                                                              url.imageFileToUrl(selectedTieredReward?.rewardImageFile) :
                                                                              selectedTieredReward?.rewardImageUrl}
                                                        />
                                                    </Card.Body>
                                                    {selectedBusinessOption.canUpgradeTo.usesMobileImageUpload && (
                                                        <Card.Footer className="bg-primary p-2 text-center">
                                                            <UpgradeCallout
                                                                canUpgradeTo={true}
                                                                text="your plan to add custom reward images."
                                                            />
                                                        </Card.Footer>
                                                    )}
                                                </Card>
                                            }
                                        </Col>
                                    }
                                </Row>
                            )}
                        /> : null
                    }
                </Container>

                <PageFooter>
                    <SubmitOnConfirmButton disabled={isSubmitting}
                                           resetForm={resetForm}
                                           isSubmitting={isSubmitting}/>
                </PageFooter>
            </Form>
        </>
    );
}

export default RewardSettingsForm;
