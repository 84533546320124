import {useCallback, useEffect, useMemo, useRef} from 'react';
import {TableSearchParams} from '@/components/table/TableSearchParams';
import {PaginatedTableFetchDataArgs} from '@/components/table/PaginatedTableFetchDataArgs';
import {useLocation, useSearchParams} from 'react-router-dom';
import {isArray} from 'lodash';
import {useAppContext} from '@/AppContext';

type FetchTableDataArgs = PaginatedTableFetchDataArgs & {
    search?: string,
    defaultSortField: string,
    sortByDirection?: string;
};

export function useTableUrlParams<T>() {
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const fetchIdRef = useRef(0);
    const {setCustomerListSearchTerm, setCampaignListSearchTerm} = useAppContext();

    const tableUrlParams = useMemo(
        () => ({search: searchParams.get('search')}),
        [searchParams],
    );

    function updateSearchTermsFromUrlParams() {
        const searchTerm = searchParams.get('search') || ''
        setCustomerListSearchTerm(searchTerm);
        setCampaignListSearchTerm(searchTerm);
    }
    useEffect(updateSearchTermsFromUrlParams, []);
    useEffect(updateSearchTermsFromUrlParams, [searchParams, location]);

    function setTableUrlParams({
            pageSize,
            pageIndex,
            sortBy,
            search,
            defaultSortField,
        }: FetchTableDataArgs & T,
        filters?: Record<string, number[] | string | number>,
    ): boolean {
        const fetchId = ++fetchIdRef.current;
        if (fetchId !== fetchIdRef.current) {
            return false;
        }

        // Prepare sort by
        const sort = {id: defaultSortField, desc: true};
        if (sortBy.length > 0) {
            sort.id = sortBy[0].id;
            sort.desc = sortBy[0].desc;
        }

        // Prepare filters
        const queryFiltersParams: Record<string, string> = {};
        if (filters) {
            const filterKeys = Object.keys(filters);
            for (let i = 0; i < filterKeys.length; i++) {
                const filterKey = filterKeys[i];
                const filterValue = filters[filterKey] ?? '';
                queryFiltersParams[filterKey] = isArray(filterValue) ? filterValue.join(',') : filterValue.toString();
            }
        }

        // Prepare query params
        const newQueryParams: TableSearchParams = {
            pageIndex: pageIndex?.toString(),
            pageSize: pageSize?.toString(),
            sortByField: sort.id,
            sortByDesc: sort.desc?.toString(),
            search: search || '',
            fetchId: fetchId.toString(),
            ...queryFiltersParams,
        };

        setSearchParams(newQueryParams);
        return true;
    }

    return {
        tableUrlParams,
        setTableUrlParams,
        setSearchParams,
    };
}
