import React, {useMemo} from 'react';
import {FormControl} from 'react-bootstrap';
import {useFormikContext} from 'formik';

interface Props {
    fieldName: string;
}

export function ValidationError({fieldName}: Props) {
    const {errors} = useFormikContext();
    const message = useMemo(() => {
        // @ts-ignore
        return errors[fieldName];
    }, [errors]);
    return (
        <FormControl.Feedback type="invalid">
            {message}
        </FormControl.Feedback>
    );
}
