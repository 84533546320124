import {DateTime} from 'luxon';
import {TimeSeriesInterval} from '@shared/TimeSeriesInterval';
import {clientTimeZone} from './timeZones';

const serverTimeZone = 'UTC';
const fromIsoOptions = {zone: serverTimeZone};

// Dates
type DateFormatFunction = (isoDate: string | null) => string;

export interface DateFormatter {
    format: DateFormatFunction;
}

export function dateFormatterFactory(format: DateFormatFunction): DateFormatter {
    return {format};
}

export function dateFormatterFromTimeSeriesIntervalFactory(timeSeriesInterval: TimeSeriesInterval): DateFormatter {
    return dateFormatterFactory(timeSeriesInterval === 'day' ? toMonthDayString : toMonthYearString);
}

export const toMonthYearString = (isoDate: string | null): string => {
    return !isoDate ?
        '' :
        DateTime.fromISO(isoDate, fromIsoOptions).toFormat('LLL');
};
export const toMonthDayString = (isoDate: string | null): string => {
    return !isoDate ?
        '' :
        DateTime.fromISO(isoDate, fromIsoOptions).toFormat('LLL d');
};
export const toShortDateString = (isoDate: string | null): string => {
    return !isoDate ?
        '' :
        DateTime.fromISO(isoDate, fromIsoOptions).setZone(clientTimeZone).toLocaleString(DateTime.DATE_SHORT);
};
export const toShortDateStringWithoutTimezone = (isoDate: string | null): string => {
    return !isoDate ?
        '' :
        DateTime.fromISO(isoDate, fromIsoOptions).toLocaleString(DateTime.DATE_SHORT);
};
export const toShortDateTimeString = (isoDate: string | null): string => {
    return !isoDate ?
        '' :
        DateTime.fromISO(isoDate, fromIsoOptions).setZone(clientTimeZone).toLocaleString(DateTime.DATETIME_SHORT);
};

export const toShortTimeString = (isoDate: string | null): string => {
    return !isoDate ?
        '' :
        DateTime.fromISO(isoDate, fromIsoOptions).setZone(clientTimeZone).toLocaleString(DateTime.TIME_SIMPLE);
};

export const toMediumDateTimeString = (isoDate: string | null): string => {
    return !isoDate ?
        '' :
        DateTime.fromISO(isoDate, fromIsoOptions).setZone(clientTimeZone).toLocaleString(DateTime.DATETIME_MED);
};


// Numbers
export const toPercentage = (numerator: number, denominator: number): number => {
    return denominator === 0 ? 0 : (numerator / denominator) * 100;
};

export const toNumberString = (number: number): string => {
    return number?.toLocaleString('en-US') ?? number;
};

// Text
export const spliceTextAtCaretPosition = (input: {
    selectionStart: number
} | null, haystack: string, needle: string): string => {
    const position = input?.selectionStart ?? haystack.length;
    return `${haystack.substring(0, position)}${needle}${haystack.substring(position)}`;
};

export function concatOrdinalSuffixToNumber(number: number) {
    const s = ['th', 'st', 'nd', 'rd'];
    const v = number % 100;
    return number + (s[(v - 20) % 10] || s[v] || s[0]);
}
