import {useMemo} from 'react';
import Notify, {NotificationKind} from '@/services/notifications/Notify';
import {INotyfNotificationOptions, Notyf} from 'notyf';
import {logger} from 'sparkloyalty-frontend/logging';
import {themeColors} from '../theme';

function messageTypeFactory(type: string, background: string): INotyfNotificationOptions {
    return {
        background,
        type,
        icon: false,
        className: 'border-radius'
    } as INotyfNotificationOptions;
}

const toaster = new Notyf({
    duration: 2300,
    ripple: true,
    dismissible: true,
    position: {x: 'center', y: 'bottom'},
    types: [
        messageTypeFactory('info', themeColors.info),
        messageTypeFactory('warning', themeColors.warning),
        messageTypeFactory('success', themeColors.success),
        messageTypeFactory('danger', themeColors.danger),
    ],
});

function show(type: NotificationKind, message: string) {
    toaster.open({type, message});
}

export default function useNotify() {
    return useMemo((): Notify => {
        return ({
            show,
            warning: (message: string) => {
                show(NotificationKind.Warning, message);
            },
            info: (message: string) => {
                show(NotificationKind.Info, message);
            },
            success: (message: string) => {
                show(NotificationKind.Success, message);
            },
            errorMessage: (message: string) => {
                if ((message?.length ?? 0) === 0) {
                    message = 'Oops... it looks like something went wrong. Please try again.';
                }
                show(NotificationKind.Error, message);
            },
            error(error: Error, message?: string, context?: Record<string, unknown>) {
                this.errorMessage(message ?? error.message);
                if (error) {
                    logger.error(error, context);
                }
            },
        }) as Notify;
    }, []);
}
