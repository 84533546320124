import React from 'react';
import {Button, FormControl, InputGroup, OverlayTrigger, Stack, Tooltip} from 'react-bootstrap';
import copy from 'copy-to-clipboard';
import {useNotify} from '@/services';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faClipboard} from '@fortawesome/free-solid-svg-icons';
import {ButtonVariant} from 'react-bootstrap/types';

interface Props {
    textToCopy: string;
    prefixText: string;
    buttonIcon?: string;
    buttonVariant?: ButtonVariant;
}

export function CopyableText({textToCopy, prefixText, buttonIcon, buttonVariant = 'primary'}: Props) {
    const notify = useNotify();
    return (
        <div>
            <Stack direction="horizontal" gap={2}>
                <div className="fw-lighter fs-7 text-nowrap">{prefixText}</div>
                <InputGroup size="sm">
                    <FormControl className="me-auto font-monospace"
                                 value={textToCopy}
                                 readOnly={true}
                    />
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Copy to your clipboard</Tooltip>}>
                        <Button variant={buttonVariant} onClick={() => {
                            copy(textToCopy);
                            notify.success('Copied to clipboard.');
                        }}>
                            {buttonIcon ?
                                <img src={buttonIcon} className="img-fluid" style={{width: 18}}/> :
                                <FontAwesomeIcon icon={faClipboard}/>
                            }
                        </Button>
                    </OverlayTrigger>
                </InputGroup>
            </Stack>
        </div>
    );
}
