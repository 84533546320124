import * as yup from 'yup';

const baseShape = {
    firstName: yup.string()
        .max(100, 'First Name should be 100 characters or fewer.')
        .required('First Name is required.'),
    lastName: yup.string()
        .max(100, 'Last Name should be 100 characters or fewer.')
        .required('Last Name is required.'),
    email: yup.string()
        .email('Must be a valid email.')
        .max(255, 'Email should be 255 characters or fewer.')
        .required('Email is required.'),

    isOwnerBusiness: yup.boolean(),
    role: yup.string()
        .when('isOwnerBusiness', {
            is: false,
            then: (schema) => schema
                .oneOf(['manager', 'cashier'])
                .required('Merchant Role must be selected.'),
        }),

    isReceivingEmail: yup.boolean(),
};

export const UserSchema = yup.object().shape({
    ...baseShape,

    locationId: yup.number()
        .when('isOwnerBusiness', {
            is: false,
            then: (schema) => schema.required('Location is needed for Manager / Cashier.'),
        }),

    password: yup.string()
        .when('role', {
            is: 'cashier',
            then: (schema) => schema
                .max(255, 'Password should be 255 characters or fewer.')
                .required('Cashier need their own password.'),
        }),
});

export const EditUserSchema = yup.object().shape({
    ...baseShape,

    location: yup.array()
        .when('isOwnerBusiness', {
            is: false,
            then: (schema) => schema
                .of(
                    yup.object().noUnknown(true).shape({
                        id: yup.number()
                            .positive()
                            .required(),
                        name: yup.string()
                            .required(),
                    }),
                )
                .required('Required at least 1 location access'),
        }),
});
