import React, {useEffect, useState} from 'react';
import {CenteredSpinner, FadeIn} from '@/components/animation';
import {useAppContext} from '@/AppContext';
import {useBusinessSettingsResource} from '@/services/api';
import {Formik} from 'formik';
import BrandSettingsForm from '@/pages/Settings/components/BrandSettingsForm';
import {BrandSettingsSchema} from '@shared/requestSchemas';

export interface BrandSettingsFormValues {
    brandColor1: string,
    brandColor2: string,
    brandColor3: string,
    logoUrl: string,
    logoFile: Blob | File | null,
    punchCardImageUrl: string,
    punchCardImageFile: Blob | File | null,
}

function BrandSettings() {
    const businessSettingsResource = useBusinessSettingsResource();
    const {selectedBusinessOption} = useAppContext();
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [brandSettings, setBrandSettings] = useState<BrandSettingsFormValues>({
        brandColor1: '',
        brandColor2: '',
        brandColor3: '',
        logoUrl: '',
        logoFile: null,
        punchCardImageUrl: '',
        punchCardImageFile: null,
    });

    function onSubmit(values: BrandSettingsFormValues, {
        setSubmitting,
        resetForm,
    }: {
        setSubmitting: (isSubmitting: boolean) => void,
        resetForm: () => void,
    }) {
        businessSettingsResource.updateBrandSettings(values)
            .then((brandSettingsResponse) => {
                setBrandSettings({...brandSettings, ...brandSettingsResponse});
                resetForm();
            }).finally(() => {
                setSubmitting(false);
                setIsPageLoading(false);
            });
    }

    const fetchData = () => {
        setIsPageLoading(true);
        businessSettingsResource.getBrandSettings()
            .then(({data}) => {
                setBrandSettings(data);
            })
            .finally(() => setIsPageLoading(false));
    };

    useEffect(() => {
        fetchData();
    }, [selectedBusinessOption]);

    if (isPageLoading) {
        return <CenteredSpinner/>;
    }

    return (
        <FadeIn>
            <Formik component={BrandSettingsForm}
                    validationSchema={BrandSettingsSchema}
                    enableReinitialize
                    initialValues={brandSettings}
                    onSubmit={onSubmit}/>
        </FadeIn>
    );
}

export default BrandSettings;
