import React, {useCallback, useState} from 'react';
import {useAppContext} from '@/AppContext';
import {FadeIn} from '@/components/animation';
import {Formik, FormikHelpers} from 'formik';
import {useCustomCampaignResource} from '@/services/api';
import {useLoaderData, useNavigate, useParams} from 'react-router';
import CustomCampaignDetailsForm from '@/pages/Campaigns/components/CustomCampaignDetailsForm';
import {SelectOption} from '@shared/SelectOptions';
import {CustomCampaignSchema} from '@shared/requestSchemas';
import {CustomCampaignDetailsResponse, LocationDetailsResponse} from '@shared/responseModels/business';
import {cloneDeep} from 'lodash';
import {useSearchParams} from 'react-router-dom';
import {useAddServerSideFieldErrors} from '@/services/useAddServerSideFieldErrors';
import {CustomCampaignSummary} from '@/pages/Campaigns/components/CustomCampaignSummary';

function CustomCampaignDetails() {
    const campaignPageConfig = useLoaderData() as {
        campaign: CustomCampaignDetailsResponse,
        locations: LocationDetailsResponse[],
    };
    const campaignResource = useCustomCampaignResource();
    const addServerSideFieldErrors = useAddServerSideFieldErrors();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const params = useParams();
    const [campaignId, setCampaignId] = useState<number | null>(
        params.campaignId ? parseInt(params.campaignId) : null,
    );
    const {selectedBusinessOption} = useAppContext();

    if (campaignPageConfig) {
        campaignPageConfig.campaign.locationOptions = campaignPageConfig.locations.map((location: LocationDetailsResponse) => {
            return {value: location.id, label: location.name} as SelectOption;
        });
    }

    const [campaign] = useState(campaignPageConfig?.campaign);

    const onSubmit = useCallback((values: CustomCampaignDetailsResponse, {
        setSubmitting,
        setFieldError,
    }: FormikHelpers<CustomCampaignDetailsResponse>) => {
        const campaignToPersist = cloneDeep(CustomCampaignSchema.cast(values));
        if (!values.hasCustomEmailTemplate) {
            // @ts-ignore
            delete campaignToPersist.campaignDetailsEmailTemplate;
            // @ts-ignore
            delete campaignToPersist.unlayerEmailDesign;
        }

        if (campaignId) {
            campaignResource.update(campaignId, campaignToPersist)
                .then(({data}) => navigate(`/campaigns/${data.campaignId}?t=${searchParams.get('t') ?? 'email'}`))
                .catch((error) => {
                    addServerSideFieldErrors(setFieldError, error);
                })
                .finally(() => setSubmitting(false));
        } else {
            campaignResource.create(campaignToPersist)
                .then(({data}) => {
                    setCampaignId(data.campaignId);
                    const to = `/campaigns/${data.campaignId}?t=${searchParams.get('t') ?? 'email'}`;
                    navigate(to);
                })
                .catch((error) => {
                    addServerSideFieldErrors(setFieldError, error);
                })
                .finally(() => setSubmitting(false));
        }
    }, [selectedBusinessOption, campaignId, searchParams.values()]);

    if (!campaignPageConfig) {
        navigate('/campaigns');
        return null;
    }

    return (
        <FadeIn>
            {
                campaign.isEditable ?
                    <div className="mb-5">
                        <Formik initialValues={campaign}
                                enableReinitialize
                                validationSchema={CustomCampaignSchema}
                                component={CustomCampaignDetailsForm}
                                onSubmit={onSubmit}
                        />
                    </div> :
                    <CustomCampaignSummary campaign={campaign}/>
            }
        </FadeIn>
    );
}

export default CustomCampaignDetails;
