import React, {FormEvent, useEffect, useMemo, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Button, Col, Dropdown, DropdownButton, Form, FormControl, InputGroup, Nav, Row, Stack} from 'react-bootstrap';
import {IconButton} from '@/components/form';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMagnifyingGlass, faRefresh} from '@fortawesome/free-solid-svg-icons';
import {useAppContext} from '@/AppContext';

interface Props {
    onSearchTermSubmitted: Function,
    onRefreshButtonClicked: Function;
}

export function CustomerListActionMenu({
    onSearchTermSubmitted,
    onRefreshButtonClicked,
}: Props) {
    const location = useLocation();
    const navigate = useNavigate();
    const [activeMenuItem, setActiveMenuItem] = useState('customers');
    const {customerListSearchTerm} = useAppContext();
    const [searchTerm, setSearchTerm] = useState(customerListSearchTerm);

    const subMenuItems = useMemo(() => {
        return {
            customers: {
                name: 'Customers',
                to: '/customers',
            },
            flags: {
                name: 'Flagged Activity',
                to: '/customers/flags',
            },
        };
    }, []);

    useEffect(() => {
        setSearchTerm(customerListSearchTerm);
    }, [customerListSearchTerm]);

    useEffect(() => {
        if (location.pathname === '/customers/flags') {
            setActiveMenuItem(subMenuItems.flags.name);
        } else {
            setActiveMenuItem(subMenuItems.customers.name);
        }
        if (!customerListSearchTerm) {
            // When the route changes, clear the local search state.
            setSearchTerm('');
        }
    }, [location]);

    const onSubmitSearch = (event: FormEvent) => {
        event.preventDefault();
        onSearchTermSubmitted(searchTerm);
    };

    return (
        <div className="pt-1 px-2 pb-0 bg-white mb-3 shadow">
            <Row>
                <Col md={4} className="d-lg-block d-none">
                    <Nav variant="pills"
                         activeKey={activeMenuItem}>
                        {
                            Object.values(subMenuItems).map((menuItem, idx) => (
                                <Nav.Item key={idx}>
                                    <Nav.Link eventKey={menuItem.name}
                                              href=""
                                              className="py-3"
                                              onClick={() => navigate(menuItem.to)}>
                                        {menuItem.name}
                                    </Nav.Link>
                                </Nav.Item>
                            ))
                        }
                    </Nav>
                </Col>
                <Col md={4} className="d-lg-none d-block py-2">
                    <DropdownButton title={activeMenuItem}>
                        {
                            Object.values(subMenuItems).map((menuItem, idx) => (
                                <Dropdown.Item key={idx}
                                               eventKey={menuItem.name}
                                               onClick={() => navigate(menuItem.to)}>
                                    {menuItem.name}
                                </Dropdown.Item>
                            ))
                        }
                    </DropdownButton>
                </Col>
                <Col md={8} sm={12} className="py-2 pe-md-4 d-flex justify-content-end">
                    <Form noValidate onSubmit={onSubmitSearch}>
                        <Stack direction="horizontal" gap={2}>
                            <div>
                                <InputGroup>
                                    <FormControl type="text"
                                                 name="searchTerm"
                                                 autoFocus={true}
                                                 autoComplete="off"
                                                 placeholder="Search for customer..."
                                                 value={searchTerm!}
                                                 onChange={(event) => {
                                                     setSearchTerm(event.target.value || '');
                                                 }}
                                    />
                                    <Button type="submit" style={{zIndex: 0}} variant="light">
                                        <FontAwesomeIcon icon={faMagnifyingGlass}/>
                                    </Button>
                                </InputGroup>
                            </div>
                            <IconButton icon={<FontAwesomeIcon icon={faRefresh} fixedWidth/>}
                                        variant="outline-primary"
                                        circular={true}
                                        onClick={() => {
                                            onRefreshButtonClicked(customerListSearchTerm);
                                        }}
                            />
                        </Stack>
                    </Form>
                </Col>
            </Row>
        </div>
    );
}

