import 'regenerator-runtime/runtime';
import {createRoot} from 'react-dom/client';
import {initConfig} from '@/config';
import {configurationResource} from '@/services/api';
import {initLogging} from 'sparkloyalty-frontend/logging';
import {makeAppContainer} from '@/AppContainer';
import {ConfigurationResponse} from '@shared/responseModels/ConfigurationResponse';

configurationResource.get()
    .then((response) => {
        const configuration = response.data as ConfigurationResponse;
        initConfig(configuration);
        initLogging(configuration.environment, configuration.isSentryEnabled, configuration.sentryDsn);
        const container = document.getElementById('merchant-dashboard-app');
        const root = createRoot(container!);
        root.render(makeAppContainer(configuration));
    })
    .catch((error) => {
        console.log('Unable to get configuration', error);
        window.location.href = '/login';
    });
