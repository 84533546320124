import React, {useEffect, useMemo, useState} from 'react';
import {FadeIn} from '@/components/animation';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import {Dropdown, DropdownButton, Nav} from 'react-bootstrap';
import {useAppContext} from '@/AppContext';
import {BottomShadow} from '@/components/container';

function RewardsLayout() {
    const {selectedBusinessOption} = useAppContext();
    const location = useLocation();
    const navigate = useNavigate();
    const [activeMenuItem, setActiveMenuItem] = useState('rewards');

    const subMenuItems = useMemo(() => {
        return {
            rewards: {
                name: 'Rewards',
                to: '/rewards',
            },
            ...(selectedBusinessOption.usesKioskApp ? {
                kiosk: {
                    name: 'Kiosk',
                    to: '/rewards/kiosk',
                }
            } : {}),
        };
    }, [selectedBusinessOption]);

    useEffect(() => {
        if (subMenuItems.kiosk && location.pathname.includes(subMenuItems.kiosk.to)) {
            setActiveMenuItem(subMenuItems.kiosk.name);
        } else {
            setActiveMenuItem(subMenuItems.rewards.name);
        }
    }, [location]);

    return (
        <FadeIn>
            {Object.keys(subMenuItems).length > 1 ?
                <div className="pt-1 px-2 pb-0 bg-white mb-3 shadow">
                    <div className="d-lg-block d-none">
                        <Nav variant="pills" activeKey={activeMenuItem}>
                            {
                                Object.values(subMenuItems).map((menuItem, idx) => (
                                    <Nav.Item key={idx}>
                                        <Nav.Link eventKey={menuItem.name}
                                                  href=""
                                                  className="py-3"
                                                  onClick={() => navigate(menuItem.to)}>
                                            {menuItem.name}
                                        </Nav.Link>
                                    </Nav.Item>
                                ))
                            }
                        </Nav>
                    </div>

                    <div className="d-lg-none d-block py-2">
                        <DropdownButton title={activeMenuItem}>
                            {
                                Object.values(subMenuItems).map((menuItem, idx) => (
                                    <Dropdown.Item key={idx}
                                                   eventKey={menuItem.name}
                                                   onClick={() => navigate(menuItem.to)}>
                                        {menuItem.name}
                                    </Dropdown.Item>
                                ))
                            }
                        </DropdownButton>
                    </div>
                </div> :
                <BottomShadow/>
            }

            <Outlet/>
        </FadeIn>
    );
}

export default RewardsLayout;
