import React, {useMemo} from 'react';
import {Stack} from 'react-bootstrap';

interface Content {
    info: number,
    color: string,
    type: string,
    percentage: number,
}

type Props = {
    content1?: Content;
    content2?: Content;
    content3?: Content;
}

const ComparisonText: React.FC<Props> = ({
    content1,
    content2,
    content3
}) => {
    const content = useMemo(() => {
        return [content1, content2, content3].filter(x => x);
    }, [content1, content2, content3]);

    if (content.length === 0) {
        return null;
    }

    return (
        <Stack style={{marginBottom: 10}} direction="horizontal" gap={2}>
            {content.map((item, index) => {
                if (!item) {
                    return null;
                }

                const {info, color, type, percentage} = item;
                const isSpacerVisible = index !== content.length - 1;
                return (
                    <div key={item.type}
                         className={isSpacerVisible ? 'border-end px-2' : ''}
                    >
                        <Stack direction="horizontal">
                            <h2 style={{marginBottom: 0, marginRight: 10}}>{info}</h2>
                            <small className={'text-muted'}>+{percentage}%</small>
                        </Stack>
                        <Stack direction="horizontal">
                            <div
                                style={{
                                    backgroundColor: color,
                                    height: 8,
                                    width: 8,
                                    borderRadius: 8 / 2,
                                    marginRight: 4
                                }}/>
                            <small className={'text-muted'}>{type}</small>
                        </Stack>
                    </div>
                );
            })}
        </Stack>
    );
};

export default ComparisonText;
