import React, {ReactElement} from 'react';
import {Card, Stack} from 'react-bootstrap';
import {DateFilter} from '@/components/filters';
import {CenteredSpinner} from '@/components/animation';

interface Props {
    title: string,
    getFilterValue: (filter: number) => void,
    setFromDate?: (date: Date) => void,
    setToDate?: (data: Date) => void,
    children: ReactElement | ReactElement[],
    isProcessing: boolean,
}

function Panel({title, children, getFilterValue, setFromDate, setToDate, isProcessing}: Props) {
    return (
        <Card className="shadow pb-5">
            <Card.Header className="bg-white border-1">
                <Stack direction="horizontal" gap={2}>
                    <h4 className="text-muted ms-3 mt-2 me-0 mb-1">{title ? title : 'Title'}</h4>
                    <DateFilter getFilterValue={getFilterValue}
                                setFromDate={setFromDate}
                                setToDate={setToDate}
                                isProcessing={isProcessing}
                    />
                </Stack>
            </Card.Header>
            <Card.Body className={'bg-white'}>
                {
                    isProcessing ?
                        <CenteredSpinner height={210}/> :
                        <div style={{height: 210}}>{children}</div>
                }
            </Card.Body>
        </Card>
    );
}

export default Panel;
