import React from 'react';
import {Button, Container, Stack} from 'react-bootstrap';
import {FiAlertCircle} from 'react-icons/fi';

interface Props {
    error: Error,
    resetErrorBoundary: Function,
}

export function FallbackComponent({error, resetErrorBoundary}: Props) {
    return (
        <div role="alert" className="mt-5">
            <Container>
                <Stack>
                    <div>
                        <div className="text-danger text-center">
                            <FiAlertCircle style={{fontSize: 100}} className="mx-auto"/>
                        </div>
                    </div>
                    <div>
                        <div className="p-3 text-center">
                            Oops...
                        </div>
                        <div className="text-center mb-4">
                            {error.message}
                        </div>
                    </div>
                    <div className="mx-auto">
                        <div>
                            <Button onClick={() => {
                                resetErrorBoundary();
                            }}>Try Refreshing?</Button>
                        </div>
                    </div>
                </Stack>
            </Container>
        </div>
    );
}
