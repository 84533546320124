import React, {useState} from 'react';
import {FadeIn} from '@/components/animation';
import {useSignUpFormSettingsResource} from '@/services/api';
import {Formik} from 'formik';
import {SignUpFormSettingsSchema} from '@shared/requestSchemas';
import {SignUpFormSettingsForm} from '@/pages/Locations/components';
import {useLoaderData, useParams} from 'react-router';
import {useNavigate} from 'react-router-dom';
import {SignUpFormSettingsResponse} from '@shared/responseModels/business';

export function SignUpFormSettings() {
    const initialSignUpFormSettings = useLoaderData();

    const params = useParams();
    const [locationId] = useState<number | null>(
        params.locationId ? parseInt(params.locationId) : null,
    );
    const signUpFormSettingsResource = useSignUpFormSettingsResource();
    const navigate = useNavigate();
    const [signUpFormSettings, setSignUpFormSettings] = useState<SignUpFormSettingsResponse>(initialSignUpFormSettings);

    async function onSubmit(values: object, {setSubmitting}: { setSubmitting: Function }) {
        if (!locationId) {
            return;
        }
        const {data} = await signUpFormSettingsResource.update(locationId, values);
        setSignUpFormSettings(data);
        setSubmitting(false);
    }

    if (!initialSignUpFormSettings || !locationId || !params.locationId) {
        navigate('/locations');
        return null;
    }

    return (
        <FadeIn>
            <Formik validationSchema={SignUpFormSettingsSchema}
                    component={SignUpFormSettingsForm}
                    initialValues={signUpFormSettings}
                    enableReinitialize={true}
                    onSubmit={onSubmit}/>
        </FadeIn>
    );
}
