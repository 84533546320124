import React, {CSSProperties, PropsWithChildren, ReactElement} from 'react';
import {Card} from 'react-bootstrap';
import {CardHeaderTitle, If} from '@/components/container/index';
import classNames from 'classnames';
import {UpgradeCallout} from '@/components/product';

interface Props {
    style?: CSSProperties | undefined;
    className?: string;
    title?: string | ReactElement;
    hasBodyPadding?: boolean;
    hasCompactCardHeader?: boolean;
    isUpgradeCalloutVisible?: boolean;
    upgradeCalloutText?: string;
}

export function SimpleCard({
    style = {},
    className = '',
    title,
    hasBodyPadding = true,
    hasCompactCardHeader = false,
    upgradeCalloutText = undefined,
    isUpgradeCalloutVisible = false,
    children,
}: PropsWithChildren<Props>) {
    return (
        <Card className={classNames('shadow', className)} style={style}>
            {!!title &&
                <Card.Header>
                    <CardHeaderTitle isCompact={hasCompactCardHeader}>{title}</CardHeaderTitle>
                </Card.Header>
            }
            <Card.Body className={classNames(!hasBodyPadding && 'p-0')}>
                {children}
            </Card.Body>
            <If isTrue={isUpgradeCalloutVisible} as={Card.Footer} className="bg-primary p-2 text-center">
                <UpgradeCallout canUpgradeTo={true} text={upgradeCalloutText}/>
            </If>
        </Card>
    );
}
