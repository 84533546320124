import React, {FormEventHandler} from 'react';
import {Col, Container, Form, FormControl, FormGroup, FormLabel, Row, Stack} from 'react-bootstrap';
import {useNavigate, useParams} from 'react-router';
import {Field} from 'formik';
import {AddressFields, HubSpotWorkAround, SubmitOnConfirmButton} from '@/components/form';
import {PageFooter, SimpleCard} from '@/components/container';
import {SetFieldValue} from '@shared/formik';

interface Props {
    handleSubmit: FormEventHandler<HTMLFormElement>;
    resetForm: FormEventHandler<HTMLFormElement>;
    isSubmitting: boolean;
    touched: Record<string, string>,
    values: any;
    errors: any;
    setFieldValue: SetFieldValue;
}

export function CustomerDetailsForm({
    isSubmitting,
    handleSubmit,
    values,
    errors,
    resetForm,
    touched,
}: Props) {
    const navigate = useNavigate();
    const params = useParams();

    if (params.customerId === undefined) {
        navigate('/not-found');
        return null;
    }

    return (
        <Form onSubmit={handleSubmit}>
            <HubSpotWorkAround/>
            <Container fluid>
                <Row>
                    <Col lg={4}>
                        <SimpleCard title="Profile">
                            <Row>
                                <FormGroup controlId="firstName" className="mb-3" as={Col} xs={6}>
                                    <Form.Label>First Name</Form.Label>
                                    <Field as={FormControl}
                                           name="firstName"
                                           isInvalid={!!errors.firstName}
                                           disabled={isSubmitting}
                                           placeholder={'First Name'}
                                    />
                                    <FormControl.Feedback type="invalid">
                                        {errors.firstName}
                                    </FormControl.Feedback>
                                </FormGroup>

                                <FormGroup controlId="lastName" className="mb-3" as={Col} xs={6}>
                                    <Form.Label>Last Name</Form.Label>
                                    <Field as={FormControl}
                                           name="lastName"
                                           isInvalid={!!errors.lastName}
                                           disabled={isSubmitting}
                                           placeholder={'Last Name'}
                                    />
                                    <FormControl.Feedback type="invalid">
                                        {errors.lastName}
                                    </FormControl.Feedback>
                                </FormGroup>
                            </Row>
                            <Row>
                                <FormGroup controlId="email" className="mb-3" as={Col} xs={12}>
                                    <Form.Label>Email</Form.Label>
                                    <Field as={FormControl}
                                           name="email"
                                           isInvalid={!!errors.email}
                                           disabled={isSubmitting}
                                           placeholder={'Email'}
                                    />
                                    <FormControl.Feedback type="invalid">
                                        {errors.email}
                                    </FormControl.Feedback>
                                </FormGroup>
                            </Row>

                            <FormGroup controlId="dateOfBirth" className="mb-3 w-50">
                                <FormLabel>Date of Birth</FormLabel>
                                <Field isInvalid={!!errors.dateOfBirth}
                                       as={FormControl}
                                       type="date"
                                       name="dateOfBirth"
                                       max={new Date()}
                                />
                                <FormControl.Feedback type="invalid">
                                    {errors.dateOfBirth}
                                </FormControl.Feedback>
                            </FormGroup>
                        </SimpleCard>
                    </Col>
                    <Col lg={4}>
                        <SimpleCard title="Address">
                            <AddressFields
                                values={values}
                                errors={errors}
                                isSubmitting={isSubmitting}
                                touched={touched}
                            />
                        </SimpleCard>
                    </Col>
                </Row>
            </Container>
            <PageFooter>
                <Stack direction="horizontal">
                    <SubmitOnConfirmButton disabled={isSubmitting}
                                           resetForm={resetForm}
                                           isSubmitting={isSubmitting}
                    />
                </Stack>
            </PageFooter>
        </Form>
    );
}
