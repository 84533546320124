import React, {PropsWithChildren} from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import {FallbackComponent} from './FallbackComponent';

interface Props {
    onReset?: Function,
}

export function ErrorContainer({
    onReset,
    children,
}: PropsWithChildren<Props>) {
    return (<ErrorBoundary
        FallbackComponent={FallbackComponent}
        onReset={() => {
            window.location.reload();
            onReset && onReset();
        }}
    >
        {children}
    </ErrorBoundary>);
}
