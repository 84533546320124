import React, {useEffect, useMemo, useState} from 'react';
import classNames from 'classnames';


interface Props {
    url: string | null;
    file: File | null | undefined;
    description: string;
    width?: 'full' | 'half';
    className?: string,
}

const CampaignImagePreview = ({
    url,
    file,
    description,
    width = 'full',
    className,
}: Props) => {

    const [previewUrl, setPreviewUrl] = useState(url);

    useEffect(() => {
        if (file) {
            setPreviewUrl(URL.createObjectURL(file));
        } else {
            setPreviewUrl(url);
        }
    }, [url, file]);

    const widths = useMemo(() => ({
        half: 'w-50',
        full: 'w-100',
    }), []);

    if (!previewUrl) {
        return null;
    }

    return (
        <img src={previewUrl}
             className={classNames('img-fluid', widths[width], className)}
             alt={description}
        />
    );
};


export default CampaignImagePreview;
