import {DateTime} from 'luxon';
import {CustomCampaignDetailsResponse} from '@shared/responseModels/business';
import {BusinessSelectOption} from '@shared/responseModels';

export function customCampaignFactory(selectedBusinessOption: BusinessSelectOption, options?: object) {
    return {
        isEditable: true,
        isOfferAvailableToNewCustomers: false,
        isAppNotificationEnabled: selectedBusinessOption.usesCustomPushNotificationCampaigns,
        isEmailEnabled: selectedBusinessOption.usesCustomEmailCampaigns,
        isTextEnabled: selectedBusinessOption.usesCustomSmsCampaigns,
        isOfferEnabled: false,
        campaignName: '',
        scheduleFor: DateTime.local().set({minute: 0}).plus({week: 1, hour: 1}).toJSDate(),
        offerDescription: '',
        offerExpirationDate: DateTime.local().set({minute: 0}).plus({month: 1, hour: 1}).toJSDate(),
        isAudienceLocationFilterEnabled: false,
        audienceLocations: [],
        locationOptions: [],
        textImage: '',
        textMessage: '',
        isTextWebLinkEnabled: false,
        textWebLinkMessage: '',
        textWebLinkUrl: '',
        appNotificationTitle: '',
        appNotificationBody: '',
        emailSubject: '',
        emailMessage: '',
        campaignDetailsEmailTemplate: '',
        hasCustomEmailTemplate: false,
        hasUnlayerEmailDesign: false,
        unlayerEmailDesign: null,
        emailImage: '',
        emailImageLink: '',
        templateVariables: {
            appNotificationTemplate: {
                signOff: '-',
                greeting: '...',
                signature: '...',
            },
            textTemplate: {
                signOff: '-',
                greeting: '...',
                signature: '...',
            },
            emailTemplate: {
                signOff: 'See you soon',
                greeting: 'Hi',
                template: {
                    footerColor: '#efefef',
                    headerColor: '#efefef',
                    claimButtonColor: '#697689',
                    claimButtonBorder: '#697689',
                    incentiveBackgroundColor: '#efefef',
                },
                signature: '...',
                buttonText: '...',
                emailImage: '',
                emailImageLink: '',
            },
        },
        totalCustomersReached: 0,
        emailMessageDelivered: 0,
        emailMessageTotal: 0,
        textMessageDelivered: 0,
        smsMessageTotal: 0,
        appNotificationMessageDelivered: 0,
        appNotificationMessageTotal: 0,
        emailMessageOpens: 0,
        emailMessageUniqueOpens: 0,
        emailMessageClicks: 0,
        emailMessageUniqueClicks: 0,
        emailMessageSpamReports: 0,
        emailMessageBounces: 0,
        urlPerformanceStats: [],
        ...(options ?? {}),
    } as CustomCampaignDetailsResponse;
}
