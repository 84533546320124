import * as yup from 'yup';
import {DateTime} from 'luxon';
import {InferType} from 'yup';

export const CustomCampaignSchema = yup.object().shape({
    // Campaign
    campaignName: yup.string().max(255, 'Campaign Name should be 255 characters or fewer.'),
    scheduleFor: yup.date().required('Required.')
        .nullable()
        .test('is valid send date/time', 'Campaign must be scheduled for at least one hour into the future.', (value) => {
            return DateTime.fromJSDate(value as Date) > DateTime.local().endOf('hour');
        }),

    // Offer
    isOfferEnabled: yup.boolean().required('Required.'),
    offerDescription: yup.string()
        .max(255, 'Offer Description name should be 255 characters or fewer.')
        .when('isOfferEnabled', {
            is: true,
            then: (schema) => schema.required('Required.'),
        }),
    offerExpirationDate: yup.date()
        .when('isOfferEnabled', {
            is: true,
            then: (schema) => schema.required('Required.')
                .nullable()
                .test('is valid offer expiration date', 'An offer must expire at least one day into the future.', (value) => {
                    return DateTime.fromJSDate(value as Date) > DateTime.local().endOf('day');
                }),
        }),
    isOfferAvailableToNewCustomers: yup.boolean()
        .when('isOfferEnabled', {
            is: true,
            then: (schema) => schema.required('Required.'),
        }),

    // Audience (Locations)
    isAudienceLocationFilterEnabled: yup.boolean().required('Required.'),
    audienceLocations: yup.array().of(yup.number())
        .when('isAudienceLocationFilterEnabled', {
            is: true,
            then: (schema) => schema.required('Required.'),
        }),

    // Audience (VIP Status)
    isAudienceVipStatusFilterEnabled: yup.boolean(),

    // Text Message
    isTextEnabled: yup.boolean(),
    textMessage: yup.string()
        .max(130, 'Text message should be 130 characters or fewer.')
        .when('isTextEnabled', {
            is: true,
            then: (schema) => schema.required('Required.'),
        }),

    // Text Image
    textImage: yup.string()
        .url('Text message image must be a valid URL')
        .nullable(),

    // Text Web Link
    isTextWebLinkEnabled: yup.boolean(),
    textWebLinkMessage: yup.string()
        .max(1000, 'Text message should be 1,000 characters or fewer.')
        .when('isTextWebLinkEnabled', {
            is: true,
            then: (schema) => schema.required('Required.'),
        }),

    // Email Message
    isEmailEnabled: yup.boolean(),
    emailSubject: yup.string()
        .max(100, 'Email subject should be 100 characters or fewer.')
        .when('isEmailEnabled', {
            is: true,
            then: (schema) => schema.required('Required.'),
        }),
    emailMessage: yup.string()
        .max(250, 'Email message should be 250 characters or fewer.')
        .when(['isEmailEnabled', 'hasCustomEmailTemplate'], {
            is: (isEmailEnabled: boolean, hasCustomEmailTemplate: boolean) => isEmailEnabled && !hasCustomEmailTemplate,
            then: (schema) => schema.required('Required.'),
        }),

    // Email Message
    hasCustomEmailTemplate: yup.boolean()
        .required('Required.'),
    campaignDetailsEmailTemplate: yup.string()
        .max(300000, 'Custom Email Template should be 300,000 characters or fewer.')
        .when(['isEmailEnabled', 'hasCustomEmailTemplate'], {
            is: (isEmailEnabled: boolean, hasCustomEmailTemplate: boolean) => isEmailEnabled && hasCustomEmailTemplate,
            then: (schema) => schema.required('Required.'),
        }),

    hasUnlayerEmailDesign: yup.boolean()
        .required(),
    unlayerEmailDesign: yup.object()
        .nullable()
        .when(['isEmailEnabled', 'hasUnlayerEmailDesign', 'hasCustomEmailTemplate'], {
            is: (isEmailEnabled: boolean, hasUnlayerEmailDesign: boolean, hasCustomEmailTemplate: boolean) => {
                return isEmailEnabled && hasUnlayerEmailDesign && hasCustomEmailTemplate;
            },
            then: (schema) => schema.required('Required.'),
        }),

    // Email Image
    emailImage: yup.string()
        .url('Email image must be a valid URL')
        .nullable(),
    emailImageLink: yup.string().url('Email Image Link must be a valid URL'),

    // App Notification
    isAppNotificationEnabled: yup.boolean(),
    appNotificationTitle: yup.string()
        .max(65, 'App notification title subject should be 65 characters or fewer.')
        .when('isAppNotificationEnabled', {
            is: true,
            then: (schema) => schema.required('Required.'),
        }),
    appNotificationBody: yup.string()
        .max(240, 'App notification message should be 240 characters or fewer.')
        .when('isAppNotificationEnabled', {
            is: true,
            then: (schema) => schema.required('Required.'),
        }),
})
    .test(
        'notificationType',
        '',
        (campaign) => {
            if (campaign.isTextEnabled || campaign.isEmailEnabled || campaign.isAppNotificationEnabled) {
                // At least one notification type has been selected.
                return true;
            }

            return new yup.ValidationError(
                'At least one delivery method must be enabled.',
                null,
                'form.notificationType',
            );
        },
    )
    .test(
        'offerExpirationDate',
        '',
        (campaign) => {
            if (!campaign.isOfferEnabled) {
                // There is no offer.
                return true;
            }

            const scheduleFor = DateTime.fromJSDate(campaign.scheduleFor as Date);
            const offerExpirationDate = DateTime.fromJSDate(campaign.offerExpirationDate as Date);
            if (scheduleFor < offerExpirationDate) {
                // The offer expires after the scheduled campaign send date.
                return true;
            }

            return new yup.ValidationError(
                'The offer must expire after the campaign is scheduled to send.',
                null,
                'offerExpirationDate',
            );
        },
    );


export interface CustomCampaignFormValues extends InferType<typeof CustomCampaignSchema> {
    campaignDetailsId?: number;
}
