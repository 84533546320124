import React, {useCallback, useEffect, useState} from 'react';
import {Panel} from '@/components/container';
import ComparisonText from '@/pages/Dashboard/components/ComparisonText';
import CustomTooltip from '@/pages/Dashboard/components/CustomTooltip';
import {Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {useBusinessAnalyticsResource} from '@/services/api';
import {useAppContext} from '@/AppContext';
import {themeColors} from '@/services/theme';
import {
    DateFormatter,
    dateFormatterFactory,
    dateFormatterFromTimeSeriesIntervalFactory,
    toMonthDayString,
} from '@/services/textFormatting';
import {TouchPointDataPoints} from '@shared/responseModels/analytics';
import {AxisInterval} from 'recharts/types/util/types';

type QueryParam = {
    dateRange: number;
    fromDate?: string;
    toDate?: string;
    locations: number[];
}

interface Props {
    locations: number[];
}

function TouchPoints({locations}: Props) {
    const businessAnalyticsResource = useBusinessAnalyticsResource();
    const {selectedBusinessOption, fields, currentClientTimeZone} = useAppContext();

    const [isProcessing, setIsProcessing] = useState(true);
    const [dateRange, setDateRange] = useState<number>(fields.dateRange.map.thisWeek ?? 0);
    const [fromDate, setFromDate] = useState<Date>();
    const [toDate, setToDate] = useState<Date>();
    const [dateFormatter, setDateFormatter] = useState<DateFormatter>(dateFormatterFactory(toMonthDayString));
    const [xAxisInterval, setXAxisInterval] = useState<AxisInterval>(0);

    const [comparisonContent, setComparisonContent] = useState({
        email: {
            info: 0,
            color: themeColors.primary,
            type: 'Email',
            percentage: 0,
        },
        phone: {
            info: 0,
            color: themeColors.success,
            type: 'Phone',
            percentage: 0,
        },
        mobileDevice: {
            info: 0,
            color: themeColors.info,
            type: 'Mobile Device',
            percentage: 0,
        },
    });
    const [dataPoints, setDataPoints] = useState<TouchPointDataPoints>();

    const fetchTouchPointsData = useCallback(() => {
        setIsProcessing(true);
        const queryParams: QueryParam = {
            dateRange,
            locations,
        };

        // Prepare date filter
        // @ts-ignore
        if (dateRange === fields.dateRange.map.custom) {
            queryParams.fromDate = fromDate?.toISOString();
            queryParams.toDate = toDate?.toISOString();
        }

        businessAnalyticsResource.getAnalyticsTouchPoints(queryParams)
            .then(({data}) => {
                comparisonContent.email.info = data.totalEmailAddresses;
                comparisonContent.email.percentage = data.deltaPercentageEmailAddresses;

                if (selectedBusinessOption.usesCustomSmsCampaigns) {
                    comparisonContent.phone.info = data.totalPhoneNumbers;
                    comparisonContent.phone.percentage = data.deltaPercentagePhoneNumbers;
                }

                comparisonContent.mobileDevice.info = data.totalMobileDevices;
                comparisonContent.mobileDevice.percentage = data.deltaPercentageMobileDevices;
                setComparisonContent(comparisonContent);
                setDataPoints(data.dataPoints);
                setDateFormatter(dateFormatterFromTimeSeriesIntervalFactory(data.timeSeriesInterval));
                setXAxisInterval(
                    dateRange === fields.dateRange.map.thisYear || dateRange === fields.dateRange.map.thisWeek ?
                        0 : 'preserveStartEnd',
                );
            })
            .finally(() => setIsProcessing(false ));
    }, [selectedBusinessOption, dateRange, fromDate, toDate, currentClientTimeZone, locations]);

    useEffect(() => {
        fetchTouchPointsData();
    }, [selectedBusinessOption, dateRange, fromDate, toDate, currentClientTimeZone, locations]);

    return (
        <Panel title="Touchpoints"
               getFilterValue={setDateRange}
               setFromDate={setFromDate}
               setToDate={setToDate}
               isProcessing={isProcessing}
        >
            <ComparisonText
                content1={selectedBusinessOption.usesCustomEmailCampaigns ? comparisonContent.email : undefined}
                content2={selectedBusinessOption.usesCustomSmsCampaigns ? comparisonContent.phone : undefined}
                content3={selectedBusinessOption.usesCustomPushNotificationCampaigns ? comparisonContent.mobileDevice : undefined}
            />
            <ResponsiveContainer height="100%" width="100%">
                <BarChart data={dataPoints}
                          margin={{top: 30, right: 60, left: 0, bottom: 30}}>
                    <XAxis dataKey="date"
                           axisLine={false}
                           tickLine={false}
                           tickFormatter={dateFormatter.format}
                           type={'category'}
                           interval={xAxisInterval}
                           angle={-45}
                           textAnchor="end"
                    />
                    <YAxis axisLine={false} tickLine={false} allowDecimals={false}/>
                    <Tooltip content={<CustomTooltip payload={dataPoints} dateFormatter={dateFormatter}/>}/>
                    <Bar dataKey="emailAddresses" stackId="a" fill={comparisonContent.email.color}/>

                    {selectedBusinessOption.usesCustomSmsCampaigns && (
                        <Bar dataKey="phoneNumbers" stackId="a" fill={comparisonContent.phone.color}/>
                    )}

                    <Bar dataKey="mobileDevices" stackId="a" fill={comparisonContent.mobileDevice.color}/>
                </BarChart>
            </ResponsiveContainer>
        </Panel>
    );
}

export default TouchPoints;
