import React, {MouseEventHandler, PropsWithChildren, useEffect, useState} from 'react';
import {
    ColumnDef,
    ColumnFiltersState,
    getCoreRowModel,
    PaginationState,
    RowData,
    SortingState,
    Updater,
    useReactTable,
} from '@tanstack/react-table';
import {Button, Card, Col, Row, Stack, Table} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleLeft, faAngleRight, faAnglesLeft, faAnglesRight, faDownload} from '@fortawesome/free-solid-svg-icons';
import {IconButton} from '@/components/form';
import classNames from 'classnames';
import {useAppContext} from '@/AppContext';
import {PaginatedTableFetchDataArgs, Pagination, Sorting} from '@/components/table/PaginatedTableFetchDataArgs';
import {If} from '@/components/container';
import {DesktopData, DesktopHeader, MobileCell} from './cells';

interface Props<TData, TValue> {
    data: TData[];
    columns: ColumnDef<TData, TValue>[],
    fetchData: (args: PaginatedTableFetchDataArgs) => Promise<void>;
    totalRows?: number;
    pageCount?: number;
    type: string;
    exportData?: MouseEventHandler<HTMLButtonElement>;
    showPagination?: boolean;
    isProcessingPromise?: Promise<unknown>;
}

export function PaginatedTable<TData extends RowData, TValue>({
    children,
    columns,
    data,
    fetchData,
    totalRows,
    pageCount,
    type,
    exportData,
    showPagination = true,
}: PropsWithChildren<Props<TData, TValue>>) {
    const {selectedBusinessOption} = useAppContext();
    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
    const [isInitiallySorted, setIsInitiallySorted] = useState(false);
    const [isInitiallyPaginated, setIsInitiallyPaginated] = useState(false);
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });
    const [isProcessing, setIsProcessing] = useState(false);
    const [sorting, setSorting] = useState<SortingState>([]);

    async function fetchOnChange(sorting: Sorting, pagination: Pagination, columnFilters: ColumnFiltersState) {
        if (!isInitiallySorted || !isInitiallyPaginated) {
            // Ensure the initial fetch only happens once, despite sorting and pagination updating independently.
            return;
        }

        setIsProcessing(false);
        await fetchData({
            pageIndex: Math.ceil(pagination.pageIndex),
            pageSize: pagination.pageSize,
            sortBy: sorting,
            columnFilters,
        });
    }

    useEffect(() => {
        setIsInitiallyPaginated(true);
        setIsInitiallySorted(true);
        fetchOnChange(sorting, pagination, columnFilters).catch(console.log);
    }, [selectedBusinessOption, columnFilters]);

    useEffect(() => {
        setIsProcessing(false);
    }, [data]);

    async function onSortingChange(updater: Updater<SortingState>) {
        if (updater instanceof Function) {
            setIsInitiallySorted(true);
            const newSorting = updater(sorting);
            setSorting(newSorting);
            await fetchOnChange(newSorting, pagination, columnFilters);
        } else {
            setSorting(updater);
        }

        return updater;
    }

    async function onPaginationChange(updater: Updater<PaginationState>) {
        if (updater instanceof Function) {
            setIsInitiallyPaginated(true);
            const newPagination = updater(pagination);
            setPagination(newPagination);
            await fetchOnChange(sorting, newPagination, columnFilters);
        } else {
            setPagination(updater);
        }

        return updater;
    }

    const table = useReactTable<TData>({
        // Setup
        debugTable: true,
        getCoreRowModel: getCoreRowModel(),
        columns,
        data,
        state: {
            columnFilters,
            pagination,
            sorting,
        },

        // Filtering
        onColumnFiltersChange: setColumnFilters,

        // Sorting
        onSortingChange,

        // Pagination
        onPaginationChange,
        pageCount: pageCount,
        manualPagination: true,
    });

    return <>
        <Card className="shadow">
            {children}

            {/* Desktop View */}
            <div className="d-lg-block d-none table-responsive pt-3 px-3">
                <Table className="mb-0">
                    <thead className="bg-light rounded">
                        <tr>
                            {table.getHeaderGroups().map((headerGroup) =>
                            headerGroup.headers.map((header, index) => (
                                <DesktopHeader header={header}
                                               position={index}
                                               key={index}
                                               disabled={isProcessing}
                                />
                            )),
                        )}
                        </tr>
                    </thead>

                    <If isTrue={!isProcessing} as="tbody" className="row-container" style={{
                        height: 'calc(100vh - 320px)',
                        minHeight: 400,
                    }}>
                        {table.getRowModel().rows.map(row => (
                            <tr key={row.id}>
                                {row.getVisibleCells().map((cell, cellIndex) => (
                                    <DesktopData cell={cell} position={cellIndex} key={cellIndex}/>
                                ))}
                            </tr>
                        ))}
                    </If>

                    <If isTrue={isProcessing} style={{
                        height: 'calc(100vh - 320px)',
                        minHeight: 400,
                    }}>
                        <div
                            className="text-center h-100 d-flex flex-column align-items-center justify-content-center">
                            <div className="bg-dark spinner-grow"/>
                        </div>
                    </If>
                </Table>
            </div>

            {/* Mobile View */}
            <div className="d-lg-none mb-3 pt-4 mt-3">
                {table.getRowModel().rows.map((row) => {
                    return <div className="mb-2 bg-light p-3" key={row.id}>
                        {row.getVisibleCells().map((cell, cellIndex) => (
                            <MobileCell cell={cell} position={cellIndex} key={cellIndex}/>
                        ))}
                    </div>;
                })}
            </div>

            <Card.Footer className={classNames('px-3 w-100 border-0', !showPagination ? 'd-none' : '')}
            >
                <div className="m-2"/>
                <Stack gap={2}>
                    <div className="bg-light ps-3 rounded d-flex align-items-center"
                         style={{height: 34.4}}>
                        {isProcessing && <span>...</span>}
                        {!isProcessing && <span>Showing {data.length} of {totalRows ?? 0} records</span>}
                    </div>

                    {!!totalRows &&
                        <Row>
                            <Col lg={2} md={3} sm={12} className="mb-2">
                                <nav aria-label="table-pagination">
                                    <ul className="pagination pagination-sm mb-0">
                                        <li className="page-item me-1">
                                            <Button
                                                className={['rounded-circle', table.getCanPreviousPage() ? '' : 'disabled'].join(' ')}
                                                onClick={() => table.setPageIndex(0)}
                                                disabled={!table.getCanPreviousPage() || isProcessing}>
                                                <FontAwesomeIcon color={'#fff'} size={'xl'}
                                                                 icon={faAnglesLeft}/>
                                            </Button>
                                        </li>
                                        <li className="page-item me-1">
                                            <Button
                                                className={['rounded-circle', table.getCanPreviousPage() ? '' : 'disabled'].join(' ')}
                                                onClick={() => table.previousPage()}
                                                disabled={!table.getCanPreviousPage() || isProcessing}>
                                                <FontAwesomeIcon color={'#fff'} size={'xl'}
                                                                 icon={faAngleLeft}/>
                                            </Button>
                                        </li>
                                        <li className="page-item me-1">
                                            <Button
                                                className={['rounded-circle', table.getCanNextPage() ? '' : 'disabled'].join(' ')}
                                                onClick={() => table.nextPage()}
                                                disabled={!table.getCanNextPage() || isProcessing}>
                                                <FontAwesomeIcon color={'#fff'} size={'xl'}
                                                                 icon={faAngleRight}/>
                                            </Button>
                                        </li>
                                        <li className="page-item me-3">
                                            <Button
                                                className={['rounded-circle', table.getCanNextPage() ? '' : 'disabled'].join(' ')}
                                                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                                                disabled={!table.getCanNextPage() || isProcessing}>
                                                <FontAwesomeIcon color={'#fff'} size={'xl'}
                                                                 icon={faAnglesRight}/>
                                            </Button>
                                        </li>
                                    </ul>
                                </nav>
                            </Col>
                            <Col lg={4} md={6} sm={12} className="mb-2">
                                <Stack direction="horizontal" gap={2}>
                                    <div>
                                        <nav aria-label="table-pagination">
                                            <ul className="pagination pagination-sm mb-0">
                                                <li className="page-item me-2">
                                                    <select
                                                        className="form-control form-control-sm w-100 border-primary bg-primary text-white clickable rounded"
                                                        style={{
                                                            height: 34.4,
                                                            fontSize: 16,
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                        }}
                                                        value={pagination.pageSize}
                                                        disabled={isProcessing}
                                                        onChange={(e) => {
                                                            const pageSize = e.target.value ? Number(e.target.value) : 0;
                                                            table.setPageSize(pageSize);
                                                        }}
                                                    >
                                                        {[10, 15, 50, 100, 500].map((pageSize) => <option
                                                            key={pageSize}
                                                            value={pageSize}>
                                                            Show {pageSize}
                                                        </option>)}
                                                    </select>
                                                </li>
                                                <li className="page-item">
                                                    <div className="form-control-sm bg-light rounded"
                                                         style={{
                                                             height: 34.4,
                                                             fontSize: 16,
                                                             display: 'flex',
                                                             alignItems: 'center',
                                                             paddingInline: 14,
                                                         }}
                                                    >
                                                        Page {Math.ceil(table.getState().pagination.pageIndex + 1)} of{' '} {Math.ceil(table.getPageCount())}
                                                    </div>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                    {type != 'campaign' && type != 'team' ? <div className="">
                                        <IconButton className={'px-3'}
                                                    onClick={exportData}
                                                    disabled={totalRows === 0}
                                                    text={'Export'}
                                                    icon={<FontAwesomeIcon icon={faDownload}/>}/>
                                    </div> : null}
                                </Stack>
                            </Col>
                        </Row>
                    }
                </Stack>
            </Card.Footer>
        </Card>
    </>;
}
