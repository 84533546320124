import React, {ChangeEvent, useEffect, useState} from 'react';
import {
    Alert,
    Badge,
    Card,
    Col,
    Container,
    FormControl,
    FormGroup,
    FormLabel,
    FormText,
    Row,
    Stack,
    Tab,
    Tabs,
} from 'react-bootstrap';
import {useAppContext} from '@/AppContext';
import {Field, Form, FormikProps} from 'formik';
import {AppNotificationPreview, InlineHtmlEmailPreview, PreviewEmailSender, TextPreview} from '@/components/marketing';
import {
    ActOnConfirmButton,
    BackButton,
    FormErrorNotification,
    FormikSelect,
    FormikSwitch,
    FormikYesNoButtons,
    HorizontalDivider,
    Input,
    ServerSideFormValidationErrors,
    SubmitOnConfirmButton,
    UnlayerEmailEditor,
} from '@/components/form';
import {CardHeaderTitle, If, PageFooter, PageHeader, SimpleCard} from '@/components/container';
import CampaignImageUploader from '@/pages/Campaigns/components/CampaignImageUploader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrash, faWarning} from '@fortawesome/free-solid-svg-icons';
import {useCustomCampaignResource, useSmsMessageContentResource} from '@/services/api';
import classNames from 'classnames';
import {CustomCampaignDetailsResponse, SmsMessageContentValidationResponse} from '@shared/responseModels/business';
import FormikDatePicker from '../../../components/form/FormikDatePicker';
import CampaignImagePreview from '@/pages/Campaigns/components/CampaignImagePreview';
import {DateTime} from 'luxon';
import {useNavigate} from 'react-router';
import TabTitle from '@/pages/Campaigns/components/TabTitle';
import {useSearchParams} from 'react-router-dom';
import {UpgradeCallout} from '@/components/product';

const CustomCampaignDetailsForm = ({
    isSubmitting,
    resetForm,
    values,
    errors,
    setFieldValue,
    touched,
    handleChange,
}: FormikProps<CustomCampaignDetailsResponse>) => {
    const smsMessageContentResource = useSmsMessageContentResource();
    const campaignResource = useCustomCampaignResource();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [activeTab] = useState(searchParams.get('t') ?? 'email');
    const [textMessagePreviewImage, setTextMessagePreviewImage] = useState<File | null>();
    const [emailPreviewImage, setEmailPreviewImage] = useState<File | null>();

    const {selectedBusinessOption} = useAppContext();
    const [timer, setTimer] = useState<number | undefined>();
    const [textMessageValidation, setTextMessageValidation] = useState<SmsMessageContentValidationResponse>({
        hasViolations: false,
        verbatimViolations: [],
        censoredMessage: '',
    });

    useEffect(() => {
        validateTextMessageContent(values.textMessage);
    }, []);

    function validateTextMessageContent(textMessage: string) {
        smsMessageContentResource.validateSmsMessage(textMessage)
            .then(({data}: { data: SmsMessageContentValidationResponse }) => {
                setTextMessageValidation(data);
            });
    }

    function onTextMessageChange(event: ChangeEvent | null) {
        const textMessage = (event?.target as HTMLInputElement).value;
        setFieldValue('textMessage', textMessage);
        textMessageValidation.censoredMessage = '';
        setTextMessageValidation(textMessageValidation);
        clearTimeout(timer!);
        const newTimer = setTimeout(() => {
            validateTextMessageContent(textMessage);
        }, 500);

        setTimer(newTimer as unknown as number);
        return handleChange(event);
    }

    function onTextMessageValidate() {
        if (!textMessageValidation.hasViolations) {
            return null;
        }
        return (
            <div>
                Please remove these words, that are banned by carriers:
                <ul>
                    {textMessageValidation.verbatimViolations.map((bannedWord) => {
                        return <li key={bannedWord}>{bannedWord}</li>;
                    })}
                </ul>
            </div>
        );
    }

    const onClickDeleteCampaign = () => {
        campaignResource.delete(values.campaignId as number).then(() => {
            navigate('/campaigns');
        });
    };

    return (
        <>
            <Form id="campaign-details-form" noValidate>
                <PageHeader pageName={(
                    <Stack direction="horizontal">
                        <div className="me-3">
                            <BackButton to="/campaigns"/>
                        </div>
                        <div>
                            {values.campaignId ? 'Edit Campaign' : 'Create Campaign'}
                        </div>
                    </Stack>
                )}/>
                <FormErrorNotification/>
                <fieldset disabled={!values.isEditable} className="p-0">
                    <Container fluid>
                        <ServerSideFormValidationErrors/>

                        <Row>
                            <Col lg={3} xs={12} className="mt-md-5">
                                <Stack direction="vertical" gap={2}>
                                    <div>
                                        <Card className="shadow">
                                            <Card.Header>
                                                <CardHeaderTitle>Settings</CardHeaderTitle>
                                            </Card.Header>
                                            <Card.Body>
                                                <FormGroup controlId="scheduleFor" className="mb-3">
                                                    <FormLabel>Send Campaign On</FormLabel>
                                                    <Field isInvalid={!!errors.scheduleFor}
                                                           as={FormikDatePicker}
                                                           id="scheduleFor"
                                                           name="scheduleFor"
                                                           className={classNames(
                                                               'form-control', errors.scheduleFor ? 'is-invalid' : '',
                                                           )}
                                                           showTimeSelect
                                                           timeFormat="hh:mm aa"
                                                           timeIntervals={60}
                                                           dateFormat="MM/dd/yyyy hh:mm aa"
                                                           timeCaption="Time"
                                                           minDate={DateTime.local().set({minute: 0}).plus({hour: 1}).toJSDate()}
                                                           disallowPastTimes={true}
                                                    />
                                                    <FormControl.Feedback type="invalid">
                                                        {errors.scheduleFor}
                                                    </FormControl.Feedback>
                                                </FormGroup>

                                                <Input label="Campaign Name"
                                                       name="campaignName"
                                                       helpText="Helps you to uniquely identify a campaign (and is not seen by customers)."
                                                />
                                            </Card.Body>
                                        </Card>
                                    </div>

                                    <If isTrue={values.locationOptions.length > 1 || selectedBusinessOption.usesLifetimePointVipStatus}
                                        as={SimpleCard}
                                        title="Audience"
                                    >
                                        <If isTrue={selectedBusinessOption.usesLifetimePointVipStatus}
                                            as={FormLabel}>
                                            <Field name="isAudienceVipStatusFilterEnabled"
                                                   label={'Only VIP Customers'}
                                                   component={FormikSwitch}
                                            />
                                        </If>

                                        <If isTrue={values.locationOptions.length > 1}
                                            as={FormGroup}
                                            controlId="audienceLocations"
                                            className="mb-3"
                                        >
                                            <FormLabel>
                                                <Field name="isAudienceLocationFilterEnabled"
                                                       label="Send to Specific Locations"
                                                       component={FormikSwitch}
                                                />
                                                <If isTrue={!values.isAudienceLocationFilterEnabled}
                                                    as={FormText} className="text-muted d-inline">
                                                    All locations will be included.
                                                </If>
                                            </FormLabel>

                                            <If isTrue={values.isAudienceLocationFilterEnabled}>
                                                <Field name="audienceLocations"
                                                       options={values.locationOptions}
                                                       component={FormikSelect}
                                                       placeholder="Select one or more locations..."
                                                       isMulti={true}
                                                />
                                                <FormControl.Feedback type="invalid">
                                                    {errors.audienceLocations}
                                                </FormControl.Feedback>
                                            </If>
                                        </If>
                                    </If>

                                    {values.isOfferEnabled &&
                                        <div>
                                            <Card className="shadow">
                                                <Card.Header>
                                                    <CardHeaderTitle>Offer</CardHeaderTitle>
                                                </Card.Header>
                                                <Card.Body>
                                                    <Input label="Description" name="offerDescription"/>

                                                    <FormGroup controlId="offerExpirationDate" className="mb-3">
                                                        <FormLabel>
                                                            Offer Expires On
                                                        </FormLabel>
                                                        <Field as={FormikDatePicker}
                                                               name="offerExpirationDate"
                                                               className={classNames(
                                                                   'form-control', errors.offerExpirationDate ? 'is-invalid' : '',
                                                               )}
                                                               dateFormat="MM/dd/yyyy"
                                                               minDate={DateTime.local().set({minute: 0}).plus({day: 1}).toJSDate()}
                                                               disabled={!values.isOfferEnabled}
                                                               isInvalid={!!errors.offerExpirationDate}
                                                        />
                                                        <FormControl.Feedback type="invalid">
                                                            {errors.offerExpirationDate}
                                                        </FormControl.Feedback>
                                                    </FormGroup>

                                                    <FormGroup controlId="isOfferAvailableToNewCustomers"
                                                               className="mb-3 pt-2">
                                                        <Stack direction="horizontal" gap={0}>
                                                            {values.isEditable ?
                                                                <Field name="isOfferAvailableToNewCustomers"
                                                                       className={classNames(
                                                                           'form-control',
                                                                           errors.isOfferAvailableToNewCustomers ? 'is-invalid' : '',
                                                                       )}
                                                                       disabled={!values.isOfferEnabled}
                                                                       isInvalid={!!errors.isOfferAvailableToNewCustomers}
                                                                       as={FormikYesNoButtons}
                                                                /> :
                                                                <Badge bg="secondary" text="white"
                                                                       className="px-3 py-2">
                                                                    {values.isOfferAvailableToNewCustomers ? 'Yes' : 'No'}
                                                                </Badge>
                                                            }
                                                            <div>
                                                                <FormLabel className="m-0 ps-2 align-middle d-inline"
                                                                           style={{height: 30}}>
                                                                    Available To New Customers
                                                                </FormLabel>
                                                            </div>
                                                        </Stack>
                                                        <div className={
                                                            classNames(
                                                                'invalid-feedback',
                                                                errors.isOfferAvailableToNewCustomers ? 'd-block' : 'd-none',
                                                            )
                                                        }>
                                                            {errors.isOfferAvailableToNewCustomers}
                                                        </div>
                                                        <FormText className="text-muted ps-0">
                                                            <div className="px-3" style={{paddingTop: '0.6rem'}}>
                                                                <ol>
                                                                    <li>Extend the offer to new customers who join after
                                                                        the
                                                                        campaign is sent, but before the offer expires.
                                                                    </li>
                                                                    <li>New customers will not receive a communication,
                                                                        but they will see the offer in the kiosk and
                                                                        mobile app.
                                                                    </li>
                                                                </ol>
                                                            </div>
                                                        </FormText>
                                                    </FormGroup>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    }
                                </Stack>
                            </Col>
                            <Col lg={9} xs={12}>
                                <Tabs defaultActiveKey={activeTab}
                                      variant="tabs"
                                      className="ms-4"
                                      onSelect={(eventKey) => {
                                          searchParams.set('t', eventKey ?? 'email');
                                          setSearchParams(searchParams);
                                      }}
                                >
                                    <Tab eventKey="email"
                                         title={(
                                             <TabTitle
                                                 isEnabled={values.isEmailEnabled}
                                                 text="Email"
                                                 errors={errors}
                                                 touched={touched}
                                                 fields={[
                                                     'emailSubject',
                                                     'emailMessage',
                                                 ]}
                                             />
                                         )}
                                    >
                                        <div className="bg-white shadow">
                                            {selectedBusinessOption.usesCustomEmailCampaigns && (<>
                                                <Field name="isEmailEnabled"
                                                       className="ms-4 mb-4 me-2 mt-0 pt-4 fs-3"
                                                       component={FormikSwitch}
                                                       label="Enabled"
                                                />

                                                <fieldset disabled={!values.isEmailEnabled}
                                                          className="mt-0 border-top p-0">
                                                    <Container fluid className="border-bottom">
                                                        <Input
                                                            label="Subject"
                                                            name="emailSubject"
                                                            className="mb-3 mt-3 w-50"
                                                            maxLength={100}
                                                            disabled={!values.isEmailEnabled}
                                                            emojiButtonSuffix={true}
                                                        />
                                                    </Container>

                                                    {(selectedBusinessOption.usesAdvancedEmailEditor || selectedBusinessOption.canUpgradeTo.usesAdvancedEmailEditor) &&
                                                        <Stack direction="horizontal" gap={0}
                                                               className={classNames('border-bottom', selectedBusinessOption.canUpgradeTo.usesAdvancedEmailEditor ? 'bg-primary text-white' : '')}>
                                                            <FormGroup controlId="hasCustomEmailTemplate"
                                                                       className="p-3">
                                                                <Field label="Advanced Email Editor"
                                                                       name="hasCustomEmailTemplate"
                                                                       component={FormikSwitch}
                                                                       disabled={!selectedBusinessOption.usesAdvancedEmailEditor}
                                                                />
                                                            </FormGroup>
                                                            <UpgradeCallout
                                                                canUpgradeTo={selectedBusinessOption.canUpgradeTo.usesAdvancedEmailEditor}
                                                                text={'your plan to access the advanced email editor.'}
                                                            />
                                                        </Stack>
                                                    }

                                                    {values.hasCustomEmailTemplate ?
                                                        <UnlayerEmailEditor
                                                            isClaimButtonAvailable={values.isOfferEnabled}
                                                            htmlFieldName="campaignDetailsEmailTemplate"
                                                            designFieldName="unlayerEmailDesign"
                                                            templateVariables={values.templateVariables}
                                                        /> : null
                                                    }

                                                    {!values.hasCustomEmailTemplate ?
                                                        <Container fluid>
                                                            <Row>
                                                                <Col md={4} className="mt-3">
                                                                    <div>
                                                                        <Input label="Message"
                                                                               name="emailMessage"
                                                                               maxLength={250}
                                                                               emojiButtonSuffix={true}
                                                                               multiline={true}
                                                                        />

                                                                        <FormGroup controlId="emailImage">
                                                                            <FormLabel>Campaign Image</FormLabel>
                                                                            <CampaignImageUploader name="emailImage"
                                                                                                   onChange={setEmailPreviewImage}
                                                                                                   onReset={() => setEmailPreviewImage(null)}/>
                                                                        </FormGroup>
                                                                    </div>

                                                                    <If isTrue={!!values.emailImage || !!emailPreviewImage}
                                                                        className="bg-light text-center p-3 mb-3">
                                                                        <CampaignImagePreview
                                                                            url={values.emailImage}
                                                                            file={emailPreviewImage}
                                                                            description="email image"
                                                                            width="half"
                                                                        />
                                                                    </If>

                                                                    <Input label="Campaign Image Link"
                                                                           name="emailImageLink"
                                                                           maxLength={1000}
                                                                           placeholder="https://"
                                                                           helpText="A link to navigate to when the image is clicked."
                                                                    />
                                                                </Col>

                                                                <Col md={8}>
                                                                    {!values.hasCustomEmailTemplate &&
                                                                        <InlineHtmlEmailPreview
                                                                            emailMessage={values.emailMessage}
                                                                            emailImage={values.emailImage}
                                                                            emailImageLink={values.emailImageLink}
                                                                            isOfferEnabled={values.isOfferEnabled}
                                                                            offerDescription={values.offerDescription}
                                                                            offerExpirationDate={values.offerExpirationDate}
                                                                            errors={errors}
                                                                        />
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        </Container> : null
                                                    }

                                                    <Stack direction="horizontal" gap={2} className="border-top p-3">
                                                        <PreviewEmailSender subject={values.emailSubject}
                                                                            message={values.emailMessage}
                                                                            emailImage={values.emailImage}
                                                                            emailImageLink={values.emailImageLink}
                                                                            isOfferEnabled={values.isOfferEnabled}
                                                                            offerDescription={values.offerDescription}
                                                                            offerExpirationDate={values.offerExpirationDate}
                                                                            hasCustomEmailTemplate={values.hasCustomEmailTemplate}
                                                                            campaignDetailsEmailTemplate={values.campaignDetailsEmailTemplate}
                                                        />
                                                    </Stack>
                                                </fieldset>
                                            </>)
                                            }
                                        </div>
                                    </Tab>

                                    <Tab eventKey="mobileNotification"
                                         title={(
                                             <TabTitle
                                                 isEnabled={values.isAppNotificationEnabled}
                                                 text="Mobile"
                                                 errors={errors}
                                                 touched={touched}
                                                 fields={[
                                                     'appNotificationTitle',
                                                     'appNotificationBody',
                                                 ]}
                                             />
                                         )}
                                    >
                                        <div className="bg-white shadow">
                                            {selectedBusinessOption.usesCustomPushNotificationCampaigns && (<>
                                                <Field name="isAppNotificationEnabled"
                                                       className="ms-4 mb-4 me-2 mt-0 pt-4 fs-3"
                                                       component={FormikSwitch}
                                                       label="Enabled"
                                                />

                                                <fieldset disabled={!values.isAppNotificationEnabled}
                                                          className="mt-0 border-top p-0">
                                                    <Container fluid className="mt-0">
                                                        <Row>
                                                            <Col md={4} className="pt-3">
                                                                <Input
                                                                    label="Title"
                                                                    name="appNotificationTitle"
                                                                    maxLength={65}
                                                                    emojiButtonSuffix={true}
                                                                />

                                                                <Input
                                                                    label="Message"
                                                                    name="appNotificationBody"
                                                                    maxLength={240}
                                                                    emojiButtonSuffix={true}
                                                                    multiline={true}
                                                                />
                                                            </Col>

                                                            <Col md={8} className="pt-3 border-start">
                                                                <Row>
                                                                    <Col md={{span: 6, offset: 3}}>
                                                                        <AppNotificationPreview
                                                                            title={values.appNotificationTitle}
                                                                            message={values.appNotificationBody}
                                                                            hasOffer={values.isOfferEnabled}
                                                                            greeting={values.templateVariables.appNotificationTemplate.greeting}
                                                                            signOff={values.templateVariables.appNotificationTemplate.signOff}
                                                                            signature={values.templateVariables.appNotificationTemplate.signature}
                                                                            offerExpirationDate={values.offerExpirationDate as Date}
                                                                            disabled={!values.isAppNotificationEnabled}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Container>
                                                </fieldset>
                                            </>)}
                                        </div>
                                    </Tab>

                                    <Tab eventKey="sms"
                                         title={(
                                             <TabTitle
                                                 isEnabled={values.isTextEnabled}
                                                 text="Text"
                                                 errors={errors}
                                                 touched={touched}
                                                 fields={[
                                                     'textMessage',
                                                 ]}
                                             />
                                         )}
                                    >
                                        <div className="bg-white shadow">
                                            <Stack direction="horizontal"
                                                   className={selectedBusinessOption.canUpgradeTo.usesCustomSmsCampaigns ? 'bg-primary text-white' : ''}>
                                                <Field name="isTextEnabled"
                                                       disabled={!selectedBusinessOption.usesCustomSmsCampaigns}
                                                       className="ms-4 mb-4 me-2 mt-0 pt-4 fs-3"
                                                       component={FormikSwitch}
                                                       label="Enabled"
                                                />
                                                <UpgradeCallout
                                                    canUpgradeTo={selectedBusinessOption.canUpgradeTo.usesCustomSmsCampaigns}/>
                                            </Stack>

                                            <fieldset
                                                disabled={!values.isTextEnabled && !selectedBusinessOption.canUpgradeTo.usesCustomSmsCampaigns}
                                                className="mt-0 border-top p-0">
                                                <Container fluid>
                                                    <Row>
                                                        <Col md={4} className="pt-3">
                                                            <If isTrue={selectedBusinessOption.usesCustomMmsCampaigns}
                                                                as={FormGroup}>
                                                                <FormLabel>Image</FormLabel>
                                                                <CampaignImageUploader name="textImage"
                                                                                       maxCompressedFileSizeInMegabytes={0.5}
                                                                                       onChange={setTextMessagePreviewImage}
                                                                                       onReset={() => setTextMessagePreviewImage(null)}
                                                                />
                                                            </If>

                                                            <Input label="Message"
                                                                   name="textMessage"
                                                                   maxLength={130}
                                                                   emojiButtonSuffix={true}
                                                                   multiline={true}
                                                                   onChange={onTextMessageChange}
                                                                   onValidate={onTextMessageValidate}
                                                            />

                                                            <If isTrue={selectedBusinessOption.usesTextWebLink}>
                                                                <HorizontalDivider className="mb-3"/>

                                                                <FormGroup controlId="isTextWebLinkEnabled"
                                                                           className="mb-3 ms-3">
                                                                    <Field label="Web Link"
                                                                           name="isTextWebLinkEnabled"
                                                                           component={FormikSwitch}
                                                                    />
                                                                </FormGroup>

                                                                <Input
                                                                    label="Content"
                                                                    name="textWebLinkMessage"
                                                                    maxLength={1000}
                                                                    emojiButtonSuffix={true}
                                                                    multiline={true}
                                                                    disabled={!values.isTextWebLinkEnabled}
                                                                />
                                                            </If>
                                                        </Col>
                                                        <Col md={8} className="border-start">
                                                            <div>
                                                                <TextPreview message={values.textMessage}
                                                                             hasOffer={values.isOfferEnabled}
                                                                             greeting={values.templateVariables.textTemplate.greeting}
                                                                             signOff={values.templateVariables.textTemplate.signOff}
                                                                             signature={values.templateVariables.textTemplate.signature}
                                                                             offerExpirationDate={values.offerExpirationDate as Date}
                                                                             disabled={!values.isTextEnabled}
                                                                             censoredMessage={textMessageValidation.censoredMessage}
                                                                             isTextWebLinkEnabled={values.isTextWebLinkEnabled}
                                                                             textWebLinkUrl={values.textWebLinkUrl}
                                                                >
                                                                    <CampaignImagePreview url={values.textImage}
                                                                                          file={textMessagePreviewImage}
                                                                                          description="text message image"
                                                                                          className="rounded"
                                                                    />
                                                                </TextPreview>
                                                            </div>

                                                            <div className="mt-0 pt-0">
                                                                <div
                                                                    className="text-danger fs-6 fw-lighter form-tex mx-5 mb-3">
                                                                    <em>
                                                                        *Messages with flagged words are blocked by
                                                                        phone
                                                                        carriers
                                                                        and need
                                                                        to be removed.
                                                                    </em>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </fieldset>
                                        </div>
                                    </Tab>
                                </Tabs>
                            </Col>
                        </Row>
                    </Container>

                    <PageFooter>
                        <Stack direction="horizontal" gap={3}>
                            <SubmitOnConfirmButton disabled={isSubmitting}
                                                   resetForm={resetForm}
                                                   formName="campaign-details-form"
                                                   isSubmitting={isSubmitting}/>
                            {(values.campaignId as number) > 0 &&
                                <ActOnConfirmButton buttonVariant="outline-secondary"
                                                    text="Delete"
                                                    icon={<FontAwesomeIcon icon={faTrash} fixedWidth/>}
                                                    confirmButtonVariant="danger"
                                                    confirmButtonText="Yes, Delete"
                                                    onConfirm={onClickDeleteCampaign}
                                />
                            }
                        </Stack>
                    </PageFooter>
                </fieldset>
            </Form>
        </>
    );
};


export default CustomCampaignDetailsForm;
