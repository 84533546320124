import React, {useMemo, useState} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {FadeIn} from '@/components/animation';
import Insights from '@/pages/Dashboard/components/Insights';
import {ChartCheckins, ChartCustomers, ChartTouchPoints} from '@/pages/Dashboard/components/Charts';
import {useAppContext} from '@/AppContext';
import {BottomShadow, If} from '@/components/container';
import {Redemptions} from '@/pages/Dashboard/components/Redemptions';
import {LocationSelectFilter} from '@/components/filters';

function DashboardLayout() {
    const {selectedBusinessOption} = useAppContext();
    const [selectedLocations, setSelectedLocations] = useState<number[]>([]);
    const hasMultipleLocations = useMemo(() => (selectedBusinessOption.locations?.length ?? 0) > 1, [selectedBusinessOption]);
    return (
        <FadeIn>
            <If isTrue={hasMultipleLocations} className="py-2 px-2 bg-white mb-3 shadow">
                <LocationSelectFilter onApplyFilter={(selectedLocations) => {
                    const locations = selectedLocations.map(x => x.value) as number[];
                    setSelectedLocations(locations);
                }}/>
            </If>
            <If isTrue={!hasMultipleLocations} as={BottomShadow}/>

            <Container fluid className="mb-3">
                <Row>
                    <Col lg={{span: selectedBusinessOption.canViewInsights ? 9 : 12}}>
                        <Row>
                            <Col md={{span: 6}} className="mb-3">
                                <ChartCheckins locations={selectedLocations}/>
                            </Col>
                            <Col md={{span: 6}} className="mb-3">
                                <ChartCustomers locations={selectedLocations}/>
                            </Col>
                        </Row>

                        {selectedBusinessOption.canViewCampaigns &&
                            <Row>
                                <Col md={{span: 6}} className="mb-3">
                                    <ChartTouchPoints locations={selectedLocations}/>
                                </Col>
                                <Col md={{span: 6}} className="mb-3">
                                    <Redemptions locations={selectedLocations}/>
                                </Col>
                            </Row>
                        }
                    </Col>

                    {selectedBusinessOption.canViewInsights &&
                        <Col lg={{span: 3}}>
                            <Insights/>
                        </Col>
                    }
                </Row>
            </Container>
        </FadeIn>
    );
}

export default DashboardLayout;
