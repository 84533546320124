import React, {useCallback, useMemo, useState} from 'react';
import {useField} from 'formik';
import {useDropzone} from 'react-dropzone';
import {useCampaignImagesResource} from '@/services/api';
import {useAppContext} from '@/AppContext';
import {useNotify} from '@/services';
import CenteredSpinner from '../../../components/animation/CenteredSpinner';
import {Button, Stack} from 'react-bootstrap';
import {faTrash} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import imageCompression from 'browser-image-compression';

const baseStyle = {
    flex: 1,
    display: 'flex',

    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
};

const focusedStyle = {
    borderColor: '#efefef',
};

const acceptStyle = {
    borderColor: '#00e676',
};

const rejectStyle = {
    borderColor: '#ff1744',
};

interface Props {
    name: string;
    onChange?: (file: File | null, url: string) => void;
    onReset?: () => void;
    maxCompressedFileSizeInMegabytes?: number | undefined;
}

function CampaignImageUploader({
    name,
    onChange,
    onReset,
    maxCompressedFileSizeInMegabytes,
}: Props) {
    const campaignImagesResource = useCampaignImagesResource();
    const notify = useNotify();
    const [field, meta, helpers] = useField(name);
    const {value} = meta;
    const {setValue} = helpers;
    const [isProcessing, setIsProcessing] = useState(false);

    const {selectedBusinessOption, constants} = useAppContext();
    const allowedFilesMessage = 'Allowed files: .jpeg, .jpg, .png, .gif, .svg, .webp';
    maxCompressedFileSizeInMegabytes ??= constants.defaultMaxImageFileSizeInMegabytes;

    const onDrop = async (acceptedFiles: File[]) => {
        const [file] = acceptedFiles;

        const allowedExtensions = new RegExp('(.jpg|.jpeg|.png|.gif|.svg|.webp)$', 'i');
        if (!allowedExtensions.exec(file.name)) {
            notify.errorMessage(`Uploaded file is not a valid image. ${allowedFilesMessage}`);
            return;
        }

        if (file.size > constants.campaignImageMaxFileSizeInBytes) {
            notify.errorMessage(`The image is too large. Maximum allowed size is ${constants.defaultMaxImageFileSizeInMegabytes} MB.`);
            return;
        }

        const compressibleExtensions = new RegExp('(.jpg|.jpeg|.png|.svg)$', 'i');
        const isCompressible = !!compressibleExtensions.exec(file.name);

        const uploadableFile = isCompressible ?
            await imageCompression(file, {maxSizeMB: maxCompressedFileSizeInMegabytes,})
            : file;

        setIsProcessing(true);
        try {
            const formData = new FormData();
            formData.append('file', uploadableFile, file.name);
            const {data} = await campaignImagesResource.create(formData);
            setValue(data.url);
            if (onChange) {
                onChange(uploadableFile, data.url);
            }
        } catch (error: any) {
            notify.errorMessage(error?.response?.data?.message || error?.response?.statusText || error?.message);
        }
        setIsProcessing(false);
    };

    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        onDrop,
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg'],
            'image/jpg': ['.jpg'],
            'image/webp': ['.webp'],
            'image/gif': ['.gif'],
            'image/svg+xml': ['.svg'],
        }
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    const onDelete = useCallback(async () => {
        setValue(null);
        if (onReset) {
            onReset();
        }
    }, []);


    if (isProcessing) {
        return <CenteredSpinner/>;
    }

    return (
        <Stack direction="horizontal" className="mb-3" gap={3}>
            <div {...getRootProps({style})} className="clickable">
                <input {...getInputProps()} />
                <div className="mx-auto d-block text-center">
                    <div>Drop an image here, or click to select.</div>
                    <div>({allowedFilesMessage})</div>
                </div>
            </div>
            <div className="mx-auto">
                <Button onClick={onDelete}
                        disabled={!value}
                        variant="secondary"
                        className="me-3"
                >
                    <FontAwesomeIcon icon={faTrash} fixedWidth/>
                </Button>
            </div>
        </Stack>
    );
}

export default CampaignImageUploader;
