import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBell} from '@fortawesome/free-solid-svg-icons';

export function NotificationCenter() {
    const elementId = 'beamer-script-loader';
    let script = document.getElementById(elementId);

    if (!script) {
        // @ts-ignore
        window.beamer_config = {
            product_id: 'MTkuScTX55818',
            selector: '.beamerButton',
        };
        script = document.createElement('script');
        script.id = elementId;
        // @ts-ignore
        script.type = 'text/javascript';
        // @ts-ignore
        script.src = '//app.getbeamer.com/js/beamer-embed.js';
        // @ts-ignore
        script.defer = true;
        document.body.appendChild(script);
    }

    function onClickBeamerButton() {
        if (script) {
            // @ts-ignore
            window.Beamer.show();
        }
    }

    return (
        <>
            <div role="button" className="nav-link beamerButton"
                 onClick={onClickBeamerButton}>
                <div className="d-inline-block">
                    <span className="bg-secondary rounded-circle p-2">
                        <FontAwesomeIcon icon={faBell}
                                         fixedWidth
                                         className="text-white"
                        />
                    </span>
                </div>
            </div>
        </>
    );
}
