import React, {useEffect, useState} from 'react';
import {useRewardResource} from '@/services/api';
import {useAppContext} from '@/AppContext';
import {Formik} from 'formik';
import {CenteredSpinner, FadeIn} from '@/components/animation';
import RewardSettingsForm from '@/pages/Rewards/components/RewardSettingsForm';
import {RewardFormValues, RewardSchema} from '@shared/requestSchemas';
import {useAddServerSideFieldErrors} from '@/services/useAddServerSideFieldErrors';
import {FormikHelpers} from 'formik/dist/types';

function RewardSettings() {
    const rewardResource = useRewardResource();
    const addServerSideFieldErrors = useAddServerSideFieldErrors();
    const {selectedBusinessOption} = useAppContext();
    const [isPageLoading, setIsPageLoading] = useState(true);

    const [rewards, setRewards] = useState({
        usesClover: selectedBusinessOption.usesClover,
        rewardImageUrl: '',
        tieredRewards: [],
        isTieredRewardsLoyaltyProgram: false,
        isPunchCardLoyaltyProgram: false,
    });

    const fetchData = () => {
        setIsPageLoading(true);
        rewardResource.getList()
            .then(({data}) => {
                setRewards({
                    usesClover: selectedBusinessOption.usesClover,
                    ...data,
                });
            })
            .finally(() => setIsPageLoading(false));
    };

    const onSubmit = (values: RewardFormValues, {setSubmitting, setFieldError}: FormikHelpers<RewardFormValues>) => {
        const rewardFormValues = {
            ...values,
            usesClover: selectedBusinessOption.usesClover,
        };
        rewardResource.update(rewardFormValues)
            .then((rewards) => {
                const updatedRewards = {...values, ...rewards};
                setRewards(updatedRewards);
                values = updatedRewards;
            })
            .catch((error) => {
                addServerSideFieldErrors(setFieldError, error);
            })
            .finally(() => setSubmitting(false));
    };

    useEffect(() => {
        fetchData();
    }, [selectedBusinessOption]);

    if (isPageLoading) {
        return <CenteredSpinner/>;
    }

    return (
        <FadeIn>
            <Formik component={RewardSettingsForm}
                    initialValues={rewards}
                    enableReinitialize
                    validationSchema={RewardSchema}
                    onSubmit={onSubmit}
            />
        </FadeIn>
    );
}

export default RewardSettings;
