import React, {PropsWithChildren} from 'react';
import './SectionHeader.css';

interface Props {
}

export function SectionHeader({children}: PropsWithChildren<Props>) {
    return (
        <div className="section-header text-muted text-uppercase mb-3 fw-light">
            <span>{children}</span>
        </div>
    );
}
