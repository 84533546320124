import React, {PropsWithChildren} from 'react';
import classNames from 'classnames';

interface Props {
    className?: string;
}

function PageFooter({className, children}: PropsWithChildren<Props>) {
    return (
        <div className={classNames('my-0 py-0 py-2 px-3 position-fixed bottom-0 mt-3 bg-white w-100 border-top', className)}
             style={{zIndex: 9000}}>
            {children}
        </div>
    );
}

export default PageFooter;
