import React from 'react';
import {Col, Image, Row} from 'react-bootstrap';
import {IconButton} from '@/components/form';

interface Props {
    businessLogoUrl: string;
    rewardName: string;
    rewardCost: number;
    rewardImageUrl: string;
}

function MobileRewardCard({
    businessLogoUrl,
    rewardName,
    rewardCost,
    rewardImageUrl,
}: Props) {
    return (
        <Row className="card-simple border shadow bg-white"
             style={{height: '233.617px', width: '415.734px'}}>
            <Col className="p-0">
                <div className="p-3 text-center"
                     style={{height: '233.617px'}}>
                    <Image src={businessLogoUrl}
                           style={{height: '20%'}}
                           fluid
                           alt="business"/>

                    <div className="h3 ps-3 pt-1 m-0 text-start overflow-hidden"
                         style={{
                            height: '40%',
                            width: 170,
                            wordWrap: 'break-word',
                        }}>
                        {rewardName}
                    </div>

                    <div style={{height: '33%'}}>
                        <div className="h5 py-3 ps-3 m-0 text-muted text-start">
                            {rewardCost} Points
                        </div>

                        <div className="d-grid gap-2">
                            <IconButton text="REDEEM"/>
                        </div>
                    </div>
                </div>
            </Col>
            <Col className="p-0">
                <Image src={rewardImageUrl}
                       style={{height: '233.617px'}}
                       className="rounded-bottom-end"
                       fluid/>
            </Col>
        </Row>
    );
}

export default MobileRewardCard;
