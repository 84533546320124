import React, {useEffect, useMemo, useState} from 'react';
import {FadeIn} from '@/components/animation';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import {Nav, Stack} from 'react-bootstrap';
import {useAppContext} from '@/AppContext';
import {config} from '@/config';
import {If} from '@/components/container';
import loyaltiCenterLogo from '@images/loyalti_center_logo.svg';
import {CopyableText} from '@/components/form';

function SettingsLayout() {
    const location = useLocation();
    const navigate = useNavigate();
    const [activeMenuItem, setActiveMenuItem] = useState('business');
    const {selectedBusinessOption} = useAppContext();

    const subMenuItems = useMemo(() => {
        return {
            businessInformation: {
                name: 'Business Information',
                to: '/settings',
                isVisible: true,
            },
            brandSettings: {
                name: 'Brand Settings',
                to: '/settings/brand',
                isVisible: true,
            },
            customerSettings: {
                name: 'Customer Settings',
                to: '/settings/customer',
                isVisible: selectedBusinessOption.usesLifetimePointVipStatus,
            },
            paymentMethod: {
                name: 'Payment Method',
                to: '/settings/update-payment-method',
                isVisible: selectedBusinessOption.usesUpdatePaymentMethod && config.stripeClientApiKey?.length > 0,
            },
        };
    }, [selectedBusinessOption]);

    useEffect(() => {
        if (location.pathname.includes(subMenuItems.brandSettings.to)) {
            setActiveMenuItem(subMenuItems.brandSettings.name);
        } else if (location.pathname.includes(subMenuItems.customerSettings.to)) {
            setActiveMenuItem(subMenuItems.customerSettings.name);
        } else if (subMenuItems.paymentMethod && location.pathname.includes(subMenuItems.paymentMethod.to)) {
            setActiveMenuItem(subMenuItems.paymentMethod.name);
        } else {
            setActiveMenuItem(subMenuItems.businessInformation.name);
        }
    }, [location]);

    return (
        <FadeIn>
            <Stack direction="horizontal" className="pt-1 px-2 pb-0 bg-white mb-3 shadow">
                <div className="d-lg-block d-none">
                    <Nav variant="pills" activeKey={activeMenuItem}>
                        {
                            Object.values(subMenuItems).map((menuItem, idx) => (
                                menuItem.isVisible ?
                                    <Nav.Item key={idx}>
                                        <Nav.Link eventKey={menuItem.name}
                                                  href=""
                                                  className="py-3"
                                                  onClick={() => navigate(menuItem.to)}>
                                            {menuItem.name}
                                        </Nav.Link>
                                    </Nav.Item>
                                    : null
                            ))
                        }
                    </Nav>
                </div>

                <If isTrue={!!selectedBusinessOption.loyaltyCenterSetupCode}
                    className="px-3 ms-auto py-3 py-lg-0"
                    style={{maxWidth: 306}}
                >
                    <CopyableText textToCopy={selectedBusinessOption.loyaltyCenterSetupCode}
                                  prefixText="Loyalti Center Setup Code"
                                  buttonIcon={loyaltiCenterLogo}
                                  buttonVariant="outline-secondary"
                    />
                </If>
            </Stack>

            <Outlet/>
        </FadeIn>
    );
}

export default SettingsLayout;
