import React, {ComponentPropsWithoutRef, PropsWithChildren} from 'react';

const defaultElement = 'div';

type Props<T extends React.ElementType> = {
    as?: T;
    children?: React.ReactNode;
    isTrue: boolean;
} & ComponentPropsWithoutRef<T>;

export function If<T extends React.ElementType>({
    as,
    isTrue,
    children = null,
    ...props
}: PropsWithChildren<Props<T>>) {
    if (!isTrue) {
        return null;
    }
    const Component = (as ?? defaultElement);
    return (
        <Component {...props}>
            {children}
        </Component>
    );
}
