import React, {useMemo, useState} from 'react';
import {SelectFilter} from './SelectFilter';
import {useAppContext} from '@/AppContext';
import {If} from '../container/If';
import {Stack} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faClose, faStore} from '@fortawesome/free-solid-svg-icons';
import {IconButton} from '@/components/form';
import {LocationSelectOptions} from '@shared/responseModels/MeResponse';

interface Props {
    onApplyFilter: (options: LocationSelectOptions) => void;
}

export function LocationSelectFilter({onApplyFilter}: Props) {
    const {selectedBusinessOption} = useAppContext();
    const [selected, setSelected] = useState<LocationSelectOptions>(selectedBusinessOption.locations || []);
    const [show, setShow] = useState(false);

    const areNoneSelected = useMemo(() => {
        return selected?.length === 0;
    }, [selected]);

    function onResetSelected() {
        setSelected(selectedBusinessOption.locations);
    }

    return (
        <>
            <If isTrue={!show}>
                <IconButton className="text-decoration-none"
                            icon={<FontAwesomeIcon icon={faStore}/>}
                            onClick={() => setShow(true)}
                            text="Locations"
                            title="Locations"
                />
            </If>
            <If isTrue={show} as={Stack} direction="horizontal" gap={2}>
                <div style={{width: 340}}>
                    <SelectFilter isInline={true}
                                  available={selectedBusinessOption.locations}
                                  selected={selected}
                                  onApplyFilter={(newSelection) => {
                                      setSelected(newSelection as LocationSelectOptions);
                                  }}
                    />
                </div>

                <IconButton className="text-decoration-none"
                            disabled={areNoneSelected}
                            icon={<FontAwesomeIcon icon={faCheck} fixedWidth/>}
                            onClick={() => onApplyFilter(selected)}
                />

                <IconButton className="text-decoration-none"
                            icon={<FontAwesomeIcon icon={faClose} fixedWidth/>}
                            onClick={() => {
                                onResetSelected();
                                onApplyFilter(selected);
                                setShow(false);
                            }}
                />
            </If>
        </>
    );
}
