import React, {PropsWithChildren} from 'react';
import classNames from 'classnames';

interface Props {
    className?: string;
}

export function Tada({
    className,
    children,
}: PropsWithChildren<Props>) {
    return (
        <div className={classNames('animate__animated animate__tada', className)}>
            {children}
        </div>
    );
}
