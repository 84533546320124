import React, {useEffect, useMemo, useRef, useState} from 'react';
import {FadeIn} from '@/components/animation';
import {Container} from 'react-bootstrap';
import {Link, useSearchParams} from 'react-router-dom';
import {useShopperResource} from '@/services/api';
import {PaginatedTable, useTableUrlParams} from '@/components/table';
import {toShortDateString, toShortDateTimeString} from '@/services/textFormatting';
import {convertToCSV} from './components';
import {ColumnDef} from '@tanstack/react-table';
import {useLoaderData} from 'react-router';
import {CustomerListActionMenu} from '@/pages/Customers/components/CustomerListActionMenu';
import {PaginatedTableFetchDataArgs} from '@/components/table/PaginatedTableFetchDataArgs';
import {BigSwitch} from '@/components/form';
import {faBan} from '@fortawesome/free-solid-svg-icons';
import {useAppContext} from '@/AppContext';

export function FlagCustomerList() {
    const loaderData = useLoaderData() as {
        shoppers: [],
        totalShoppers: number,
    };
    const {setTableUrlParams} = useTableUrlParams();
    const {
        customerListSearchTerm,
    } = useAppContext();
    const shopperResource = useShopperResource();
    const [tableSettings, setTableSettings] = useState<PaginatedTableFetchDataArgs>({
        pageSize: 10, pageIndex: 0, sortBy: [],
    });

    const columns = useMemo<ColumnDef<any>[]>(() => [
            {
                accessorKey: 'created',
                header: () => (<div style={{marginLeft: 10}}>Date</div>),
                size: 100,
                cell: ({getValue}) => {
                    return <div style={{marginLeft: 10}}>{toShortDateTimeString(getValue() as string)}</div>;
                },
            },
            {
                accessorKey: 'humanReadablePhone',
                header: 'Phone',
                size: 80,
                cell: ({row, getValue}) => {
                    return <Link to={`/customers/${row.original.id}`}>{getValue() as string}</Link>;
                },
            },
            {
                accessorKey: 'fullName',
                header: 'Name',
                size: 80,
            },
            {
                accessorKey: 'points',
                header: 'Points Given',
                size: 200,
                cell: ({getValue}) => <div>{getValue() as string}</div>,
            },
            {
                accessorKey: 'isBlocked',
                header: 'Blocked',
                size: 50,
                cell: ({row}) => {
                    const [isBlockedLoading, setIsBlockedLoading] = useState(false);

                    async function toggleShopperBlocked(isBlocked: boolean) {
                        try {
                            setIsBlockedLoading(true);
                            await shopperResource.setBlocked(row.original.id, isBlocked);
                            row.original.isBlocked = isBlocked;
                        } finally {
                            setIsBlockedLoading(false);
                        }
                    }

                    return (
                        <BigSwitch checked={!row.original.isBlocked as boolean}
                                   onChange={async (value: boolean) => await toggleShopperBlocked(!value)}
                                   disabled={isBlockedLoading}
                                   uncheckedIcon={faBan}
                        />
                    );
                },
            },
        ],
        [],
    );

    const fetchData = async (
        {pageSize, pageIndex, sortBy}: PaginatedTableFetchDataArgs,
        updatedSearchTerm?: string,
    ) => {
        setTableUrlParams({
            pageIndex,
            pageSize,
            sortBy,
            search: updatedSearchTerm ?? customerListSearchTerm ?? '',
            defaultSortField: 'created',
        });
        setTableSettings({pageSize, pageIndex, sortBy});
    };

    async function onSubmitSearch(newSearchTerm: string) {
        tableSettings.pageIndex = 0;
        await fetchData(tableSettings, newSearchTerm);
    }

    return (
        <div>
            <CustomerListActionMenu onSearchTermSubmitted={onSubmitSearch}
                                    onRefreshButtonClicked={onSubmitSearch}
            />

            <FadeIn>
                <Container fluid>
                    <PaginatedTable
                        columns={columns}
                        data={loaderData.shoppers}
                        fetchData={fetchData}
                        pageCount={loaderData.totalShoppers / tableSettings.pageSize}
                        totalRows={loaderData.totalShoppers}
                        type={'flag'}
                        exportData={() => {
                            convertToCSV(`flagCustomersExport-${toShortDateString(new Date().toISOString())}.csv`, JSON.stringify(loaderData.shoppers));
                        }}
                    />
                </Container>
            </FadeIn>
        </div>
    );
}
