import React from 'react';
import {Card, Col, Container, FormControl, FormGroup, FormLabel, Row} from 'react-bootstrap';
import {Field, Form, FormikProps} from 'formik';
import {CardHeaderTitle, PageFooter} from '@/components/container';
import {HtmlEditor, SubmitOnConfirmButton} from '@/components/form';
import {KioskSettingsFormValues} from '@shared/requestSchemas/KioskSettingsSchema';

function KioskSettingsForm({
    errors,
    isSubmitting,
    resetForm,
}: FormikProps<KioskSettingsFormValues>) {

    return (
        <Form>
            <Container fluid className="mb-3">
                <Row>
                    <Col lg={{offset: 4, span: 4}}
                         md={{offset: 3, span: 6}}>
                        <Card className="shadow">
                            <Card.Header>
                                <CardHeaderTitle>Home Screen</CardHeaderTitle>
                            </Card.Header>
                            <Card.Body>
                                <FormGroup controlId="headerLine1" className="mb-3">
                                    <FormLabel>Header Line 1</FormLabel>
                                    <Field as={FormControl}
                                           name="headerLine1"
                                           isInvalid={!!errors.headerLine1}/>
                                    <FormControl.Feedback type="invalid">
                                        {errors.headerLine1}
                                    </FormControl.Feedback>
                                </FormGroup>

                                <FormGroup controlId="headerLine2" className="mb-3">
                                    <FormLabel>Header Line 2</FormLabel>
                                    <Field as={FormControl}
                                           name="headerLine2"
                                           isInvalid={!!errors.headerLine2}/>
                                    <FormControl.Feedback type="invalid">
                                        {errors.headerLine2}
                                    </FormControl.Feedback>
                                </FormGroup>

                                <FormGroup controlId="headerLine3" className="mb-3">
                                    <FormLabel>Header Line 3</FormLabel>
                                    <Field as={FormControl}
                                           name="headerLine3"
                                           isInvalid={!!errors.headerLine3}/>
                                    <FormControl.Feedback type="invalid">
                                        {errors.headerLine3}
                                    </FormControl.Feedback>
                                </FormGroup>

                                <FormGroup controlId="rewardYourselfMessage" className="mb-3">
                                    <FormLabel>Reward Yourself Message</FormLabel>
                                    <Field as={HtmlEditor}
                                           name="rewardYourselfMessage"
                                           isInvalid={!!errors.rewardYourselfMessage}/>
                                    <FormControl.Feedback type="invalid">
                                        {errors.rewardYourselfMessage}
                                    </FormControl.Feedback>
                                </FormGroup>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <PageFooter>
                <SubmitOnConfirmButton disabled={isSubmitting}
                                       resetForm={() => resetForm()}
                                       isSubmitting={isSubmitting}/>
            </PageFooter>
        </Form>
    );
}

export default KioskSettingsForm;
