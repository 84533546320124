import React, {useMemo, useState} from 'react';
import {Badge, Button, FormControl, InputGroup, Stack} from 'react-bootstrap';
import {useNavigate, useParams} from 'react-router';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faBan,
    faCheck,
    faClose,
    faComment,
    faMinus,
    faPlus,
    faSpinner,
    faUndo,
    faX,
} from '@fortawesome/free-solid-svg-icons';
import {useShopperResource} from '@/services/api';
import {ShopperDetailsResponse} from '@shared/responseModels/business';
import {BackButton, BigSwitch, VerticalDivider} from '@/components/form';
import {If} from '@/components/container';
import {toNumberString, toShortDateTimeString} from '@/services/textFormatting';
import ActOnConfirmButton from '@/components/form/ActOnConfirmButton';
import {useAppContext} from '@/AppContext';

interface Props {
    shopper: ShopperDetailsResponse;
    setShopper: (shopper: ShopperDetailsResponse) => void;
}

export function CustomerDetailsToolbar({shopper, setShopper}: Props) {
    const {selectedBusinessOption} = useAppContext();
    const shopperResource = useShopperResource();
    const navigate = useNavigate();
    const params = useParams();
    const shouldShowAdHocOptInFeature = useMemo(() => {
        const businessCanUseTextCampaigns = selectedBusinessOption.usesCustomSmsCampaigns || selectedBusinessOption.usesAutoSmsCampaigns;
        const isShopperAbleToOptIn = !!shopper.humanReadablePhone && !shopper.canReceiveSms;
        return businessCanUseTextCampaigns && isShopperAbleToOptIn;
    }, [selectedBusinessOption, shopper]);

    if (params.customerId === undefined) {
        navigate('/not-found');
        return null;
    }

    const customerId = parseInt(params.customerId);

    const [isMerchantSetVipLoading, setIsMerchantSetVipLoading] = useState(false);
    const [isBlockedLoading, setIsBlockedLoading] = useState(false);
    const [isPointsLoading, setIsPointsLoading] = useState(false);
    const [shopperPoints, setShopperPoints] = useState(shopper.points);
    const [isPointInputVisible, setIsPointInputVisible] = useState(false);

    async function toggleShopperVip(isMerchantSetVip: boolean) {
        try {
            setIsMerchantSetVipLoading(true);
            const {data} = await shopperResource.setVip(customerId, isMerchantSetVip);
            shopper.isMerchantSetVip = data.isMerchantSetVip;
            setShopper(shopper);
        } finally {
            setIsMerchantSetVipLoading(false);
        }
    }

    async function toggleShopperBlocked(isBlocked: boolean) {
        try {
            setIsBlockedLoading(true);
            const {data} = await shopperResource.setBlocked(customerId, isBlocked);
            shopper.isBlocked = data.isBlocked;
            setShopper(shopper);
        } finally {
            setIsBlockedLoading(false);
        }
    }

    function onSubmitShopperPoints(points: number) {
        setIsPointsLoading(true);
        points = points > 0 ? points : 0;
        shopperResource.setPoints(customerId, points)
            .then(({data}) => {
                shopper.points = data.points;
                shopper.lifetimePoints = data.lifetimePoints;
                shopper.visits = data.visits;
                setShopper(shopper);
                setShopperPoints(shopper.points);
            })
            .finally(() => {
                setIsPointsLoading(false);
                setIsPointInputVisible(false);
            });
    }

    if (!shopper) {
        return null;
    }

    return (
        <Stack direction="horizontal" className="shadow"
               style={{
                   paddingBlock: 10,
                   paddingInline: 20,
                   backgroundColor: '#fdfdfd',
               }}
        >
            <div style={{color: 'black', fontWeight: 'bold'}}>
                <Stack direction="horizontal" gap={2}>
                    <div className="me-3">
                        <BackButton to="/customers"/>
                    </div>
                    <div style={{color: 'black', fontSize: 26, fontWeight: 'bold'}}>
                        {shopper.fullName}
                    </div>
                </Stack>
            </div>

            <div className="ms-auto">
                <Stack direction="horizontal" gap={2}
                       style={{
                           paddingBlock: 10,
                           paddingInline: 20,
                       }}
                >
                    {shouldShowAdHocOptInFeature &&
                        <>
                            <If isTrue={!shopper.hasPendingAdHocTextOptInRequest}
                                as={ActOnConfirmButton}
                                alertMessage={
                                    (
                                        <p className="fs-6">
                                            <p>A text message opt-in request will be sent<br/>to your customer
                                                ({shopper.humanReadablePhone}).</p>
                                            <p>This message should ONLY be sent at the request of the customer.</p>
                                            <p>This message can ONLY be sent once every 7 days.</p>
                                            <p>Failure to comply with these guidelines can result in your message being
                                                marked as spam and account suspension.</p>
                                        </p>
                                    )
                                }
                                tooltipText="Send Opt-In Text"
                                icon={<FontAwesomeIcon icon={faComment} className="text-white"/>}
                                confirmButtonText="I understand. Yes, send text"
                                buttonVariant="primary"
                                onConfirm={async () => {
                                    const {data} = await shopperResource.sendTextOptInMessage(customerId);
                                    shopper.lastAdHocTextOptInMessageSentAt = data.lastAdHocTextOptInMessageSentAt;
                                    shopper.hasPendingAdHocTextOptInRequest = true;
                                    setShopper(shopper);
                                }}
                            />
                            <If isTrue={!shopper.hasPendingAdHocTextOptInRequest}>Send Opt-In Text</If>
                            <If isTrue={shopper.hasPendingAdHocTextOptInRequest} as={Badge} className="p-2" bg="info">
                                Pending text opt-in request
                                sent on {toShortDateTimeString(shopper.lastAdHocTextOptInMessageSentAt)}
                            </If>
                            <VerticalDivider/>
                        </>
                    }

                    <If isTrue={selectedBusinessOption.usesLifetimePointVipStatus}
                        as={BigSwitch}
                        text={shopper.vipStatusDisplayName}
                        switchable={!shopper.isLifetimePointVip}
                        checkedWhileUnSwitchableIf={shopper.isLifetimePointVip}
                        unSwitchableTooltip={`Customer has earned enough lifetime points to achieve ${shopper.vipStatusDisplayName} status`}
                        checked={shopper.isMerchantSetVip}
                        uncheckedIcon={faX}
                        uncheckedColor="muted"
                        onChange={toggleShopperVip}
                        disabled={isMerchantSetVipLoading}
                    />
                    <If isTrue={selectedBusinessOption.usesLifetimePointVipStatus} as={VerticalDivider}/>

                    <BigSwitch checked={!shopper.isBlocked as boolean}
                               onChange={async (value: boolean) => await toggleShopperBlocked(!value)}
                               disabled={isBlockedLoading}
                               uncheckedIcon={faBan}
                    >
                        <small className="d-inline-block float-start me-2 mx-2" style={{width: 42}}>
                            {shopper.isBlocked ? 'Blocked' : 'Active'}
                        </small>
                    </BigSwitch>
                    <VerticalDivider/>

                    <If isTrue={!isPointInputVisible} as={Button} className="rounded-circle px-2"
                        disabled={isPointsLoading || shopperPoints === shopper.points}
                        title="Cancel"
                        onClick={() => setShopperPoints(shopper.points)}>
                        <FontAwesomeIcon icon={faUndo} fixedWidth/>
                    </If>
                    <If isTrue={!isPointInputVisible} as={Button} className="rounded-circle px-2"
                        disabled={isPointsLoading || shopperPoints === 0}
                        onClick={() => shopperPoints > 0 && setShopperPoints(shopperPoints - 1)}>
                        <FontAwesomeIcon icon={faMinus} fixedWidth/>
                    </If>

                    <If isTrue={isPointInputVisible} as={Button}
                        onClick={() => setIsPointInputVisible(false)}
                        disabled={!(shopperPoints >= 0)}
                    >
                        <FontAwesomeIcon icon={faClose} fixedWidth/>
                    </If>

                    <If isTrue={isPointInputVisible} as={InputGroup}>
                        <FormControl name="shopperPoints"
                                     value={shopperPoints}
                                     onChange={(event) => {
                                         setShopperPoints(parseInt(event.target.value));
                                     }}
                                     size="sm"
                                     type="number"
                                     min="0"
                                     autoFocus={true}
                                     className="text-end pe-3"
                                     style={{width: 130}}
                        />
                        <InputGroup.Text className="bg-white border-start-0">Points</InputGroup.Text>
                    </If>

                    <If isTrue={!isPointInputVisible}
                        className="text-primary clickable"
                        onClick={() => {
                            setIsPointInputVisible(true);
                        }}>
                        {toNumberString(shopperPoints)} Points
                    </If>

                    <If isTrue={!isPointInputVisible} as={Button} className="rounded-circle px-2"
                        disabled={isPointsLoading}
                        onClick={() => setShopperPoints(shopperPoints + 1)}>
                        <FontAwesomeIcon icon={faPlus} fixedWidth/>
                    </If>
                    <Button className="rounded-circle px-2"
                            disabled={isPointsLoading || shopperPoints === shopper.points}
                            title="Save"
                            onClick={() => onSubmitShopperPoints(shopperPoints)}>
                        <FontAwesomeIcon spin={isPointsLoading}
                                         icon={isPointsLoading ? faSpinner : faCheck}
                                         fixedWidth/>
                    </Button>
                </Stack>
            </div>
        </Stack>
    );
}
