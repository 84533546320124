import * as yup from 'yup';
import {InferType} from 'yup';

export const AutoCampaignSchema = yup.object().shape({
    isEnabled: yup.boolean().required('Required.'),

    // Trigger
    hasTrigger: yup.boolean(),
    triggerValue: yup.number()
        .min(1, 'Must be greater than 0')
        .when('hasTrigger', {
            is: true,
            then: (schema) => schema.required('Required.'),
        }),
    triggerOperator: yup.string()
        .oneOf(['equals', 'gte', 'modulo'])
        .when('hasTrigger', {
            is: true,
            then: (schema) => schema.required('Required.'),
        }),

    // Recurrence
    hasRecurrence: yup.boolean(),
    recurrence: yup.number()
        .when('hasRecurrence', {
            is: true,
            then: (schema) => schema
                .min(1, 'Must be greater than 0')
                .nullable()
                .test('isNumber', 'Must be greater than 0', (value) => !isNaN(value)),
        }),
    recurrenceLimit: yup.number()
        .when('hasRecurrence', {
            is: true,
            then: (schema) => schema
                .min(0, 'Must be greater than or equal to 0')
                .nullable()
                .test('isNumber', 'Must be greater than or equal to 0', (value) => !isNaN(value)),
        }),

    // Offer
    offerDescription1: yup.string()
        .max(255, 'Offer Description name should be 255 characters or fewer.')
        .required('Required.'),
    offerValidForDays1: yup.number()
        .min(1)
        .max(28)
        .required('Required.'),

    hasSecondOffer: yup.boolean(),
    offerDescription2: yup.string()
        .when('hasSecondOffer', {
            is: true,
            then: (schema) => schema
                .max(255, 'Offer Description name should be 255 characters or fewer.')
                .required('Required.'),
        }),
    offerValidForDays2: yup.number()
        .when('hasSecondOffer', {
            is: true,
            then: (schema) => schema
                .min(1)
                .max(28)
                .required('Required.'),
        }),

    // Text Message
    isTextEnabled: yup.boolean(),
    hasCustomTextMessage: yup.boolean(),
    textMessage: yup.string()
        .max(130, 'Text message should be 130 characters or fewer.')
        .when(['isTextEnabled', 'hasCustomTextMessage'], {
            is: true,
            then: (schema) => schema.required('Required.'),
        }),

    // Email Message
    emailSubject: yup.string()
        .max(100, 'Email subject should be 100 characters or fewer.')
        .required('Required.'),
    emailMessage: yup.string()
        .max(250, 'Email message should be 250 characters or fewer.')
        .required('Required.'),

    // App Notification
    isAppNotificationEnabled: yup.boolean(),
    appNotificationTitle: yup.string()
        .max(65, 'App notification title subject should be 65 characters or fewer.')
        .when('isAppNotificationEnabled', {
            is: true,
            then: (schema) => schema.required('Required.'),
        }),
    appNotificationBody: yup.string()
        .max(240, 'App notification message should be 240 characters or fewer.')
        .when('isAppNotificationEnabled', {
            is: true,
            then: (schema) => schema.required('Required.'),
        }),
});

export interface AutoCampaignFormValues extends InferType<typeof AutoCampaignSchema> {
    id: number;
}
