import React, {FormEventHandler} from 'react';
import {Field, Form, FormikValues} from 'formik';
import {Col, Container, Row} from 'react-bootstrap';
import {FormikColorPicker, Input, SubmitOnConfirmButton} from '@/components/form';
import {PageFooter, SimpleCard} from '@/components/container';
import {SetFieldValue} from '@shared/formik';
import {CustomerSettingsFormValues} from '@shared/requestSchemas/CustomerSettingsSchema';
import {useAppContext} from '@/AppContext';
import {CustomerStatusBadge} from '@/components/indicators';

interface Props<T> {
    resetForm: FormEventHandler<HTMLFormElement>;
    isSubmitting: boolean;
    values: FormikValues & T;
    setFieldValue: SetFieldValue;
}

function CustomerSettingsForm({
    resetForm,
    isSubmitting,
    values,
}: Props<CustomerSettingsFormValues>) {
    const {selectedBusinessOption} = useAppContext();

    return (
        <Form noValidate>
            <Container fluid>
                <Row>
                    <Col xl={{span: 4}}>
                        <SimpleCard title="VIP Status"
                                    upgradeCalloutText="to access the VIP customer status feature."
                                    isUpgradeCalloutVisible={selectedBusinessOption.canUpgradeTo.usesLifetimePointVipStatus}
                        >
                            <fieldset disabled={!selectedBusinessOption.usesLifetimePointVipStatus || isSubmitting}>
                                <Row>
                                    <Col md={4} xs={6}>
                                        <Field as={Input}
                                               label="Required Points"
                                               name="pointsNeededToEarnVipStatus"
                                        />
                                    </Col>
                                    <Col md={8} xs={12}>
                                        <Field as={Input}
                                               label="Status Name"
                                               name="vipStatusDisplayName"
                                        />
                                    </Col>
                                </Row>
                                <div className="mx-2 mb-3 h5 fw-light">Status Branding</div>
                                <div className="mx-2 mb-3 fw-light fst-italic">These branding options determine how the
                                    VIP status of a customer is indicated in the Loyalti mobile app and Loyalty
                                    Center.
                                </div>
                                <Field as={FormikColorPicker}
                                       label="Text Color"
                                       name="vipStatusTextColor"
                                       className="w-50"
                                />
                                <Field as={FormikColorPicker}
                                       label="Background Color"
                                       name="vipStatusBackgroundColor"
                                       className="w-50"
                                />
                                <div className="mx-2 mb-3 h5 fw-light">Example Branding Badge</div>
                                <CustomerStatusBadge text={values.vipStatusDisplayName!}
                                                     textColor={values.vipStatusTextColor}
                                                     backgroundColor={values.vipStatusBackgroundColor}
                                                     size="lg"
                                />
                            </fieldset>
                        </SimpleCard>
                    </Col>
                </Row>
            </Container>

            <PageFooter>
                <SubmitOnConfirmButton disabled={!selectedBusinessOption.usesLifetimePointVipStatus || isSubmitting}
                                       resetForm={resetForm}
                                       isSubmitting={isSubmitting}/>
            </PageFooter>
        </Form>
    );
}

export default CustomerSettingsForm;
