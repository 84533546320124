import React, {useEffect, useState} from 'react';
import {CenteredSpinner, FadeIn} from '@/components/animation';
import {useAppContext} from '@/AppContext';
import {useLocationResource} from '@/services/api';
import {Formik} from 'formik';
import {LocationFormValues, LocationSchema} from '@shared/requestSchemas';
import {LocationDetailsForm} from '@/pages/Locations/components';
import {useParams} from 'react-router';
import {useNavigate} from 'react-router-dom';
import {FormikHelpers} from 'formik/dist/types';

function LocationDetails() {
    const locationResource = useLocationResource();
    const params = useParams();
    const navigate = useNavigate();
    const {selectedBusinessOption} = useAppContext();
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [locationDetails, setLocationDetails] = useState<LocationFormValues>({} as LocationFormValues);
    const [locationId] = useState<number | null>(
        params.locationId ? parseInt(params.locationId) : null,
    );

    if (!locationId) {
        navigate('/not-found');
        return null;
    }

    const onSubmit = (values: LocationFormValues, {setSubmitting}: FormikHelpers<LocationFormValues>) => {
        locationResource.update(locationId, values)
            .then(({data}) => {
                setLocationDetails(data);
            })
            .finally(() => setSubmitting(false));
    };

    const fetchData = () => {
        setIsPageLoading(true);
        locationResource.get(locationId)
            .then(({data}) => {
                setLocationDetails(data);
            })
            .catch(() => {
                navigate('/locations');
            })
            .finally(() => setIsPageLoading(false));
    };

    useEffect(() => {
        fetchData();
    }, [selectedBusinessOption]);

    if (isPageLoading) {
        return <CenteredSpinner/>;
    }

    return (
        <FadeIn>
            <Formik validationSchema={LocationSchema}
                    component={LocationDetailsForm}
                    initialValues={locationDetails}
                    onSubmit={onSubmit}/>
        </FadeIn>
    );
}

export default LocationDetails;
