import {schemeCategory10} from 'd3-scale-chromatic';

interface ThemeColors {
    primary: string;
    secondary: string;
    success: string;
    danger: string;
    warning: string;
    info: string;
    light: string;
    dark: string;
    muted: string;
    white: string;
}

export const themeColors: ThemeColors = {
    primary: '#6658EA',
    secondary: '#1B1642',
    success: '#2CD4A2',
    danger: '#F9655B',
    warning: '#F5C070',
    info: '#1f77b4',
    light: '#ededf0',
    dark: '#212529',
    muted: '#bbbbbb',
    white: '#ffffff',
};

export const dataPalette = schemeCategory10;
