interface SocialMediaLink {
    url: string;
    image: string;
}

function makeSocialLinkMarkup(socialMediaLinks: SocialMediaLink[]): string {
    const links = socialMediaLinks.map(socialMediaLink => {
        return !socialMediaLink?.url ? '' :
            `<a href="${socialMediaLink.url}"><img src="${socialMediaLink.image}" alt="" style="max-height: 28px; margin: 0 4px;"></a>`;
    }).filter(x => x != '');

    return links.length > 0 ? `
            <div style="text-align:center; margin-left: auto; margin-right: auto; border-top: solid 1px #efefef; padding-top: 10px;">
                ${links.join(' ')}
            </div>
        ` : '';
}

interface UnlayerEmailTemplateFactory {
    businessLogo: string;
    businessLogoHeight: number;
    businessLogoWidth: number;
    greeting: string;
    signOff: string;
    businessName: string;
    yelpUrl: string,
    instagramUrl: string,
    facebookUrl: string,
    twitterUrl: string,
    mapsUrl: string,

    emailTemplate: {
        signOff: string,
        greeting: string,
        template: {
            footerColor: string,
            headerColor: string,
            claimButtonColor: string,
            claimButtonBorder: string,
            incentiveTextColor: string,
            claimButtonTextColor: string,
            incentiveBackgroundColor: string,
        },
        signature: string,
        buttonText: string,
    },
}

const unlayerEmailTemplateFactory = function({
    businessLogo,
    businessLogoHeight = 256,
    businessLogoWidth = 256,
    // greeting = 'Hello',
    // signOff = 'Thanks',
    businessName = '',
    emailTemplate: {
        greeting = '',
        signOff = '',
        signature = '',
    },
    yelpUrl = '',
    instagramUrl = '',
    facebookUrl = '',
    twitterUrl = '',
    mapsUrl = '',
}: UnlayerEmailTemplateFactory) {
    // Sign-off / Signature
    const signOffMarkup = signOff ? `<p style="font-size: 14px; line-height: 140%;">${signOff},</p>\n` : '';
    const signatureMarkup = `<p style="font-size: 14px; line-height: 140%;">${signature ?? businessName}</p>`;
    const fullSignOffAndSignature = signOffMarkup + signatureMarkup;

    // Social Media Links
    const socialMediaLinkMarkup = makeSocialLinkMarkup([
        {url: yelpUrl, image: 'https://static.sparkloyalty.net/marketing-images/email-yelp.svg'},
        {url: instagramUrl, image: 'https://static.sparkloyalty.net/marketing-images/email-instagram.svg'},
        {url: facebookUrl, image: 'https://static.sparkloyalty.net/marketing-images/email-facebook.svg'},
        {url: twitterUrl, image: 'https://static.sparkloyalty.net/marketing-images/email-twitter.svg'},
        {url: mapsUrl, image: 'https://static.sparkloyalty.net/marketing-images/email-maps.svg'},
    ]);

    const template = {
        'counters': {
            'u_column': 7,
            'u_row': 7,
            'u_content_image': 5,
            'u_content_text': 3,
            'u_content_html': 3,
        },
        'body': {
            'id': '1_lY-wPtaK',
            'rows': [
                {
                    'id': 'jEu9V6q-i4',
                    'cells': [
                        1,
                    ],
                    'columns': [
                        {
                            'id': 'h8aaTSRteu',
                            'contents': [
                                {
                                    'id': 'y4iScBoRii',
                                    'type': 'image',
                                    'values': {
                                        'containerPadding': '10px',
                                        'anchor': '',
                                        'src': {
                                            'url': businessLogo,
                                            'width': businessLogoWidth,
                                            'height': businessLogoHeight,
                                            'autoWidth': false,
                                            'maxWidth': '28%',
                                        },
                                        'textAlign': 'center',
                                        'altText': '',
                                        'action': {
                                            'name': 'web',
                                            'values': {
                                                'href': '',
                                                'target': '_blank',
                                            },
                                        },
                                        'displayCondition': null,
                                        '_meta': {
                                            'htmlID': 'u_content_image_2',
                                            'htmlClassNames': 'u_content_image',
                                        },
                                        'selectable': true,
                                        'draggable': true,
                                        'duplicatable': true,
                                        'deletable': true,
                                        'hideable': true,
                                    },
                                },
                            ],
                            'values': {
                                'backgroundColor': '#ffffff',
                                'padding': '0px',
                                'border': {},
                                '_meta': {
                                    'htmlID': 'u_column_1',
                                    'htmlClassNames': 'u_column',
                                },
                            },
                        },
                    ],
                    'values': {
                        'displayCondition': null,
                        'columns': false,
                        'backgroundColor': '',
                        'columnsBackgroundColor': '',
                        'backgroundImage': {
                            'url': '',
                            'fullWidth': true,
                            'repeat': false,
                            'center': true,
                            'cover': false,
                        },
                        'padding': '0px',
                        'anchor': '',
                        'hideDesktop': false,
                        '_meta': {
                            'htmlID': 'u_row_1',
                            'htmlClassNames': 'u_row',
                        },
                        'selectable': true,
                        'draggable': true,
                        'duplicatable': true,
                        'deletable': true,
                        'hideable': true,
                    },
                },
                {
                    'id': '66LB00rTIb',
                    'cells': [
                        1,
                    ],
                    'columns': [
                        {
                            'id': 'nG_B9d5-lR',
                            'contents': [
                                {
                                    'id': 'reKIgNUOJE',
                                    'type': 'text',
                                    'values': {
                                        'containerPadding': '10px',
                                        'anchor': '',
                                        'textAlign': 'left',
                                        'lineHeight': '140%',
                                        'linkStyle': {
                                            'inherit': true,
                                            'linkColor': '#0000ee',
                                            'linkHoverColor': '#0000ee',
                                            'linkUnderline': true,
                                            'linkHoverUnderline': true,
                                        },
                                        'displayCondition': null,
                                        '_meta': {
                                            'htmlID': 'u_content_text_1',
                                            'htmlClassNames': 'u_content_text',
                                        },
                                        'selectable': true,
                                        'draggable': true,
                                        'duplicatable': true,
                                        'deletable': true,
                                        'hideable': true,
                                        'text': `<p style="font-size: 14px; line-height: 140%;">${greeting} {{firstName}},</p>`,
                                    },
                                },
                            ],
                            'values': {
                                'backgroundColor': '#ffffff',
                                'padding': '15px',
                                'border': {},
                                'borderRadius': '0px',
                                '_meta': {
                                    'htmlID': 'u_column_4',
                                    'htmlClassNames': 'u_column',
                                },
                            },
                        },
                    ],
                    'values': {
                        'displayCondition': null,
                        'columns': false,
                        'backgroundColor': '',
                        'columnsBackgroundColor': '',
                        'backgroundImage': {
                            'url': '',
                            'fullWidth': true,
                            'repeat': false,
                            'center': true,
                            'cover': false,
                        },
                        'padding': '0px',
                        'anchor': '',
                        '_meta': {
                            'htmlID': 'u_row_4',
                            'htmlClassNames': 'u_row',
                        },
                        'selectable': true,
                        'draggable': true,
                        'duplicatable': true,
                        'deletable': true,
                        'hideable': true,
                    },
                },
                {
                    'id': 'XZRFefy_GQ',
                    'cells': [
                        1,
                    ],
                    'columns': [
                        {
                            'id': 'ql5i_-VWRc',
                            'contents': [],
                            'values': {
                                'backgroundColor': '#ffffff',
                                'padding': '15px',
                                'border': {},
                                'borderRadius': '0px',
                                '_meta': {
                                    'htmlID': 'u_column_3',
                                    'htmlClassNames': 'u_column',
                                },
                            },
                        },
                    ],
                    'values': {
                        'displayCondition': null,
                        'columns': false,
                        'backgroundColor': '',
                        'columnsBackgroundColor': '',
                        'backgroundImage': {
                            'url': '',
                            'fullWidth': true,
                            'repeat': false,
                            'center': true,
                            'cover': false,
                        },
                        'padding': '0px',
                        'anchor': '',
                        '_meta': {
                            'htmlID': 'u_row_3',
                            'htmlClassNames': 'u_row',
                        },
                        'selectable': true,
                        'draggable': true,
                        'duplicatable': true,
                        'deletable': true,
                        'hideable': true,
                    },
                },
                {
                    'id': 'e6bfPTRVd6',
                    'cells': [
                        1,
                    ],
                    'columns': [
                        {
                            'id': 'w-sq56xw18',
                            'contents': [
                                {
                                    'id': 'LqP1D9Mxd3',
                                    'type': 'text',
                                    'values': {
                                        'containerPadding': '10px',
                                        'anchor': '',
                                        'textAlign': 'left',
                                        'lineHeight': '140%',
                                        'linkStyle': {
                                            'inherit': true,
                                            'linkColor': '#0000ee',
                                            'linkHoverColor': '#0000ee',
                                            'linkUnderline': true,
                                            'linkHoverUnderline': true,
                                        },
                                        'displayCondition': null,
                                        '_meta': {
                                            'htmlID': 'u_content_text_3',
                                            'htmlClassNames': 'u_content_text',
                                        },
                                        'selectable': true,
                                        'draggable': true,
                                        'duplicatable': true,
                                        'deletable': true,
                                        'hideable': true,
                                        'text': fullSignOffAndSignature,
                                    },
                                },
                            ],
                            'values': {
                                'backgroundColor': '#ffffff',
                                'padding': '15px',
                                'border': {},
                                'borderRadius': '0px',
                                '_meta': {
                                    'htmlID': 'u_column_2',
                                    'htmlClassNames': 'u_column',
                                },
                            },
                        },
                    ],
                    'values': {
                        'displayCondition': null,
                        'columns': false,
                        'backgroundColor': '',
                        'columnsBackgroundColor': '',
                        'backgroundImage': {
                            'url': '',
                            'fullWidth': true,
                            'repeat': false,
                            'center': true,
                            'cover': false,
                        },
                        'padding': '0px',
                        'anchor': '',
                        '_meta': {
                            'htmlID': 'u_row_2',
                            'htmlClassNames': 'u_row',
                        },
                        'selectable': true,
                        'draggable': true,
                        'duplicatable': true,
                        'deletable': true,
                        'hideable': true,
                    },
                },
                {
                    'id': 'RD2jVdLg-8',
                    'cells': [
                        1,
                    ],
                    'columns': [
                        {
                            'id': 'YzLCyCwRFa',
                            'contents': [
                                {
                                    'id': 'YtQ1uSL1iv',
                                    'type': 'html',
                                    'values': {
                                        // 'html': `
                                        //     <div style="text-align:center; margin-left: auto: margin-right: auto;"><a href="https://www.yelp.com/biz/"><img src="https://static.sparkloyalty.net/marketing-images/email-yelp.svg"\n         alt="" style="height: 48px; margin: 0 4px;">\n</a>\n\n<a href="https://www.facebook.com/biz/">\n    <img src="https://static.sparkloyalty.net/marketing-images/email-facebook.svg"\n         alt="" style="height: 48px; margin: 0 4px;">\n</a>\n
                                        //     </div>
                                        // `,
                                        'html': socialMediaLinkMarkup,
                                        'displayCondition': null,
                                        'containerPadding': '10px',
                                        'anchor': '',
                                        '_meta': {
                                            'htmlID': 'u_content_html_3',
                                            'htmlClassNames': 'u_content_html',
                                        },
                                        'selectable': true,
                                        'draggable': true,
                                        'duplicatable': true,
                                        'deletable': true,
                                        'hideable': true,
                                    },
                                },
                            ],
                            'values': {
                                'backgroundColor': '#ffffff',
                                'padding': '15px',
                                'border': {},
                                'borderRadius': '0px',
                                '_meta': {
                                    'htmlID': 'u_column_5',
                                    'htmlClassNames': 'u_column',
                                },
                            },
                        },
                    ],
                    'values': {
                        'displayCondition': null,
                        'columns': false,
                        'backgroundColor': '',
                        'columnsBackgroundColor': '',
                        'backgroundImage': {
                            'url': '',
                            'fullWidth': true,
                            'repeat': false,
                            'center': true,
                            'cover': false,
                        },
                        'padding': '0px',
                        'anchor': '',
                        '_meta': {
                            'htmlID': 'u_row_5',
                            'htmlClassNames': 'u_row',
                        },
                        'selectable': true,
                        'draggable': true,
                        'duplicatable': true,
                        'deletable': true,
                        'hideable': true,
                    },
                },
                {
                    'id': 'vQWdhv7PB8',
                    'cells': [
                        1,
                    ],
                    'columns': [
                        {
                            'id': 'g_oXRfm0Jw',
                            'contents': [
                                {
                                    'id': 'hr3j7xcfqC',
                                    'type': 'html',
                                    'values': {
                                        'html': `<div style="color: #999999; font-size: 12px; text-align: center;">\n<a href="{{unsubscribeLink}}" style="text-decoration: underline; color: #999999; font-size: 12px; text-align: center;">Unsubscribe</a>\nto stop receiving emails from ${businessName}\n</div>`,
                                        'displayCondition': null,
                                        'containerPadding': '10px',
                                        'anchor': '',
                                        '_meta': {
                                            'htmlID': 'u_content_html_2',
                                            'htmlClassNames': 'u_content_html',
                                        },
                                        'selectable': true,
                                        'draggable': true,
                                        'duplicatable': true,
                                        'deletable': true,
                                        'hideable': true,
                                    },
                                },
                            ],
                            'values': {
                                'backgroundColor': '',
                                'padding': '0px',
                                'border': {},
                                'borderRadius': '0px',
                                '_meta': {
                                    'htmlID': 'u_column_7',
                                    'htmlClassNames': 'u_column',
                                },
                            },
                        },
                    ],
                    'values': {
                        'displayCondition': null,
                        'columns': false,
                        'backgroundColor': '',
                        'columnsBackgroundColor': '',
                        'backgroundImage': {
                            'url': '',
                            'fullWidth': true,
                            'repeat': false,
                            'center': true,
                            'cover': false,
                        },
                        'padding': '0px',
                        'anchor': '',
                        '_meta': {
                            'htmlID': 'u_row_7',
                            'htmlClassNames': 'u_row',
                        },
                        'selectable': true,
                        'draggable': true,
                        'duplicatable': true,
                        'deletable': true,
                        'hideable': true,
                    },
                },
            ],
            'values': {
                'popupPosition': 'center',
                'popupWidth': '600px',
                'popupHeight': 'auto',
                'borderRadius': '10px',
                'contentAlign': 'center',
                'contentVerticalAlign': 'center',
                'contentWidth': '500px',
                'fontFamily': {
                    'label': 'Arial',
                    'value': 'arial,helvetica,sans-serif',
                },
                'textColor': '#000000',
                'popupBackgroundColor': '#FFFFFF',
                'popupBackgroundImage': {
                    'url': '',
                    'fullWidth': true,
                    'repeat': false,
                    'center': true,
                    'cover': true,
                },
                'popupOverlay_backgroundColor': 'rgba(0, 0, 0, 0.1)',
                'popupCloseButton_position': 'top-right',
                'popupCloseButton_backgroundColor': '#DDDDDD',
                'popupCloseButton_iconColor': '#000000',
                'popupCloseButton_borderRadius': '0px',
                'popupCloseButton_margin': '0px',
                'popupCloseButton_action': {
                    'name': 'close_popup',
                    'attrs': {
                        'onClick': 'document.querySelector(\'.u-popup-container\').style.display = \'none\';',
                    },
                },
                'backgroundColor': '#e7e7e7',
                'backgroundImage': {
                    'url': '',
                    'fullWidth': true,
                    'repeat': false,
                    'center': true,
                    'cover': false,
                },
                'preheaderText': '',
                'linkStyle': {
                    'body': true,
                    'linkColor': '#0000ee',
                    'linkHoverColor': '#0000ee',
                    'linkUnderline': true,
                    'linkHoverUnderline': true,
                },
                '_meta': {
                    'htmlID': 'u_body',
                    'htmlClassNames': 'u_body',
                },
            },
        },
        'schemaVersion': 10,
    };
    return template;
};

export default unlayerEmailTemplateFactory;
