import React from 'react';
import DatePicker from 'react-datepicker';
import {useField, useFormikContext} from 'formik';
import {DateTime} from 'luxon';

export default function FormikDatePicker({...props}) {
    const {setFieldValue} = useFormikContext();
    // @ts-ignore
    const [field, meta] = useField(props);

    return (
        <>
            <DatePicker
                filterTime={(date) => {
                    if (!date || !props.disallowPastTimes) {
                        return true;
                    }
                    return DateTime.fromJSDate(date) > DateTime.local();
                }}
                {...field}
                {...props}
                selected={(field.value && new Date(field.value)) || null}
                onChange={val => {
                    // if date value is in the past, force it to be one hour ahead of now.
                    const date = val && props.disallowPastTimes && DateTime.fromJSDate(val) < DateTime.local() ?
                        DateTime.local().set({minute: 0}).plus({hour: 1}).toJSDate() :
                        val;
                    setFieldValue(field.name, date);
                }}
            />
            {/*This is a hack to get the react-bootstrap error message to appear*/}
            <input type="hidden" className={meta.error ? 'is-invalid' : ''}/>
        </>
    );
}
