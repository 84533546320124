import React, {useMemo, useState} from 'react';
import {Badge, Container, Stack} from 'react-bootstrap';
import {useAppContext} from '@/AppContext';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {PaginatedTable, useTableUrlParams} from '@/components/table';
import {faBan, faComment, faEnvelope, faFlag, faMobileScreen} from '@fortawesome/free-solid-svg-icons';
import {Link, useLoaderData, useSearchParams} from 'react-router-dom';
import {toNumberString, toShortDateString, toShortDateTimeString} from '@/services/textFormatting';
import {HelpTip} from '@/components/form';
import {PaginatedTableFetchDataArgs} from '@/components/table/PaginatedTableFetchDataArgs';
import {CustomerListActionMenu} from '@/pages/Customers/components/CustomerListActionMenu';
import {FadeIn} from '@/components/animation';
import {ColumnDef, createColumnHelper} from '@tanstack/react-table';
import {ShopperListItem, ShopperListResponse} from '@shared/responseModels/business';
import {keyBy} from 'lodash';
import {SelectOption, SelectOptions} from '@shared/responseModels';
import {convertToCSV} from '@/pages/Customers/components';
import {UpgradeCallout} from '@/components/product';
import CardHeader from 'react-bootstrap/CardHeader';

export function CustomerList() {
    const makeColumnDef = createColumnHelper<ShopperListItem>();
    const loaderData = useLoaderData() as ShopperListResponse;
    const {setTableUrlParams} = useTableUrlParams();
    const {
        selectedBusinessOption,
        constants,
        customerListSearchTerm,
    } = useAppContext();

    const [tableSettings, setTableSettings] = useState<PaginatedTableFetchDataArgs>({
        pageSize: 10, pageIndex: 0, sortBy: [],
    });

    const columns = useMemo<ColumnDef<ShopperListItem>[]>(() => {
        return [
            makeColumnDef.accessor('locationName', {
                header: 'Location',
                enableColumnFilter: true,
                meta: {
                    discreteFilterOptions: {
                        available: loaderData?.availableLocationOptions || [],
                        selected: loaderData?.selectedLocationOptions || [],
                    },
                },
                size: 80,
                minSize: 140,
                cell: (info) => {
                    return (
                        <div className="text-wrap">
                            {info.getValue()}
                        </div>
                    );
                },
            }),
            makeColumnDef.accessor('fullName', {
                header: 'Name',
                cell: (info) => {
                    return (
                        <Stack direction="horizontal" gap={2}>
                            <Link to={`/customers/${info.row.original.id}`}>{info.getValue()}</Link>
                            {info.row.original.isVip ?
                                <Badge bg="info">{info.row.original.vipStatusDisplayName}</Badge> : null}
                        </Stack>
                    );
                },
            }),
            makeColumnDef.accessor('humanReadablePhone', {
                header: 'Phone',
                size: 80,
                minSize: 80,
            }),
            makeColumnDef.accessor('canReceiveSms', {
                header: 'Opt-ins',
                size: 40,
                minSize: 60,
                enableSorting: false,
                cell: ({row}) => {
                    const {canReceiveEmail, canReceiveAppNotification, canReceiveSms} = row.original;
                    return (
                        <Stack direction="horizontal" gap={1}>
                            <FontAwesomeIcon icon={faEnvelope} fixedWidth
                                             title={`${canReceiveEmail ? 'Opted-in to' : 'Not opted-in to'} email`}
                                             className={canReceiveEmail ? 'text-info' : 'text-muted'}/>
                            <FontAwesomeIcon icon={faMobileScreen} fixedWidth
                                             title={`${canReceiveAppNotification ? 'Opted-in to' : 'Not opted-in to'} mobile notifications`}
                                             className={canReceiveAppNotification ? 'text-info' : 'text-muted'}/>
                            <FontAwesomeIcon icon={faComment} fixedWidth
                                             title={`${canReceiveSms ? 'Opted-in to' : 'Not opted-in to'} Text`}
                                             className={canReceiveSms ? 'text-info' : 'text-muted'}/>
                        </Stack>
                    );
                },
            }),
            makeColumnDef.accessor('isBlocked', {
                header: 'Blocked',
                size: 40,
                minSize: 80,
                cell: ({getValue}) => {
                    return getValue() ?
                        <FontAwesomeIcon icon={faBan} className="text-danger"/> : <span>{constants.unknownValue}</span>;
                },
            }),

            makeColumnDef.accessor('flagActivity', {
                header: 'Flagged',
                size: 40,
                minSize: 80,
                cell: ({getValue}) => {
                    return getValue() > 0 ? <FontAwesomeIcon icon={faFlag} className="text-danger"/> :
                        constants.unknownValue;
                },
            }),

            makeColumnDef.accessor('points', {
                header: 'Points',
                size: 30,
                minSize: 80,
                meta: {
                    mobileCell: info => toNumberString(info.getValue()),
                },
                cell: ({getValue}) => <div className="text-end">{toNumberString(getValue())}</div>,
            }),

            makeColumnDef.accessor('lifetimePoints', {
                header: 'Lifetime Points',
                size: 58,
                // maxSize: 56,
                minSize: 80,
                meta: {
                    mobileCell: (cellContext) => toNumberString(cellContext.getValue()),
                },
                cell: ({getValue}) => <div className="text-end">{toNumberString(getValue())}</div>,
            }),

            makeColumnDef.accessor('redemptionsInLast7Days', {
                header: () => {
                    return <div className="text-nowrap">
                        Rewards
                        <HelpTip text="Redeemed in last 7 days"/>
                    </div>;
                },
                size: 45,
                // maxSize: 40,
                minSize: 90,
                enableSorting: false,
                meta: {
                    mobileCell: cellContext => toNumberString(cellContext.getValue()),
                },
                cell: ({getValue}) => <div className="text-end">{toNumberString(getValue())}</div>,
            }),

            makeColumnDef.accessor('humanReadableLevel', {
                header: 'Level',
                size: 45,
                minSize: 80,
                cell: cellContext => <div>{toNumberString(cellContext.getValue())}</div>,
            }),

            makeColumnDef.accessor('lastVisitedAt', {
                header: 'Last Check-in',
                size: 90,
                minSize: 80,
                cell: cellContext => <div>{toShortDateTimeString(cellContext.getValue())}</div>,
            }),
        ] as ColumnDef<ShopperListItem>[];
    }, [loaderData]);

    const fetchData = async (
        {pageSize, pageIndex, sortBy, columnFilters}: PaginatedTableFetchDataArgs,
        updatedSearchTerm?: string,
    ) => {
        const filters = keyBy(columnFilters, 'id') as unknown as { locationName: { value: SelectOptions } };
        const locations = filters?.locationName?.value?.map((selectOption: SelectOption) => selectOption.value);
        setTableUrlParams({
            pageIndex,
            pageSize,
            sortBy,
            search: updatedSearchTerm ?? customerListSearchTerm ?? '',
            defaultSortField: 'lastVisitedAt',
        }, {
            locations,
        });
        setTableSettings({pageSize, pageIndex, sortBy});
    };

    async function onSubmitSearch(newSearchTerm: string) {
        tableSettings.pageIndex = 0;
        await fetchData(tableSettings, newSearchTerm);
    }

    return (
        <div>
            <CustomerListActionMenu onSearchTermSubmitted={onSubmitSearch}
                                    onRefreshButtonClicked={onSubmitSearch}
            />
            <FadeIn>
                <Container fluid>
                    <PaginatedTable
                        columns={columns}
                        data={loaderData.shoppers}
                        fetchData={fetchData}
                        pageCount={loaderData.totalShoppers / tableSettings.pageSize}
                        totalRows={loaderData.totalShoppers}
                        type={'customer'}
                        exportData={() => {
                            convertToCSV(`customersExport-${toShortDateString(new Date().toISOString())}.csv`, JSON.stringify(loaderData.shoppers));
                        }}
                    >
                        {(selectedBusinessOption.maximumShopperAccounts > 0
                            && loaderData.totalShoppers > (selectedBusinessOption.maximumShopperAccounts * .80)
                            && loaderData.totalShoppers <= selectedBusinessOption.maximumShopperAccounts
                        ) && (
                            <CardHeader className="bg-primary p-2 text-center">
                                <UpgradeCallout
                                    canUpgradeTo={true}
                                    text={`your plan to add more customers - you're getting close to your plan limit of ${selectedBusinessOption.maximumShopperAccounts}.`}
                                />
                            </CardHeader>
                        )}

                        {(selectedBusinessOption.maximumShopperAccounts > 0 && loaderData.totalShoppers > selectedBusinessOption.maximumShopperAccounts) && (
                            <CardHeader className="bg-primary p-2 text-center">
                                <UpgradeCallout
                                    canUpgradeTo={true}
                                    text={`your plan to add more customers - you've exceeded your plan limit of ${selectedBusinessOption.maximumShopperAccounts}.`}
                                />
                            </CardHeader>
                        )}
                    </PaginatedTable>
                </Container>
            </FadeIn>
        </div>
    );
}
