import React, {useCallback, useEffect, useState} from 'react';
import {Button, Container, Nav, Navbar, NavDropdown, Stack} from 'react-bootstrap';
import Select, {SingleValue} from 'react-select';
import {useAppContext} from '@/AppContext';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faCopy,
    faEnvelope,
    faLifeRing,
    faLightbulb,
    faPhone,
    faQuestion,
    faSignOut,
    faUser,
} from '@fortawesome/free-solid-svg-icons';
import {SelectOption} from '@shared/responseModels';
import appLogoIcon from '@images/app_logo_icon.svg';
import {MenuItem as SidebarMenuItem} from '@/services/menu';
import {NavLink} from 'react-router-dom';
import classNames from 'classnames';
import {NotificationCenter} from '@/components/support';
import copy from 'copy-to-clipboard';
import {useNotify} from '@/services';
import {UpgradePlanModal} from '@/components/product';
import {TimeZonePicker} from '@/components/user';

function MainNav() {
    const {
        customerSupport,
        currentUser,
        businessOptions,
        selectedBusinessOption,
        setSelectedBusinessOption,
        sidebarMenuItems,
    } = useAppContext();
    const notify = useNotify();
    const [menuItems, setMenuItems] = useState<SidebarMenuItem[]>([]);

    useEffect(() => {
        const pathParts = location.pathname.split('/').filter((x) => x !== '');
        const updatedMenuItems = sidebarMenuItems.map((menuItem: SidebarMenuItem) => {
            const isRootMatch = pathParts.length >= 1 && pathParts[0] === menuItem.to;
            const isExactMatch = menuItem.to === location.pathname;
            menuItem.active = isRootMatch || isExactMatch;
            return menuItem as SidebarMenuItem;
        });
        setMenuItems(updatedMenuItems);
    }, [sidebarMenuItems, location.pathname]);

    const onBusinessChanged = useCallback((option: SingleValue<SelectOption>) => {
        setSelectedBusinessOption(option as SelectOption);
    }, []);

    return (
        <>
            <Navbar bg="secondary"
                    variant="dark"
                    expand="lg"
                    className="shadow d-lg-none"
                    style={{zIndex: 102}}>
                <Container fluid>
                    <Navbar.Brand>
                        <img src={appLogoIcon}
                             alt="Loyalti"
                             style={{height: '30px', marginLeft: '12px'}}/>
                        <span className="ms-2 text-white">Loyalti</span>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            {menuItems.map((x: SidebarMenuItem, idx: number) => (
                                <NavLink to={x.to}
                                         className={classNames('nav-link')}
                                         key={idx}>
                                    <FontAwesomeIcon icon={x.icon} fixedWidth className="me-1"/>
                                    <span>{x.name}</span>
                                </NavLink>
                            ))}
                        </Nav>

                        {
                            businessOptions.length > 1 &&
                            <div>
                                <hr className="border-bottom"/>
                                <Nav className="me-auto">
                                    <div className="h5 text-white text-muted">
                                        Business
                                    </div>
                                    {
                                        selectedBusinessOption && businessOptions.length > 1 ?
                                            <Select options={businessOptions}
                                                    key={`business_select__${selectedBusinessOption.value}`}
                                                    onChange={onBusinessChanged}
                                                    value={selectedBusinessOption || ''}
                                                    styles={{
                                                    control: (css) => ({
                                                        ...css,
                                                    }),
                                                    menu: (css) => ({
                                                        ...css,
                                                        zIndex: 9999,
                                                        width: 'max-content',
                                                        minWidth: '100%',
                                                    }),
                                                    menuPortal: (css) => ({
                                                        ...css,
                                                        zIndex: 9999,
                                                    }),
                                                }}/> :
                                            null
                                    }
                                </Nav>
                            </div>
                        }

                        <TimeZonePicker/>

                        <hr className="border-bottom"/>

                        <div className="h5 text-white text-muted">
                            Contact Us
                        </div>
                        <Nav className="me-auto">
                            {
                                selectedBusinessOption.usesSupportKnowledgeBase && (
                                    <Nav.Link href={customerSupport.knowledgeBaseUrl} target="_blank">
                                        <FontAwesomeIcon icon={faLightbulb} fixedWidth/>
                                        <span className="ms-1">Knowledge Base</span>
                                    </Nav.Link>
                                )
                            }

                            {
                                selectedBusinessOption.usesSupportEmailUs && (
                                    <Nav.Link href={`mailto:${customerSupport.emailAddress}`} target="_blank">
                                        <FontAwesomeIcon icon={faEnvelope} fixedWidth/>
                                        <span className="ms-1">{customerSupport.emailAddress}</span>
                                    </Nav.Link>
                                )
                            }

                            {
                                selectedBusinessOption.usesSupportCallUs && (
                                    <Nav.Link href={`tel:${customerSupport.phoneNumber}`} target="_blank">
                                        <FontAwesomeIcon icon={faPhone} fixedWidth/>
                                        <span className="ms-1">{customerSupport.phoneNumber}</span>
                                    </Nav.Link>
                                )
                            }

                            {
                                selectedBusinessOption.usesSupportCustomerPortal && (
                                    <Nav.Link href={customerSupport.customerPortalUrl} target="_blank">
                                        <FontAwesomeIcon icon={faLifeRing} fixedWidth/>
                                        <span className="ms-1">Customer Portal</span>
                                    </Nav.Link>
                                )
                            }
                        </Nav>

                        <hr className="border-bottom"/>
                        <Nav className="me-auto">
                            <Nav.Link href="/logout"
                                      className={classNames('nav-link')}>
                                <FontAwesomeIcon icon={faSignOut} fixedWidth className="me-1"/>
                                <span>Logout</span>
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Navbar bg="white"
                    className="d-lg-flex d-none border-bottom"
                    style={{zIndex: 102}}
            >
                <Container fluid>
                    {businessOptions.length <= 1 && (<Navbar.Brand>{selectedBusinessOption.label}</Navbar.Brand>)}
                    <Navbar.Toggle aria-controls="main-top-nav"/>
                    <Navbar.Collapse id="main-top-nav">
                        <Nav className="me-auto">
                            <Stack direction="horizontal" gap={1}>
                                {
                                    selectedBusinessOption && businessOptions.length > 1 ?
                                        <Select options={businessOptions}
                                                key={`business_select__${selectedBusinessOption.value}`}
                                                onChange={onBusinessChanged}
                                                value={selectedBusinessOption || ''}
                                                styles={{
                                                control: (css) => ({
                                                    ...css,
                                                    minWidth: 400,
                                                }),
                                                menu: (css) => ({
                                                    ...css,
                                                    zIndex: 9999,
                                                    width: 'max-content',
                                                    minWidth: '100%',
                                                }),
                                                menuPortal: (css) => ({
                                                    ...css,
                                                    zIndex: 9999,
                                                }),
                                            }}/> :
                                        null
                                }

                                <TimeZonePicker isMobile={true}/>

                            </Stack>
                        </Nav>
                        <Nav className="me-md-2">
                            <div className="me-md-2">
                                <UpgradePlanModal/>
                            </div>

                            <NotificationCenter/>
                            <NavDropdown title={
                                <div className="d-inline-block">
                                    <span className="bg-secondary rounded-circle p-2">
                                        <FontAwesomeIcon icon={faQuestion}
                                                         fixedWidth
                                                         className="text-white"/>
                                    </span>
                                    <span className="ms-1">Contact Us</span>
                                </div>
                            } className="basic-nav-dropdown" align="end">

                                {
                                    selectedBusinessOption.usesSupportKnowledgeBase && (
                                        <NavDropdown.Item href={customerSupport.knowledgeBaseUrl} target="_blank">
                                            <FontAwesomeIcon icon={faLightbulb} fixedWidth/>
                                            <span className="ms-1">Knowledge Base</span>
                                        </NavDropdown.Item>
                                    )
                                }

                                {
                                    selectedBusinessOption.usesSupportEmailUs && (
                                        <NavDropdown.Item href={`mailto:${customerSupport.emailAddress}`} target="_blank">
                                            <FontAwesomeIcon icon={faEnvelope} fixedWidth/>

                                            <span className="ms-1 me-2">{customerSupport.emailAddress}</span>

                                            <Button size="sm" variant="outline-dark"
                                                    onClick={(event) => {
                                                    event.preventDefault();
                                                    copy(customerSupport.emailAddress);
                                                    notify.success('Copied support email address to clipboard.');
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faCopy} fixedWidth size="sm"/>
                                            </Button>
                                        </NavDropdown.Item>
                                    )
                                }

                                {
                                    selectedBusinessOption.usesSupportCallUs && (
                                        <NavDropdown.Item href={`tel:${customerSupport.phoneNumber}`} target="_blank">
                                            <FontAwesomeIcon icon={faPhone} fixedWidth/>
                                            <span className="ms-1">{customerSupport.phoneNumber}</span>
                                        </NavDropdown.Item>
                                    )
                                }

                                {
                                    selectedBusinessOption.usesSupportCustomerPortal && (
                                        <NavDropdown.Item href={customerSupport.customerPortalUrl} target="_blank">
                                            <FontAwesomeIcon icon={faLifeRing} fixedWidth/>
                                            <span className="ms-1">Customer Portal</span>
                                        </NavDropdown.Item>
                                    )
                                }

                            </NavDropdown>
                        </Nav>
                        <Nav className="me-md-2">
                            <NavDropdown title={
                                <span>
                                    <span className="bg-secondary rounded-circle p-2">
                                        <FontAwesomeIcon icon={faUser}
                                                         fixedWidth
                                                         className="text-white"/>
                                    </span>
                                    <span className="ms-1 t-merchant-greeting">Hello, {currentUser.firstName}</span>
                                </span>
                            } className="basic-nav-dropdown"
                                         align="end">
                                <NavDropdown.Item href="/logout">
                                    <FontAwesomeIcon icon={faSignOut} fixedWidth/>
                                    <span className="ms-1">Logout</span>
                                </NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
}

export default MainNav;
