import React from 'react';
import {Card} from 'react-bootstrap';
import {FadeIn} from '@/components/animation';

function NotFound() {
    return (
        <FadeIn>
            <div className="m-5">
                <div className="container-sm">
                    <Card>
                        <Card.Body>
                            <div className="text-center p-5 text-muted">
                                <div className="pb-4">
                                    <i className="fa fa-fw fa-warning fa-3x"/>
                                </div>
                                <h3 className="pb-3">
                                    <div>Oops... looks like this page does not exist.</div>
                                </h3>
                                <hr/>
                                <h6>(404: not found)</h6>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </FadeIn>
    );
}

export default NotFound;
