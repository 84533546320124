import React, {PropsWithChildren, useRef} from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {faCircleQuestion} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

interface Props {
    text: string | undefined;
}

export function HelpTip({text, children}: PropsWithChildren<Props>) {
    const target = useRef(null);

    return (
        <OverlayTrigger
            placement="top"
            overlay={
                <Tooltip>
                    {text}
                </Tooltip>
            }>
            <div ref={target} className="d-inline-block">
                {!children && <FontAwesomeIcon icon={faCircleQuestion} className="ms-1"/>}
                {!!children && children}
            </div>
        </OverlayTrigger>
    );
}
