import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import {FiDownload} from 'react-icons/fi';
import QRCodeStyling, {FileExtension, Options} from 'qr-code-styling';
import {useNotify} from '@/services';
import {Button, Stack} from 'react-bootstrap';

enum ImageFormat {
    Png = 'png',
    Svg = 'svg',
    Jpeg = 'jpeg',
    Webp = 'webp',
}

interface Props {
    content: string;
    fileDownloadName: string;
    enabled?: boolean;
}

export function QrCodePreview({content = '', fileDownloadName, enabled = true}: Props) {
    const notify = useNotify();
    const ref = useRef<HTMLDivElement>(null);
    const qrCodeOptions = useMemo((): Options => {
        // @ts-ignore
        return {
            type: 'svg',
            data: content,
        };
    }, [content]);

    const qrCode = useMemo(() => new QRCodeStyling(qrCodeOptions), []);

    useEffect(() => {
        if (ref?.current?.children?.length === 1) {
            ref?.current?.removeChild(ref.current.children[0]);
        }
        qrCode.append(ref.current!);
    }, []);

    useEffect(() => {
        qrCode.update(qrCodeOptions);
    }, [qrCodeOptions]);

    const onClickDownload = useCallback((extension: FileExtension) => {
        if (!content) {
            return;
        }
        qrCode.download({
            name: fileDownloadName,
            extension,
        }).then(() => {
            notify.success('Download successful.');
        });
    }, [fileDownloadName]);


    return (
        <div>
            <div className="m-3 mb-4 text-center">
                <div ref={ref}/>
            </div>

            <Stack direction="horizontal" gap={2} className="mb-1">
                <Button onClick={() => onClickDownload(ImageFormat.Png)}
                        className="w-100"
                        disabled={!content || !enabled}
                >
                    <FiDownload className="me-1"/>
                    PNG
                </Button>
                <Button onClick={() => onClickDownload(ImageFormat.Svg)}
                        className="w-100"
                        disabled={!content || !enabled}
                >
                    <FiDownload className="me-1"/>
                    SVG
                </Button>
            </Stack>

            <Stack direction="horizontal" gap={2}>
                <Button onClick={() => onClickDownload(ImageFormat.Jpeg)}
                        className="w-100"
                        disabled={!content || !enabled}
                >
                    <FiDownload className="me-1"/>
                    JPEG
                </Button>
                <Button onClick={() => onClickDownload(ImageFormat.Webp)}
                        className="w-100"
                        disabled={!content || !enabled}
                >
                    <FiDownload className="me-1"/>
                    WEBP
                </Button>
            </Stack>
        </div>
    );
}
