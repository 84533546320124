import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import 'cropperjs/dist/cropper.css';
import imageCompression from 'browser-image-compression';
import {useField} from 'formik';
import {Button, Image, Modal, Stack} from 'react-bootstrap';
import {IconButton} from '@/components/form/index';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCamera} from '@fortawesome/free-solid-svg-icons';
import {useDropzone} from 'react-dropzone';
import {themeColors} from '@/services/theme';
import {Cropper} from 'react-cropper';
import {FadeIn} from '@/components/animation';
import classNames from 'classnames';
import {url, useNotify} from '@/services';
import {logger} from 'sparkloyalty-frontend/logging';

const baseStyle = {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    padding: 20,
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: themeColors.dark,
    outline: 'none',
    transition: 'border .24s ease-in-out',
    height: 400,
};

const focusedStyle = {
    borderColor: themeColors.info,
};

const acceptStyle = {
    borderColor: themeColors.primary,
};

const rejectStyle = {
    borderColor: themeColors.danger,
};

interface Props {
    name: string;
    aspectRatio?: number;
    initialAspectRatio?: number;
    imageUrl: string;
    className?: string;
    size?: 'sm' | 'lg';
    layout?: 'form' | 'table';
    disabled?: boolean;
    maxCompressedFileSizeInMegabytes?: number;
}


function FormikImage({
    name,
    aspectRatio = NaN,
    initialAspectRatio = 1,
    imageUrl,
    className,
    size = 'lg',
    layout = 'form',
    disabled = false,
    maxCompressedFileSizeInMegabytes = 2,
}: Props) {
    const notify = useNotify();
    const mimeType = 'image/png';
    const cropperRef = useRef<HTMLImageElement>(null);
    const [, meta, helpers] = useField(name);
    const {value} = meta;
    const {setValue} = helpers;

    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [isApplying, setIsApplying] = useState<boolean>(false);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const defaultValue = useMemo(() => value, []);
    const [imageFile, setImageFile] = useState(defaultValue);

    const onEnableEditing = useCallback(() => {
        if (disabled) {
            return;
        }
        setIsEditing(true);
    }, [disabled]);

    const onCancel = useCallback(() => {
        setImageFile(defaultValue);
        setIsEditing(false);
        setErrorMessage(null);
    }, []);

    const dataUrlToFile = useCallback((dataUrl: string, filename: string): File | undefined => {
        const arr = dataUrl.split(',');
        const blobString = window.atob(arr[1]);
        let arrayLength = blobString.length;
        const u8arr = new Uint8Array(arrayLength);

        while (arrayLength--) {
            u8arr[arrayLength] = blobString.charCodeAt(arrayLength);
        }

        return new File([u8arr], filename, {type: mimeType});
    }, []);

    const onApply = useCallback(async () => {
        try {
            setIsApplying(true);
            // Use cropped image to set field value as a new file object.
            const imageElement: any = cropperRef?.current;
            const cropper: any = imageElement?.cropper;
            const croppedImageDataUrl = cropper.getCroppedCanvas().toDataURL(mimeType);
            const file = dataUrlToFile(croppedImageDataUrl, imageFile.filename);

            setValue(file);

            // Reset component state.
            setImageFile(defaultValue);
            setIsEditing(false);
            setIsApplying(false);
        } catch (error) {
            setIsApplying(false);
            notify.errorMessage('There was an issue applying the image. Please try again, or use a different image.');
            logger.error(error);
        }
    }, [imageFile]);

    useEffect(() => {
        if (value === undefined) {
            onCancel();
        }
    }, [value]);

    const layoutStyle = useMemo(() => {
        return layout === 'table' ?
            {height: '27.85px'} :
            {maxHeight: '300px'};
    }, [layout]);

    /* ------------------------------------------------------------------------
     * Setup Dropzone
     * ------------------------------------------------------------------------
     */
    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg'],
            'image/jpg': ['.jpg'],
        },
        onDrop: async (acceptedFiles) => {
            const file = acceptedFiles[0];
            const maxSizeInBytes = 10000000;
            if (file.size > maxSizeInBytes) {
                setErrorMessage(`The image is too big. Please try another.`);
                return;
            }

            const compressedFile = await imageCompression(file, {
                maxSizeMB: maxCompressedFileSizeInMegabytes,
            });

            setImageFile(compressedFile);
        },
    });

    const dropzoneStyle = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
    }), [
        isFocused,
        isDragAccept,
        isDragReject,
    ]);

    return (
        <div className="mx-3">
            <Modal show={isEditing} onHide={onCancel} animation={false}>
                <Modal.Body>
                    <div className="">
                        {isEditing && !!imageFile &&
                            <div className="mb-3">
                                <Cropper
                                    src={url.imageFileToUrl(imageFile)}
                                    style={{height: 400, width: '100%'}}
                                    // Cropper.js options
                                    aspectRatio={aspectRatio}
                                    initialAspectRatio={initialAspectRatio}
                                    guides={false}
                                    ref={cropperRef}
                                />
                            </div>
                        }

                        {isEditing && !imageFile &&
                            <div className="mb-3">
                                <div className="clickable">
                                    <div {...getRootProps({style: dropzoneStyle})}>
                                        <input {...getInputProps()} />
                                        <FadeIn className="text-center p-3 w-100">
                                            <div>
                                                <div className="h5">
                                                    Drag and drop a file here, or click to select a file.
                                                </div>

                                                <div className="mt-3">
                                                    Max image size is 10 MB.
                                                </div>

                                                <div className="h5 text-danger mt-3">
                                                    {errorMessage}
                                                </div>
                                            </div>
                                        </FadeIn>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Stack direction="horizontal" gap={2}>
                        <Button variant="outline-secondary"
                                onClick={onCancel}
                                disabled={isApplying}>
                            Cancel
                        </Button>
                        <Button variant="primary"
                                onClick={onApply}
                                disabled={!imageFile || isApplying}>
                            Apply
                        </Button>
                    </Stack>
                </Modal.Footer>
            </Modal>

            <div style={layoutStyle}
                 className={classNames('d-flex justify-content-center align-items-center', className)}
            >
                {
                    // Non-persisted Image Preview
                    !isEditing && value &&
                    <Image src={url.imageFileToUrl(value)}
                           style={layoutStyle}
                           className={classNames('clickable non-persisted',
                               className,
                               disabled ? 'opacity-50' : '',
                           )}
                           fluid
                           onClick={onEnableEditing}/>
                }

                {
                    // Persisted Image Preview
                    !value && imageUrl &&
                    <Image src={imageUrl}
                           style={layoutStyle}
                           className={classNames('clickable persisted',
                               className,
                               disabled ? 'opacity-50' : '',
                           )}
                           fluid
                           onClick={onEnableEditing}/>
                }

                {
                    // No Image Persisted - Camera Icon
                    !value && !imageUrl &&
                    <IconButton icon={<FontAwesomeIcon icon={faCamera} fixedWidth/>}
                                variant="outline-primary"
                                size={size}
                                onClick={onEnableEditing}/>
                }
            </div>
        </div>
    );
}

export default FormikImage;
