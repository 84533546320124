import React, {useEffect, useMemo, useState} from 'react';
import {Dropdown, Stack} from 'react-bootstrap';
import {useAppContext} from '@/AppContext';
import {customDateFilterRangeFactory, dateFilterRangesFactory} from '@/services/dateRanges';
import {DatePickerCalendar} from './DatePickerCalendar';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCalendar} from '@fortawesome/free-solid-svg-icons';

interface Props {
    getFilterValue: (range: number) => void;
    setFromDate?: (date: Date) => void;
    setToDate?: (date: Date) => void;
    isProcessing: boolean;
}

export function DateFilter({getFilterValue, setFromDate, setToDate, isProcessing}: Props) {
    const {fields} = useAppContext();
    const staticDateFilterRanges = useMemo(() => dateFilterRangesFactory(fields), []);
    const customDateFilterRange = useMemo(() => customDateFilterRangeFactory(fields), []);

    const [dateFilterRange, setDateFilterRange] = useState(staticDateFilterRanges[1]);
    const [fromDateFilter, setFromDateFilter] = useState(new Date());
    const [toDateFilter, setToDateFilter] = useState(new Date());

    useEffect(() => {
        getFilterValue(dateFilterRange.id);

        if (dateFilterRange.id === fields.dateRange.map.custom) {
            if (setFromDate) {
                setFromDate(fromDateFilter);
            }
            if (setToDate) {
                setToDate(toDateFilter);
            }
        }
    }, [dateFilterRange, fromDateFilter, toDateFilter]);

    return (
        <Dropdown className="ms-auto">
            <Stack direction="horizontal" gap={2}>
                <Dropdown.Toggle className="text-white" disabled={isProcessing}>
                    <FontAwesomeIcon icon={faCalendar} fixedWidth className="pe-1"/>
                    {dateFilterRange.label}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {staticDateFilterRanges.map((range, i) => (
                        <Dropdown.Item key={`date-range-${i}`}
                                       active={dateFilterRange.id === range.id}
                                       onClick={(event) => {
                                           event.preventDefault();
                                           setDateFilterRange(range);
                                       }}
                        >
                            {range.label}
                        </Dropdown.Item>
                    ))}
                    <Dropdown.Divider/>
                    <Dropdown.Item active={dateFilterRange.id === fields.dateRange.map.custom}
                                   onClick={(event) => {
                                       event.preventDefault();
                                       setDateFilterRange(customDateFilterRange);
                                   }}>
                        {customDateFilterRange.label}
                    </Dropdown.Item>
                </Dropdown.Menu>
                {
                    dateFilterRange.id === fields.dateRange.map.custom ?
                        <Stack direction="horizontal" gap={2}>
                            <DatePickerCalendar date={fromDateFilter}
                                                onDateChange={(value: Date) => {
                                                    setFromDateFilter(value);
                                                }}/>
                            <span className="ms-auto">to</span>
                            <DatePickerCalendar date={toDateFilter}
                                                onDateChange={(value: Date) => {
                                                    setToDateFilter(value);
                                                }}/>
                        </Stack> : null
                }
            </Stack>
        </Dropdown>
    );
}
