import React, {PropsWithChildren} from 'react';
import {Card} from 'react-bootstrap';
import {AiFillInfoCircle} from 'react-icons/all';

interface Props {
}

export function InfoCard({children}: PropsWithChildren<Props>) {
    return (
        <Card className="text-center my-5 py-5 fs-5 bg-white w-75 mx-auto">
            <div className="text-info text-center p-4">
                <AiFillInfoCircle style={{fontSize: 90}} className="mx-auto"/>
            </div>
            <div className="mt-1 mb-5 fs-4 p-3">
                {children}
            </div>
        </Card>
    );
}
