import React from 'react';
import {Button, ButtonGroup} from 'react-bootstrap';
import {useField} from 'formik';

interface Props {
    name: string,
    disabled: boolean,
}

export const FormikYesNoButtons = ({
    name,
    disabled,
}: Props) => {
    const [field, meta, helpers] = useField(name);
    const {value} = meta;
    const {setValue} = helpers;

    return (
        <>
            <div className="d-block">
                <ButtonGroup aria-label="Yes/No Buttons"
                >
                    <Button variant="outline-secondary"
                            size="sm"
                            active={value === false} onClick={() => setValue(false)}
                    >
                        No
                    </Button>
                    <Button variant="outline-secondary"
                            size="sm"
                            active={value === true}
                            onClick={() => setValue(true)}
                    >
                        Yes
                    </Button>
                </ButtonGroup>
            </div>
            {/*This is a hack to get the react-bootstrap error message to appear*/}
            <input type="hidden"
                   className={meta.error ? 'is-invalid' : ''}
                   id={name}
                   name={name}
                   value={value}
            />
        </>
    );
};
