import * as yup from 'yup';
import {hexColorRule} from '../matchRules';

export const BrandSettingsSchema = yup.object().shape({
    brandColor1: yup.string()
        .required('Primary color is required.')
        .matches(...hexColorRule),

    brandColor2: yup.string()
        .required('Secondary color is required.')
        .matches(...hexColorRule),

    brandColor3: yup.string()
        .required('Tertiary color is required.')
        .matches(...hexColorRule),

    logoUrl: yup.string()
        .nullable()
        .url('The logo URL must be a valid URL.'),

    punchCardImageUrl: yup.string()
        .nullable()
        .url('The punch chard URL must be a valid URL.'),
});
