import * as yup from 'yup';
import {InferType} from 'yup';
import {validateZipCode} from '../regularExpressions';
import {SelectOptions} from '../responseModels';
import {AddressFormFields} from '../AddressFormFields';

export const LocationSchema = yup.object().shape({
    name: yup.string()
        .max(255, 'Location name should be 255 characters or fewer.'),

    addressLine1: yup.string()
        .max(255, 'Address Line 1 should be 255 characters or fewer.')
        .required('Address Line 1 is required.'),
    addressLine2: yup.string()
        .max(255, 'Address Line 2 should be 255 characters or fewer.'),
    city: yup.string()
        .max(255, 'City should be 255 characters or fewer.')
        .required('City is required.'),
    state: yup.string()
        .when('country', {
            is: 'USA',
            then: (schema) => schema
                .max(255, 'State should be 255 characters or fewer.')
                .required('State is required.'),
        }),
    province: yup.string()
        .max(255, 'Province/Region name should be 255 characters or fewer.'),
    country: yup.string()
        .max(255, 'Country should be 255 characters or fewer.')
        .required('Country is required.'),
    zipCode: yup.string()
        .required('Zip is required.')
        .when('country', {
            is: 'USA',
            then: (schema) => schema
                .matches(
                    validateZipCode,
                    'Must be a valid zip code, e.g. 12345 or 12345-6789.',
                ),
        }),
    amenityTags: yup.array().of(
        yup.number(),
    ),
});

interface SchemaFormValues extends InferType<typeof LocationSchema>{
    availableAmenityTags: SelectOptions,
}

export type LocationFormValues = SchemaFormValues & AddressFormFields;
