import React, {useEffect, useRef, useState} from 'react';
import {useFormikContext} from 'formik';
import {useNotify} from '@/services';
import {Alert} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faWarning} from '@fortawesome/free-solid-svg-icons';
import {intersection} from 'lodash';
import classNames from 'classnames';

export function FormErrorNotification() {
    const ref = useRef(null);
    const {touched, isValid, isValidating, isSubmitting, errors} = useFormikContext<Record<string, unknown>>();
    const notify = useNotify();
    const [errorCount, setErrorCount] = useState(0);
    const [hasSubmitted, setHasSubmitted] = useState(false);

    useEffect(() => {
        const touchedFields = Object.keys(touched);
        const errorFields = Object.keys(errors);
        const errorCount = intersection(touchedFields, errorFields).length;
        setErrorCount(errorCount);
    }, [touched, errors]);


    useEffect(() => {
        if (!isValid && !isValidating && isSubmitting) {
            setHasSubmitted(true);
            notify.errorMessage(`Could not save.`);
            // @ts-ignore
            ref?.current?.scrollIntoView();
        }
    }, [isSubmitting, isValid, isValidating]);

    return (
        <div ref={ref}
             style={{
                 scrollMarginTop: 10,
             }}
             className={classNames(
                 'm-3',
                 errorCount === 0 || !hasSubmitted ? 'visually-hidden' : '',
             )}
        >
            <Alert variant="danger">
                <FontAwesomeIcon icon={faWarning} fixedWidth className="me-1"/>
                There are <strong>{errorCount}</strong> validation error(s). Please fix them.
            </Alert>
        </div>
    );
}
