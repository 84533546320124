import React from 'react';
import {Button, FormControl, Stack} from 'react-bootstrap';
import copy from 'copy-to-clipboard';
import {useNotify} from '@/services';
import {QrCodePreview} from '@/components/marketing';
import {SimpleModal} from '@/components/container';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faClipboard, faExternalLinkAlt, faQrcode} from '@fortawesome/free-solid-svg-icons';
import {HelpTip} from '@/components/form';

interface Props {
    url: string;
    fileDownloadName: string;
    enabled: boolean;
}

export function CopyableUrl({url, fileDownloadName, enabled = true}: Props) {
    const notify = useNotify();
    return (
        <Stack direction="horizontal" gap={2}>
            <FormControl className="me-auto d-none"
                         defaultValue={url}
                         disabled={true}
            />
            <div>
                <HelpTip text="Copy the sign-up form URL to your clipboard">
                    <Button variant="outline-primary"
                            disabled={!enabled}
                            className="btn-circle"
                            onClick={() => {
                            copy(url);
                            notify.success('Copied to clipboard.');
                        }}
                    >
                        <FontAwesomeIcon icon={faClipboard}/>
                    </Button>
                </HelpTip>
            </div>
            <div>
                <HelpTip text="Share the sign-up form with a QR code">
                    <SimpleModal buttonIcon={<FontAwesomeIcon icon={faQrcode}/>}
                                 styleButtonAsLink={false}
                                 modalTitle="Sign-up Form QR Code"
                                 modalBodyHeight="100%"
                    >
                        <QrCodePreview content={url}
                                       fileDownloadName={fileDownloadName}
                                       enabled={enabled}
                        />
                    </SimpleModal>
                </HelpTip>
            </div>
            <div>
                <HelpTip text="Open the sign-up form in a new tab">
                    <Button variant="outline-primary"
                            href={url}
                            className="btn-circle"
                            target="_blank"
                            disabled={!enabled}
                    >
                        <FontAwesomeIcon icon={faExternalLinkAlt}/>
                    </Button>
                </HelpTip>
            </div>
        </Stack>
    );
}
