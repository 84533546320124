import {useAppContext} from '@/AppContext';

export function HubSpotSupportChat() {
    const {customerSupport} = useAppContext();

    if (!customerSupport.isHubSpotSupportChatEnabled) {
        return null;
    }

    const elementId = 'hs-script-loader';
    const element = document.getElementById(elementId)

    if (!element) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.id = elementId;
        script.src = "//js-na1.hs-scripts.com/9484043.js";
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
    }

    return null;
}
