import React from 'react';
import classNames from 'classnames';

interface Props {
    className?: string;
}

function HorizontalDivider({className = ''}: Props) {
    return (<hr className={classNames('p-0 m-0', className)}/>);
}

export default HorizontalDivider;
