import {
    faBullhorn,
    faChartSimple,
    faGear,
    faPeopleGroup,
    faStar,
    faStore,
    IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import {BusinessSelectOption} from '@shared/responseModels';

export interface MenuItem {
    key: number;
    name: string;
    to: string;
    icon: IconDefinition;
    active: boolean;
}

function makeMenuItem(key: number, name: string, to: string, icon: IconDefinition): MenuItem {
    return {key, name, to, icon, active: false};
}

const dashboard = makeMenuItem(1, 'Dashboard', '/', faChartSimple);
const customers = makeMenuItem(2, 'Customers', 'customers', faPeopleGroup);
const campaigns = makeMenuItem(3, 'Campaigns', 'campaigns', faBullhorn);
const rewards = makeMenuItem(4, 'Rewards', 'rewards', faStar);
const settings = makeMenuItem(5, 'Settings', 'settings', faGear);
const locations = makeMenuItem(6, 'Locations', 'locations', faStore);

export function getSidebarMenuItems(business: BusinessSelectOption): MenuItem[] {
    const menuItems = [];
    if (business.canViewAnalytics) {
        menuItems.push(dashboard);
    }
    if (business.canViewCustomers) {
        menuItems.push(customers);
    }
    if (business.canViewCampaigns) {
        menuItems.push(campaigns);
    }
    if (business.canViewSettings) {
        menuItems.push(rewards);
        menuItems.push(settings);
        menuItems.push(locations);
    }

    return menuItems;
}
