import React, {useMemo} from 'react';
import {Badge, Stack} from 'react-bootstrap';
import {BsFillCheckCircleFill, BsFillDashCircleFill} from 'react-icons/all';
import {FormikErrors, FormikTouched} from 'formik';

interface Props<Values> {
    text: string;
    isEnabled: boolean;
    errors?: FormikErrors<Values>;
    touched?: FormikTouched<Values>;
    fields?: string[],
}

function TabTitle<Values>({
    text,
    isEnabled,
    errors,
    touched,
    fields,
}: Props<Values>
) {
    const count = useMemo(
        () => {
            // @ts-ignore
            return errors ? Object.keys(errors).filter((x: string) => fields.includes(x) && touched[x]).length : 0;
        },
        [errors, fields],
    );

    return (
        <div className="fs-4 px-2">
            <Stack direction="horizontal"
                   gap={1}
                   className={isEnabled ? '' : 'text-muted'}
            >
                {isEnabled ? <BsFillCheckCircleFill/> : <BsFillDashCircleFill/>}
                <div>{text}</div>
                {isEnabled && count > 0 ?
                    <Badge bg="danger">{count}</Badge>
                    : null
                }
            </Stack>
        </div>
    );
}

export default TabTitle;
