import React from 'react';
import {themeColors} from '@/services/theme';
import classNames from 'classnames';

interface Props {
    textColor: string;
    backgroundColor: string;
    text: string;
    size?: 'lg',
}

export function CustomerStatusBadge({textColor, backgroundColor, text, size}: Props) {
    return (
        <div className={classNames('badge', size === 'lg' ? 'fs-6 px-3 py-2' : '')}
             style={{backgroundColor: backgroundColor || themeColors.info, color: textColor || '#fff'}}
        >
            {text}
        </div>
    );
}
