import * as yup from 'yup';
import {InferType} from 'yup';

export const KioskSettingsSchema = yup.object().shape({
    headerLine1: yup.string().max(100, 'Header Line 1'),
    headerLine2: yup.string().max(100, 'Header Line 2'),
    headerLine3: yup.string().max(100, 'Header Line 3'),
    rewardYourselfMessage: yup.string().max(1000, 'Header Line 3'),
});

export interface KioskSettingsFormValues extends InferType<typeof KioskSettingsSchema> {
}
