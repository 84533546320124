import React, {FormEventHandler} from 'react';
import {Field, Form, FormikErrors, FormikTouched, FormikValues} from 'formik';
import {Card, Col, Container, FormControl, FormGroup, FormLabel, Row} from 'react-bootstrap';
import {FormikPhone, FormikSelect, HorizontalDivider, Input, SubmitOnConfirmButton} from '@/components/form';
import {useAppContext} from '@/AppContext';
import {CardHeaderTitle, PageFooter} from '@/components/container';
import {BusinessInfoFormValues} from '@shared/requestSchemas/BusinessInfoSchema';

interface Props<T> {
    handleSubmit: FormEventHandler<HTMLFormElement>;
    resetForm: FormEventHandler<HTMLFormElement>;
    isSubmitting: boolean;
    touched: FormikTouched<T>;
    values: FormikValues;
    errors: FormikErrors<T>;
}

const BusinessInfoForm = ({
    handleSubmit,
    values,
    touched,
    errors,
    isSubmitting,
    resetForm,
}: Props<BusinessInfoFormValues>) => {
    const {fields, selectOptions} = useAppContext();

    return (
        <Form onSubmit={handleSubmit} className="mb-5">
            <Container fluid>
                <Row>
                    <Col lg={{span: 5}}>
                        <Card className="shadow mb-3">
                            <Card.Header>
                                <CardHeaderTitle>Business</CardHeaderTitle>
                            </Card.Header>
                            <Card.Body className="pb-0">
                                <Row>
                                    <FormGroup controlId="businessName" className="mb-3" as={Col} sm={6}>
                                        <FormLabel>Business Name</FormLabel>
                                        <Field as={FormControl}
                                               name="businessName"
                                               isInvalid={!!errors.businessName && touched.businessName}/>
                                        <FormControl.Feedback type="invalid">
                                            {errors.businessName}
                                        </FormControl.Feedback>
                                    </FormGroup>

                                    <FormGroup controlId="category" className="mb-3" as={Col} sm={6}>
                                        <FormLabel>Category</FormLabel>
                                        <Field component={FormikSelect}
                                               name="category"
                                               isGrouped={true}
                                               options={selectOptions.businessCategory}
                                               isInvalid={!!errors.category && touched.category}/>
                                        <FormControl.Feedback type="invalid">
                                            {errors.category}
                                        </FormControl.Feedback>
                                    </FormGroup>

                                </Row>
                            </Card.Body>
                            <HorizontalDivider/>
                            <Card.Body className="pb-0">
                                <h5 className="mb-3 ps-3 text-muted">Contact Info</h5>
                                <Row>
                                    <FormGroup controlId="phone" className="mb-3" as={Col} sm={6}>
                                        <FormLabel>Phone</FormLabel>
                                        <Field as={FormikPhone}
                                               name="phone"
                                               isInvalid={!!errors.phone && touched.phone}
                                        />
                                        <FormControl.Feedback type="invalid">
                                            {errors.phone}
                                        </FormControl.Feedback>
                                    </FormGroup>
                                    <FormGroup controlId="email" className="mb-3" as={Col} sm={6}>
                                        <FormLabel>Email Address</FormLabel>
                                        <Field as={FormControl}
                                               name="email"
                                               isInvalid={!!errors.email && touched.email}/>
                                        <FormControl.Feedback type="invalid">
                                            {errors.email}
                                        </FormControl.Feedback>
                                    </FormGroup>
                                </Row>

                                <Row>
                                    <FormGroup controlId="website" className="mb-3" as={Col}>
                                        <FormLabel>Website</FormLabel>
                                        <Field as={FormControl}
                                               placeholder="https://..."
                                               name="website"
                                               isInvalid={!!errors.website && touched.website}/>
                                        <FormControl.Feedback type="invalid">
                                            {errors.website}
                                        </FormControl.Feedback>
                                    </FormGroup>
                                </Row>
                            </Card.Body>
                            <HorizontalDivider/>
                            <Card.Body className="pb-0">
                                <h5 className="mb-3 ps-3 text-muted">Address</h5>
                                <Row>
                                    <FormGroup controlId="address1" className="mb-3" as={Col}>
                                        <FormLabel>Address Line 1</FormLabel>
                                        <Field as={FormControl}
                                               name="address1"
                                               isInvalid={!!errors.address1 && touched.address1}/>
                                        <FormControl.Feedback type="invalid">
                                            {errors.address1}
                                        </FormControl.Feedback>
                                    </FormGroup>
                                </Row>
                                <Row>
                                    <FormGroup controlId="city" className="mb-3" as={Col} sm={4}>
                                        <FormLabel>City</FormLabel>
                                        <Field as={FormControl}
                                               name="city"
                                               isInvalid={!!errors.city && touched.city}/>
                                        <FormControl.Feedback type="invalid">
                                            {errors.city}
                                        </FormControl.Feedback>
                                    </FormGroup>

                                    {
                                        values.country === 'USA' ?
                                            <FormGroup controlId="state" className="mb-3" as={Col} sm={5}>
                                                <FormLabel>State</FormLabel>
                                                <Field component={FormikSelect}
                                                       name="state"
                                                       options={fields.usState.items}
                                                       isInvalid={!!errors.state && touched.state}/>
                                                <FormControl.Feedback type="invalid">
                                                    {errors.state}
                                                </FormControl.Feedback>
                                            </FormGroup> :
                                            <FormGroup controlId="province" className="mb-3" as={Col} sm={5}>
                                                <FormLabel>Province/Region</FormLabel>
                                                <Field as={FormControl}
                                                       name="province"
                                                       isInvalid={!!errors.province && touched.province}/>
                                                <FormControl.Feedback type="invalid">
                                                    {errors.province}
                                                </FormControl.Feedback>
                                            </FormGroup>
                                    }

                                    <FormGroup controlId="zip" className="mb-3" as={Col} xs={3}>
                                        <FormLabel>Zip</FormLabel>
                                        <Field as={FormControl}
                                               name="zip"
                                               isInvalid={!!errors.zip && touched.zip}/>
                                        <FormControl.Feedback type="invalid">
                                            {errors.zip}
                                        </FormControl.Feedback>
                                    </FormGroup>
                                </Row>

                                <Row>
                                    <FormGroup controlId="country" className="mb-3" as={Col} sm={4}>
                                        <FormLabel>Country</FormLabel>
                                        <Field component={FormikSelect}
                                               name="country"
                                               options={fields.country.items}
                                               isInvalid={!!errors.country && touched.country}/>
                                        <FormControl.Feedback type="invalid">
                                            {errors.country}
                                        </FormControl.Feedback>
                                    </FormGroup>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col lg={{span: 5}}>
                        <Card className="shadow mb-3">
                            <Card.Header>
                                <CardHeaderTitle>Social Media</CardHeaderTitle>
                            </Card.Header>
                            <Card.Body>
                                <Field as={Input}
                                       label="Instagram"
                                       name="instagramUrl"
                                       placeholder="https://instagram.com/..."
                                />
                                <Field as={Input}
                                       label="Facebook"
                                       name="facebookUrl"
                                       placeholder="https://facebook.com/..."
                                />
                                <Field as={Input}
                                       label="Twitter"
                                       name="twitterUrl"
                                       placeholder="https://twitter.com/..."
                                />
                                <Field as={Input}
                                       label="Yelp"
                                       name="yelpProfileUrl"
                                       placeholder="https://yelp.com/..."
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <PageFooter>
                <SubmitOnConfirmButton disabled={isSubmitting}
                                       resetForm={resetForm}
                                       isSubmitting={isSubmitting}/>
            </PageFooter>
        </Form>
    );
};

export default BusinessInfoForm;
