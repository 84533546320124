import {Button} from 'react-bootstrap';
import React, {PropsWithChildren} from 'react';
import classNames from 'classnames';
import {useAppContext} from '@/AppContext';

interface Props {
    className?: string;
    isMonthlyBilling: boolean;
    yearlyPrice: string;
    monthlyPrice: string;
    planName: string;
    isCurrentPlan: boolean;
    canUpgradeTo: boolean;
    subHeading: string;
    upgradeButtonClassName?: string;
}

export function BillingPlan({
    className,
    isMonthlyBilling,
    yearlyPrice,
    monthlyPrice,
    planName,
    isCurrentPlan,
    canUpgradeTo,
    subHeading,
    upgradeButtonClassName,
    children,
}: PropsWithChildren<Props>) {
    const {billing} = useAppContext();

    return (
        <div className={classNames('px-3 pb-4', className)}>
            <div className="text-center mx-3 mb-3 pt-3">
                <div className="h2">
                    {planName}
                </div>
                <div className="h1 text-center">
                    {isMonthlyBilling ? `$${monthlyPrice}/mo` : `$${yearlyPrice}/mo`}
                </div>
                <div className="text-center text-muted">
                    {isMonthlyBilling ? 'billed monthly' : 'billed yearly'}
                </div>
            </div>

            <div className="py-2 text-uppercase text-primary">
                {subHeading}
            </div>

            <div style={{minHeight: 240}}>
                {children}
            </div>

            <div className="pt-3">
                <Button
                    className={classNames('w-100', (!isCurrentPlan && !canUpgradeTo) ? 'invisible' : '', upgradeButtonClassName)}
                    href={billing.stripeCustomerPortalUrl}
                    disabled={!canUpgradeTo}
                    target="_blank"
                >
                    <span>
                        {isCurrentPlan ? 'Current Plan' : `Upgrade to ${planName}`}
                    </span>
                </Button>
            </div>
        </div>
    );
}
