import React from 'react';
import {Outlet} from 'react-router-dom';
import Aside from '@/components/layout/Aside';
import MainNav from '@/components/layout/MainNav';
import {ErrorContainer} from '@/components/container';
import {useNavigate} from 'react-router';
import {useNotify} from '@/services';
import {axiosInterceptorsSetup} from '@/services/api';

function Layout() {
    const navigate = useNavigate();
    const notify = useNotify();
    axiosInterceptorsSetup(navigate, notify);

    return (
        <div>
            <div className="d-flex">
                <Aside/>

                <div style={{
                    height: '100vh',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <MainNav/>
                    <div style={{
                        height: 'calc(100% - 57px)',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        zIndex: 101,
                        background: '#edecf0',
                    }}
                    >
                        <ErrorContainer>
                            <Outlet/>
                        </ErrorContainer>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Layout;
