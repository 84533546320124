import React, {CSSProperties, useEffect, useState} from 'react';
import {FadeIn} from '@/components/animation';
import classNames from 'classnames';
import {DateTime} from 'luxon';

interface Props {
    className?: string;
    showRefreshNotice: boolean;
    url: string;
    style?: CSSProperties;
}

export function SignUpFormPreview({className = '', showRefreshNotice, url, style = {}}: Props) {
    const [refreshToken, setRefreshToken] = useState(0);
    useEffect(() => {
        if (!showRefreshNotice) {
            setRefreshToken(DateTime.now().toUnixInteger());
        }
    }, [showRefreshNotice]);

    return (
        <>
            <div style={style}
                 className={classNames(
                    className,
                    'shadow p-0 m-0 flex-grow-1 border-0 w-100 h-100 bg-white position-relative top-0 left-0',
                )}
            >
                <iframe src={`${url}?mode=preview&t=${refreshToken}`}
                        className="p-0 m-0 flex-grow-1 border-0 w-100 h-100 bg-white position-relative top-0 left-0"
                />
                {showRefreshNotice &&
                    <div style={{
                        backgroundColor: 'rgba(0,0,0,0.75)',
                        zIndex: 2,
                        cursor: 'pointer',
                    }}
                         className="position-fixed w-100 h-100 top-0 left-0 right-0 bottom-0"
                    >
                        <FadeIn>
                            <div style={{
                                top: '25%',
                                left: '90%',
                                transform: 'translate(-70%,-50%)',
                            }}
                                 className="position-absolute text-white fs-2 w-100"
                            >
                                Save your settings to see an updated preview.
                            </div>
                        </FadeIn>

                    </div>
                }
            </div>

        </>
    );
}
