import React from 'react';
import {Col, Form, FormControl, FormGroup, FormLabel, Row} from 'react-bootstrap';
import {Field, FormikErrors, FormikTouched, FormikValues} from 'formik';
import FormikSelect from '@/components/form/FormikSelect';
import {useAppContext} from '@/AppContext';
import {AddressFormFields} from '@shared/AddressFormFields';

export interface Props<T> {
    errors: FormikErrors<T>
    touched: FormikTouched<T>,
    isSubmitting: boolean,
    values: FormikValues,
}

export function AddressFields({
    errors,
    touched,
    isSubmitting,
    values,
}: Props<AddressFormFields>) {
    const {fields} = useAppContext();

    return (<>
        <FormGroup controlId="addressLine1" className="mb-3">
            <Form.Label className="visually-hidden">Address Line 1</Form.Label>
            <Field as={FormControl}
                   name="addressLine1"
                   isInvalid={touched.addressLine1 && !!errors.addressLine1}
                   disabled={isSubmitting}
                   placeholder="Street address"
            />
            <FormControl.Feedback type="invalid">
                {errors.addressLine1 as string}
            </FormControl.Feedback>
        </FormGroup>
        <FormGroup controlId="addressLine2" className="mb-3">
            <Form.Label className="visually-hidden">Address Line 2</Form.Label>
            <Field as={FormControl}
                   name="addressLine2"
                   isInvalid={touched.addressLine2 && !!errors.addressLine2}
                   disabled={isSubmitting}
                   placeholder="Apt, suite, unit, building floor, etc."
            />
            <FormControl.Feedback type="invalid">
                {errors.addressLine2 as string}
            </FormControl.Feedback>
        </FormGroup>

        <Row>
            <FormGroup controlId="city" className="mb-3" as={Col} sm={6}>
                <FormLabel>City</FormLabel>
                <Field as={FormControl}
                       name="city"
                       isInvalid={touched.city && !!errors.city}/>
                <FormControl.Feedback type="invalid">
                    {errors.city as string}
                </FormControl.Feedback>
            </FormGroup>

            {
                values.country === 'USA' ?
                    <FormGroup controlId="state" className="mb-3" as={Col} sm={6}>
                        <FormLabel>State</FormLabel>
                        <Field component={FormikSelect}
                               name="state"
                               options={fields.usState.items}
                               isInvalid={touched.state && !!errors.state}
                               error={errors.state}
                        />
                        <FormControl.Feedback type="invalid">
                            {errors.state as string}
                        </FormControl.Feedback>
                    </FormGroup> :
                    <FormGroup controlId="province" className="mb-3" as={Col} sm={6}>
                        <FormLabel>Province/Region</FormLabel>
                        <Field as={FormControl}
                               name="province"
                               isInvalid={touched.province && !!errors.province}/>
                        <FormControl.Feedback type="invalid">
                            {errors.province as string}
                        </FormControl.Feedback>
                    </FormGroup>
            }
        </Row>
        <Row>
            <FormGroup controlId="country" className="mb-3" as={Col} sm={6}>
                <FormLabel>Country</FormLabel>
                <Field component={FormikSelect}
                       name="country"
                       options={fields.country.items}
                       isInvalid={touched.country && !!errors.country}
                       error={errors.state}
                />
                <FormControl.Feedback type="invalid">
                    {errors.country as string}
                </FormControl.Feedback>
            </FormGroup>

            <FormGroup controlId="zipCode" className="mb-3" as={Col} xs={5}>
                <FormLabel>Zip</FormLabel>
                <Field as={FormControl}
                       name="zipCode"
                       isInvalid={touched.zipCode && !!errors.zipCode}/>
                <FormControl.Feedback type="invalid">
                    {errors.zipCode as string}
                </FormControl.Feedback>
            </FormGroup>
        </Row>
    </>);
}
