import React, {FormEventHandler} from 'react';
import {Field, Form, FormikErrors, FormikTouched, FormikValues} from 'formik';
import {Card, Col, Container, FormControl, FormGroup, FormLabel, Row} from 'react-bootstrap';
import {FormikColorPicker, FormikImage, SubmitOnConfirmButton} from '@/components/form';
import {CardHeaderTitle, PageFooter} from '@/components/container';
import {useAppContext} from '@/AppContext';
import {UpgradeCallout} from '@/components/product';
import {SetFieldValue} from '@shared/formik';
import {InferType} from 'yup';
import {BrandSettingsSchema} from '@shared/requestSchemas';

interface BrandSettingsFormFields extends InferType<typeof BrandSettingsSchema> {
    logoFile: string;
    punchCardImageFile: string;
}

interface Props<T> {
    resetForm: FormEventHandler<HTMLFormElement>;
    isSubmitting: boolean;
    errors: FormikErrors<T>;
    values: FormikValues;
    touched: FormikTouched<T>;
    setFieldValue: SetFieldValue;
}

function BrandSettingsForm({
    resetForm,
    isSubmitting,
    errors,
    values,
    touched,
}: Props<BrandSettingsFormFields>) {
    const {selectedBusinessOption} = useAppContext();

    return (
        <Form noValidate>
            <Container fluid>
                <Row>
                    <Col lg={{span: 3}}>
                        <Card className="shadow mb-3">
                            <Card.Header>
                                <CardHeaderTitle>Brand Colors</CardHeaderTitle>
                            </Card.Header>
                            <Card.Body>
                                <Field as={FormikColorPicker}
                                       name="brandColor1"
                                       label="Primary Color"
                                       disabled={isSubmitting}/>
                                <Field as={FormikColorPicker}
                                       name="brandColor2"
                                       label="Secondary Color"
                                       disabled={isSubmitting}/>
                                <Field as={FormikColorPicker}
                                       name="brandColor3"
                                       label="Tertiary Color"
                                       disabled={isSubmitting}/>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={{span: 4}}>
                        <Card className="shadow mb-3">
                            <Card.Header>
                                <CardHeaderTitle>Brand Logo</CardHeaderTitle>
                            </Card.Header>
                            <Card.Body>
                                <FormGroup controlId="logoFile" className="mb-3">
                                    <FormLabel className="sr-only">Brand Logo</FormLabel>

                                    <Field as={FormikImage}
                                           name="logoFile"
                                           disabled={isSubmitting}
                                           isInvalid={!!errors.logoFile && touched.logoFile}
                                           imageUrl={values.logoUrl}
                                    />
                                    <FormControl.Feedback type="invalid">
                                        {errors.logoFile}
                                    </FormControl.Feedback>
                                </FormGroup>
                            </Card.Body>
                        </Card>
                    </Col>
                    {selectedBusinessOption.usesMobileApp &&
                        <Col lg={{span: 4}}>
                            <Card className="shadow mb-3">
                                <Card.Header>
                                    <CardHeaderTitle>Punch Card Image (Mobile)</CardHeaderTitle>
                                </Card.Header>
                                <Card.Body>
                                    <FormGroup controlId="punchCardImageFile" className="mb-3">
                                        <FormLabel className="sr-only">Punch Card Image (Mobile)</FormLabel>

                                        <Field as={FormikImage}
                                               name="punchCardImageFile"
                                               disabled={isSubmitting || !selectedBusinessOption.usesMobileImageUpload}
                                               aspectRatio={(16 / 4.5)}
                                               isInvalid={!!errors.punchCardImageFile && touched.punchCardImageFile}
                                               imageUrl={values.punchCardImageUrl}
                                        />

                                        <FormControl.Feedback type="invalid">
                                            {errors.punchCardImageFile}
                                        </FormControl.Feedback>
                                    </FormGroup>
                                </Card.Body>

                                {selectedBusinessOption.canUpgradeTo.usesMobileImageUpload && (
                                    <Card.Footer className="bg-primary p-2 text-center">
                                        <UpgradeCallout
                                            canUpgradeTo={true}
                                            text="your plan to add a custom card image."
                                        />
                                    </Card.Footer>
                                )}
                            </Card>
                        </Col>
                    }
                </Row>
            </Container>

            <PageFooter>
                <SubmitOnConfirmButton disabled={isSubmitting}
                                       resetForm={resetForm}
                                       isSubmitting={isSubmitting}/>
            </PageFooter>
        </Form>
    );
}

export default BrandSettingsForm;
