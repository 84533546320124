import React, {PropsWithChildren, useEffect, useMemo, useState} from 'react';
import {Nav} from 'react-bootstrap';
import {useLocation, useNavigate} from 'react-router-dom';
import {useAppContext} from '@/AppContext';

type Search = {
    searchTerm: string,
}

interface MenuItem {
    name: string;
    to: string;
}

interface Props {
}

export function CampaignSubNav({children}: PropsWithChildren<Props>) {
    const location = useLocation();
    const navigate = useNavigate();
    const {selectedBusinessOption} = useAppContext();
    const menuItems = useMemo(() => {
        const isVisible = selectedBusinessOption.usesAutomatedCampaigns;
        return {
            custom: {
                name: 'Custom',
                to: '/campaigns',
                isVisible,
            },
            automated: {
                name: 'Automated',
                to: '/campaigns/auto',
                isVisible,
            },
        };
    }, [selectedBusinessOption]);

    const [activeMenuItem, setActiveMenuItem] = useState<MenuItem>(menuItems.custom);

    useEffect(() => {
        if (location.pathname.includes('/campaigns/auto')) {
            setActiveMenuItem(menuItems.automated);
        } else {
            setActiveMenuItem(menuItems.custom);
        }
    }, [location]);

    return (
        <>
            <div className="pt-1 px-2 pb-0 bg-white mb-md-3 shadow">
                <div className="d-flex">
                    <div className="me-3">
                        <Nav variant="pills"
                             activeKey={activeMenuItem.name}
                        >
                            {
                                Object.values(menuItems)
                                    .filter((menuItem) => menuItem.isVisible)
                                    .map((menuItem, idx) => (
                                        <Nav.Item key={idx}>
                                            <Nav.Link eventKey={menuItem.name}
                                                      href=""
                                                      className="py-3"
                                                      onClick={() => navigate(menuItem.to)}>
                                                {menuItem.name}
                                            </Nav.Link>
                                        </Nav.Item>
                                    ))
                            }
                        </Nav>
                    </div>
                    <div className="flex-grow-1 d-md-block d-none">
                        {children}
                    </div>
                </div>
            </div>
            <div className="d-md-none px-3 py-1 mb-3 bg-white shadow border-top">
                {children}
            </div>
        </>
    );
}
