export enum NotificationKind {
    Error = 'error',
    Success = 'success',
    Warning = 'warning',
    Info = 'info',
}

export default interface Notify {
    success: (message: string) => void;
    errorMessage: (message: string) => void;
    error: (error: Error, message?: string, context?: Record<string, unknown>) => void;
    info: (message: string) => void;
    warning: (message: string) => void;
    show: (notificationKind: NotificationKind, message: string) => void;
}
