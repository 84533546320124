import React, {useEffect, useMemo, useState} from 'react';
import {CenteredSpinner} from '@/components/animation';
import {useNavigate, useParams} from 'react-router';
import {Card, Col, Container, Row} from 'react-bootstrap';
import {useAppContext} from '@/AppContext';
import {useShopperResource} from '@/services/api';
import {toShortDateString, toShortDateStringWithoutTimezone, toShortDateTimeString} from '@/services/textFormatting';
import {AgGridReact} from 'ag-grid-react';
import {ICellRendererParams} from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';
import {ShopperDetailsResponse} from '@shared/responseModels/business';
import {ShopperSchema} from '@shared/requestSchemas';
import {Formik} from 'formik';
import {CustomerDetailsForm, CustomerDetailsToolbar} from '@/pages/Customers/components';
import {CardHeaderTitle} from '@/components/container';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCake, faClockRotateLeft, faEnvelope, faPhone, faStar, faTrophy} from '@fortawesome/free-solid-svg-icons';
import {cloneDeep, keyBy, mapValues} from 'lodash';

export function CustomerDetails() {
    const shopperResource = useShopperResource();
    const navigate = useNavigate();
    const params = useParams();

    if (params.customerId === undefined) {
        navigate('/not-found');
        return null;
    }

    const customerId = parseInt(params.customerId);

    const {selectedBusinessOption, constants} = useAppContext();
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [shopper, setShopper] = useState<ShopperDetailsResponse>({
        fullName: '',
        firstName: '',
        lastName: '',
        dateOfBirth: null,
        email: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        province: '',
        country: '',
        zipCode: '',
        hasPendingAdHocTextOptInRequest: false,
        lastAdHocTextOptInMessageSentAt: null,
    } as unknown as ShopperDetailsResponse);

    const onSubmit = (values, {resetForm, setSubmitting, setErrors}) => {
        const valuesToUpdate = cloneDeep(values);
        delete valuesToUpdate.visits;
        delete valuesToUpdate.offers;

        shopperResource.update(values.id, valuesToUpdate)
            .then(({data}) => {
                setShopper(data);
                resetForm();
            })
            .catch(({response: {data: {validationMessages}}}) => {
                const errors = mapValues(keyBy(validationMessages, 'paramName'), 'message');
                setErrors(errors);
            })
            .finally(() => setSubmitting(false));
    };

    const defaultColDef = useMemo(() => ({
        sortable: true,
    }), []);

    const visitColumnDefs = useMemo(() => [
        {
            field: 'created',
            width: 140,
            cellRenderer: ({value}: ICellRendererParams) => <div
                className="text-end">{toShortDateTimeString(value)}</div>,
        },
        {
            field: 'locationName',
            headerName: 'Location',
            flex: 1,
        },
        {
            field: 'description',
            flex: 2,
        },
        {
            field: 'points',
            width: 100,
            cellRenderer: ({value}: ICellRendererParams) => <div className="text-end">{value}</div>,
        },
    ], []);

    const offerColumnDefs = useMemo(() => [
        {
            field: 'id',
            headerName: 'Name',
            flex: 1,
            cellRenderer: ({data}: ICellRendererParams) => {
                return data.properties?.title ?? constants.unknownValue;
            },
        },
        {
            field: 'claimed',
            width: 100,
            cellRenderer: ({value}: ICellRendererParams) => <div>{value ? 'Yes' : 'No'}</div>,
        },
        {
            field: 'redeemed',
            width: 100,
            cellRenderer: ({value}: ICellRendererParams) => <div>{value ? 'Yes' : 'No'}</div>,
        },
        {
            field: 'redeemedTime',
            width: 130,
            cellRenderer: ({value}: ICellRendererParams) => <div>{toShortDateTimeString(value)}</div>,
        },
        {
            field: 'expiration',
            width: 130,
            cellRenderer: ({value}: ICellRendererParams) => <div>{toShortDateString(value)}</div>,
        },
    ], []);

    useEffect(() => {
        setIsPageLoading(true);
        shopperResource.get(customerId)
            .then(({data}) => {
                setShopper(data);
            })
            .catch((error) => {
                navigate('/customers');
            })
            .finally(() => setIsPageLoading(false));
    }, [selectedBusinessOption]);

    const summaryItems = [
        {
            icon: faPhone,
            text: shopper.humanReadablePhone ? shopper.humanReadablePhone : 'Not Given',
        },
        {
            icon: faEnvelope,
            text: shopper.email ? shopper.email : 'Not Given',
        },
        {
            icon: faCake,
            text: shopper.dateOfBirth ? toShortDateStringWithoutTimezone(shopper.dateOfBirth) : 'Not Given',
        },
        {
            icon: faClockRotateLeft,
            text: `Member since ${new Date(shopper.created).getFullYear().toString()}`,
        },
        {
            icon: faStar,
            text: shopper.humanReadableLevel,
        },
        {
            icon: faTrophy,
            text: (
                <span>
                    <strong>{shopper.lifetimePoints}</strong>
                    <span> Lifetime Points</span>
                </span>
            ),
        },
    ];

    if (isPageLoading) {
        return <CenteredSpinner/>;
    }

    return (
        <div className="mb-5">
            <div>
                <CustomerDetailsToolbar shopper={shopper} setShopper={setShopper}/>
            </div>
            <div className="mx-2 pt-3 px-3">
                <Container fluid>
                    <Row lg="auto">
                        {
                            summaryItems.map((item, index) => (
                                <Col key={index} sm={6} xs={12}>
                                    <div className="d-inline-block pb-1 text-nowrap">
                                        <FontAwesomeIcon icon={item.icon} fixedWidth className="me-1"/>
                                        <span>{item.text}</span>
                                    </div>
                                </Col>
                            ))
                        }
                    </Row>
                </Container>
            </div>
            <div className="pt-3">
                <Container fluid>
                    <Row>
                        <Col xl={6} xs={12}>
                            <Card className="shadow">
                                <Card.Header>
                                    <CardHeaderTitle>Activity</CardHeaderTitle>
                                </Card.Header>
                                <Card.Body className="p-0 m-0">
                                    <div className="ag-theme-balham" style={{height: 300, width: '100%'}}>
                                        <AgGridReact
                                            defaultColDef={defaultColDef}
                                            animateRows={true}
                                            rowData={shopper.visits}
                                            columnDefs={visitColumnDefs}>
                                        </AgGridReact>
                                    </div>
                                </Card.Body>
                                <Card.Footer style={{height: 40}} className="border-0">
                                    <></>
                                </Card.Footer>
                            </Card>
                        </Col>
                        <Col xl={6} xs={12}>
                            <Card className="shadow">
                                <Card.Header>
                                    <CardHeaderTitle>Offers</CardHeaderTitle>
                                </Card.Header>
                                <Card.Body className="p-0 m-0">
                                    {shopper.offers.length > 0 ?
                                        <div className="ag-theme-balham" style={{height: 300, width: '100%'}}>
                                            <AgGridReact
                                                defaultColDef={defaultColDef}
                                                animateRows={true}
                                                rowData={shopper.offers}
                                                columnDefs={offerColumnDefs}>
                                            </AgGridReact>
                                        </div> :
                                        <div className="d-flex justify-content-center text-muted" style={{height: 300}}>
                                            <div style={{paddingTop: 180}}>No offers yet.</div>
                                        </div>
                                    }
                                </Card.Body>
                                <Card.Footer style={{height: 40}} className="border-0">
                                    <></>
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <Formik
                    initialValues={shopper}
                    validationSchema={ShopperSchema}
                    onSubmit={onSubmit}
                    component={CustomerDetailsForm}
                    enableReinitialize={true}
                />
            </div>
        </div>
    );
}
