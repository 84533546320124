import React, {useEffect, useState} from 'react';
import {useField} from 'formik';
import {DefaultEditor} from 'react-simple-wysiwyg';

interface Props {
    name?: string;
    disabled?: boolean;
}

function HtmlEditor({name, disabled}: Props) {
    const [isReady, setIsReady] = useState(false);
    const [field, meta, helpers] = useField(name);
    const {value} = meta;
    const {setValue} = helpers;

    useEffect(() => {
        setIsReady(true);
    }, []);

    if (!isReady) {
        return null;
    }

    return (
        <DefaultEditor
            value={value}
            disabled={disabled}
            onChange={(event) => {
                setValue(event.target.value);
            }}
        />
    );
}

export default HtmlEditor;
