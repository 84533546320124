import React from 'react';
import {Outlet} from 'react-router-dom';

export function CampaignsLayout() {
    return (
        <>
            <Outlet/>
        </>
    );
}
