import React from 'react';
import {FormCheck} from 'react-bootstrap';
import classNames from 'classnames';

export const FormikSwitch = (
    {
        form,
        field,
        className,
        label,
        disabled,
    },
) => {
    const onChange = (event) => {
        form.setFieldValue(field.name, event.target.checked);
    };

    return (
        <FormCheck type="switch"
                   label={label}
                   className={classNames(className)}
                   id={field.name}
                   name={field.name}
                   value={field.value}
                   defaultChecked={field.value}
                   onChange={onChange}
                   disabled={disabled}
        />
    );
};

export default FormikSwitch;
