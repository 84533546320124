import React from 'react';

function BottomShadow() {
    return <div className="mb-4 shadow" style={{
        zIndex: 102,
        height: '1rem',
        marginTop: '-1rem',
    }}/>;
}

export default BottomShadow;
