import React, {useState} from 'react';
import {Button, Col, Container, Modal, Row, Stack} from 'react-bootstrap';
import {useAppContext} from '@/AppContext';
import classNames from 'classnames';
import {BsLightningChargeFill} from 'react-icons/all';
import Switch from 'react-switch';
import {themeColors} from '@/services/theme';
import {BillingPlan} from '@/components/product/BillingPlan';
import {Tada} from '@/components/animation';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleCheck} from '@fortawesome/free-solid-svg-icons';
import {ButtonVariant} from 'react-bootstrap/types';

function Feature({text}: { text: string }) {
    return (
        <li>
            <div className="d-flex flex-row mb-1">
                <div><FontAwesomeIcon icon={faCircleCheck} className="me-2 text-success"/></div>
                <div>{text}</div>
            </div>
        </li>
    );
}

interface Props {
    isCollapsed?: boolean;
    variant?: ButtonVariant,
    buttonClassNames?: string,
    additionalButtonText?: string;
}

export function UpgradePlanModal({
    variant = 'success',
    isCollapsed = false,
    buttonClassNames,
    additionalButtonText,
}: Props) {
    const {selectedBusinessOption, billing} = useAppContext();
    const [isBilledMonthly, setIsBilledMonthly] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const productTier = {
        custom: 1,
        start: 2,
        pro: 4,
        premium: 5,
    };

    // @ts-ignore
    const canUpgrade = selectedBusinessOption.productTierTypeId > productTier.custom && selectedBusinessOption.productTierTypeId < productTier.premium;

    if (!canUpgrade || !billing.stripeCustomerPortalUrl) {
        return null;
    }

    return (
        <>
            <Button variant={variant} onClick={handleShow}
                    className={classNames('px-4 text-decoration-none', variant === 'success' ? 'text-white' : '', buttonClassNames)}>
                <Stack direction="horizontal" gap={1}>
                    <BsLightningChargeFill/>
                    {!isCollapsed && <Tada>Upgrade</Tada>}
                    <div>{additionalButtonText}</div>
                </Stack>
            </Button>

            <Modal show={show} onHide={handleClose} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <Stack direction={'horizontal'} gap={1}>
                            <BsLightningChargeFill/>
                            <span>Upgrade Your Plan</span>
                        </Stack>
                        <div className="text-muted h6 ps-4 pt-2 m-0">
                            Upgrade your plan and get access to powerful loyalty features.
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <div className="h5 m-0 p-3 pb-1 ">
                    <div className="d-flex justify-content-center gap-2">
                        <div>Billed Yearly</div>
                        <div>
                            <Switch checked={isBilledMonthly}
                                    aria-label="Switch between annual and monthly billing"
                                    onColor={themeColors.primary}
                                    offColor={themeColors.primary}
                                    checkedIcon={<></>}
                                    uncheckedIcon={<></>}
                                    onChange={() => {
                                    setIsBilledMonthly(!isBilledMonthly);
                                }}
                            />
                        </div>
                        <div>Billed Monthly</div>
                    </div>

                </div>
                <div className="text-center p-2 pb-3 m-0 border-bottom">
                    <em>You save up to 25% by paying yearly</em>
                </div>

                <div>
                    <Container fluid>
                        <Row>
                            <Col lg={4}>
                                <BillingPlan planName="Start"
                                             isCurrentPlan={productTier.start === selectedBusinessOption.productTierTypeId}
                                             canUpgradeTo={false}
                                             isMonthlyBilling={isBilledMonthly}
                                             yearlyPrice="9.99"
                                             monthlyPrice="9.99"
                                             subHeading="Loyalty Program Features"
                                             className="border-end-lg border-bottom border-bottom-lg-0"
                                >
                                    <ul className="list-unstyled" style={{fontSize: '0.75rem'}}>
                                        {[
                                            '1 Merchant Login',
                                            '2 Spark Stamps',
                                            'Audience Size Up to 250',
                                            'Up to 5 Reward Tiers',
                                            'Apply Loyalty Points in App',
                                            'Email Campaigns (5 pre-set automated)',
                                            'Mobile Campaigns (automated)',
                                            'Mobile Campaigns (custom)',
                                            'Points Per Visit - Points Per Item - Points Per Dollar',
                                            'Public Sign-Up Form (QR code & URL)',
                                        ].map((text) => <Feature text={text} key={text}/>)}
                                    </ul>
                                </BillingPlan>
                            </Col>
                            <Col lg={4}>
                                <BillingPlan planName="Pro"
                                             isCurrentPlan={productTier.pro === selectedBusinessOption.productTierTypeId}
                                             canUpgradeTo={selectedBusinessOption.productTierTypeId < productTier.pro}
                                             isMonthlyBilling={isBilledMonthly}
                                             yearlyPrice="99"
                                             monthlyPrice="129"
                                             subHeading="Start Features +"
                                             className="border-end-lg border-bottom border-bottom-lg-0"
                                >
                                    <ul className="list-unstyled" style={{fontSize: '0.75rem'}}>
                                        {[
                                            'Up to 3 Merchant Logins',
                                            '50% off Additional & Replacement Stamps',
                                            'Audience Size Up to 2,000',
                                            '6-8 Reward Tiers',
                                            'In-Store Retail Kiosk: Standard Branding',
                                            'Up to 3 Additional Automated Campaigns',
                                            'Email Campaigns: Custom',
                                            'Text Campaigns: Custom',
                                            'Text Campaigns (limited)',
                                            'Enhanced Sign-up Form: Configure Profile and Preference Fields',
                                        ].map((text) => <Feature text={text} key={text}/>)}
                                    </ul>
                                </BillingPlan>
                            </Col>
                            <Col lg={4}>
                                <BillingPlan planName="Premium"
                                             isCurrentPlan={productTier.premium === selectedBusinessOption.productTierTypeId}
                                             canUpgradeTo={selectedBusinessOption.productTierTypeId < productTier.premium}
                                             isMonthlyBilling={isBilledMonthly}
                                             yearlyPrice="279"
                                             monthlyPrice="349"
                                             subHeading="Pro Features +"
                                             upgradeButtonClassName="text-white btn-success"
                                >
                                    <ul className="list-unstyled" style={{fontSize: '0.75rem'}}>
                                        {[
                                            'VIP Status Customer Segmentation',
                                            'Free Additional & Replacements Stamps',
                                            'Unlimited Shopper Accounts',
                                            '9+ Reward Tiers*',
                                            'Advanced Kiosk Brand Customization',
                                            '4-10 Customized Automated Campaigns',
                                            'MMS Campaigns (unlimited)',
                                            'Expanded Text Messaging with URL (SHAFT Compliant)',
                                            'AI-Driven Campaign Tools',
                                            'Enhanced Sign-up Form: Collect Customer Addresses',
                                        ].map((text) => <Feature text={text} key={text}/>)}
                                    </ul>
                                </BillingPlan>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={handleClose}>
                        Maybe Later
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
