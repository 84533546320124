import React, {useMemo} from 'react';
import {useFormikContext} from 'formik';

export function ValidationSummary() {
    const {errors, touched} = useFormikContext();
    const count = useMemo(() => {
        // @ts-ignore
        return Object.keys(errors).filter((fieldName: string) => !!touched[fieldName]).length;
    }, [errors, touched]);

    if (count === 0) {
        return null;
    }

    const hasSingleError = count === 1;
    const hasMoreThanOneError = count > 1;

    return (
        <div >
            <span className="me-1">Oops! Looks like we hit a little snag.</span>
            {hasSingleError && <>There is <span className="fw-bold text-danger">{count} validation issue.</span></>}
            {hasMoreThanOneError && <>There are <span className="fw-bold text-danger">{count} validation issues.</span></>}
        </div>
    );
}
