import React from 'react';
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconButton} from '@/components/form';

interface Props {
    to?: string | number;
    text?: string;
}

function BackButton({
    to = -1,
    text = '',
}: Props) {
    return (
        <IconButton icon={<FontAwesomeIcon icon={faArrowLeft}/>}
                    variant="outline-primary"
                    text={text}
                    circular={true}
                    to={to}
        />
    );
}

export default BackButton;
