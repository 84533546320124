import React, {useEffect, useState} from 'react';
import {CenteredSpinner, FadeIn} from '@/components/animation';
import {useAppContext} from '@/AppContext';
import {useBusinessSettingsResource} from '@/services/api';
import {Formik} from 'formik';
import CustomerSettingsForm from '@/pages/Settings/components/CustomerSettingsForm';
import {CustomerSettingsSchema} from '@shared/requestSchemas';
import {CustomerSettingsFormValues} from '@shared/requestSchemas/CustomerSettingsSchema';

export function CustomerSettings() {
    const businessSettingsResource = useBusinessSettingsResource();
    const {selectedBusinessOption} = useAppContext();
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [customerSettings, setCustomerSettings] = useState<CustomerSettingsFormValues>({
        pointsNeededToEarnVipStatus: 0,
        vipStatusDisplayName: 'VIP'
    });

    async function onSubmit(values: CustomerSettingsFormValues, {
        setSubmitting,
        resetForm,
    }: {
        setSubmitting: (isSubmitting: boolean) => void,
        resetForm: () => void,
    }) {
        const {data} = await businessSettingsResource.updateCustomerSettings(values);
        setCustomerSettings({...customerSettings, ...data});
        resetForm();
        setSubmitting(false);
        setIsPageLoading(false);
    }

    const fetchData = () => {
        setIsPageLoading(true);
        businessSettingsResource.getCustomerSettings()
            .then(({data}) => {
                setCustomerSettings(data);
            })
            .finally(() => setIsPageLoading(false));
    };

    useEffect(() => {
        fetchData();
    }, [selectedBusinessOption]);

    if (isPageLoading) {
        return <CenteredSpinner/>;
    }

    return (
        <FadeIn>
            <Formik component={CustomerSettingsForm}
                    validationSchema={CustomerSettingsSchema}
                    enableReinitialize
                    initialValues={customerSettings}
                    onSubmit={onSubmit}/>
        </FadeIn>
    );
}
