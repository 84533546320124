import React, {PropsWithChildren, ReactElement, useState} from 'react';
import {Button, Modal} from 'react-bootstrap';
import classNames from 'classnames';

interface Props {
    buttonHoverHelpTip?: string;
    buttonText?: string,
    buttonIcon?: ReactElement,
    modalTitle?: ReactElement | string,
    fullscreen?: true | undefined,
    showFooter?: boolean,
    hasBodyPadding?: boolean,
    styleButtonAsLink?: boolean;
    modalBodyHeight?: string;
    modalSize?: 'sm' | 'lg' | 'xl';
}

export function SimpleModal({
    buttonHoverHelpTip = '',
    buttonIcon,
    buttonText,
    modalTitle,
    fullscreen,
    showFooter = false,
    hasBodyPadding = true,
    styleButtonAsLink = true,
    modalBodyHeight = '80vh',
    children,
    modalSize,
}: PropsWithChildren<Props>) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            {styleButtonAsLink &&
                <div onClick={handleShow}
                     className="text-decoration-none clickable text-primary"
                     title={buttonHoverHelpTip}
                >
                    <>
                        {buttonIcon}
                        {buttonText}
                    </>
                </div>
            }

            {!styleButtonAsLink &&
                <Button onClick={handleShow}
                        variant="outline-primary"
                        title={buttonHoverHelpTip}
                >
                    <>
                        {buttonIcon}
                        {buttonText}
                    </>
                </Button>
            }

            <Modal show={show}
                   size={modalSize}
                   onHide={handleClose}
                   keyboard={false}
                   fullscreen={fullscreen}
                   centered
                   animation={true}
            >
                {modalTitle &&
                    <Modal.Header closeButton>
                        <Modal.Title>{modalTitle}</Modal.Title>
                    </Modal.Header>
                }

                <Modal.Body className={classNames(hasBodyPadding ? '' : 'p-0 m-0')}
                            style={{height: modalBodyHeight}}>
                    {children}
                </Modal.Body>

                {showFooter &&
                    <Modal.Footer>
                        <Button variant="outline-secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                }
            </Modal>
        </>
    );
}
