import {DateTime} from 'luxon';
import {ConfigurationFields} from '@shared/responseModels/ConfigurationResponse';

interface DateRange {
    fromDate: string | null;
    toDate: string | null;
}

export interface DateFilterRange {
    label: string;
    id: number;
    range: () => DateRange;
}

export const dateFilterRangesFactory = (fields: ConfigurationFields): DateFilterRange[] => {
    return [
        {
            label: 'Today',
            id: fields.dateRange.map.today as number,
            range: () => ({
                fromDate: DateTime.local().startOf('day').toUTC().toISO(),
                toDate: DateTime.local().endOf('day').toUTC().toISO(),
            }),
        },
        {
            label: 'This Week',
            id: fields.dateRange.map.thisWeek as number,
            range: () => ({
                fromDate: DateTime.local().startOf('week').toUTC().toISO(),
                toDate: DateTime.local().endOf('day').toUTC().toISO(),
            }),
        },
        {
            label: 'This Month',
            id: fields.dateRange.map.thisMonth as number,
            range: () => ({
                fromDate: DateTime.local().startOf('month').toUTC().toISO(),
                toDate: DateTime.local().endOf('day').toUTC().toISO(),
            }),
        },
        {
            label: 'This Year',
            id: fields.dateRange.map.thisYear as number,
            range: () => ({
                fromDate: DateTime.local().startOf('year').toUTC().toISO(),
                toDate: DateTime.local().endOf('day').toUTC().toISO(),
            }),
        },
    ];
};

export const customDateFilterRangeFactory = (fields: ConfigurationFields) => {
    return {
        label: 'Custom',
        id: fields.dateRange.map.custom as number,
        range: () => ({
            fromDate: DateTime.local().startOf('day').toUTC().toISO(),
            toDate: DateTime.local().endOf('day').toUTC().toISO(),
        }),
    };
};
