import React, {FormEvent, useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Col, Container, Dropdown, Form, FormControl, InputGroup, Row, Stack} from 'react-bootstrap';
import {useCustomCampaignResource} from '@/services/api';
import {useAppContext} from '@/AppContext';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {PaginatedTable, useTableUrlParams} from '@/components/table';
import {
    faBullhorn,
    faClone,
    faEdit,
    faEye,
    faMagnifyingGlass,
    faPlus,
    faRefresh,
    faStar,
    faTrash,
} from '@fortawesome/free-solid-svg-icons';
import {Link, useLoaderData, useLocation, useSearchParams} from 'react-router-dom';
import {ActOnConfirmButton, IconButton} from '@/components/form';
import {FadeIn} from '@/components/animation';
import {toNumberString, toShortDateTimeString} from '@/services/textFormatting';
import {useNavigate} from 'react-router';
import {useNotify} from '@/services';
import {CustomCampaignListItem, CustomCampaignListResponse} from '@shared/responseModels/business';
import {PaginatedTableFetchDataArgs} from '@/components/table/PaginatedTableFetchDataArgs';
import {CampaignSubNav} from '@/pages/Campaigns/components/CampaignSubNav';
import {If, InfoCard} from '@/components/container';
import classNames from 'classnames';
import {ColumnDef, createColumnHelper} from '@tanstack/react-table';

function CustomCampaignList() {
    const location = useLocation();
    const makeColumnDef = createColumnHelper<CustomCampaignListItem>();
    const loaderData = useLoaderData() as CustomCampaignListResponse;
    const campaignResource = useCustomCampaignResource();
    const notify = useNotify();
    const navigate = useNavigate();
    const {
        selectedBusinessOption,
        constants,
        campaignListSearchTerm,
    } = useAppContext();
    const {setTableUrlParams} = useTableUrlParams();
    const [searchTerm, setSearchTerm] = useState(campaignListSearchTerm);
    const [tableSettings, setTableSettings] = useState<PaginatedTableFetchDataArgs>({
        pageSize: 10, pageIndex: 0, sortBy: [],
    });

    useEffect(() => {
        setSearchTerm(campaignListSearchTerm);
    }, [campaignListSearchTerm]);

    useEffect(() => {
        if (!campaignListSearchTerm) {
            // When the route changes, clear the local search state.
            setSearchTerm('');
        }
    }, [location]);

    const messageProgressCellRenderer = useCallback((messagesSent: number, totalMessages: number, className?: string) => {
        return (
            <div className={classNames('text-end', className)}>
                <If as="div" isTrue={!messagesSent}>-</If>
                <If as={Stack} isTrue={!!messagesSent} gap={1}>
                    <span>
                        {totalMessages > 0 ? `${((messagesSent / totalMessages) * 100).toFixed(0)}%` : '-'}
                    </span>

                    <If isTrue={selectedBusinessOption.canSendSmsToEntireAudience}
                        as="span"
                        className="fw-lighter"
                        style={{fontSize: 11}}
                    >
                        {toNumberString(messagesSent)}
                        <span> {totalMessages ? '/' : constants.unknownValue} </span>
                        {toNumberString(totalMessages)}
                    </If>
                </If>
            </div>
        );
    }, []);

    const columns = useMemo<ColumnDef<CustomCampaignListItem>[]>(() => [
        makeColumnDef.accessor('id', {
            header: 'Actions',
            size: 40,
            cell: ({getValue, row}) => (
                <div>
                    <IconButton className="m-0 text-primary"
                                size="sm"
                                variant="btn-link"
                                title={row.original.isEditable ? 'Edit' : 'View'}
                                icon={<FontAwesomeIcon icon={row.original.isEditable ? faEdit : faEye} fixedWidth/>}
                                to={`/campaigns/${getValue()}`}/>

                    <IconButton className="m-0 text-primary"
                                size="sm"
                                variant="btn-link"
                                title="Clone"
                                icon={<FontAwesomeIcon icon={faClone} fixedWidth/>}
                                onClick={async () => {
                                    try {
                                        const {data} = await campaignResource.clone(getValue());
                                        navigate(`/campaigns/${data.campaignId}`);
                                    } catch (error) {
                                        notify.errorMessage('Could not clone campaign');
                                    }
                                }}/>

                    <ActOnConfirmButton icon={<FontAwesomeIcon icon={faTrash} fixedWidth/>}
                                        confirmButtonVariant="danger"
                                        confirmButtonText="Yes, Delete"
                                        className="btn-sm text-primary"
                                        buttonVariant="link"
                                        disabled={!row.original.isEditable}
                                        onConfirm={async () => {
                                            await campaignResource.delete(getValue());
                                            await reFetchData();
                                        }}
                    />
                </div>
            ),
            enableSorting: false,
        }),

        makeColumnDef.accessor('scheduledTime', {
            header: 'Scheduled For',
            size: 45,
            cell: ({getValue}) => toShortDateTimeString(getValue()),
        }),

        makeColumnDef.accessor('name', {
            header: 'Name',
            cell: ({row, getValue}) => <Link to={`/campaigns/${row.original.id}`}
                                             className="text-truncate text-wrap">{getValue()}</Link>,
        }),
        makeColumnDef.accessor('communicationType', {
            header: 'Type',
            size: 40,
            enableSorting: false,
            cell: ({getValue}) => <span className="fw-lighter">{getValue()}</span>,
        }),

        ...(selectedBusinessOption.usesCustomEmailCampaigns ? [makeColumnDef.accessor('emailMessageTotal', {
            header: () => <div className="text-end">Emails</div>,
            size: 40,
            enableSorting: false,
            cell: ({
                row,
                getValue,
            }) => messageProgressCellRenderer(row.original.emailMessageDelivered, getValue()),
        })] : []),

        ...(selectedBusinessOption.usesCustomPushNotificationCampaigns ? [
            makeColumnDef.accessor('appNotificationMessageTotal', {
                header: () => <div className="text-end">Mobile</div>,
                size: 40,
                enableSorting: false,
                cell: ({
                    row,
                    getValue,
                }) => messageProgressCellRenderer(row.original.appNotificationMessageTotal, getValue()),
            }),
        ] : []),

        ...(selectedBusinessOption.usesCustomSmsCampaigns ? [makeColumnDef.accessor('smsMessageTotal', {
            header: () => <div className="text-end pe-2">Texts</div>,
            size: 40,
            enableSorting: false,
            cell: ({
                row,
                getValue,
            }) => messageProgressCellRenderer(row.original.textMessageDelivered, getValue(), 'pe-2'),
        })] : []),
    ] as ColumnDef<CustomCampaignListItem>[], [selectedBusinessOption, loaderData]);

    const fetchData = async (
        {pageSize, pageIndex, sortBy, columnFilters}: PaginatedTableFetchDataArgs,
        updatedSearchTerm?: string,
    ) => {
        setTableUrlParams({
            pageIndex,
            pageSize,
            sortBy,
            search: updatedSearchTerm || campaignListSearchTerm || '',
            defaultSortField: 'scheduledTime',
        });
        setTableSettings({pageSize, pageIndex, sortBy});
    };

    async function reFetchData() {
        await fetchData(tableSettings, searchTerm!);
    }

    async function onSubmitSearch(event: FormEvent) {
        event.preventDefault();
        tableSettings.pageIndex = 0;
        await reFetchData();
    }

    return (
        <>
            <CampaignSubNav>
                <div className="d-flex">
                    <div className="d-md-none mt-2">
                        <Dropdown>
                            <Dropdown.Toggle variant="primary" className="text-white">
                                <FontAwesomeIcon icon={faPlus}
                                                 fixedWidth
                                                 className="me-1"/>
                                <span>Create...</span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={(event) => {
                                    event.preventDefault();
                                    navigate('/campaigns/create-announcement');
                                }}>
                                    <FontAwesomeIcon icon={faBullhorn} fixedWidth className="me-1"/>
                                    <span>Announcement</span>
                                </Dropdown.Item>
                                <Dropdown.Item onClick={(event) => {
                                    event.preventDefault();
                                    navigate('/campaigns/create-offer');
                                }}>
                                    <FontAwesomeIcon icon={faStar} fixedWidth className="me-1"/>
                                    <span>Offer</span>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>

                    <div className={classNames('d-none d-md-block mt-2', loaderData.hasAnyCampaigns ? '' : 'mb-2')}>
                        <Stack direction="horizontal" gap={2}>
                            <div>
                                <Button className="pe-3"
                                        variant="outline-primary"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            navigate('/campaigns/create-announcement');
                                        }}
                                >
                                    <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1"/>
                                    <span>Announcement</span>
                                </Button>
                            </div>
                            <div>
                                <Button className="pe-3"
                                        variant="outline-primary"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            navigate('/campaigns/create-offer');
                                        }}
                                >
                                    <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1"/>
                                    <span>Offer</span>
                                </Button>
                            </div>
                        </Stack>
                    </div>

                    <If isTrue={loaderData.hasAnyCampaigns} className="py-2 pe-md-2 flex-grow-1">
                        <div className="d-flex justify-content-end">
                            <Form noValidate onSubmit={onSubmitSearch}>
                                <Stack direction="horizontal" gap={2}>
                                    <div>
                                        <InputGroup>
                                            <FormControl type="text"
                                                         name="searchTerm"
                                                         autoFocus={true}
                                                         autoComplete="off"
                                                         placeholder="Search for campaign..."
                                                         value={searchTerm!}
                                                         onChange={(event) => {
                                                             setSearchTerm(event.target.value || '');
                                                         }}
                                            />
                                            <Button type="submit" style={{zIndex: 0}} variant="light">
                                                <FontAwesomeIcon icon={faMagnifyingGlass}/>
                                            </Button>
                                        </InputGroup>
                                    </div>
                                    <IconButton icon={<FontAwesomeIcon icon={faRefresh} fixedWidth/>}
                                                variant="outline-primary"
                                                circular={true}
                                                onClick={reFetchData}
                                    />
                                </Stack>
                            </Form>
                        </div>
                    </If>
                </div>
            </CampaignSubNav>

            <If isTrue={!loaderData.hasAnyCampaigns} as={Row}>
                <Col md={{span: 6, offset: 3}}>
                    <InfoCard>
                        <p>Ready to spread the word?</p>
                        <span>Let&apos;s create your first</span>
                        <a className="text-decoration-none clickable mx-1"
                           onClick={() => {
                               navigate('/campaigns/create-announcement');
                           }}>Announcement</a>
                        <span>or</span>
                        <a className="text-decoration-none clickable mx-1"
                           onClick={() => {
                               navigate('/campaigns/create-offer');
                           }}
                        >Offer</a>
                        <span>campaign!</span>
                    </InfoCard>
                </Col>
            </If>

            <If isTrue={loaderData.hasAnyCampaigns && loaderData.campaigns.length === 0} as={Row}>
                <Col md={{span: 6, offset: 3}}>
                    <InfoCard>
                        <p>Hmm....</p>
                        <p>We could not find any campaigns that match your search.</p>
                    </InfoCard>
                </Col>
            </If>

            <If isTrue={loaderData.campaigns.length > 0} as={FadeIn}>
                <Container fluid>
                    <PaginatedTable
                        columns={columns}
                        data={loaderData.campaigns}
                        fetchData={fetchData}
                        pageCount={(loaderData.totalCampaigns / tableSettings.pageSize)}
                        totalRows={loaderData.totalCampaigns}
                        type="campaign"
                    />
                </Container>
            </If>
        </>
    );
}

export default CustomCampaignList;
