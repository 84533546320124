import React, {ReactElement} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {toNumberString} from '@/services/textFormatting';
import classNames from 'classnames';
import {If} from '@/components/container/If';

interface Props {
    disabled?: boolean;
    title: ReactElement | string;
    value?: number;
    showValue?: boolean;
    percentage?: number | null;
    icon?: IconProp;
    control?: ReactElement;
    color: string;
}

function ScalarCard({
    disabled = false,
    title,
    value = 0,
    showValue = false,
    percentage = null,
    icon,
    control,
    color = '#000',
}: Props) {

    return (
        <div
            className={classNames('mb-3 h-100 py-3')}
        >
            <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-uppercase mb-1">
                        {title}
                    </div>

                    <If isTrue={disabled}
                        className="fs-5 fw-light pt-2 text-center text-muted"
                        style={{minHeight: '4rem'}}
                    >
                        n/a
                    </If>

                    <If isTrue={showValue && !disabled && percentage === null}
                        className="fs-1 fw-light d-flex align-items-center justify-content-center"
                        style={{minHeight: '5rem', color}}
                    >
                        <div className="text-center">
                            {toNumberString(value)}
                        </div>
                    </If>

                    <If isTrue={!disabled && percentage !== null && percentage >= 0}>
                        <div className={classNames('fs-4 fw-light pt-1')}
                             style={{color}}
                        >
                            {percentage?.toFixed(2)}%
                        </div>
                        <If isTrue={showValue} className="fs-6 fw-lighter">
                            {toNumberString(value)}
                        </If>
                    </If>
                </div>
                {
                    icon ?
                        <div className="col-auto opacity-25">
                            <FontAwesomeIcon icon={icon} className="fa-2x" fixedWidth/>
                        </div> :
                        null
                }

                {
                    control ?
                        <div className="col-auto">
                            {control}
                        </div> :
                        null
                }
            </div>
        </div>
    );
}

export default ScalarCard;
