import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Panel from '@/components/container/Panel';
import CustomTooltip from '@/pages/Dashboard/components/CustomTooltip';
import {Stack} from 'react-bootstrap';
import {Area, AreaChart, Cell, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {useBusinessAnalyticsResource} from '@/services/api';
import {useAppContext} from '@/AppContext';
import {themeColors} from '@/services/theme';
import {CustomerDataPoint} from '@shared/responseModels/analytics/CustomerResponse';
import {
    DateFormatter,
    dateFormatterFactory,
    dateFormatterFromTimeSeriesIntervalFactory,
    toMonthDayString,
} from '@/services/textFormatting';
import {AxisInterval} from 'recharts/types/util/types';

type QueryParam = {
    dateRange: number;
    fromDate?: string;
    toDate?: string;
    locations: number[];
}

interface Props {
    locations: number[];
}

function Customers({locations}: Props) {
    const businessAnalyticsResource = useBusinessAnalyticsResource();
    const {selectedBusinessOption, fields, currentClientTimeZone} = useAppContext();
    const [pieData, setPieData] = useState<{ name: string, value: number }[]>([]);
    const [customerTotal, setCustomerTotal] = useState<number>();

    const [isProcessing, setIsProcessing] = useState(true);
    const [dateRange, setDateRange] = useState<number>(fields.dateRange.map.thisWeek ?? 0);
    const [fromDate, setFromDate] = useState<Date>();
    const [toDate, setToDate] = useState<Date>();
    const [dateFormatter, setDateFormatter] = useState<DateFormatter>(dateFormatterFactory(toMonthDayString));
    const [xAxisInterval, setXAxisInterval] = useState<AxisInterval>(0);

    const [dataPoints, setDataPoints] = useState<CustomerDataPoint[]>();

    const chartColors = useMemo(() => {
        return [themeColors.primary, themeColors.success, themeColors.warning];
    }, []);

    const fetchCustomersData = useCallback(() => {
        setIsProcessing(true);
        const queryParams: QueryParam = {
            dateRange,
            locations,
        };

        // Prepare date filter
        // @ts-ignore
        if (dateRange === fields.dateRange.map.custom) {
            queryParams.fromDate = fromDate?.toISOString();
            queryParams.toDate = toDate?.toISOString();
        }

        businessAnalyticsResource.getAnalyticsCustomers(queryParams)
            .then(({data}) => {
                setPieData(data.levelTotals);
                setCustomerTotal(data.total);
                setDataPoints(data.dataPoints);
                setDateFormatter(dateFormatterFromTimeSeriesIntervalFactory(data.timeSeriesInterval));
                setXAxisInterval(
                    dateRange === fields.dateRange.map.thisYear || dateRange === fields.dateRange.map.thisWeek ?
                        0 : 'preserveStartEnd',
                );
            })
            .finally(() => setIsProcessing(false));

    }, [selectedBusinessOption, dateRange, fromDate, toDate, currentClientTimeZone]);

    useEffect(() => {
        fetchCustomersData();
    }, [selectedBusinessOption, dateRange, fromDate, toDate, currentClientTimeZone]);


    const pieLegend = () => {
        return pieData.map(({name}, index) => {
            return (
                <Stack direction={'horizontal'} gap={2} key={index}>
                    <div style={{height: 10, width: 10, borderRadius: 10 / 2, backgroundColor: chartColors[index]}}/>
                    <small className="text-muted">{name}</small>
                </Stack>
            );
        });
    };

    return (
        <Panel title={'Customers'}
               getFilterValue={setDateRange}
               setFromDate={setFromDate}
               setToDate={setToDate}
               isProcessing={isProcessing}
        >
            <Stack direction={'horizontal'}>
                <Stack>
                    <h2 style={{marginBottom: 0}}>{customerTotal}</h2>
                    <small className={'text-muted'}>Total</small>
                </Stack>
                <Stack direction={'horizontal'} gap={3}>
                    <Stack>
                        {pieLegend()}
                    </Stack>
                    <PieChart width={60} height={60}>
                        <Pie data={pieData} dataKey="value" cx="50%" cy="50%" outerRadius={30} innerRadius={16}
                             labelLine={false}>
                            {dataPoints?.map((_entry, index) => (
                                <Cell key={`cell-${index}`} fill={chartColors[index % chartColors.length]}/>
                            ))}
                        </Pie>
                        <Tooltip/>
                    </PieChart>
                </Stack>
            </Stack>
            <ResponsiveContainer height="100%" width="100%">
                <AreaChart data={dataPoints}
                           margin={{top: 30, right: 60, left: 0, bottom: 30}}>
                    <XAxis dataKey="date"
                           axisLine={false}
                           tickLine={false}
                           tickFormatter={dateFormatter.format}
                           type={'category'}
                           interval={xAxisInterval}
                           angle={-45}
                           textAnchor="end"
                    />
                    <YAxis width={40} axisLine={false} tickLine={false} allowDecimals={false}/>
                    <Tooltip content={<CustomTooltip payload={dataPoints} dateFormatter={dateFormatter}/>}/>
                    <Area type="monotone" dataKey="cumulativeCustomers"/>
                    <Area type="monotone" dataKey="newCustomers"/>
                </AreaChart>
            </ResponsiveContainer>
        </Panel>
    );
}

export default Customers;
