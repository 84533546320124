import React, {useMemo} from 'react';
import {CustomCampaignDetailsResponse} from '@shared/responseModels/business';
import {Col, Container, Row, Stack, Tab, Tabs} from 'react-bootstrap';
import {BackButton} from '@/components/form';
import {If, PageHeader, ScalarCard, SimpleCard, SimpleModal} from '@/components/container';
import {toPercentage, toShortDateString, toShortDateTimeString} from '@/services/textFormatting';
import {AppNotificationPreview, InlineHtmlEmailPreview, TextPreview} from '@/components/marketing';
import CampaignImagePreview from '@/pages/Campaigns/components/CampaignImagePreview';
import TabTitle from '@/pages/Campaigns/components/TabTitle';
import {AgGridReact} from 'ag-grid-react';
import {dataPalette} from '@/services/theme';
import {useAppContext} from '@/AppContext';

interface Props {
    campaign: CustomCampaignDetailsResponse,
}

export function CustomCampaignSummary({campaign}: Props) {
    const {selectedBusinessOption} = useAppContext();
    const statsColor = dataPalette[0];
    const clickThroughPerformanceColumnDefs = useMemo(() => [
        {
            field: 'url',
            headerName: 'URL',
            minWidth: 180,
            flex: 1,
        },
        {
            field: 'totalClicks',
            width: 180,
        },
        {
            field: 'uniqueClicks',
            width: 180,
        },
    ], []);

    const deliveryMetrics = [
        {
            name: 'Email',
            type: 'rate',
            disabled: !campaign.isEmailEnabled,
            value: campaign.emailMessageDelivered,
            percentage: toPercentage(campaign.emailMessageDelivered, campaign.emailMessageTotal),
            color: statsColor,
        },
        {
            name: 'Mobile',
            type: 'rate',
            disabled: !campaign.isAppNotificationEnabled,
            value: campaign.appNotificationMessageDelivered,
            percentage: toPercentage(campaign.appNotificationMessageDelivered, campaign.appNotificationMessageTotal),
            color: statsColor,
        },
        {
            name: 'Text',
            type: 'rate',
            disabled: !campaign.isTextEnabled,
            value: campaign.textMessageDelivered,
            percentage: toPercentage(campaign.textMessageDelivered, campaign.smsMessageTotal),
            color: statsColor,
        },
    ];

    const emailMetrics = [
        {
            name: 'Unique Opens',
            type: 'rate',
            disabled: !campaign.isEmailEnabled,
            value: campaign.emailMessageUniqueOpens,
            percentage: toPercentage(campaign.emailMessageUniqueOpens, campaign.emailMessageTotal),
            color: statsColor,
        },
        {
            name: 'Unique Clicks',
            type: 'rate',
            disabled: !campaign.isEmailEnabled,
            value: campaign.emailMessageUniqueClicks,
            percentage: toPercentage(campaign.emailMessageUniqueClicks, campaign.emailMessageTotal),
            color: statsColor,
        },
        {
            name: 'Spam Reports',
            type: 'rate',
            disabled: !campaign.isEmailEnabled,
            value: campaign.emailMessageSpamReports,
            percentage: toPercentage(campaign.emailMessageSpamReports, campaign.emailMessageTotal),
            color: statsColor,
        },
        {
            name: 'Bounces',
            type: 'rate',
            disabled: !campaign.isEmailEnabled,
            value: campaign.emailMessageBounces,
            percentage: toPercentage(campaign.emailMessageBounces, campaign.emailMessageTotal),
            color: statsColor,
        },
    ];

    return (
        <div>
            <PageHeader pageName={(
                <Stack direction="horizontal">
                    <div className="me-3">
                        <BackButton  to="/campaigns"/>
                    </div>
                    <div>
                        <div className="me-2">{campaign.campaignName}</div>
                        <div className="text-muted align-text-bottom" style={{fontSize: '0.8rem'}}>
                            Campaign Summary
                        </div>
                    </div>
                </Stack>
            )}/>
            <Container fluid className="px-3 py-2">
                <Row>
                    <Col lg={5} md={12} className="mb-3">
                        <SimpleCard title="Delivery Statistics"
                                    style={{borderLeft: `solid ${statsColor} 4px`}}
                                    hasBodyPadding={false}
                        >
                            <Row className="text-center">
                                {deliveryMetrics.map((metric, index) => (
                                    <Col key={index} className="text-center">
                                        <ScalarCard title={metric.name}
                                                    value={metric.value}
                                                    percentage={metric.percentage}
                                                    disabled={metric.disabled}
                                                    color={metric.color}
                                        />
                                    </Col>
                                ))}
                            </Row>
                        </SimpleCard>
                    </Col>
                    <Col lg={6} md={12} className="mb-3">
                        <SimpleCard title="Email Engagement" style={{borderLeft: `solid ${statsColor} 4px`}}
                                    hasBodyPadding={false}
                        >
                            <Row>
                                {emailMetrics.map(metric => (
                                    <Col key={metric.name} className="text-center" md={3} xs={6}>
                                        <ScalarCard title={metric.name}
                                                    value={metric.value}
                                                    percentage={metric.percentage}
                                                    disabled={metric.disabled}
                                                    color={metric.color}
                                        />
                                    </Col>
                                ))}
                            </Row>
                        </SimpleCard>
                    </Col>
                </Row>
            </Container>

            <Container fluid>
                <Row>
                    <Col lg={3} md={6}>
                        <SimpleCard title="Campaign" hasBodyPadding={true}>
                            <div>
                                <span className="fw-lighter py-1">Delivered</span>
                                <span className="ms-2 fs-6 fw-light">
                                    {toShortDateTimeString((campaign.scheduleFor as Date).toISOString())}
                                </span>
                            </div>

                            <div className="mb-3">
                                <span className="ms-2 fs-6 fw-light">
                                    <SimpleModal buttonText="Message Preview"
                                                 fullscreen={true}
                                                 hasBodyPadding={false}
                                                 modalTitle={campaign.campaignName}
                                    >
                                        <Tabs variant="pills"
                                        >
                                            {campaign.isEmailEnabled &&
                                                <Tab eventKey="email"
                                                     title={<TabTitle text="Email"
                                                                      isEnabled={campaign.isEmailEnabled}/>}
                                                >
                                                    {!campaign.hasCustomEmailTemplate ?
                                                        <div>
                                                            <InlineHtmlEmailPreview
                                                                emailMessage={campaign.emailMessage}
                                                                emailImage={campaign.emailImage}
                                                                emailImageLink={campaign.emailImageLink}
                                                                isOfferEnabled={campaign.isOfferEnabled}
                                                                offerDescription={campaign.offerDescription}
                                                                offerExpirationDate={campaign.offerExpirationDate as Date}
                                                            />
                                                        </div> :
                                                        <div>
                                                            <iframe
                                                                src={`/api/business/${selectedBusinessOption.value}/custom-campaigns/${campaign.campaignId}/advanced-email-preview`}
                                                                style={{
                                                                    flexGrow: 1,
                                                                    margin: 0,
                                                                    padding: 0,
                                                                    border: 'none',
                                                                    width: '100%',
                                                                    height: '86vh',
                                                                    backgroundColor: '#fff',
                                                                    position: 'relative',
                                                                    top: 0,
                                                                    left: 0,
                                                                }}
                                                            />
                                                        </div>
                                                    }
                                                </Tab>
                                            }

                                            {campaign.isAppNotificationEnabled &&
                                                <Tab eventKey="mobile"
                                                     title={<TabTitle text="Mobile"
                                                                      isEnabled={campaign.isAppNotificationEnabled}/>}>
                                                    <div className="bg-white pt-5 border-top">
                                                        <div style={{maxWidth: 300}} className="mx-auto">
                                                            <AppNotificationPreview
                                                                title={campaign.appNotificationTitle}
                                                                message={campaign.appNotificationBody}
                                                                hasOffer={campaign.isOfferEnabled}
                                                                greeting={campaign.templateVariables.appNotificationTemplate.greeting}
                                                                signOff={campaign.templateVariables.appNotificationTemplate.signOff}
                                                                signature={campaign.templateVariables.appNotificationTemplate.signature}
                                                                offerExpirationDate={campaign.offerExpirationDate as Date}
                                                                disabled={!campaign.isAppNotificationEnabled}
                                                            />
                                                        </div>
                                                    </div>
                                                </Tab>
                                            }
                                            {campaign.isTextEnabled &&
                                                <Tab eventKey="text"
                                                     title={<TabTitle text="Text" isEnabled={campaign.isTextEnabled}/>}>
                                                    <div className="bg-white pt-0 border-top">
                                                        <TextPreview message={campaign.textMessage}
                                                                     hasOffer={campaign.isOfferEnabled}
                                                                     greeting={campaign.templateVariables.textTemplate.greeting}
                                                                     signOff={campaign.templateVariables.textTemplate.signOff}
                                                                     signature={campaign.templateVariables.textTemplate.signature}
                                                                     offerExpirationDate={campaign.offerExpirationDate as Date}
                                                                     disabled={!campaign.isTextEnabled}
                                                                     censoredMessage={''}
                                                                     isTextWebLinkEnabled={campaign.isTextWebLinkEnabled}
                                                                     textWebLinkUrl={campaign.textWebLinkUrl}
                                                        >
                                                            <CampaignImagePreview url={campaign.textImage}
                                                                                  file={null}
                                                                                  description="text message image"
                                                                                  className="rounded"
                                                            />
                                                        </TextPreview>
                                                    </div>
                                                </Tab>
                                            }
                                        </Tabs>
                                    </SimpleModal>
                                </span>
                            </div>
                        </SimpleCard>
                        {campaign.isOfferEnabled &&
                            <SimpleCard title="Offer">
                                <Stack direction="vertical" gap={2}>
                                    <div>
                                        <span className="fw-lighter">Name</span>
                                        <span className="ms-2 fs-6 fw-light">
                                            {campaign.offerDescription}
                                        </span>
                                    </div>

                                    <div>
                                        <span className="fw-lighter py-1">Expiration</span>
                                        <span className="ms-2 fs-6 fw-light">
                                            {toShortDateString((campaign.offerExpirationDate as Date).toISOString())}
                                        </span>
                                    </div>

                                    <div>
                                        <span className="fw-lighter py-1">Available To New Customers?</span>
                                        <span className="ms-2 fs-6 fw-light">
                                            {campaign.isOfferAvailableToNewCustomers ? 'Yes' : 'No'}
                                        </span>
                                    </div>
                                </Stack>
                            </SimpleCard>
                        }
                        {campaign.isAudienceLocationFilterEnabled &&
                            <SimpleCard title="Audience">
                                <Stack direction="vertical" gap={2}>
                                    <div>
                                        <span className="fw-lighter py-1">Locations</span>
                                        <span className="ms-2 fs-6 fw-light">
                                            {campaign.audienceLocations.join(', ')}
                                        </span>
                                    </div>
                                </Stack>
                            </SimpleCard>
                        }
                    </Col>
                    <Col lg={9} md={6}>
                        <SimpleCard title="Email Click-through Performance" hasBodyPadding={false}>
                            <div className="p-0 m-0">
                                <If isTrue={campaign.urlPerformanceStats?.length > 0}
                                    className="ag-theme-balham"
                                    style={{height: 300, width: '100%'}}
                                >
                                    <AgGridReact
                                        defaultColDef={{sortable: true}}
                                        animateRows={true}
                                        rowData={campaign.urlPerformanceStats}
                                        columnDefs={clickThroughPerformanceColumnDefs}>
                                    </AgGridReact>
                                </If>

                                <If isTrue={!(campaign.urlPerformanceStats?.length > 0)}
                                    style={{height: 300, width: '100%'}}
                                >
                                    <div className="h-100 d-flex flex-column align-items-center justify-content-center">
                                        {campaign.isEmailEnabled ?
                                            <div>No links have been clicked yet.</div> :
                                            <div className="fs-5 fw-light pt-2 text-center text-muted"
                                                 style={{minHeight: '5rem'}}>
                                                n/a
                                            </div>
                                        }

                                    </div>
                                </If>
                            </div>
                        </SimpleCard>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
