import * as yup from 'yup';
import {validateZipCode} from '../regularExpressions';
import {isPossiblePhoneNumber} from 'libphonenumber-js';
import {InferType} from 'yup';

export const BusinessInfoSchema = yup.object().shape({
    businessName: yup.string()
        .max(255, 'Business Name should be 255 characters or fewer.')
        .required('Business Name is required.'),
    category: yup.number()
        .required('Category is required.'),
    email: yup.string()
        .email('Must be a valid email address.')
        .max(255, 'Email should be 255 characters or fewer.')
        .nullable(),
    phone: yup.string()
        .test('isValidPhoneNumber', 'Must be a valid phone number.', (value) => {
            return value ? isPossiblePhoneNumber(value) : true;
        }),
    address1: yup.string()
        .required('Address Line 1 is required.'),
    city: yup.string()
        .required('City is required.'),
    state: yup.string()
        .when('country', {
            is: 'USA',
            then: (schema) => schema
                .max(255, 'State should be 255 characters or fewer.')
                .required('State is required.'),
        }),
    province: yup.string()
        .max(255, 'Province/Region name should be 255 characters or fewer.'),
    country: yup.string()
        .max(255, 'Country should be 255 characters or fewer.')
        .required('Country is required.'),
    zip: yup.string()
        .required('Zip is required.')
        .when('country', {
            is: 'USA',
            then: (schema) => schema
                .matches(
                    validateZipCode,
                    'Must be a valid zip code, e.g. 12345 or 12345-6789.',
                ),
        }),
    website: yup.string()
        .nullable()
        .url('Website must be a valid URL.'),

    facebookUrl: yup.string()
        .nullable()
        .url('Facebook URL must be a valid URL.'),
    yelpProfileUrl: yup.string()
        .nullable()
        .url('Yelp URL must be a valid URL.'),
    instagramUrl: yup.string()
        .nullable()
        .url('Instagram URL must be a valid URL.'),
    twitterUrl: yup.string()
        .nullable()
        .url('Twitter URL must be a valid URL.'),
});

export interface BusinessInfoFormValues extends InferType<typeof BusinessInfoSchema> {
}
