import React from 'react';
import {Field, useField} from 'formik';
import {SketchPicker} from 'react-color';
import {FormControl, FormGroup, FormLabel, InputGroup, OverlayTrigger, Popover, Stack} from 'react-bootstrap';
import Feedback from 'react-bootstrap/Feedback';
import classNames from 'classnames';

interface Props {
    name: string;
    label: string;
    disabled: boolean;
    className: string;
}

function FormikColorPicker({
    name,
    label,
    disabled,
    className,
}: Props) {
    const [field, meta, helpers] = useField(name);
    const {value, touched, error} = meta;
    const {setValue} = helpers;

    return (
        <FormGroup controlId={name} className={classNames('mb-3', className)}>
            {label && <FormLabel>{label}</FormLabel>}
            <Stack direction="horizontal">
                <InputGroup>
                    <OverlayTrigger
                        trigger="click"
                        placement="right"
                        overlay={
                            <Popover className="p-0 m-0 border-0">
                                <Popover.Body className="p-0 m-0 border-0">
                                    <SketchPicker color={value}
                                                  disableAlpha={true}
                                                  onChangeComplete={(colorResult) => {
                                                      setValue(colorResult.hex);
                                                  }}/>
                                </Popover.Body>
                            </Popover>
                        }
                        rootClose={true}
                    >
                        <InputGroup.Text className=" clickable"
                                         style={{
                                             border: '3px solid rgba(64, 59, 96, 0.1)',
                                             width: 35,
                                             backgroundColor: value,
                                         }}
                        />
                    </OverlayTrigger>

                    <Field as={FormControl}
                           name={name}
                           disabled={disabled}
                           isInvalid={!!error && touched}
                           className="rounded-end"
                    />
                    <Feedback type="invalid">
                        {error}
                    </Feedback>
                </InputGroup>
            </Stack>
        </FormGroup>
    );
}

export default FormikColorPicker;
