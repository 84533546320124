import React, {useMemo} from 'react';
import {useField, useFormikContext} from 'formik';
import PhoneInput, {Country} from 'react-phone-number-input';
import {useAppContext} from '@/AppContext';
import {FormControl} from 'react-bootstrap';

export default function FormikPhone({...props}) {
    const {internationalization} = useAppContext();
    const {setFieldValue} = useFormikContext();
    // @ts-ignore
    const [field, meta] = useField(props);

    const countries = useMemo((): Country[] => {
        return Object.values(internationalization.phoneCountryCodes).map(x => x as Country);
    }, []);

    return (
        <>
            <PhoneInput
                name={field.name}
                international={false}
                addInternationalOption={false}
                countries={countries}
                defaultCountry="US"
                value={field.value}
                inputComponent={
                    FormControl
                }
                onChange={(value: string) => {
                    setFieldValue(field.name, value ?? '');
                }}
            />

            {/*This is a hack to get the react-bootstrap error message to appear*/}
            <input type="hidden" className={meta.error && meta.touched ? 'is-invalid' : ''}/>
        </>
    );
}
