import React, {useEffect, useRef, useState} from 'react';
import * as emojiMartData from '@emoji-mart/data';
import {Picker} from 'emoji-mart';
import {Button, Overlay} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFaceGrin} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

export interface Emoji {
    id: string;
    native: string;
}

interface Props {
    className?: string;
    standalone?: boolean;
    onEmojiSelect: Function;
}

function EmojiPicker({
    onEmojiSelect,
    standalone,
    className,
}: Props) {
    const ref = useRef(null);
    const target = useRef(null);
    const [show, setShow] = useState(false);
    const [picker, setPicker] = useState<Picker>();

    function onEmojiSelectInternal(emoji: Emoji) {
        onEmojiSelect(emoji);
        setShow(false);
    }

    useEffect(() => {
        setPicker(new Picker({
            onEmojiSelect: onEmojiSelectInternal,
            theme: 'light',
            emojiMartData,
            ref,
        }));
    }, [show]);

    return (
        <>
            <Button ref={target} onClick={() => setShow(!show)}
                    variant="secondary"
                    className={classNames(className, standalone ? '' : 'rounded-end')}>
                <FontAwesomeIcon icon={faFaceGrin} fixedWidth/>
            </Button>
            <Overlay target={target.current} show={show} placement="bottom">
                {({placement, arrowProps, show: _show, popper, ...props}) => (
                    <div
                        {...props}
                        style={{
                            position: 'absolute',
                            height: 200,
                            width: 200,
                            zIndex: 1000,
                            ...props.style,
                        }}
                    >
                        <div ref={ref}/>
                    </div>
                )}
            </Overlay>
        </>
    );
}

export default EmojiPicker;
