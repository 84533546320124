import React, {createRef, forwardRef} from 'react';
import DatePicker from 'react-datepicker';

interface DatePickerCustomInputRenderArgs {
    value?: string;
    onClick?: () => void;
}

const DatePickerCustomInput = forwardRef(
    ({value, onClick}: DatePickerCustomInputRenderArgs) => (
        <div className="calendar-icon text-primary" onClick={onClick}>{value}</div>
    ),
);
DatePickerCustomInput.displayName = 'DatePickerCustomInput';

interface Props {
    date: Date | null | undefined,
    onDateChange: (date: Date) => void;
}

export function DatePickerCalendar({date, onDateChange}: Props) {
    // we need to add a Dom ref to the new Component to avoid Dom reference Error
    const ref = createRef();

    return (
        <div className="datepicker mx-auto" style={{
            display: 'inline-block',
            fontSize: '1em',
            cursor: 'pointer',
        }}>
            <DatePicker
                selected={date}
                onChange={(newDate) => {
                    if (onDateChange) {
                        onDateChange(newDate!);
                    }
                }}
                customInput={<DatePickerCustomInput ref={ref}/>}
                dateFormat="MM/dd/yyyy"
            />
        </div>
    );
}
