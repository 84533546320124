import React from 'react';
import {Form, FormikProps} from 'formik';
import {Col, Container, Row, Stack} from 'react-bootstrap';
import {BackButton, CopyableUrl, FormikToggleInput, SubmitOnConfirmButton} from '@/components/form';
import {PageFooter, PageHeader, SimpleCard} from '@/components/container';
import {useAppContext} from '@/AppContext';
import {faAsterisk, faCheck, faXmark} from '@fortawesome/free-solid-svg-icons';
import {SignUpFormPreview} from '@/pages/Locations/components/SignUpFormPreview';
import {SignUpFormSettingsFormValues} from '@shared/requestSchemas';


export const SignUpFormSettingsForm = ({
    handleSubmit,
    errors,
    isSubmitting,
    resetForm,
    values,
    dirty,
}: FormikProps<SignUpFormSettingsFormValues>) => {
    const {selectedBusinessOption} = useAppContext();
    const excludeRadio = {label: 'Exclude', icon: faXmark, value: 0};
    const includeRadio = {label: 'Include', icon: faCheck, value: 1};
    const requireRadio = {label: 'Require', icon: faAsterisk, value: 2};
    const basicStateRadios = [
        excludeRadio,
        includeRadio,
    ];
    const allStateRadios = [
        ...basicStateRadios,
        requireRadio,
    ];

    return (
        <div className="mb-5">
            <PageHeader pageName={(
                <Stack direction="horizontal">
                    <div className="me-3">
                        <BackButton to="/locations"/>
                    </div>
                    <div>
                        <div>{values.locationName}</div>
                        <div className="text-muted align-text-bottom" style={{fontSize: '0.8rem'}}>
                            Sign-up Form
                        </div>
                    </div>
                </Stack>
            )}>
                <div className="mt-1">
                    <CopyableUrl url={values.signUpFormUrl}
                                 enabled={selectedBusinessOption.usesPublicSignUpForm}
                                 fileDownloadName={`Sign-up Form for ${values.locationName}`}
                    />
                </div>
            </PageHeader>
            <Form onSubmit={handleSubmit}>
                <Container fluid>
                    <Row>
                        <Col lg={5} md={6} className="ps-md-5 pt-3">
                            <SimpleCard title="Profile"
                                        className="mx-auto"
                                        style={{maxWidth: 500}}
                                        isUpgradeCalloutVisible={selectedBusinessOption.canUpgradeTo.canConfigureSignUpFormProfileFields}
                                        upgradeCalloutText="your plan to configure profile fields."
                            >
                                <FormikToggleInput name="mobileNumberState" label="Mobile Number"
                                                   disabled={!selectedBusinessOption.canConfigureSignUpFormProfileFields}
                                                   options={[requireRadio]}
                                                   staticLabel={requireRadio.label}
                                                   align="right"
                                />
                                <FormikToggleInput name="firstNameState" label="First Name"
                                                   disabled={!selectedBusinessOption.canConfigureSignUpFormProfileFields}
                                                   options={[requireRadio]}
                                                   staticLabel={requireRadio.label}
                                                   align="right"
                                />
                                <FormikToggleInput name="lastNameState" label="Last Name"
                                                   disabled={!selectedBusinessOption.canConfigureSignUpFormProfileFields}
                                                   options={allStateRadios}/>
                                <FormikToggleInput name="emailState" label="Email"
                                                   disabled={!selectedBusinessOption.canConfigureSignUpFormProfileFields}
                                                   options={allStateRadios}/>
                                <FormikToggleInput name="dateOfBirthState" label="Date of Birth"
                                                   disabled={!selectedBusinessOption.canConfigureSignUpFormProfileFields || !values.isDateOfBirthConfigurable}
                                                   options={allStateRadios}/>
                            </SimpleCard>

                            <SimpleCard title="Address"
                                        className="mx-auto"
                                        style={{maxWidth: 500}}
                                        isUpgradeCalloutVisible={selectedBusinessOption.canUpgradeTo.canConfigureSignUpFormAddressFields}
                                        upgradeCalloutText="your plan to collect customer addresses."
                            >
                                <FormikToggleInput name="addressLine1State" label="Address Line 1"
                                                   disabled={!selectedBusinessOption.canConfigureSignUpFormAddressFields}
                                                   options={allStateRadios}/>
                                <FormikToggleInput name="addressLine2State" label="Address Line 2"
                                                   disabled={!selectedBusinessOption.canConfigureSignUpFormAddressFields}
                                                   options={allStateRadios}/>
                                <FormikToggleInput name="cityState" label="City"
                                                   disabled={!selectedBusinessOption.canConfigureSignUpFormAddressFields}
                                                   options={allStateRadios}/>
                                <FormikToggleInput name="stateState" label="State / Province"
                                                   disabled={!selectedBusinessOption.canConfigureSignUpFormAddressFields}
                                                   options={allStateRadios}/>
                                <FormikToggleInput name="countryState" label="Country"
                                                   disabled={!selectedBusinessOption.canConfigureSignUpFormAddressFields}
                                                   options={allStateRadios}/>
                                <FormikToggleInput name="zipCodeState" label="Postal/Zip"
                                                   disabled={!selectedBusinessOption.canConfigureSignUpFormAddressFields}
                                                   options={allStateRadios}/>
                            </SimpleCard>

                            <SimpleCard title="Preferences"
                                        className="mx-auto"
                                        style={{maxWidth: 500}}
                                        isUpgradeCalloutVisible={selectedBusinessOption.canUpgradeTo.canConfigureSignUpFormPreferenceFields}
                                        upgradeCalloutText="your plan to configure preference fields."
                            >
                                <FormikToggleInput name="emailOptInState" label="Email Opt-in"
                                                   disabled={!selectedBusinessOption.canConfigureSignUpFormPreferenceFields}
                                                   options={basicStateRadios}/>
                                <FormikToggleInput name="textOptInState" label="Text Opt-in"
                                                   disabled={!selectedBusinessOption.canConfigureSignUpFormPreferenceFields}
                                                   options={basicStateRadios}/>
                            </SimpleCard>
                        </Col>

                        <Col md={{span: 6, offset: 1}} xs={12} className="pt-4 border-start"
                             style={{paddingLeft: '6rem'}}
                        >

                            <div className="w-50 h-100 d-none d-md-block position-fixed">
                                <SignUpFormPreview url={values.signUpFormUrl as string}
                                                   showRefreshNotice={dirty}
                                                   style={{
                                                       transform: 'scale(0.75, 0.70)',
                                                       transformOrigin: 'top left',
                                                   }}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>

                <PageFooter>
                    <SubmitOnConfirmButton disabled={isSubmitting}
                                           resetForm={resetForm}
                                           isSubmitting={isSubmitting}/>
                </PageFooter>
            </Form>
        </div>
    );
};
