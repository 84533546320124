import React, {useEffect, useMemo, useState} from 'react';
import {toShortDateString} from '@/services/textFormatting';
import appLogoIcon from '@images/app_logo_icon.svg';
import {useAppContext} from '@/AppContext';

interface Props {
    disabled: boolean;
    signOff: string;
    signature: string;
    greeting: string;
    title: string;
    message: string;
    hasOffer: boolean;
    offerExpirationDate: Date;
}

function AppNotificationPreview({
    disabled,
    signOff,
    signature,
    greeting,
    title,
    message,
    hasOffer,
    offerExpirationDate,
}: Props) {
    const {currentUser} = useAppContext();
    const [fullText, setFullText] = useState('');
    const firstName = useMemo(() => {
        return currentUser.firstName ?? 'Jeff';
    }, [currentUser]);

    useEffect(() => {
        const text = hasOffer && offerExpirationDate ?
            `${greeting} ${firstName}, ${message}. Valid until ${toShortDateString(offerExpirationDate.toISOString())} ${signOff} ${signature}` :
            `${greeting} ${firstName}, ${message} ${signOff} ${signature}`;
        setFullText(text);
    }, [message, hasOffer, offerExpirationDate]);

    return (
        <div className="mb-3 bg-light" style={{borderRadius: '20px'}}>
            <div className="ms-3 pt-2">
                <img src={appLogoIcon} alt="logo" style={{height: '20px'}}/>
                <span className="ms-2 h6 fw-bold">Loyalti</span>
            </div>
            <div style={{opacity: disabled ? 0.5 : 1}} className="p-3">
                <div className="fw-bold pb-2">{title}</div>
                <div>{fullText}</div>
            </div>
        </div>
    );
}

export default AppNotificationPreview;
