import React from 'react';
import {Cell, flexRender, RowData} from '@tanstack/react-table';

interface Props<TData> {
    cell: Cell<TData, unknown>;
    position: number;
}

export function DesktopData<TData extends RowData>({cell, position}: Props<TData>) {
    return (
        <td key={cell.id}
            className={position === 0 ? 'ps-3' : ''}
            style={{width: cell.column.getSize()}}
        >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </td>
    );
}
