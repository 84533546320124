import React from 'react';
import {ButtonGroup, FormLabel, Stack, ToggleButton} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconDefinition} from '@fortawesome/free-solid-svg-icons';
import {useField} from 'formik';

interface RadioOption {
    label: string;
    value: number;
    icon: IconDefinition;
}

interface Props {
    name: string;
    label: string;
    options: RadioOption[];
    disabled?: boolean;
    staticLabel?: string;
    align?: 'none' | 'right',
}

export function FormikToggleInput({
    name,
    label,
    options,
    disabled = false,
    staticLabel,
    align = 'none',
}: Props) {
    const [field, meta, helpers] = useField(name);
    const {value} = meta;
    const {setValue} = helpers;

    return (
        <>
            <Stack direction="horizontal" className="mb-2">
                <div className="w-50 fs-6 text-nowrap" style={{minWidth: 110}}>
                    <FormLabel className="pt-2">{label}</FormLabel>
                </div>
                <div className="w-100 border-bottom mx-2">
                    <div className="position-relative">
                        <div className="position-absolute top-50 start-50 translate-middle">
                            <span className="px-1 bg-white fw-lighter text-capitalize">{staticLabel ?? options[value]?.label}</span>
                        </div>
                    </div>
                </div>
                <div style={{minWidth: 92}}>
                    <ButtonGroup size="sm" style={{float: align}}>
                        {options.map((option, idx) => (
                            <ToggleButton
                                key={idx}
                                id={`radio-${name}-${idx}`}
                                type="radio"
                                variant="outline-primary"
                                name={name}
                                disabled={disabled}
                                value={value}
                                checked={value === option.value}
                                onChange={() => {
                                    setValue(option.value);
                                }}
                            >
                                <FontAwesomeIcon icon={option.icon}/>
                            </ToggleButton>
                        ))}
                    </ButtonGroup>
                </div>
            </Stack>
            <input name={name} defaultValue={value} type={'hidden'}/>
        </>
    );
}
