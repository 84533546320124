import React, {useEffect, useState} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLineChart} from '@fortawesome/free-solid-svg-icons';
import {useBusinessAnalyticsResource} from '@/services/api';
import {useAppContext} from '@/AppContext';
import classNames from 'classnames';

function Insights() {
    const businessAnalyticsResource = useBusinessAnalyticsResource();
    const {selectedBusinessOption} = useAppContext();
    const [isProcessing, setIsProcessing] = useState(true);
    const [insights, setInsights] = useState([]);

    useEffect(() => {
        setIsProcessing(true);
        businessAnalyticsResource.getInsights({})
            .then(({data}) => {
                setInsights(data);
            })
            .finally(() => setIsProcessing(false));
    }, [selectedBusinessOption]);

    if (isProcessing) {
        return null;
    }

    return (
        <Container className="mt-3" fluid>
            <Row>
                <Col>
                    <h2 className="pb-2"><FontAwesomeIcon icon={faLineChart} fixedWidth/> Insights</h2>
                </Col>
            </Row>

            <Row>
                {!isProcessing && insights.length === 0 && (<Col>No insights available.</Col>)}
                <Col>
                    {insights.map((insight, idx) => (
                        <div key={idx}
                             className={classNames(
                                idx === 0 ? 'pb-3' : 'py-3',
                                idx < insights.length - 1 ? 'border-bottom' : '',
                            )}>
                            <div>
                                <strong className="text-sm">{insight?.title}</strong>
                            </div>
                            <div className="text-sm">{insight?.details}</div>
                        </div>
                    ))}
                </Col>
            </Row>
        </Container>
    );
}

export default Insights;
