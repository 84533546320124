import React, {useMemo, useRef, useState} from 'react';
import {Badge, Container} from 'react-bootstrap';
import {useLocationResource} from '@/services/api';
import {PaginatedTable} from '@/components/table';
import {FadeIn} from '@/components/animation';
import {toShortDateTimeString} from '@/services/textFormatting';
import {useLoaderData, useSearchParams} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit, faList} from '@fortawesome/free-solid-svg-icons';
import {HelpTip, IconButton} from '@/components/form';
import {PaginatedTableFetchDataArgs} from '@/components/table/PaginatedTableFetchDataArgs';
import {PageHeader} from '@/components/container';
import {useAppContext} from '@/AppContext';
import {ColumnDef} from '@tanstack/react-table';
import {LocationListItem} from '@shared/responseModels/business';

function LocationList() {
    const loaderData = useLoaderData() as object[];
    const {selectedBusinessOption} = useAppContext();
    const locationResource = useLocationResource();
    const setSearchParams = useSearchParams()[1];
    const [data, setData] = useState(loaderData);
    const fetchIdRef = useRef(0);

    const columns = useMemo<ColumnDef<LocationListItem>[]>(() => [
        {
            accessorKey: 'id',
            header: 'Actions',
            size: 42,
            cell: ({getValue, row}) => (
                <>
                    {!!row.original.isActive &&
                        <HelpTip text="Edit">
                            <IconButton className="m-0"
                                        size="sm"
                                        variant="link"
                                        icon={<FontAwesomeIcon icon={faEdit} fixedWidth/>}
                                        to={`/locations/${getValue()}/edit`}/>
                        </HelpTip>
                    }
                    {selectedBusinessOption.usesPublicSignUpForm &&
                        <HelpTip text="Configure sign-up form">
                            <IconButton className="m-0"
                                        size="sm"
                                        variant="link"
                                        icon={<FontAwesomeIcon icon={faList} fixedWidth/>}
                                        to={`/locations/${getValue()}/sign-up-form`}/>
                        </HelpTip>
                    }
                </>
            ),
            enableSorting: false,
        },
        {
            accessorKey: 'isActive',
            header: 'Active',
            size: 50,
            enableSorting: false,
            cell: ({getValue}) => <Badge bg={getValue() ? 'success' : 'dark'}
                                         pill={true}>{getValue() ? 'Yes' : 'No'}</Badge>,
        },
        {
            accessorKey: 'name',
            header: 'Name',
            flex: 1,
        },
        {
            accessorKey: 'addressLine1',
            header: 'Address',
            flex: 1,
            cell: ({row}) => {
                const street = [row.original.addressLine1, row.original.addressLine2]
                    .filter(x => x).join(', ').trim();
                const province = [row.original.city, row.original.state]
                    .filter(x => x).join(', ').trim();
                const zipCode = row.original.zipCode;
                return (
                    <div>
                        <div>{street}</div>
                        <div>{province} {zipCode}</div>
                    </div>
                );
            },
        },
        {
            accessorKey: 'created',
            header: 'Created',
            size: 100,
            cell: ({getValue}) => toShortDateTimeString(getValue() as string),
        },
        {
            accessorKey: 'updated',
            header: 'Updated',
            size: 100,
            cell: ({getValue}) => toShortDateTimeString(getValue() as string),
        },
    ], []);

    const fetchData = async ({sortBy}: PaginatedTableFetchDataArgs) => {
        // Give this fetch an ID
        const fetchId = ++fetchIdRef.current;

        if (fetchId !== fetchIdRef.current) {
            return;
        }

        const queryParams = {
            sortByField: sortBy.length > 0 ? sortBy[0].id : 'name',
            sortByDesc: sortBy.length > 0 ? sortBy[0].desc : false,
        };

        // @ts-ignore
        setSearchParams(queryParams);

        const {data} = await locationResource.getList(queryParams);
        if (fetchId === fetchIdRef.current) {
            setData(data);
        }
    };

    return (
        <FadeIn>
            <PageHeader pageName="Locations"/>
            <Container fluid>
                <PaginatedTable
                    columns={columns as ColumnDef<object>[]}
                    data={data}
                    fetchData={fetchData}
                    showPagination={false}
                    type={'location'}
                />
            </Container>
        </FadeIn>
    );
}

export default LocationList;
