function makeKey(key: string) {
    return `SparkLoyalty::${key}`;
}

const cache = {
    get: (key: string) => {
        const payload = localStorage.getItem(makeKey(key));
        return payload !== null ? JSON.parse(payload) : null;
    },
    set: (key: string, obj: object) => localStorage.setItem(makeKey(key), JSON.stringify(obj)),
};

export default cache;
