import React from 'react';
import {Card} from 'react-bootstrap';
import {DateFormatter} from '@/services/textFormatting';
import {startCase} from 'lodash';

interface Props {
    active?: boolean;
    payload: { dataKey: string, value: number, payload: Record<'date', string> }[];
    dateFormatter: DateFormatter;
    date: string;
}

const CustomTooltip: React.FC<Props> = ({
    active = false,
    payload,
    dateFormatter,
}) => {
    if (!active || !payload || !payload.length) {
        return null;
    }

    return (
        <Card>
            <Card.Header>
                <Card.Title className="m-1 ps-2">{dateFormatter.format(payload[0].payload.date)}</Card.Title>
            </Card.Header>
            <Card.Body>
                <div>
                    {
                        payload?.map((value, index) => {
                            const label = startCase(value.dataKey);
                            const data = value.value;
                            return <p key={`${label}-${index}`}>{label}: {data}</p>;
                        })
                    }
                </div>
            </Card.Body>
        </Card>
    );
};

export default CustomTooltip;
