import React, {ChangeEvent, ReactElement, SetStateAction, useEffect, useMemo, useState} from 'react';
import {
    Alert,
    Button,
    Col,
    Container,
    FormControl,
    FormGroup,
    FormLabel,
    FormText,
    InputGroup,
    Row,
    Stack,
    Tab,
    Tabs,
} from 'react-bootstrap';
import {useAppContext} from '@/AppContext';
import {Field, Form, FormikErrors, FormikTouched, FormikValues} from 'formik';
import {
    ActOnConfirmButton,
    BackButton,
    EmojiPicker,
    FormikSelect,
    FormikSwitch,
    Input,
    ServerSideFormValidationErrors,
    SubmitOnConfirmButton,
    ValidationSummary,
} from '@/components/form';
import {If, PageFooter, PageHeader, SimpleCard} from '@/components/container';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrash, faWarning} from '@fortawesome/free-solid-svg-icons';
import TabTitle from '@/pages/Campaigns/components/TabTitle';
import classNames from 'classnames';
import {AppNotificationPreview, InlineHtmlEmailPreview, TextPreview} from '@/components/marketing';
import {useAutoCampaignResource, useSmsMessageContentResource} from '@/services/api';
import {SmsMessageContentValidationResponse} from '@shared/responseModels/business';
import FormRange from 'react-bootstrap/FormRange';
import {concatOrdinalSuffixToNumber, spliceTextAtCaretPosition} from '@/services/textFormatting';
import {Emoji} from '@/components/form/EmojiPicker';
import {DateTime} from 'luxon';
import {ValidationError} from '@/components/form/ValidationError';
import {AutoCampaignDetailsResponse} from '@shared/responseModels/business/AutoCampaignDetailsResponse';
import {useNavigate} from 'react-router';
import {UpgradeCallout} from '@/components/product';

interface Props<T> {
    isSubmitting: boolean;
    resetForm: () => void;
    values: FormikValues;
    errors: FormikErrors<T>;
    touched: FormikTouched<T>;
    setFieldValue: (field: string, value: unknown, shouldValidate?: boolean) => Promise<void | FormikErrors<T>>;
    handleChange: (event: ChangeEvent) => void;
}

const AutoCampaignDetailsForm = ({
    isSubmitting,
    resetForm,
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
}: Props<AutoCampaignDetailsResponse>) => {
    const autoCampaignResource = useAutoCampaignResource();
    const navigate = useNavigate();
    const [emailSubjectInput, setEmailSubjectInput] = useState(null);
    const [emailMessageInput, setEmailMessageInput] = useState(null);
    const [textMessageInput, setTextMessageInput] = useState(null);
    const [appNotificationTitleInput, setAppNotificationTitleInput] = useState(null);
    const [appNotificationBodyInput, setAppNotificationBodyInput] = useState(null);

    const smsMessageContentResource = useSmsMessageContentResource();
    const {selectedBusinessOption, selectOptions} = useAppContext();
    const offerExpirationDate = useMemo(() => DateTime.local().plus({days: values.offerValidForDays1}).toJSDate(), [values]);
    const [timer, setTimer] = useState<number | undefined>();
    const mergeTags = [
        {
            get: () => values.offerDescription1,
            tag: '{{incentive}}',
            name: 'Incentive',
            description: 'The name of the offer.',
        },
        {
            get: () => DateTime.fromJSDate(offerExpirationDate).toFormat('MMM D'),
            tag: '{{incentiveExpires}}',
            name: 'Incentive Expires',
            description: 'The date the offer expires.',
        },
        {
            get: () => selectedBusinessOption.label,
            tag: '{{businessName}}',
            name: 'Business Name',
            description: 'The name of your business.',
        },
    ];

    const textMessagePreview = useMemo(() => {
        if (!values.hasCustomTextMessage) {
            return values.textMessagePreview;
        }
        let messagePreview = values.textMessage;
        for (let i = 0; i < mergeTags.length; i++) {
            messagePreview = messagePreview.replaceAll(mergeTags[i].tag, mergeTags[i].get());
        }
        return messagePreview;
    }, [mergeTags, values.hasCustomTextMessage, values.textMessage, values.textMessagePreview]);

    const [
        textMessageValidation,
        setTextMessageValidation,
    ] = useState<SmsMessageContentValidationResponse>({
        hasViolations: false,
        verbatimViolations: [],
        censoredMessage: '',
    });

    useEffect(() => {
        validateTextMessageContent(values.textMessage);
    }, []);

    function validateTextMessageContent(textMessage: string) {
        smsMessageContentResource.validateSmsMessage(textMessage)
            .then(({data}: { data: SmsMessageContentValidationResponse }) => {
                setTextMessageValidation(data);
            });
    }

    async function onTextMessageChange(event: ChangeEvent | undefined) {
        const textMessage = (event?.target as HTMLInputElement).value;
        await setFieldValue('textMessage', textMessage);
        textMessageValidation.censoredMessage = '';
        setTextMessageValidation(textMessageValidation);
        clearTimeout(timer!);
        const newTimer = setTimeout(() => {
            validateTextMessageContent(textMessage);
        }, 500);

        setTimer(newTimer as unknown as number);
        return handleChange(event as ChangeEvent);
    }

    function onTextMessageValidate(_: string) {
        if (textMessageValidation.hasViolations) {
            return <div>
                Please remove these words, that are banned by carriers:
                <ul>
                    {
                        textMessageValidation.verbatimViolations.map((bannedWord) => {
                            return <li key={bannedWord}>{bannedWord}</li>;
                        })
                    }
                </ul>
            </div>;
        }
    }

    const triggerValueLabel = useMemo(() => {
        const milestoneTriggerValueLabel: Record<number, string> = {
            10: 'Lifetime Points',
            11: 'Visits',
        };
        return milestoneTriggerValueLabel[values.type];
    }, [values.type]);


    const triggerDescription = useMemo(() => {
        const triggerValue = values.triggerValue > 0 ? values.triggerValue : null;
        if (!triggerValue) {
            return null;
        }

        const milestoneTriggerDescription: Record<number, Record<string, ReactElement>> = {
            10: {
                'equals': <>Is sent to customers when they earn <strong>exactly {triggerValue}</strong> lifetime
                    points</>,
                'gte': <>Is sent to customers when they earn <strong>{triggerValue} or more</strong> lifetime points</>,
                'modulo': <>Is sent to
                    customers <strong>every {concatOrdinalSuffixToNumber(triggerValue)}</strong> point they earn</>,
            },
            11: {
                'equals': <>Is sent to customers when they have
                    checked-in <strong>exactly {triggerValue}</strong> times</>,
                'gte': <>Is sent to customers when they have checked-in <strong>{triggerValue} or
                    more</strong> times</>,
                'modulo': <>Is sent to customers
                    after <strong>every {concatOrdinalSuffixToNumber(triggerValue)}</strong> check-in.</>,
            },
        };

        return milestoneTriggerDescription[values.type][values.triggerOperator];
    }, [values.type, values.triggerOperator, values.triggerValue]);

    const onClickDeleteCampaign = () => {
        autoCampaignResource.delete(values.id).then(() => navigate('/campaigns/auto'));
    };

    return (
        <>
            <Form id="automated-campaign-details-form" noValidate>
                <PageHeader pageName={(
                    <>
                        <Stack direction="horizontal">
                            <div className="me-3">
                                <BackButton to="/campaigns/auto"/>
                            </div>
                            <div>
                                <Field name="isEnabled"
                                       className="fs-4 mb-1"
                                       component={FormikSwitch}
                                />
                            </div>
                            <div>
                                <label htmlFor="isEnabled" className="clickable mb-1">{values.name} Campaign</label>
                                <div className="text-muted align-text-bottom" style={{fontSize: '0.8rem'}}>
                                    {values.description}
                                </div>
                            </div>
                        </Stack>
                    </>
                )}>
                </PageHeader>
                <fieldset className="p-0">
                    <Container fluid>
                        <ServerSideFormValidationErrors/>

                        <Row>
                            <Col lg={3} xs={12} className="mt-md-5">
                                <SimpleCard title={values.hasSecondOffer ? 'Offer 1' : 'Offer'}>
                                    <Stack direction="vertical">
                                        <Field as={Input}
                                               label="Description"
                                               name="offerDescription1"
                                        />
                                        <FormGroup controlId="offerValidForDays1" className="mb-3 w-100">
                                            <FormLabel>
                                                <span className="me-1">Expires After</span>
                                                <span>
                                                    <strong
                                                        className="me-1">{values.offerValidForDays1}</strong>
                                                    <span>Day(s)</span>
                                                </span>
                                            </FormLabel>
                                            <Field as={FormRange}
                                                   name="offerValidForDays1"
                                                   min="1"
                                                   max="28"
                                                   step="1"
                                                   className="px-1"
                                            />
                                            <ValidationError fieldName="offerValidForDays1"/>
                                        </FormGroup>

                                    </Stack>
                                </SimpleCard>

                                {values.hasSecondOffer &&
                                    <SimpleCard title="Offer 2">
                                        <Stack direction="vertical">
                                            <Field as={Input}
                                                   label="Description"
                                                   name="offerDescription2"
                                            />
                                            <FormGroup controlId="offerValidForDays2"
                                                       className="mb-3 w-100">
                                                <FormLabel>
                                                    <span className="me-1">Expires After</span>
                                                    <span>
                                                        <strong
                                                            className="me-1">{values.offerValidForDays2}</strong>
                                                        <span>Day(s)</span>
                                                    </span>
                                                </FormLabel>
                                                <Field as={FormRange}
                                                       name="offerValidForDays2"
                                                       min="1"
                                                       max="28"
                                                       step="1"
                                                       className="px-1"
                                                />
                                                <FormControl.Feedback type="invalid">
                                                    {errors.offerValidForDays2}
                                                </FormControl.Feedback>
                                            </FormGroup>
                                        </Stack>
                                    </SimpleCard>
                                }

                                {values.hasTrigger &&
                                    <SimpleCard title="Trigger">
                                        <Stack direction="vertical">
                                            <FormGroup controlId="triggerOperator" className="mb-3"
                                                       as={Col}>
                                                <FormLabel>Condition</FormLabel>
                                                <Field component={FormikSelect}
                                                       name="triggerOperator"
                                                       options={selectOptions.milestoneTriggerOperator}
                                                       isInvalid={!!errors.triggerOperator}/>
                                                <FormControl.Feedback type="invalid">
                                                    {errors.triggerOperator}
                                                </FormControl.Feedback>
                                            </FormGroup>
                                            <Field as={Input}
                                                   label={triggerValueLabel}
                                                   name="triggerValue"
                                                   type="number"
                                            />
                                        </Stack>
                                        <div className="mx-3 mb-3">
                                            {triggerDescription}
                                        </div>
                                    </SimpleCard>
                                }

                                {values.hasRecurrence &&
                                    <SimpleCard title="Schedule">
                                        <Stack direction="vertical">
                                            <Field as={Input}
                                                   label="Days Between Sending"
                                                   name="recurrence"
                                                   type="number"
                                            />
                                            <Field as={Input}
                                                   label="Maximum Times to Send (per customer)"
                                                   name="recurrenceLimit"
                                                   type="number"
                                            />
                                        </Stack>
                                        <div className="mx-3 mb-3">
                                            {triggerDescription}
                                        </div>
                                    </SimpleCard>
                                }
                            </Col>
                            <Col lg={9} xs={12}>
                                <Tabs defaultActiveKey="email"
                                      variant="tabs"
                                      className="ms-4"
                                >
                                    <Tab eventKey="email"
                                         title={(
                                             <TabTitle isEnabled={true}
                                                       text="Email"
                                                       errors={errors}
                                                       touched={touched}
                                                       fields={[
                                                           'emailSubject',
                                                           'emailMessage',
                                                       ]}
                                             />
                                         )}
                                    >
                                        <div className="bg-white shadow">
                                            <fieldset className="mt-0 p-0"
                                                      disabled={!selectedBusinessOption.usesAutoEmailCampaigns}>
                                                <Container fluid className="border-bottom">
                                                    <Row>
                                                        <Col md={4} className="px-4 pt-3">
                                                            <FormGroup controlId="emailSubject" className="mb-4">
                                                                <FormLabel>Subject</FormLabel>
                                                                <InputGroup>
                                                                    <Field
                                                                        innerRef={(input: SetStateAction<null>) => setEmailSubjectInput(input)}
                                                                        as={FormControl}
                                                                        name="emailSubject"
                                                                        isInvalid={!!errors.emailSubject && touched.emailSubject}
                                                                        maxLength={100}
                                                                    />
                                                                    <EmojiPicker onEmojiSelect={(emoji: Emoji) => {
                                                                        setFieldValue(
                                                                            'emailSubject',
                                                                            spliceTextAtCaretPosition(emailSubjectInput, values.emailSubject, emoji.native),
                                                                        );
                                                                    }}/>
                                                                    <ValidationError fieldName="emailSubject"/>
                                                                </InputGroup>

                                                                <FormText>
                                                                    Characters
                                                                    remaining: {100 - values.emailSubject.length}
                                                                </FormText>
                                                            </FormGroup>

                                                            <FormGroup controlId="emailMessage" className="mb-4">
                                                                <FormLabel>Message</FormLabel>
                                                                <Field as="textarea"
                                                                       name="emailMessage"
                                                                       id="emailMessage"
                                                                       innerRef={(input: SetStateAction<null>) => setEmailMessageInput(input)}
                                                                       className={classNames(
                                                                           'form-control',
                                                                           !!errors.emailMessage && touched.emailMessage ? 'is-invalid is-invalid-textarea' : '',
                                                                       )}
                                                                       style={{height: 130, resize: 'none'}}
                                                                       maxLength={250}
                                                                />

                                                                <Stack direction="horizontal" gap={2}
                                                                       className="mt-2">
                                                                    <div>
                                                                        <FormText>
                                                                            Characters
                                                                            remaining: {250 - values.emailMessage.length}
                                                                        </FormText>
                                                                    </div>
                                                                    <div className="ms-auto">
                                                                        <EmojiPicker standalone
                                                                                     className="me-3"
                                                                                     onEmojiSelect={(emoji: Emoji) => {
                                                                                         setFieldValue(
                                                                                             'emailMessage',
                                                                                             spliceTextAtCaretPosition(emailMessageInput, values.emailMessage, emoji.native),
                                                                                         );
                                                                                     }}/>
                                                                    </div>
                                                                </Stack>

                                                                <ValidationError fieldName="emailMessage"/>
                                                            </FormGroup>
                                                        </Col>

                                                        <Col md={8}>
                                                            <InlineHtmlEmailPreview
                                                                emailMessage={values.emailMessage}
                                                                emailImage={''}
                                                                emailImageLink={''}
                                                                isOfferEnabled={true}
                                                                offerDescription={values.offerDescription1}
                                                                claimButtonText={values.emailClaimButtonText}
                                                                offerExpirationDate={offerExpirationDate}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </fieldset>
                                        </div>
                                    </Tab>

                                    <Tab eventKey="mobileNotification"
                                         title={(
                                             <TabTitle
                                                 isEnabled={values.isAppNotificationEnabled}
                                                 text="Mobile"
                                                 errors={errors}
                                                 touched={touched}
                                                 fields={[
                                                     'appNotificationTitle',
                                                     'appNotificationBody',
                                                 ]}
                                             />
                                         )}
                                    >
                                        <div className="bg-white shadow">
                                            <If isTrue={selectedBusinessOption.canUpgradeTo.usesAutoPushNotificationCampaigns}
                                                className="bg-primary text-white p-2"
                                            >
                                                <UpgradeCallout
                                                    canUpgradeTo={selectedBusinessOption.canUpgradeTo.usesAutoPushNotificationCampaigns}/>
                                            </If>
                                            <fieldset className="mt-0 p-0"
                                                      disabled={!selectedBusinessOption.usesAutoPushNotificationCampaigns}
                                            >
                                                <Container fluid>
                                                    <Row>
                                                        <Col md={4} className="px-4 pt-3">
                                                            <FormGroup controlId="appNotificationTitle"
                                                                       className="mb-3">
                                                                <FormLabel>Title</FormLabel>
                                                                <InputGroup>
                                                                    <Field
                                                                        innerRef={(input: SetStateAction<null>) => setAppNotificationTitleInput(input)}
                                                                        as={FormControl}
                                                                        name="appNotificationTitle"
                                                                        disabled={!values.isAppNotificationEnabled}
                                                                        isInvalid={!!errors.appNotificationTitle && touched.appNotificationTitle}
                                                                        maxLength={65}
                                                                    />
                                                                    <EmojiPicker onEmojiSelect={(emoji: Emoji) => {
                                                                        setFieldValue(
                                                                            'appNotificationTitle',
                                                                            spliceTextAtCaretPosition(appNotificationTitleInput, values.appNotificationTitle, emoji.native),
                                                                        );
                                                                    }}/>
                                                                    <ValidationError
                                                                        fieldName="appNotificationTitle"/>
                                                                </InputGroup>

                                                                <FormText>
                                                                    Characters
                                                                    remaining: {65 - values.appNotificationTitle.length}
                                                                </FormText>
                                                            </FormGroup>

                                                            <FormGroup controlId="appNotificationBody"
                                                                       className="mb-3 pt-3">
                                                                <FormLabel>Message</FormLabel>
                                                                <Field as="textarea"
                                                                       name="appNotificationBody"
                                                                       id="appNotificationBody"
                                                                       innerRef={(input: SetStateAction<null>) => setAppNotificationBodyInput(input)}
                                                                       className={classNames(
                                                                           'form-control',
                                                                           !!errors.appNotificationBody && touched.appNotificationBody ? 'is-invalid is-invalid-textarea' : '',
                                                                       )}
                                                                       style={{height: 130, resize: 'none'}}
                                                                       disabled={!values.isAppNotificationEnabled}
                                                                />

                                                                <Stack direction="horizontal" gap={2}
                                                                       className="mt-2">
                                                                    <div>
                                                                        <FormText>
                                                                            Characters
                                                                            remaining: {130 - values.appNotificationBody.length}
                                                                        </FormText>
                                                                    </div>
                                                                    <div className="ms-auto">
                                                                        <EmojiPicker standalone
                                                                                     className="me-3"
                                                                                     onEmojiSelect={(emoji: Emoji) => {
                                                                                         setFieldValue(
                                                                                             'appNotificationBody',
                                                                                             spliceTextAtCaretPosition(appNotificationBodyInput, values.appNotificationBody, emoji.native),
                                                                                         );
                                                                                     }}
                                                                        />
                                                                    </div>
                                                                </Stack>

                                                                <ValidationError fieldName="appNotificationBody"/>
                                                            </FormGroup>
                                                        </Col>

                                                        <Col md={8} className="pt-md-5 border-start">
                                                            <Row>
                                                                <Col md={{span: 6, offset: 3}}>
                                                                    <AppNotificationPreview
                                                                        title={values.appNotificationTitle}
                                                                        message={values.appNotificationBody}
                                                                        hasOffer={true}
                                                                        greeting={values.appNotificationGreeting}
                                                                        signOff={values.appNotificationSignOff}
                                                                        signature={values.appNotificationSignature}
                                                                        offerExpirationDate={offerExpirationDate}
                                                                        disabled={!values.isAppNotificationEnabled}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </fieldset>
                                        </div>
                                    </Tab>

                                    <Tab eventKey="sms"
                                         title={(
                                             <TabTitle
                                                 isEnabled={values.isTextEnabled}
                                                 text="Text"
                                                 errors={errors}
                                                 touched={touched}
                                                 fields={[
                                                     'textMessage',
                                                 ]}
                                             />
                                         )}
                                    >
                                        <div className="bg-white shadow">
                                            {selectedBusinessOption.canUpgradeTo.usesAutoSmsCampaigns && (
                                                <div className="bg-primary text-white p-2">
                                                    <UpgradeCallout
                                                        canUpgradeTo={selectedBusinessOption.canUpgradeTo.usesAutoSmsCampaigns}/>
                                                </div>
                                            )}

                                            <fieldset className="mt-0 p-0"
                                                      disabled={!selectedBusinessOption.usesAutoSmsCampaigns}
                                            >
                                                <Container fluid>
                                                    <Row>
                                                        <Col md={4} className="px-4 pt-3">
                                                            {values.hasDefaultTextMessage &&
                                                                <FormGroup controlId="hasCustomTextMessage"
                                                                           className="px-3">
                                                                    <Field label="Custom Message"
                                                                           name="hasCustomTextMessage"
                                                                           component={FormikSwitch}
                                                                    />
                                                                </FormGroup>
                                                            }

                                                            <fieldset disabled={!values.hasCustomTextMessage}>
                                                                <FormGroup controlId="textMessage" className="mb-3">
                                                                    <FormLabel
                                                                        className="d-none">Message</FormLabel>
                                                                    <Field as="textarea"
                                                                           id="textMessage"
                                                                           innerRef={(input: SetStateAction<null>) => setTextMessageInput(input)}
                                                                           name="textMessage"
                                                                           className={classNames(
                                                                               'form-control',
                                                                               !!errors.textMessage && touched.textMessage ? 'is-invalid is-invalid-textarea' : '',
                                                                           )}
                                                                           style={{height: 130, resize: 'none'}}
                                                                           onChange={onTextMessageChange}
                                                                           validate={onTextMessageValidate}
                                                                           disabled={!values.isTextEnabled}
                                                                    />

                                                                    <Stack direction="horizontal" gap={2}
                                                                           className="mt-2">
                                                                        <div>
                                                                            <FormText>
                                                                                Characters
                                                                                remaining: {130 - values.textMessage.length}
                                                                            </FormText>
                                                                        </div>
                                                                        <div className="ms-auto">
                                                                            <EmojiPicker standalone
                                                                                         className="me-3"
                                                                                         onEmojiSelect={(emoji: Emoji) => {
                                                                                             setFieldValue(
                                                                                                 'textMessage',
                                                                                                 spliceTextAtCaretPosition(textMessageInput, values.textMessage, emoji.native),
                                                                                             );
                                                                                         }}/>
                                                                        </div>
                                                                    </Stack>
                                                                    <ValidationError fieldName="textMessage"/>

                                                                    <FormText>
                                                                        <div>
                                                                            <div className="mb-3">
                                                                                <strong>Available Tags</strong>
                                                                            </div>
                                                                            <Stack direction="horizontal"
                                                                                   className="flex-wrap" gap={1}>
                                                                                {mergeTags.map((mergeTag) => (
                                                                                    <Button
                                                                                        variant="outline-secondary"
                                                                                        key={mergeTag.tag}
                                                                                        size="sm"
                                                                                        title={mergeTag.description}
                                                                                        onClick={async () => {
                                                                                            await setFieldValue(
                                                                                                'textMessage',
                                                                                                spliceTextAtCaretPosition(textMessageInput, values.textMessage, mergeTag.tag),
                                                                                            );
                                                                                            // @ts-ignore
                                                                                            textMessageInput.focus();
                                                                                        }}
                                                                                    >
                                                                                        {mergeTag.name}
                                                                                    </Button>
                                                                                ))}
                                                                            </Stack>
                                                                        </div>
                                                                    </FormText>
                                                                </FormGroup>
                                                            </fieldset>
                                                        </Col>
                                                        <Col md={8} className="pt-md-3 border-start">
                                                            <div>
                                                                <TextPreview
                                                                    message={textMessagePreview}
                                                                    isMessagePreRendered={!values.hasCustomTextMessage}
                                                                    shouldShowSignOff={!values.hasCustomTextMessage}
                                                                    hasOffer={true}
                                                                    greeting={values.textGreeting}
                                                                    signOff={values.textSignOff}
                                                                    signature={values.textSignature}
                                                                    offerExpirationDate={offerExpirationDate}
                                                                    disabled={!values.isTextEnabled}
                                                                    censoredMessage={textMessageValidation.censoredMessage}
                                                                    isTextWebLinkEnabled={false}
                                                                    textWebLinkUrl=""
                                                                />
                                                            </div>

                                                            <div className="mt-0 pt-0">
                                                                <div
                                                                    className="text-danger fs-6 fw-lighter form-tex mx-5 mb-3">
                                                                    <em>
                                                                        *Messages with flagged words are blocked by
                                                                        phone
                                                                        carriers
                                                                        and need
                                                                        to be removed.
                                                                    </em>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </fieldset>
                                        </div>
                                    </Tab>
                                </Tabs>
                            </Col>
                        </Row>
                    </Container>

                    <PageFooter>
                        <Stack direction="horizontal" gap={3}>
                            <SubmitOnConfirmButton disabled={isSubmitting}
                                                   resetForm={() => resetForm()}
                                                   formName="automated-campaign-details-form"
                                                   isSubmitting={isSubmitting}/>

                            {(values.hasTrigger && values.id > 0) &&
                                <ActOnConfirmButton buttonVariant="outline-secondary"
                                                    text="Delete"
                                                    icon={<FontAwesomeIcon icon={faTrash} fixedWidth/>}
                                                    confirmButtonVariant="danger"
                                                    confirmButtonText="Yes, Delete"
                                                    onConfirm={onClickDeleteCampaign}
                                />
                            }

                            <ValidationSummary/>
                        </Stack>
                    </PageFooter>
                </fieldset>
            </Form>
        </>
    );
};

export default AutoCampaignDetailsForm;
