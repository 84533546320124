import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useField} from 'formik';
import {unlayerEmailTemplateFactory} from '@/services';
import EmailEditor, {FileInfo, FileUploadDoneCallback, MergeTag} from 'react-email-editor';
import {useCampaignImagesResource} from '@/services/api';
import imageCompression from 'browser-image-compression';
import {useAppContext} from '@/AppContext';

function HtmlField({
    name,
    htmlValue,
    setHtmlValue,
}: {
    name: string,
    htmlValue: string,
    setHtmlValue: (value: string) => void,
}) {
    const field = useField(name);
    const fieldHelpers = field[2];

    useEffect(() => {
        fieldHelpers.setValue(htmlValue);
        setHtmlValue(htmlValue);
    }, [htmlValue]);

    return null;
}

interface Props {
    isClaimButtonAvailable: boolean;
    htmlFieldName: string;
    designFieldName: string;
    templateVariables: Record<string, string | number>;
}

function UnlayerEmailEditor
({
    isClaimButtonAvailable,
    htmlFieldName,
    designFieldName,
    templateVariables,
}: Props) {
    const campaignImagesResource = useCampaignImagesResource();
    const {constants, currentUser} = useAppContext();
    const designField = useField(designFieldName);
    const fieldMeta = designField[1];
    const fieldHelpers = designField[2];
    const {value} = fieldMeta;
    const {setValue} = fieldHelpers;

    const emailEditorRef = useRef(null);
    const [htmlFieldValue, setHtmlFieldValue] = useState<string>();
    const [isInitialized, setIsInitialized] = useState<boolean>(false);

    const loadDesignFromValue = useCallback((editor: { loadDesign: (design: object) => void }) => {
        const parsedValue = value;
        if (Object.keys(parsedValue ?? {}).length > 0) {
            editor.loadDesign(parsedValue);
        } else {
            const template = unlayerEmailTemplateFactory(templateVariables);
            editor.loadDesign(template);
        }
    }, [value]);

    const onReady = () => {
        // @ts-ignore
        const editor = emailEditorRef?.current?.editor;
        if (!editor) {
            return;
        }

        if (isInitialized) {
            return;
        }

        setIsInitialized(true);

        // --------------------------------------------------------------------
        // Register custom storage backend.
        // --------------------------------------------------------------------
        editor.registerCallback('image', async function(fileInfo: FileInfo, done: FileUploadDoneCallback) {
            const file = fileInfo.accepted[0];

            if (!file) {
                done({progress: 100});
                return;
            }

            // The upload endpoint doesn't support progress, so fake it with a 1% progress indicator.
            done({progress: 0.1});

            const compressedFile = await imageCompression(file, {
                maxSizeMB: constants.defaultMaxImageFileSizeInMegabytes,
            });

            const formData = new FormData();
            formData.append('file', compressedFile, file.name);
            const {data} = await campaignImagesResource.create(formData);
            done({progress: 100, url: data.url});
        });

        // --------------------------------------------------------------------
        // Load the unlayer design, and update the form field when it changes.
        // --------------------------------------------------------------------
        loadDesignFromValue(editor);
        editor.exportHtml(({html}: { html: string }) => {
            setHtmlFieldValue(html);
        });

        editor.addEventListener('design:updated', () => {
            // @ts-ignore
            editor.saveDesign((design) => {
                setValue(design);
            });

            editor.exportHtml(({html}: { html: string }) => {
                setHtmlFieldValue(html);
            });
        });
    };

    return (
        <div className="mb-3">
            <HtmlField name={htmlFieldName}
                       htmlValue={htmlFieldValue ?? ''}
                       setHtmlValue={setHtmlFieldValue}
            />
            <EmailEditor ref={emailEditorRef}
                         style={{height: 600}}
                         onReady={onReady}
                         projectId={constants.unlayerProjectId}
                         tools={{
                    'custom#claim_offer': {
                        enabled: isClaimButtonAvailable,
                    },
                }}
                         options={{
                    customJS: [`${window.location.protocol}//${window.location.host}/assets/unlayerTools.js`],
                    mergeTags: [
                                 {
                                     name: 'First Name',
                                     value: '{{firstName}}',
                                     sample: 'Jeff',
                                 } as MergeTag,
                                 {
                                     name: 'Claim Offer Link',
                                     value: '{{claimLink}}',
                                     sample: 'https://example.com#claimOffer',
                                 } as MergeTag,
                                 {
                                     name: 'Unsubscribe Link',
                                     value: '{{unsubscribeLink}}',
                                     sample: 'https://example.com#unsubscribe',
                                 } as MergeTag,
                    ],
                    user: {
                        id: currentUser.merchantId,
                        signature: currentUser.merchantUnlayerSignature,
                    },
                }}
            />
        </div>
    );
}

export default UnlayerEmailEditor;
