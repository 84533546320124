import React, {PropsWithChildren} from 'react';
import classNames from 'classnames';

interface Props {
    isCompact?: boolean;
}

function CardHeaderTitle({isCompact = false, children}: PropsWithChildren<Props>) {
    return (
        <div className={classNames(
            'h5 fw-bold',
            isCompact ? 'm-0 mt-1 ms-3 p-0' : 'mt-2 me-0 mb-0 pb-1 ms-3')
        }>{children}</div>
    );
}

export default CardHeaderTitle;
