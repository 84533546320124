import React from 'react';
import {FadeIn} from '@/components/animation';
import {Outlet} from 'react-router-dom';

export function CustomersLayout() {
    return (
        <FadeIn>
            <Outlet/>
        </FadeIn>
    );
}
