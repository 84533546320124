import React from 'react';
import {UpgradePlanModal} from '@/components/product/UpgradePlanModal';

interface Props {
    text?: string;
    canUpgradeTo: boolean|null;
}

export function UpgradeCallout({text, canUpgradeTo}: Props) {
    return canUpgradeTo ? (
        <UpgradePlanModal variant="primary"
                          buttonClassNames="fs-5"
                          additionalButtonText={text ?? 'your plan and get access to this powerful loyalty feature.'}
        />
    ) : null;
}
